import React, { ChangeEvent } from 'react';
 // Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IFilteredService } from '../../../components/src/interfaces.web';
export const configJSON = require("./config");

interface S{
  selectedService: IFilteredService
  isOpen: {
    general: boolean,
    commodities: boolean,
    services: boolean,
    spaces: boolean,
    rooms: boolean,
    priceH: boolean,
    priceV: boolean,
  }
}
export interface Props {
    // Customizable Area Start
    selectedService: IFilteredService;
    getServicesList:(id:string) => void;
    // Customizable Area End
  }

export interface Ss {
}
 export default class GeneralInformationController extends BlockComponent<Props,S,Ss> {
  constructor(props:any) {
    super(props);
    this.state = {
      selectedService: this.props.selectedService,
      isOpen: {
        general: false,
        commodities: false,
        services: false,
        spaces: false,
        rooms: false,
        priceH: false,
        priceV: false,
      }
    }
  }

   async componentDidMount() {
     this.setState({
       selectedService: this.props.selectedService
     })
   }

   componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: Ss | undefined): void {
     if (prevProps.selectedService !== this.props.selectedService) {
       this.resetMountStates()
     }
   }

   resetMountStates = () => {
     this.setState({
       isOpen: {
         general: false,
         commodities: false,
         services: false,
         spaces: false,
         rooms: false,
         priceH: false,
         priceV: false,
       },
       selectedService: this.props.selectedService
     })
   }
   
   setOpenAccordian = (key: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    event.stopPropagation()
    event.preventDefault()
     this.setState({
       isOpen: {
         ...this.state.isOpen,
         [key]: isExpanded
       }
     })
   }
   closePricesSections = () => {
     this.setState({
       isOpen: {
         ...this.state.isOpen,
         priceH: false,
         priceV: false
       }
     })
   }
 }
    // Customizable Area End