const MAP_WIDTH = 325; 
const MAP_HEIGHT = 375;

function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
}

export function getDeltaValues(km: number, atLatitude: number) {
    const earthCircumference = 40075;
    const latitudePerKm = 360 / earthCircumference;
    const longitudePerKm = 360 / (earthCircumference * Math.cos((atLatitude * Math.PI) / 180));
    const latDelta = km * latitudePerKm;
    const longDelta = km * longitudePerKm;

    return { latDelta, longDelta }
}

export function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; 
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
}

