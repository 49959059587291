import React from "react";
// Customizable Area Start
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  Paper,
  Typography,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import pluralize from "pluralize";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { CustomSelectField } from "../../../components/src/CustomSelectField";
import { LandingPageHeader } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import WebMapView from "../../../components/src/WebMapView";
import Maps from "../../../blocks/maps/src/Maps";
import Filters from "./components/FilterSection";
import ListItem from "./components/ListItem";
import { noResults } from "./assets";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    const handleRenderContent = () => {
      if(this.state.isLoading) {
        return (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )
      } else if (this.state.searchItems && this.state.searchItems.length) {
        return (
          <>
            {this.state.searchItems?.map((item) => (
              <ListItem 
                item={item} 
                key={item.id} 
                searchData={this.state.searchData} 
                onSeeOffer={this.handleSeeOffer} 
                MapButton={<Maps id={this.props.id} navigation={this.props.navigation } searchItems={this.state.searchItems} />}/>
            ))}
          </>
        )
      } else {
        return (
          <Box className="no-results-wrapper">
            <img src={noResults} />
            <Typography className="no-results-text">No results found!</Typography>
          </Box>
        )
      }
    }

    return (
      <>
        <AppHeader navigation={this.props.navigation} />
        <LandingPageHeader
          data-test-id="page-header"
          tabValue={this.state.searchTab}
          onTabChange={(_, value) =>
            this.setState({ searchTab: Number(value) })
          }
          SearchDataFromHeader={(data) => this.makeSearch(data)}
          petList={this.state.petsList}
          hideLabel={true}
        />
        <PageWrapper maxWidth="xl" data-test-id="advanced-search-wrapper">
          <Box className="sort-wrapper">
            <Typography className="result-text">
                {`Showing ${pluralize(
                  "result",
                  this.state.searchItems?.length || 0,
                  true
                )}`}
            </Typography>
            <SelectWrapper style={{ width: "190px" }} elevation={3}>
              <FormControl fullWidth>
                <CustomSelectField
                  id="sort-options"
                  name="sort-options"
                  onChange={(e) =>
                    this.handleChangeSortOrder(e.target.value)
                  }
                  value={this.state.sortOption}
                  list={[
                    ...(this.state.currrentCords ? [{ label: "Closest to me", value: "closest" }] : []),
                    { label: "Low to High", value: "asc" },
                    { label: "High to low", value: "desc" },
                  ]}
                  paperProps={{ width: "195px" }}
                  fullWidth
                />
              </FormControl>
            </SelectWrapper>
          </Box>
          <Box className="content">
            <Box className="map-filter-block">
              <Box className="map-wrapper">
                <WebMapView
                  center={this.state.region}
                  searchItems={this.state.searchItems}
                />
                <Box className="view-map-btn">
                  <Typography>View map</Typography>
                </Box>
              </Box>
              <Box className="filters">
                <Filters
                  type={this.state.searchTab}
                  values={this.state.filters}
                  onChange={this.changeFilterValue}
                />
              </Box>
            </Box>
            <Box className="list-block">
              {handleRenderContent()}
            </Box>
          </Box>
        </PageWrapper>
        {/* <Maps id={this.props.id} navigation={this.props.navigation} /> */}
        <AppFooter />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const SelectWrapper = styled(Paper)({
  padding: "0 12px",

  "& .MuiInputBase-root.MuiInput-root.MuiInput-underline": {
    background: '#FBFBFB',
    borderRadius: '4px',
    
    "&:before": {
      borderBottom: "none",
    },
  },
  "& .MuiList-root.MuiMenu-list.MuiList-padding": {
    padding: 0,
    width: "195px",
  },
});

const LoaderWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const PageWrapper = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "46px",
  paddingBottom: "50px",
  paddingTop: "90px",
  width: "calc(100% - 280px)",
  paddingLeft: 0,
  paddingRight: 0,

  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 170px)",
  },
  [theme.breakpoints.down("xs")]: {
    width: "calc(100% - 40px)",
  },
  "& *": {
    boxSizing: "border-box",
  },

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: "Montserrat",
  },
  "& .content": {
    display: "flex",
    flexDirection: "column",
    gap: "46px",

    [theme.breakpoints.up(960)]: {
      flexDirection: "row",
    },
  },
  "& .filters": {
    width: "100%",
    background: "#F7F7F7",
    display: "flex",
    padding: "32px",
    borderRadius: "8px",
  },
  "& .sort-wrapper": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .result-text": {
    color: "#6F05EA",
    fontWeight: 700,
    fontSize: "16px",
  },
  "& .view-map-btn": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    padding: "10px 16px",
    borderRadius: "8px",
    color: "#6F05EA",
    fontSize: "16px",
    fontWeight: 600,
    cursor: "pointer",
  },

  "& .no-results-wrapper": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px'
  },

  "& .no-results-text": {
    color: '#B7B7B7',
    fontSize: '32px',
    fontWeight: 600
  },

  "& .map-filter-block": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    [theme.breakpoints.up(960)]: {
      width: "325px",
    },
  },

  "& .map-wrapper": {
    position: "relative",
  },

  "& .list-block": {
    width: "100%",
    display: "flex",
    gap: "40px",
    flexDirection: "column",
  },
}));

// Customizable Area End
