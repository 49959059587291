
import { BlockComponent } from "../../../framework/src/BlockComponent";


// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilteredService, IService } from "../../../components/src/interfaces.web";
import { checkCondition, returnTruthyString } from "../../../components/src/HelperUtils";

export const configJSON = require("./config");

// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  apiToken: string;
  currentSettingsTab:number;
  isSideBarOpen:boolean;
  servicesList: IService[];
  filtredServicesList: IFilteredService[];
  selectedService: IFilteredService;
  addServiceModalOpen: boolean;
  tempSelectedService: IFilteredService;
  openSetting:boolean;
  openRequestDetails:boolean;
  selectedRequestId:IFilteredService;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesVenderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiServicesListCallId: string = ""; 

  // Customizable Area End
  constructor(props: Props) {
    super(props);
   
      // Customizable Area Start
      
      this.receive = this.receive.bind(this);
      this.subScribedMessages = [
        getName(MessageEnum.AlertMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.RestAPIResponceMessage),
      ];
      // Customizable Area End
   

    this.state = {
      // Customizable Area Start
      apiToken: localStorage.getItem("login_token") || "" ,
      currentSettingsTab:0,
      isSideBarOpen:false,
      servicesList: [],
      filtredServicesList: [],
      selectedService: {
        name: "",
        service_id: "",
      },
      addServiceModalOpen: false,
      tempSelectedService: {
        name: "",
        service_id: "",
      },
      openSetting:false,
      openRequestDetails:false,
      selectedRequestId:{
        name: "",
        service_id: "",
      },
        // Customizable Area End
    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
   
  }

    // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiServicesListCallId) {
        this.handleServiceListResp(responseJson)
      }
      
    }
  }
    // Customizable Area End
  


  // Customizable Area Start
  handleServiceListResp = (responseJson: { data: IService[] }) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({
        servicesList: responseJson.data,
        filtredServicesList: responseJson.data.map(record => {
          return {
            name: returnTruthyString(record.attributes.service_type),
            service_id: returnTruthyString(record.attributes.id),
          }
        })
      })
      if (this.state.selectedService.name) {
        const newServiceob = responseJson.data.find((record) => String(record.attributes.service_type) == String(this.state.selectedService.name))
        this.setState({
          selectedService: {
            name: this.state.selectedService.name,
            service_id: String(newServiceob?.attributes.id)
          }
        })
      }
      if (!this.state.selectedService.name && !this.state.selectedService.service_id) {
        this.setState({
          selectedService: {
            name: returnTruthyString(responseJson.data[0].attributes.service_type),
            service_id: returnTruthyString(responseJson.data[0].attributes.id),
          }
        })
      }
    } else {
      this.setState({
        servicesList: [],
        filtredServicesList: [],
        selectedService: {
          name: "",
          service_id: ""
        }
      })
    }
  }


  async componentDidMount() {
    const establishment_id = checkCondition(Boolean(localStorage.getItem("establishment_ID")),localStorage.getItem("establishment_ID"),"")as string
    this.getServicesList(establishment_id)
  }



  // Customizable Area End



  // Customizable Area Start
  setTabId=(id:number)=>{
    this.setState({ currentSettingsTab: id })
  }
  handleSetting = () => {
    this.setState({
      openSetting: true,
      // RESET OTHER STATES
      openRequestDetails: false,
      selectedService: {
        name: "",
        service_id: ""
      }
    })
  }
  handleRequestScreen = () =>{
    this.setState({openRequestDetails:!this.state.openRequestDetails})
  }


  toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    this.setState({ isSideBarOpen: open });
  };

  getServicesList = (establishment_id: string) => {
    if (!establishment_id) return
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const header = {
      token: this.state.apiToken,
    };

    const endpoint = `account_block/services?service[establishment_id]=${establishment_id}`
    this.apiServicesListCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };
 
  handleSetService = (service_name: string, service_id: string) => {
    this.setState({
      selectedService: {
        name: service_name,
        service_id: service_id,
      }, openRequestDetails:false,
      openSetting:false
    })
    
    this.setTabId(0);
  }
  handleSetRequestId=(selectedRequestId:string)=>{
    this.setState({
      selectedRequestId:{
        name:'',
        service_id:selectedRequestId
      },
      openRequestDetails:!this.state.openRequestDetails,
      openSetting:false
    })
  }
  openServiceModal = () => {
    this.setState({
      addServiceModalOpen: true
    })
  }
  closeServiceModal = () => {
    this.setState({
      addServiceModalOpen: false
    })
  }
  nextButtonModal = (tempService: IFilteredService) => {
    const newServiceList = [...this.state.filtredServicesList, { ...tempService }]
    this.setState({
      filtredServicesList: newServiceList,
      selectedService: tempService
    })
    this.setTabId(3);
  }
 
  // Customizable Area End
}
