import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { getCountryCode } from "countries-list";
import { ICountryModel } from "../../../blocks/email-account-registration/src/EmailAccountRegistrationController";
import { ISearchModel } from "../../../blocks/landingpage/src/LandingPageController";
import { useSearchHeaderContext } from "../../../components/src/context/SearchHeaderContext";
import InputField from "../InputField";
import CommonSelect from "../SelectField";
import {
  CalendarIcon,
  CloseIcon,
  DayCareIcon,
  DogWalkingIcon,
  FilterIcon,
  FootPrintIcon,
  GroomingIcon,
  HomeIcon,
  LocationIcon,
  NextArrowIcon,
  PetSittingIcon,
  RepeatIcon,
  RepeatPurpleIcon,
  TrainingIcon,
} from "./assets";

interface PetCategory {
  label: string;
  value: string;
}

export interface SearchData {
  country?: string;
  city?: string;
  start_date?: Date;
  end_date?: Date;
  your_pet?: string;
  everyWeek?: number;
  everyDay?: string;
  endAfter?: Date;
  service_type?: string;
}

interface HeaderProps {
  tabValue: number;
  onTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  SearchDataFromHeader: (data: SearchData) => void;
  isRepeatShow?: boolean;
  petList?: PetCategory[];
  hideLabel?: boolean;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const LandingPageHeaderContainer = styled(Box)(({ theme }) => ({
  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: "Montserrat",
  },
  "& .MuiTabScrollButton-root.MuiTabs-scrollButtons": {
    color: '#fff'
  },
  "& .MuiTabScrollButton-root.MuiTabs-scrollButtons:hover": {
    color: '#fff'
  },
  "& .header-title": {
    background: "linear-gradient(to right, #6928F2, #884FFF)",
    padding: "40px 139px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 85px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 20px",
    },
  },
  "& .header-title-text": {
    fontSize: "44px",
    color: "#fff",
    width: "450px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      width: "320px",
    },
  },
  "& .header-title-text-span": {
    color: "#FFB800",
    fontSize: "44px",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  "& .tab": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    opacity: 1,
    paddingTop: '25px',
    fontSize: "12px",
    minHeight: "auto",
    "& > .MuiTab-wrapper": {
      flexDirection: "row",
    },
  },
  "& .tabs": {
    font: "Montserrat",
    textTransform: "none",
    marginBottom: "35px",
    [theme.breakpoints.between("md", 1635)]: {
      marginBottom: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  "& .tabIcon": {
    marginRight: theme.spacing(1),
    height: "24px",
    width: "24px",
  },
  "& .container": {
    backgroundColor: "#fff",
    borderRadius: "15px",
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[2],
  },
  "& .widgetContainer": {
    position: "absolute",
    bottom: "-125px",
    padding: "0px 140px",
    width: "calc(100% - 280px)",
    left: 0,
    [theme.breakpoints.down("md")]: {
      padding: "0px 85px",
      width: "calc(100% - 170px)",
      bottom: "-115px",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "-48px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px",
      width: "calc(100% - 40px)",
    },
  },
  "& .widgetContainerWithoutRepeat": {
    position: "absolute",
    bottom: "-48px !important",
    padding: "0px 140px",
    width: "calc(100% - 280px)",
    left: 0,
  },
  "& .widgetContainerOpen": {
    position: "absolute",
    bottom: "-86px",
    padding: "0px 140px",
    width: "calc(100% - 280px)",
    zIndex: 10,
    left: 0,
    top: 0,
    [theme.breakpoints.down("md")]: {
      padding: "0px 85px",
      width: "calc(100% - 170px)",
      bottom: "-188px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px",
      width: "calc(100% - 40px)",
      bottom: "-256px",
    },
  },
  "& .fieldContainer": {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
  },
  "& .widgetTextContainer": {
    display: "flex",
    flexDirection: "column",
  },
  "& .widgetTextContainer:not(:last-child)": {
    borderRight: "1px solid #CCCCCC",
  },
  "& .icon": {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  "& .button": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#6F05EA",
    boxShadow: "none",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6F05EA",
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px 25px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 75px",
      fontSize: "14px",
    },
  },
  "& .textField": {
    width: "120px",
    textAlign: "center",
  },
  "& .widgetTitleText": {
    fontSize: "13px",
    color: "#A6A6A6",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  "& .widgetContent": {
    display: "flex",
    gap: 4,
    alignItems: "center",

    "& img": {
      marginBottom: '4px'
    }
  },
  "& .widgetImage": {
    height: "16px",
    width: "16px",
    [theme.breakpoints.down("md")]: {
      height: "14px",
      width: "14px",
    },
  },
  "& .widgetText": {
    fontSize: "18px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  "& .widgetSelectedDay": {
    backgroundColor: "#6F05EA",
    color: "#fff",
    border: "1px solid #6F05EA",
  },
  "& .widgetNotSelect": {
    border: "1px solid #E2E8F0",
  },
  "& .widgetDay": {
    fontSize: "14px",
    borderRadius: "6px",
    height: "34px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      height: "32px",
      width: "30px",
    },
  },
  "& .repeatContainer": {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "2px 10px",
    borderRadius: "100px",
    justifyContent: "center",
  },
  "& .repeatSelect": {
    border: "1px solid #6F05EA",
  },
  "& .repeatNotSelect": {
    border: "1px solid #B7B7B7",
  },
  "& .repeatSelectText": {
    color: "#6F05EA",
    fontSize: "11px",
  },
  "& .repeatText": {
    color: "#B7B7B7",
    fontSize: "11px",
  },
  "& .reset-close-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .reset-close-img": {
    height: "20px",
    width: "20px",
  },
  "& .reset-filter-text": {
    color: "#6200EA",
    fontSize: "16px",
  },
  "& .filter-icon-btn": {
    "&:hover": {
      color: "#6F05EA",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  "& .render-responsive-widget-container": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "contents",
    },
  },
  "& .render-widget-container": {
    display: "contents",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .service-repeat-container": {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "168px !important",
    },
  },
  "& .is-repeat-service-repeat-container": {
    width: "170px",
    [theme.breakpoints.between("md", 1635)]: {
      width: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
  },
  "& .search-btn-container": {
    display: "flex",
    margin: "20px auto",
  },
  "& .widgetAnimalSelect": {
    "& .MuiSelect-icon": {
      display: "none",
    },
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
    },
    "& .MuiMenuItem-root": {
      fontSize: "12px !important",
    },
    "& .MuiInput-underline::before": {
      border: "none",
    },
    "& .MuiInput-underline::after": {
      border: "none",
    },
    "& .MuiSelect-select": {
      fontSize: "18px",
      fontWeight: "600",
      paddingRight: 0,
      fontFamily: "Montserrat",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  "& .widget-divider": {
    borderBottom: "1px solid #CCCCCC",
    margin: "15px 0px 10px 0px",
  },
  "& .repeat-data-container": {
    display: "flex",
    gap: "10px",
  },
  "& .widget-repeat-grid": {
    alignItems: "center",
    gap: "25px",
    [theme.breakpoints.between("md", 1635)]: {
      gap: "10px",
    },
  },
  "& .widgetDayContainer": {
    display: "flex",
    gap: "5px",
  },
  "& .widgetRepeatFontSize": {
    fontSize: "12px",
  },
  "& .widgetEndAfterContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    justifyContent: "center",
  },
  "& .widgetEveryDayBox": {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  "& .repeat-data-child-container": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  "& .display-none": {
    display: "none",
  },
}));
const StyledDatePickerContainer = styled(Box)(({ theme }) => ({
  "& input": {
    outline: 'none !important'
  },
  "& .error-date::placeholder": {
      color: "#cc0000 !important",
  },
  "& .react-datepicker__input-container": {
    opacity: "1 !important",
    top: "40px !important",
    position: "unset",
  },
  "& .react-datetime-picker__wrapper": {
    border: "none"
  },
  "& .react-datepicker__input-container input": {
    border: "none",
    width: "170px",
    fontSize: "18px",
    fontWeight: "600",
    [theme.breakpoints.down(1281)]: {
      width: "165px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "6px 0px 5px 19px;",
      fontSize: "14px",
      width: "117px",
    },
  },
  "& .react-datepicker__input-container input::placeholder": {
    fontSize: "12px",
    color: "#A6A6A6",
    fontWeight: "400",
  },
  "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
    padding: "0.5rem 0px",
  },
  "& .react-datepicker__day--keyboard-selected,.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range":
    {
      backgroundColor: "#E1CCFF",
      color: "#6F05EA",
    },
  "& .react-datepicker__day--selected::after": {
    content: "none",
  },
  "& .react-datepicker__current-month": {
    border: "none !important",
  },
  "& .react-datepicker__navigation": {
    top: "20px",
  },
  "& .react-datepicker__navigation--next": {
    [theme.breakpoints.down("xs")]: {
      top: "8px",
      left: "67%",
    },
  },
  "& .react-datepicker__navigation--previous": {
    [theme.breakpoints.down("xs")]: {
      top: "8px",
      left: "106px",
    },
    [theme.breakpoints.down(401)]: {
      left: "67px",
    },
  },
}));
const StyledEndAfterDatePickerContainer = styled(Box)(({ theme }) => ({
  "& .react-datepicker__input-container": {
    opacity: "1 !important",
    position: "unset",
  },
  "& .react-datepicker__input-container input": {
    border: "none",
    width: "170px",
    fontSize: "18px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      padding: "6px 0px 5px 19px;",
      fontSize: "14px",
      width: "117px",
    },
  },
  "& .react-datepicker__input-container input:focus-visible": {
    outline: "none",
    border: "none",
    boxShadow: "none",
  },
  "& .react-datepicker__input-container input::placeholder": {
    fontSize: "12px",
    color: "#A6A6A6",
    fontWeight: "400",
  },
  "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
    padding: "0.5rem 0px",
  },
  "& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected":
    {
      backgroundColor: "#E1CCFF",
      color: "#6F05EA",
    },
  "& .react-datepicker__day--selected::after": {
    content: "none",
  },
}));
const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  "& .MuiOutlinedInput-adornedStart": {
    padding: "0px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    height: "34px",
    width: "62px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
    width: "62px",
    height: "34px",
  },
}));

const NO_END_DATE_TAB_VALUES = [3, 4, 5];

export const LandingPageHeader: React.FC<HeaderProps> = ({
  tabValue,
  onTabChange,
  SearchDataFromHeader,
  isRepeatShow,
  petList,
  hideLabel
}) => {
  const {
    startDate, setStartDate,
    endDate, setEndDate,
    selectedDay, setSelectedDay,
    isRepeat, setIsRepeat,
    isFilterOpen, setIsFilterOpen,
    petType, setPetType,
    endAfterDate, setEndAfterDate,
    countries, setCountries,
    cities, setCities,
    selectedCountry, setSelectedCountry,
    selectedCity, setSelectedCity,
    errors, setErrors,
    removeError,
    handleResetClick
  } = useSearchHeaderContext();

  const selectPetList = (petList && petList.length > 0) ? petList : [
    { label: "Dog", value: "dog" },
    { label: "Cat", value: "cats" },
    { label: "Bird", value: "bird" },
    { label: "Fish", value: "fish" },
    { label: "Rabbit/Rodent", value: "rabbit" },
    { label: "Reptile", value: "reptile" },
  ]

  const getCountryListData = () => {
    const countriesWithCities: string[] =
      require("countries-cities").getCountries();
    const countryList: ICountryModel[] = countriesWithCities
      .filter((country: string) => {
        const cities: string[] = require("countries-cities").getCities(country);
        return cities && cities.length > 0;
      })
      .map((country: string) => ({
        label: country,
        value: country,
      }));
    setCountries(countryList);
  };

  const getCityListData = (country: string) => {
    const cityList = require("countries-cities")
      .getCities(country || "Portugal")
      .map((city: string) => ({
        label: city,
        value: city,
      }));
    setCities(cityList);
    setSelectedCity(cityList[0].label);
  };

  useEffect(() => {
    if (selectedCountry) {
      getCityListData(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    getCountryListData();
    getCityListData("Portugal");
  }, []);

  const validate = () => {
    const shouldAlwaysBeValidForToField = NO_END_DATE_TAB_VALUES.includes(tabValue);
    const fields = {
      country: !!selectedCountry,
      city: !!selectedCity,
      from: !!startDate,
      to: shouldAlwaysBeValidForToField ? true : !!endDate,
      animal: !!petType
    };
  
    const failedFields = Object.entries(fields)
      .filter(([key, value]) => !value)
      .map(([key]) => key);
  
    const isValid = failedFields.length === 0;
  
    return {
      isValid,
      failedFields
    };
  };

  const getServiceType = () => {
    const mapServiceTypes: Record<number, string> = {
      0: 'Hotels',
      1: 'Dog Walking',
      2: 'Grooming',
      3: 'Pet Sitting', 
      4: 'Day Care',
      5: 'Training'
    }

    return mapServiceTypes[tabValue];
  }

  const handleSearch = () => {
    const { isValid, failedFields } = validate();

    if (!isValid) {
      setErrors(failedFields);
      return;
    }

    setIsFilterOpen(false);
    SearchDataFromHeader({
      start_date: startDate,
      service_type: getServiceType(),
      endAfter: endAfterDate,
      end_date: endDate,
      country: getCountryCode(selectedCountry) || '',
      everyDay: selectedDay,
      your_pet: petType,
      city: selectedCity
    });
  };

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => (
    <div
      className="react-datepicker__header"
      style={{ borderBottom: "0.5px solid #b7b7b7" }}
    >
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className="react-datepicker__navigation react-datepicker__navigation--previous"
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
          {"<"}
        </span>
      </button>
      <span className="react-datepicker__current-month">
        {date.toLocaleString("default", { month: "long" })}
      </span>
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className="react-datepicker__navigation react-datepicker__navigation--next"
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
          {">"}
        </span>
      </button>
    </div>
  );


  const renderStartDateCalendar = () => {
    return (
      <StyledDatePickerContainer>
        <DatePicker
          className={errors.includes('from') ? 'error-date' : ''}
          renderCustomHeader={renderCustomHeader}
          selected={startDate}
          icon={
            <img
              className="widgetImage"
              src={CalendarIcon}
              alt="calendar-icon"
            />
          }
          showIcon
          placeholderText="Choose a Date"
          onChange={(date) => {
            setStartDate(date as Date);
            removeError('from')
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          withPortal
          dateFormat="EEE dd/MM/yy"
          minDate={new Date()}
          maxDate={endDate}
          onKeyDown={(e) => {
            e.preventDefault();
         }}
        />
      </StyledDatePickerContainer>
    );
  };

  const renderEndDateCalendar = () => {
    return (
      <StyledDatePickerContainer>
        <DatePicker
          className={errors.includes('to') ? 'error-date' : ''}
          selected={endDate}
          renderCustomHeader={renderCustomHeader}
          onChange={(date) => {
            setEndDate(date as Date);
            removeError('to');
          }}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          withPortal
          showIcon
          icon={
            <img
              className="widgetImage"
              src={CalendarIcon}
              alt="calendar-icon"
            />
          }
          placeholderText="Choose a Date"
          dateFormat="EEE dd/MM/yy"
          minDate={startDate}
          onKeyDown={(e) => {
            e.preventDefault();
         }}
        />
      </StyledDatePickerContainer>
    );
  };
  const renderEndAfterCalendar = () => {
    return (
      <StyledEndAfterDatePickerContainer>
        <DatePicker
          selected={endAfterDate}
          onChange={(date) => setEndAfterDate(date as Date)}
          withPortal
          showIcon
          icon={
            <img
              className="widgetImage"
              src={CalendarIcon}
              alt="calendar-icon"
            />
          }
          placeholderText="Choose a Date"
          dateFormat="EEE dd/MM/yy"
          minDate={new Date()}
          onKeyDown={(e) => {
            e.preventDefault();
         }}
        />
      </StyledEndAfterDatePickerContainer>
    );
  };
  const renderResponsiveWidget = () => {
    return (
      <Box className="render-responsive-widget-container">
        {!isFilterOpen && (
          <Grid container>
            <Grid item xs={6}>
              <Typography className="widgetTitleText">Country</Typography>
              <Box className="widgetContent">
                <img
                  className="widgetImage"
                  src={LocationIcon}
                  alt="location-icon"
                />
                <Typography className="widgetText">{selectedCountry}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                className="filter-icon-btn"
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              >
                <img src={FilterIcon} alt="filter-icon" />
                <span className="reset-filter-text">Filter</span>
              </IconButton>
            </Grid>
          </Grid>
        )}
        {isFilterOpen && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              Filter
            </Grid>
            <Grid item xs={6}>
              <Box className="reset-close-container">
                <span className="reset-filter-text" onClick={handleResetClick}>
                  Reset
                </span>
                <img
                  className="reset-close-img"
                  src={CloseIcon}
                  onClick={() => setIsFilterOpen(false)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography className="widgetTitleText">Country</Typography>
              <Box className="widgetContent widgetAnimalSelect">
                <img
                  className="widgetImage"
                  src={LocationIcon}
                  alt="location-icon"
                />
                <CommonSelect
                  value={selectedCountry}
                  id="country"
                  placeholder="Select country"
                  onChange={(event) => {
                    setSelectedCountry(event?.target.value);
                  }}
                  fullWidth
                  name="country"
                  menuProps={{
                    disableScrollLock: true,
                    marginThreshold: undefined,
                  }}
                  list={countries}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography className="widgetTitleText">City</Typography>
              <Box className="widgetContent widgetAnimalSelect">
                <img
                  className="widgetImage"
                  src={LocationIcon}
                  alt="location-icon"
                />
                 <CommonSelect
                    value={selectedCity}
                    id="city"
                    placeholder="Select city"
                    onChange={(event) => {
                      setSelectedCity(event?.target.value);
                    }}
                    fullWidth
                    name="city"
                    menuProps={{
                      disableScrollLock: true,
                      marginThreshold: undefined,
                    }}
                    list={cities}
                  />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography className="widgetTitleText">Start Date</Typography>
              {renderStartDateCalendar()}
            </Grid>
            {!NO_END_DATE_TAB_VALUES.includes(tabValue) && 
              <Grid item xs={6}>
                <Typography className="widgetTitleText">End Date</Typography>
                {renderEndDateCalendar()}
              </Grid>
            }
            <Grid item sm={6}>
              <Typography className="widgetTitleText ">Your Pet</Typography>
              <Box className="widgetContent widgetAnimalSelect">
                <CommonSelect
                  value={petType}
                  id="petType"
                  startAdornment={<img src={FootPrintIcon} />}
                  placeholder="Select animal"
                  onChange={(event) => {
                    setPetType(event?.target.value);
                  }}
                  fullWidth
                  name="petType"
                  list={selectPetList}
                />
              </Box>
            </Grid>
            <Grid xs={12}></Grid>
            <Grid xs={6}>
              <Box
                className={`${
                  !isRepeatShow ? "display-none" : "service-repeat-container"
                }`}
                style={isRepeat ? { width: "170px" } : {}}
              >
                <Typography className="widgetText">
                  How often do you need this service?
                </Typography>
                {!isRepeat && (
                  <Typography className="widgetTitleText">
                    Choose Repeat Weekly for recurring booking{" "}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Box
                className={`${isRepeat ? "repeatSelect" : "repeatNotSelect"} ${
                  !isRepeatShow ? "display-none" : ""
                } } repeatContainer`}
                style={{}}
                onClick={() => setIsRepeat(!isRepeat)}
              >
                <img
                  className="widgetImage"
                  src={isRepeat ? RepeatPurpleIcon : RepeatIcon}
                />
                <Typography
                  className={`${isRepeat ? "repeatSelectText" : "repeatText"}`}
                >
                  Repeat Weekly
                </Typography>
              </Box>
            </Grid>
            {isRepeat && (
              <>
                <Grid item xs={6}>
                  <Box className="repeat-data-child-container">
                    <span className="widgetRepeatFontSize">Every</span>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <StyledTextFieldContainer>
                        <InputField type="number" variant="outlined" label="" />
                      </StyledTextFieldContainer>
                      <span style={{ color: "#94A3B8", fontSize: "12px" }}>
                        weeks
                      </span>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className="widgetEndAfterContainer">
                    <span className="widgetRepeatFontSize">End After</span>
                    {renderEndAfterCalendar()}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="repeat-data-child-container">
                    <span className="widgetRepeatFontSize">Every</span>
                    <Box className="widgetEveryDayBox">
                      <Box className="widgetDayContainer">
                        {weekDays.map((day, index) => (
                          <Box
                            className={`${
                              selectedDay === day
                                ? "widgetSelectedDay"
                                : "widgetNotSelect"
                            } widgetDay`}
                            onClick={() => setSelectedDay(day)}
                            key={index}
                          >
                            {day}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
            <Box className="search-btn-container">
              <Button
                endIcon={<img src={NextArrowIcon} alt="next-arrow" />}
                className="button"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    );
  };
  const renderWidget = () => {
    return (
      <Box className="render-widget-container">
        <Grid container>
          <Grid item md={11}>
            <Grid container>
              <Grid container spacing={2}>
                <Grid item md={2} className="widgetTextContainer">
                  <Typography className="widgetTitleText">Country</Typography>
                  <Box className="widgetContent widgetAnimalSelect">
                    <img
                      className="widgetImage"
                      src={LocationIcon}
                      alt="location-icon"
                    />
                    <CommonSelect
                      value={selectedCountry}
                      id="country"
                      placeholder="Select country"
                      onChange={(event) => {
                        setSelectedCountry(event?.target.value);
                      }}
                      fullWidth
                      name="country"
                      menuProps={{
                        disableScrollLock: true,
                        marginThreshold: undefined,
                      }}
                      list={countries}
                    />
                  </Box>
                </Grid>
                <Grid item md={2} className="widgetTextContainer">
                  <Typography className="widgetTitleText">City</Typography>
                  <Box className="widgetContent widgetAnimalSelect">
                    <img
                      className="widgetImage"
                      src={LocationIcon}
                      alt="location-icon"
                    />
                    <CommonSelect
                      value={selectedCity}
                      id="city"
                      placeholder="Select city"
                      onChange={(event) => {
                        setSelectedCity(event?.target.value);
                      }}
                      fullWidth
                      name="city"
                      menuProps={{
                        disableScrollLock: true,
                        marginThreshold: undefined,
                      }}
                      list={cities}
                    />
                  </Box>
                </Grid>
                <Grid item md={3} className="widgetTextContainer">
                  <Typography className="widgetTitleText">
                    Start Date
                  </Typography>
                  {renderStartDateCalendar()}
                </Grid>
                {!NO_END_DATE_TAB_VALUES.includes(tabValue) && 
                  <Grid item md={3} className="widgetTextContainer">
                    <Typography className="widgetTitleText">End Date</Typography>
                    {renderEndDateCalendar()}
                  </Grid>
                }
                <Grid item md={2} className="widgetTextContainer">
                  <Typography className="widgetTitleText ">Your Pet</Typography>
                  <Box className="widgetContent widgetAnimalSelect">
                    <img src={FootPrintIcon} />
                    <CommonSelect
                      error={errors.includes('animal')}
                      value={petType}
                      id="pet"
                      placeholder="Select animal"
                      label=""
                      onChange={(event) => {
                        setPetType(event?.target.value);
                        removeError('animal')
                      }}
                      fullWidth
                      menuProps={{
                        disableScrollLock: true,
                        marginThreshold: undefined,
                      }}
                      name="breed"
                      list={selectPetList}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={11}>
            <Box
              className={`${!isRepeatShow ? "display-none" : "widget-divider"}`}
            ></Box>
          </Grid>
          <Grid item md={11}>
            <Grid
              className={`${
                !isRepeatShow ? "display-none" : "widget-repeat-grid"
              }`}
              container
            >
              <Box
                className={`${
                  isRepeat
                    ? "is-repeat-service-repeat-container"
                    : "service-repeat-container"
                }`}
              >
                <Typography className="widgetText">
                  How often do you need this service?
                </Typography>
                {!isRepeat && (
                  <Typography className="widgetTitleText">
                    Choose Repeat Weekly for recurring booking{" "}
                  </Typography>
                )}
              </Box>
              <Box
                className={`${
                  isRepeat ? "repeatSelect" : "repeatNotSelect"
                } repeatContainer`}
                style={{}}
                onClick={() => setIsRepeat(!isRepeat)}
              >
                <img
                  className="widgetImage"
                  src={isRepeat ? RepeatPurpleIcon : RepeatIcon}
                />
                <Typography
                  className={`${isRepeat ? "repeatSelectText" : "repeatText"}`}
                >
                  Repeat Weekly
                </Typography>
              </Box>
              {isRepeat && (
                <Box className="repeat-data-container">
                  <Box className="repeat-data-child-container">
                    <span className="widgetRepeatFontSize">Every</span>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <StyledTextFieldContainer>
                        <InputField type="number" variant="outlined" label="" />
                      </StyledTextFieldContainer>
                      <span style={{ color: "#94A3B8", fontSize: "12px" }}>
                        weeks
                      </span>
                    </Box>
                  </Box>
                  <Box className="repeat-data-child-container">
                    <span className="widgetRepeatFontSize">Every</span>
                    <Box className="widgetEveryDayBox">
                      <Box className="widgetDayContainer">
                        {weekDays.map((day, index) => (
                          <Box
                            className={`${
                              selectedDay === day
                                ? "widgetSelectedDay"
                                : "widgetNotSelect"
                            } widgetDay`}
                            onClick={() => setSelectedDay(day)}
                            key={index}
                          >
                            {day}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="widgetEndAfterContainer">
                    <span className="widgetRepeatFontSize">End After</span>
                    {renderEndAfterCalendar()}
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box>
          <Button
            endIcon={<img src={NextArrowIcon} alt="next-arrow" />}
            className="button"
            onClick={handleSearch}
            data-test-id="search-btn"
          >
            Search
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <LandingPageHeaderContainer>
      <Box position="relative">
        <Box className="header-title">
          {!hideLabel && 
            <Typography className="header-title-text">
              {" "}
              Welcome to the home of{" "}
              <span className="header-title-text-span">pet hotels</span>
            </Typography>
          }
          <Box>
            <Tabs
              value={tabValue}
              variant="scrollable"
              onChange={onTabChange}
              textColor="inherit"
              className="tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#FFB800",
                  height: "4px",
                },
              }}
              scrollButtons="auto"
              data-test-id="tabs"
            >
              <Tab
                icon={<img src={HomeIcon} className="tabIcon" />}
                label="HOTELS"
                className="tab"
                {...a11yProps(0)}
              />
              <Tab
                label="DOG WALKING"
                icon={<img src={DogWalkingIcon} className="tabIcon" />}
                className="tab"
                {...a11yProps(1)}
              />
              <Tab
                icon={
                  <div style={{ position: 'relative' }}>
                    <img src={GroomingIcon} className="tabIcon" />
                    <Box style={{ 
                      position: 'absolute', 
                      top: '-25px', 
                      right: '-75px', 
                      background: '#FFB800', 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '3px',
                      width: '73px',
                      height: '17px'
                    }}>
                      <Typography style={{ fontSize: '12px', fontWeight: 700, color: '#fff'}}>Soon</Typography>
                    </Box>
                </div>
                }
                label="GROOMING"
                className="tab"
                {...a11yProps(2)}
                disabled={true}
              />
              <Tab
                icon={<img src={PetSittingIcon} className="tabIcon" />}
                label="PET SITTING"
                className="tab"
                {...a11yProps(3)}
              />
              <Tab
                icon={<img src={DayCareIcon} className="tabIcon" />}
                label="DAY CARE"
                className="tab"
                {...a11yProps(4)}
              />
              <Tab
                icon={<img src={TrainingIcon} className="tabIcon" />}
                label="TRAINING"
                className="tab"
                {...a11yProps(5)}
              />
            </Tabs>
          </Box>
          <Box
            className={`${
              isFilterOpen ? "widgetContainerOpen" : "widgetContainer"
            } ${isRepeatShow ? "" : "widgetContainerWithoutRepeat"}`}
          >
            <Box className="container">
              {renderWidget()}
              {renderResponsiveWidget()}
            </Box>
          </Box>
        </Box>
      </Box>
    </LandingPageHeaderContainer>
  );
};
