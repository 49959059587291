import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  currentMenuItem:number;
  isSideBarOpen:boolean;
  currentSettingsTab: number;
  profileImageUrl: string;
  isEditGeneral: boolean;
  iscancelAppointment:boolean;
  isOpenLanguageModal:boolean;

  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
   
    this.state = {
      currentMenuItem:0,
      currentSettingsTab: 0,
      isSideBarOpen:false,
      iscancelAppointment:false,
      profileImageUrl: "",
      isEditGeneral: false,
      isOpenLanguageModal: false,
   
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  openSideBar = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  };
  closeModal=()=>{
    this.setState({isOpenLanguageModal: !this.state.isOpenLanguageModal})
  }
  GetCancelAppointment=()=>{
    this.setState({iscancelAppointment:!this.state.iscancelAppointment,isOpenLanguageModal:false})
  }
  GetRejectCancelAppointment=()=>{
    this.setState({iscancelAppointment:false,isOpenLanguageModal:false})
  }
  
  setTabId=(id:number)=>{
    this.setState({ currentSettingsTab: id })
  }
  EditAppointment=()=>{
    this.setState({ isEditGeneral: !this.state.isEditGeneral })
  }
  // Customizable Area End
}
