import { Box, Tabs, makeStyles, styled } from '@material-ui/core';
import React from 'react';

type VericalTypeProps = {
    value: number | string;
    onChange: (e: any, idx: number) => void;
    children: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row', 
      alignItems: 'flex-start',
    },
    indicator: {
      left: 0, 
      right: 'auto', 
      backgroundColor: '#6F05EA',
    },
  }));

const TabsWrapper = styled(Box)({
  width: '100%',

  '& .MuiTab-wrapper': {
    alignItems: 'flex-start',
    paddingLeft: '10px'
  }
})


const VericalTabs = ({value, onChange, children} : VericalTypeProps) => {
    const classes = useStyles();

    return (
      <TabsWrapper>
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={onChange}
            aria-label="vertical-tabs"
            className={classes.root}
            classes={{ indicator: classes.indicator }}
        >
            {children}
        </Tabs>
      </TabsWrapper>
    )
}

export default VericalTabs;