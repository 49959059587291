// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IFilteredService } from "../../../../components/src/interfaces.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import { isValidNumber, returnTruthyString } from "../../../../components/src/HelperUtils";

const defaultForm = (subServiceList: ISubServicesList[]) => {
  const formattedList: IPriceForm[] = subServiceList.map((subService) => ({
    id: "",
    title: returnTruthyString(subService.title),
    price: '',
    sub_service_id: returnTruthyString(subService.id)
  }));
  return formattedList
}
const initalTransportation = {
  checked: false,
  title: "transportation",
  id: "",
  price: "",
  sub_service_id: ""
}
const initalMedication = {
  checked: false,
  title: "medication",
  id: "",
  price: "",
  sub_service_id: ""
}
interface ISubServicesList {
  "id": string | number,
  "title": string
}

interface IPriceDetails {
  id: string
  type: string
  attributes: {
    id: number | string
    title: string
    price: number | string
    sub_service_id: number | string
  }
}
interface IGetPriceDetailsResp {
  data: {
    sub_services: ISubServicesList[],
    data: IPriceDetails[]
  },
  message?: string
}
type TSwtichKey = "transportation" | "medication"

interface IPriceForm {
  id: string
  title: string
  price: string
  sub_service_id: string
}
interface IOherServiceForm {
  checked: boolean,
  id: string
  title: string
  price: string
  sub_service_id: string
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedService: IFilteredService;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  apiToken: string,
  isEdit: boolean,
  // FORM
  priceFormList: IPriceForm[],
  transportation: IOherServiceForm,
  medication: IOherServiceForm,
  // SAVED FORM
  savedPriceFormList: IPriceForm[],
  savedTransportation: IOherServiceForm,
  savedMedication: IOherServiceForm,
  // ERROR
  errorArray: IPriceForm[],
  // API
  getPriceDetailsLoading: boolean,
  getPriceDetails: IPriceDetails[],
  subServicesList: ISubServicesList[],
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PriceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPriceDetailsCallId: string = "";
  createPriceDetailsCallId: string = "";
  updatePriceDetailsCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      apiToken: localStorage.getItem("login_token") || "",
      isEdit: false,
      // FORM
      priceFormList: [],
      transportation: initalTransportation,
      medication: initalMedication,
      // SAVED FORM
      savedPriceFormList: [],
      savedTransportation: initalTransportation,
      savedMedication: initalMedication,
      // ERROR
      errorArray: [],
      // API
      subServicesList: [],
      getPriceDetails: [],
      getPriceDetailsLoading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId) {
        switch (apiRequestCallId) {
          case this.getPriceDetailsCallId:
            this.getPriceDetailsResp(response);
            break;
          case this.createPriceDetailsCallId:
            this.createPriceDetailsResp(response);
            break;
          case this.updatePriceDetailsCallId:
            this.updatePriceDetailsResp(response);
            break;
          default:
            break;
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  getPriceDetailsResp = (responseJson: IGetPriceDetailsResp) => {
    this.setState({ getPriceDetailsLoading: false })
    if (responseJson && responseJson.data && !responseJson.message) {
      this.setPriceForms(responseJson)
    } else {
      this.setState({
        subServicesList: [],
        getPriceDetails: [],
        priceFormList: [],
        savedPriceFormList: [],
        transportation: initalTransportation,
        savedTransportation: initalTransportation,
        medication: initalMedication,
        savedMedication: initalMedication,
      })
    }
  }
  createPriceDetailsResp = (responseJson: { message: string }) => {
    if (responseJson && responseJson.message) {
      this.getPriceDetails()
    }
  }
  updatePriceDetailsResp = (responseJson: { message: string }) => {
    if (responseJson && responseJson.message) {
      this.getPriceDetails()
    }
  }

  async componentDidMount() {
    this.fetchInitialApicall()
  }
  getPriceDetails = async () => {
    const establishmentId = await getStorageData("establishment_ID");
    this.setState({
      getPriceDetailsLoading: true,
    })
    const header = { token: this.state.apiToken };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const endpoint = `account_block/sub_services_prices?establishment_id=${establishmentId}&service_type=${this.props.selectedService.name}`
    this.getPriceDetailsCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  fetchInitialApicall = () => {
    if (this.props.selectedService.service_id) {
      this.getPriceDetails()
    } else {
      // RESET STATES
      this.setState({
        getPriceDetailsLoading: false,
        getPriceDetails: [],
        subServicesList: []
      })
    }
  }

  setPriceForms = (responseJson: IGetPriceDetailsResp) => {
    const sub_service_data = responseJson.data.sub_services
    const price_data = responseJson.data.data
    const initialFormList = defaultForm(sub_service_data)

    if (sub_service_data.length && !price_data.length) {
      this.setState({
        getPriceDetails: [],
        subServicesList: sub_service_data,
        priceFormList: initialFormList,
        transportation: {
          checked: false,
          title: "transportation",
          id: "",
          price: "",
          sub_service_id: ""
        },
        medication: {
          checked: false,
          title: "medication",
          id: "",
          price: "",
          sub_service_id: ""
        },
        // SAVED
        savedPriceFormList: initialFormList,
        savedTransportation: {
          checked: false,
          title: "transportation",
          id: "",
          price: "",
          sub_service_id: ""
        },
        savedMedication: {
          checked: false,
          title: "medication",
          id: "",
          price: "",
          sub_service_id: ""
        },
      })
    }

    if (sub_service_data.length && price_data.length) {
      const updatedPriceFormArray = initialFormList.map((item) => {
        const matchingService = price_data.find(
          (service) => String(service.attributes.sub_service_id) === String(item.sub_service_id) &&
            service.attributes.title == item.title
        );
        return {
          ...item,
          id: returnTruthyString(matchingService?.attributes.id),
          price: matchingService ? returnTruthyString(matchingService.attributes.price) : item.price
        };
      });

      const findByTitle = (title: string) => price_data.find((record) => record.attributes.title == title)

      const transportationObj = findByTitle("transportation")
      const transportation = {
        checked: Boolean(transportationObj),
        title: "transportation",
        id: returnTruthyString(transportationObj?.attributes.id),
        price: returnTruthyString(transportationObj?.attributes.price),
        sub_service_id: returnTruthyString(transportationObj?.attributes.sub_service_id)
      }
      const medicationObj = findByTitle("medication")
      const medication = {
        checked: Boolean(medicationObj),
        title: "medication",
        id: returnTruthyString(medicationObj?.attributes.id),
        price: returnTruthyString(medicationObj?.attributes.price),
        sub_service_id: returnTruthyString(medicationObj?.attributes.sub_service_id)
      }

      this.setState({
        getPriceDetails: price_data,
        subServicesList: sub_service_data,
        priceFormList: updatedPriceFormArray,
        transportation: transportation,
        medication: medication,
        // SAVE INITIAL
        savedPriceFormList: updatedPriceFormArray,
        savedTransportation: transportation,
        savedMedication: medication,
      })
    }

    if (!sub_service_data.length && !price_data.length) {
      this.setState({
        subServicesList: [],
        getPriceDetails: [],
        priceFormList: [],
        savedPriceFormList: [],
        transportation: initalTransportation,
        savedTransportation: initalTransportation,
        medication: initalMedication,
        savedMedication: initalMedication,
      })
    }
  }

  setOtherServiceCheck = (checkedValue: boolean, key: TSwtichKey) => {
    if (key == "transportation") {
      this.setState({
        transportation: {
          ...this.state.transportation,
          checked: checkedValue
        }
      });
    } else {
      this.setState({
        medication: {
          ...this.state.medication,
          checked: checkedValue
        }
      });
    }

  };
  setOtherServicePrice = (price_value: string, state_key: TSwtichKey) => {
    if (state_key == "transportation") {
      this.setState({
        transportation: {
          ...this.state.transportation,
          price: price_value
        }
      });
    } else {
      this.setState({
        medication: {
          ...this.state.medication,
          price: price_value

        }
      });
    }
  };

  setPriceValue = (inputValue: string, service_title: string, sub_service_id: string) => {
    const updatedForm = this.state.priceFormList.map((service) => {
      if (service.sub_service_id == sub_service_id && service_title == service.title) {
        return {
          ...service,
          price: inputValue
        };
      }
      return service
    });
    this.setState({
      priceFormList: updatedForm
    })
  }

  handleEditClick = () => {
    this.setState({ isEdit: true });
  };

  handleCancelClick = () => {
    this.setState({
      isEdit: false,
      priceFormList: this.state.savedPriceFormList,
      transportation: this.state.savedTransportation,
      medication: this.state.savedMedication,
      errorArray: []
    });
  };

  validateFormData = (bodyData: IPriceForm[]) => {
    const errorArr: IPriceForm[] = []
    const copyBodyArr: IPriceForm[] = JSON.parse(JSON.stringify(bodyData))

    copyBodyArr.forEach((record) => {
      if (!isValidNumber(record.price)) {
        errorArr.push(record)
      }
    })
    this.setState({
      errorArray: errorArr
    })
    return !errorArr.length
  }
  getError = (title: string, sub_service_id: string, checkByTitle: boolean) => {
    const copyErrorArr = [...this.state.errorArray]

    let result = {
      error: false,
      errorMsg: ""
    }
    if (checkByTitle) {
      const matchingObj = copyErrorArr.find((errorObj) => (errorObj.title == title))
      if (matchingObj) {
        result.error = true
        result.errorMsg = "*Please enter valid Number"
      }
    } else {
      const matchingObj = copyErrorArr.find((errorObj) => (errorObj.title == title) && (errorObj.sub_service_id == sub_service_id))
      if (matchingObj) {
        result.error = true
        result.errorMsg = "*Please enter valid Number"
      }
    }
    return result
  }

  handleSaveClick = async () => {
    const { priceFormList, transportation, medication, subServicesList } = this.state
    const bodyData = [...priceFormList]

    // TRANSPORTATION BODY
    const notAllowedServices = [configJSON.SERVICES_ENUM.PET_SITTING, configJSON.SERVICES_ENUM.DOG_WALKING]
    const isShowTransportation = !notAllowedServices.includes(this.props.selectedService.name)
    if (isShowTransportation && transportation.checked) {
      const transportation_Body = {
        id: transportation.id,
        title: transportation.title,
        price: transportation.price,
        sub_service_id: String(subServicesList[0].id)
      }
      bodyData.push(transportation_Body)
    }

    // MEDICATION BODY
    const notAllowedServicesMedication = [configJSON.SERVICES_ENUM.GROOMING]
    const isShowMedication = !notAllowedServicesMedication.includes(this.props.selectedService.name)
    if (isShowMedication && medication.checked) {
      const medication_Body = {
        id: medication.id,
        title: medication.title,
        price: medication.price,
        sub_service_id: String(subServicesList[0].id)
      }
      bodyData.push(medication_Body)
    }

    const reqBody = {
      data: bodyData
    }

    const isValidated = this.validateFormData(bodyData)
    if (isValidated) {
      const header = { token: this.state.apiToken, "Content-Type": "application/json" };
      const establishmentId = await getStorageData("establishment_ID");

      if (this.state.getPriceDetails.length) {

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const endpoint = `account_block/sub_services_prices/${establishmentId}`
        this.updatePriceDetailsCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqBody));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        runEngine.sendMessage(reqMessage.id, reqMessage);

      } else {

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const endpoint = `account_block/sub_services_prices`
        this.createPriceDetailsCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(reqBody));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(reqMessage.id, reqMessage);
      }
    }
  }
  // Customizable Area End
}