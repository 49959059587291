import React from "react";
import { Box, Grid, styled } from "@material-ui/core";
import { playIcon } from "../../../blocks/chat/src/assets";
interface Props {
  imageVideoArray: { id: number; url: string }[];
  handleSelectedImgVideo: (selectedId: number) => void;
}
const ImageGalleryContainer = styled(Box)({
  "& .booking-single-img": {
    width: "100%",
    height: "417px",
    "@media (max-width: 1280px)": {
      height: "494px",
    },
    "@media (max-width: 960px)": {
      height: "434px",
    },
    "@media (max-width: 600px)": {
      height: "242px",
    },
    "@media (max-width: 430px)": {
      height: "176px",
    },
  },
  "& .booking-group-img": {
    width: "100%",
    height: "204px",
    "@media (max-width: 1280px)": {
      height: "243px",
    },
    "@media (max-width: 960px)": {
      height: "213px",
    },
    "@media (max-width: 600px)": {
      height: "117px",
    },
    "@media (max-width: 430px)": {
      height: "84px",
    },
  },
  "& .video-container": {
    position: "relative",
  },
  "& .video-icon-container": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  "& .video-play-icon": {
    height: "20px !important",
    width: "20px !important",
  },
});

const VendorImageVideoGallery = (props: Props) => {
  const { imageVideoArray, handleSelectedImgVideo } = props;
  const getMediaType = (url: string) => {
    if (/\.(jpg|jpeg|png|gif)$/i.test(url)) {
      return "image";
    } else if (/\.(mp4|webm|ogg)$/i.test(url)) {
      return "video";
    } else {
      return "unknown";
    }
  };
  const firstSingleImage = imageVideoArray[0];
  const lastSingleImage = imageVideoArray[imageVideoArray.length - 1];
  const firstGroupImages = imageVideoArray.slice(1, 5);
  const lastGroupImages = imageVideoArray.slice(5, imageVideoArray.length - 1);
  return (
    <>
      <ImageGalleryContainer>
        <Grid container spacing={1}>
          <Grid item md={5} sm={6} xs={6}>
            {getMediaType(firstSingleImage.url) === "image" ? (
              <img
                className="booking-single-img"
                src={firstSingleImage.url}
                onClick={() => handleSelectedImgVideo(firstSingleImage.id)}
                data-test-id="booking-gallery-img"
              />
            ) : (
              <Box className="video-container">
                <Box className="video-icon-container" style={{}}>
                  <img
                    className="video-play-icon"
                    src={playIcon}
                    alt="play-pause-icon"
                  />
                </Box>
                <video
                  style={{ width: "100%", height: "100%" }}
                  src={firstSingleImage.url}
                  onClick={() => handleSelectedImgVideo(firstSingleImage.id)}
                  data-test-id="booking-gallery-img"
                />
              </Box>
            )}
          </Grid>
          <Grid item md={7} sm={6} xs={6}>
            <Grid container spacing={1}>
              {firstGroupImages.map((img) => (
                <Grid item md={6} sm={6} xs={6} key={`group-img-${img.id}`}>
                  {getMediaType(img.url) === "image" ? (
                    <img
                      src={img.url}
                      className={`booking-group-img`}
                      onClick={() => handleSelectedImgVideo(img.id)}
                    />
                  ) : (
                    <Box className="video-container">
                      <Box className="video-icon-container" style={{}}>
                        <img
                          className="video-play-icon"
                          src={playIcon}
                          alt="play-pause-icon"
                        />
                      </Box>
                      <video
                        style={{ width: "100%", height: "100%" }}
                        src={img.url}
                        onClick={() => handleSelectedImgVideo(img.id)}
                      />
                    </Box>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={7} sm={6} xs={6}>
            <Grid container spacing={1}>
              {lastGroupImages.map((img) => (
                <Grid item md={6} sm={6} xs={6} key={`group-img2-${img.id}`}>
                  {getMediaType(img.url) === "image" ? (
                    <img
                      src={img.url}
                      className="booking-group-img"
                      onClick={() => handleSelectedImgVideo(img.id)}
                    />
                  ) : (
                    <Box className="video-container">
                      <Box className="video-icon-container" style={{}}>
                        <img
                          className="video-play-icon"
                          src={playIcon}
                          alt="play-pause-icon"
                        />
                      </Box>
                      <video
                        style={{ width: "100%", height: "100%" }}
                        src={img.url}
                        onClick={() => handleSelectedImgVideo(img.id)}
                      />
                    </Box>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={5} sm={6} xs={6}>
            {getMediaType(lastSingleImage.url) === "image" ? (
              <img
                className="booking-single-img"
                src={lastSingleImage.url}
                onClick={() => handleSelectedImgVideo(lastSingleImage.id)}
              />
            ) : (
              <Box className="video-container">
                <Box className="video-icon-container" style={{}}>
                  <img
                    className="video-play-icon"
                    src={playIcon}
                    alt="play-pause-icon"
                  />
                </Box>
                <video
                  style={{ width: "100%", height: "100%" }}
                  src={lastSingleImage.url}
                  onClick={() => handleSelectedImgVideo(lastSingleImage.id)}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </ImageGalleryContainer>
    </>
  );
};

export default VendorImageVideoGallery;
