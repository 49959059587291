export const HomeIcon = require("./assets/home-icon.svg");
export const DogWalkingIcon = require("./assets/dog-walking-icon.svg");
export const GroomingIcon = require("./assets/grooming-icon.svg");
export const DayCareIcon = require("./assets/day-care-icon.svg");
export const PetSittingIcon = require("./assets/pet-sitting.icon.svg");
export const TrainingIcon = require("./assets/training-icon.svg");
export const LocationIcon = require("./assets/location-icon.svg");
export const FootPrintIcon = require("./assets/footprint-icon.svg");
export const CalendarIcon = require("./assets/calendar-icon.svg");
export const NextArrowIcon = require("./assets/next-arrow-icon.svg");
export const RepeatIcon = require("./assets/repeat-icon.svg");
export const RepeatPurpleIcon = require("./assets/repeat-purple-icon.svg");
export const FilterIcon = require("./assets/filter-icon.svg");
export const CloseIcon = require("./assets/close-icon.svg");


