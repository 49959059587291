import React, { useState } from 'react';
import CustomerCalendar from './CustomerCalendar';
import { Box, Typography, styled } from '@material-ui/core';
import ApointmentDetails from './ApointmentDetails';
import ApointmentsList from './ApointmentsList';
import { Views } from 'react-big-calendar';
import { Shedule } from '../CustomisableUserProfilesController';

type Keys = keyof typeof Views;

type Props = {
    shedules: Shedule[];
    fetchAppointments: (view: (typeof Views)[Keys], date: Date) => void;
}

const CalendarTabWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '35px',

    '& .calendar-section': {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },

    "& .calendar-header": {
        fontSize: '40px',
        color: '#000',
        fontWeight: 600,
        textAlign: 'left'
    }
})

const CalendarTab = ({ shedules, fetchAppointments }: Props) => {
    const [selectedEvent, setSelectedEvent] = useState<Shedule | null>(null);

    const handleSelectEvent = (event: Shedule | null) => {
        setSelectedEvent(event);
    }

    return (
        <CalendarTabWrapper>
            {selectedEvent ? <ApointmentDetails selectedEvent={selectedEvent} onBackClick={handleSelectEvent}/> : 
            <>
                <Box className='calendar-section'>
                    <Typography className='calendar-header'>Calendar</Typography>
                    <CustomerCalendar events={shedules} onEventSelect={handleSelectEvent} fetchAppointments={fetchAppointments} />
                </Box>
                <ApointmentsList appointments={shedules} onEventSelect={handleSelectEvent} />
            </>
            }
            
        </CalendarTabWrapper>
    )
}

export default CalendarTab;