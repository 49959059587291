import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  BookingImg1,
  BookingImg2,
  BookingImg3,
} from "../../landingpage/src/assets";
import { video } from "../../chat/src/assets";
import { video2 } from "./assets";
interface IVendorImgVideoDataModel {
  id: number;
  url: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedImageVideoId: number;
  openViewImageVideo: boolean;
  isOpenImgGallery:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VendorPhotoLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  VendorImgVideoData: IVendorImgVideoDataModel[] = [
    {
      id: 1,
      url: BookingImg1,
    },
    {
      id: 2,
      url: BookingImg2,
    },
    {
      id: 3,
      url: BookingImg3,
    },
    {
      id: 4,
      url: video2,
    },
    {
      id: 5,
      url: BookingImg3,
    },
    {
      id: 6,
      url: BookingImg2,
    },
    {
      id: 7,
      url: BookingImg1,
    },
    {
      id: 8,
      url: BookingImg3,
    },
    {
      id: 9,
      url: video,
    },
    {
      id: 10,
      url: video2,
    },
  ];
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      selectedImageVideoId: 0,
      openViewImageVideo: false,
      isOpenImgGallery:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleImageClick = (id: number) => {
    this.setState({ selectedImageVideoId: id, openViewImageVideo: true });
  };
  handleBackViewImgVideo = () => {
    this.setState({openViewImageVideo:false, selectedImageVideoId:0})
  }
  handleViewMoreClick = () =>{
    this.setState({isOpenImgGallery:true})
  }
  handleCloseClick= () =>{
    this.setState({isOpenImgGallery:false})
  }
  // Customizable Area End
}
