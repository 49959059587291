import { styled } from '@material-ui/core';
import React, { memo, useEffect, useRef } from 'react';
import { MarkerIcon } from './assets';
import { useLoadScript } from '@react-google-maps/api';
import { SearchItem } from '../../blocks/advancedsearch/src/AdvancedSearchController';

const MapWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '375px',

    [theme.breakpoints.up(960)]: {
        width: '325px'
    },
}))

type Props = {
    center: {
      latitude: number;
      longitude: number;
    },
    searchItems: SearchItem[]
}

const WebMapView = ({ center, searchItems }: Props) => {
  const mapRef = useRef<HTMLDivElement | null>(null);


  const getMarkersList = () => {
    if (searchItems) {
      return searchItems.map((item) => ({
        id: item.id,
        position: { lat: item.latitude, lng: item.longitude },
      }));
    }
    return [];
  };

  useEffect(() => {
    if(mapRef.current) {
        const map = new window.google.maps.Map(mapRef.current, {
            center: {
              lat: center.latitude,
              lng: center.longitude,
            },
            zoom: 12,
            disableDefaultUI: true,
            zoomControl: false
        });

        getMarkersList().forEach(marker => {
            new window.google.maps.Marker({
              position: marker.position,
              map,
              icon: MarkerIcon
            });
        });
    }
  }, [center, searchItems]);

  return <MapWrapper ref={mapRef}></MapWrapper>;
}

const MemoizedWebMapView = memo(WebMapView);


export default MemoizedWebMapView;