import React from "react";

// Customizable Area Start
import { Box, Typography, IconButton, TextField, Button, LinearProgress } from "@material-ui/core";
import {
  createTheme,
  styled,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  attachFileIcon,
  backIcon,
  closeIcon,
  closeImageIcon,
  downLoadIcon,
  purpleCloseIcon,
  sendIcon,
  uploadDocumentIcon,
  UserIcon,
} from "./assets";
import CustomViewImage from "../../../components/src/ChatViewImageVideo.web";
import CustomDialog from "../../../components/src/CustomDialog.web";
import Dropzone, { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    secondary:{
      main:"#6F05EA"
    }
  },
  typography: {
    fontFamily: "Montserrat",
  },
});
interface MessageProps {
  theme: Theme;
  isUser?: boolean;
}
// Customizable Area End

import ViewChatController, { configJSON, IMessage, Props } from "./ViewChatController";

// Customizable Area Start

const MessageContainer = styled(Box)(({ theme, isUser }: MessageProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: isUser ? "flex-end" : "flex-start",
  margin: "0.5rem 0",
  "& .message-bubble": {
    maxWidth: "60%",
    padding: "0.5rem 1rem",
    borderRadius: "10px",
    backgroundColor: isUser ? "#f3e5f5" : "#e0e0e0",
  },
  "& .replay-msg-container": {
    backgroundColor: "#fff",
    padding: "10px 11px",
    borderRadius: "10px",
    color: "#B7B7B7",
  },
  "& .message-image": {
    height: "152px",
    width: "152px",
    borderRadius: "4px",
  },
  "& .message-bubble-text": {
    fontSize: "15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  "& .msg-timestamp-container": {
    display: "flex",
    gap: "10px",
  },
  "& .msg-timestamp": {
    color: "#00000040",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  "& .msg-reply-link": {
    color: "#6200ea",
    cursor: "pointer",
    borderBottom: "1px solid #6F05EA",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  "& .image-container":{
    height:"280px",
    width:"280px",
    [theme.breakpoints.down("md")]: {
      height:"250px",
      width:"250px"
    },
    [theme.breakpoints.down("sm")]: {
      height:"200px",
      width:"200px"
    },
    [theme.breakpoints.down("xs")]: {
      height:"150px",
      width:"150px"
    },
  },
  "& .video-container":{
    maxHeight:"280px",
    width:"280px",
    "& video":{
      borderRadius:"8px",
    },
    [theme.breakpoints.down("md")]: {
      width:"250px"
    },
    [theme.breakpoints.down("sm")]: {
      width:"200px"
    },
    [theme.breakpoints.down("xs")]: {
      width:"150px"
    },
  }
}));
const UserNameHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  height: "91px",
  paddingLeft: "35px",
  borderBottom: "1px solid #00000012",
  "& .header-user-img": {
    width: "48px",
    height: "48px",
    [theme.breakpoints.down("md")]: {
      width: "42px",
      height: "42px",
    },
  },
  "& .header-user-name": {
    fontSize: "24px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  "& .back-icon": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
const MessageTypingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: "66px",
  border: "1px solid #ccc",
  alignItems: "center",
  background: "#fff",
  "& .message-input": {
    width: "100%",
    marginRight: 10,
  },
  "& .message-input > div > div > fieldset": {
    borderColor: "transparent !important",
  },
  "& .attach-file-icon-btn": {
    position: "relative",
    "& :hover": {
      backgroundColor: "transparent",
    },
  },
  [theme.breakpoints.down("md")]: {
    height: "55px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "45px",
  },
}));
const TimeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .line": {
    width: "100%",
    borderBottom: "1px solid #00000026",
  },
  "& .msg-day": {
    backgroundColor: "#EFEFEF",
    padding: "11px 26px",
    fontSize: "14px",
    fontWeight: 600,
    borderRadius: "7px",
    margin: "0px 50px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      margin: "0px 20px",
      padding: "11px 22px",
    },
  },
}));
const ImageContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "4px",
  gridTemplateColumns: "repeat(2, 135px)",
  justifyContent: "flex-start",
  "& .image-item": {
    position: "relative",
    width: "135px",
    height: "135px",
    borderRadius: "8px",
    overflow: "hidden",
  },
  "& .image-container.multi": {
    width: "calc(25% - 4px)",
  },
  "& .image-container.single": {
    width: "280px",
    height: "280px",
  },
  "& .gallery-image": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  },
  "& .more-images-overlay": {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    background: "rgba(0, 0, 0, 0.6)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    borderRadius: "8px",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(2, 115px)",
    "& .image-item": {
      width: "100px",
      height: "100px",
    },
  },
}));
const MessageReplyContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F6F0FF",
  padding: "10px",
  borderRadius: "10px",
  "& .reply-message-box": {
    flexGrow: 1,
  },
  "& .reply-message-text": {
    color: "#666",
    fontSize: "15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  "& .reply-message-image": {
    height: "49px",
    width: "45px",
    borderRadius: "4px",
  },
});
const ReplyWriteContainer = styled(Box)({
  display:"flex", 
  flexDirection:"column", 
  marginTop:"auto"
})
const FileUploadContainer = styled(Box)(({ theme }) => ({
  "& .drop-zone-container":{
    border: '2px dashed #00000059',
    borderRadius: '5px',
    padding: '20px 70px',
    textAlign: 'center' as const,
    cursor: 'pointer', 
    display:"flex", 
    alignItems:"center", 
    backgroundColor:"#F8F8F8",
    [theme.breakpoints.down("xs")]: {
      padding: '20px 60px',
  },
  },
  "& .drop-zone-info":{
    display:"flex",
    justifyContent:"center", 
    alignItems:"center", 
    flexDirection:"column"
  },
  "& .upload-icon":{
    height:"90px", 
    width:"90px",
    [theme.breakpoints.down("sm")]: {
        width: "70px",
        height: "70px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
  },
  },
  "& .drag-drop-text":{
    color:"#787878",
    fontSize:"24px", 
    marginTop:"10px",
    [theme.breakpoints.down("sm")]: {
      fontSize:"20px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize:"16px",
  },
  },
  "& .support-file":{
    color:"#787878", 
    fontSize:"17px", 
    fontWeight:"700", 
    marginTop:"15px",
    [theme.breakpoints.down("sm")]: {
      fontSize:"14px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize:"12px",
  },
  },
  "& .upload-progress-container":{
    border:"1px solid #00000059",
      "& .MuiLinearProgress-colorPrimary":{
      backgroundColor:"#6F05EA"
    }
  },
  "& .upload-progress-file":{
    display:"flex", 
    justifyContent:"space-between",
    alignItems:"center"
  },
  "& .upload-progress-file-name":{
    paddingLeft:"10px",
    fontSize:"12px",
    overflow:"hidden",
    whiteSpace:"nowrap",
    textOverflow:"ellipsis"
  },
  "& .file-list-container":{
    display:"flex", 
    flexDirection:"column", 
    gap:"5px",
    marginTop:"10px"
  },
  "& .upload-text":{
    fontSize:"14px",
    fontWeight:"600"
  },
  "& .upload-file-close-icon":{
    height:"14px"
  },
  "& .upload-file-btn-container":{
    display:"flex",
    justifyContent:"center",
    marginTop:"25px"
  },
  "& .upload-file-btn":{
    border: "1px solid #6F05EA",
  borderRadius: "100px",
  padding: "5px 45px",
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "none",
  color: "#6200EA",
  backgroundColor: "transparent",
  boxShadow: "none",
  "&:hover": {
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 35px",
    fontSize: "14px",
  },
  },
  "& .file-error":{
    color:"#f44336",
    fontSize:"12px",
    textAlign:"center",
  }
}));
const ViewChatContainer = styled(Box)(({ theme }) => ({
  height:"100%",
}));
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUserNameWithChat = () => {
    return (
      <UserNameHeader>
        <img
          src={backIcon}
          alt="back-icon"
          onClick={this.props.handleBackListClick}
          className="back-icon"
          data-test-id="view-chat-back-btn"
        />
        <img className="header-user-img" src={UserIcon} alt="user-icon" />
        <Typography className="header-user-name">
          {this.state.dummyUserName}
        </Typography>
      </UserNameHeader>
    );
  };

  renderReplyMsgImageVideo = () => {
    return this.state.replyMessageData.type === "image" ? (
      <img
        className="message-image"
        src={this.state.replyMessageData.image}
        data-test-id="reply-msg-image"
      />
    ) : (
      <video
        src={this.state.replyMessageData.image}
        className="message-image"
      ></video>
    );
  };

  renderMessageList = () => {
    return this.state.dummyMassageList.map((message: any) => {
      return (
        <React.Fragment key={message.id}>
          <MessageContainer isUser={message.isUser}>
            {message.text && (
              <>
                <Box className="message-bubble">
                  <Box>
                    {this.state.replyMessageData &&
                      this.state.replyMessageData.messageId === message.id && (
                        <Box className="replay-msg-container">
                          {this.state.replyMessageData.image ? (
                            this.renderReplyMsgImageVideo()
                          ) : (
                            <Typography
                              className="message-bubble-text"
                              data-test-id="reply-msg-text"
                            >
                              {this.state.replyMessageData.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    <Typography
                      className="message-bubble-text"
                      data-test-id="user-msg"
                    >
                      {message.text}
                    </Typography>
                  </Box>
                </Box>
                <Box className="msg-timestamp-container">
                  <Typography className="msg-timestamp">
                    {message.timestamp}
                  </Typography>
                  <Typography
                    className="msg-reply-link"
                    onClick={() => this.handleReplyMessageClick(message.text)}
                    data-test-id="msg-reply-link"
                  >
                    Reply
                  </Typography>
                </Box>
              </>
            )}
            {message.images &&
              message.images.length > 0 &&
              this.renderImageGallery(message.images, message.timestamp)}
            {message.video &&
              message.video.length > 0 &&
              this.renderVideoGallery(message.video, message.timestamp)}
          </MessageContainer>
        </React.Fragment>
      );
    });
  };

  renderImageGallery = (images: [], timestamp: string) => {
    if (images.length <= 4) {
      return images.map((image, index) => (
        <>
          <Box key={index} style={{ display: "flex", alignItems: "flex-end" }}>
            <Box
              className="image-container"
              data-test-id="image-gallery-container"
              onClick={() => this.handleImageClick(image, "image", timestamp)}
            >
              <img
                src={image}
                alt="single-img"
                className="gallery-image"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <img src={downLoadIcon} alt="download-icon" />
          </Box>
          <Box className="msg-timestamp-container">
            <Typography className="msg-timestamp">{timestamp}</Typography>
            <Typography
              onClick={() => this.handleReplyMessageClick("", image, "image")}
              className="msg-reply-link"
              data-test-id="reply-image"
            >
              Reply
            </Typography>
          </Box>
        </>
      ));
    }
    return (
      <Box style={{ display: "flex", alignItems: "flex-end" }}>
        <ImageContainer>
          {images.slice(0, 4).map((image, index) => (
            <Box key={index} className={`image-item`}>
              <img src={image} alt={`img-${index}`} className="gallery-image" />
              {index === 3 && images.length > 4 && (
                <Box
                  className="more-images-overlay"
                  data-test-id="more-images-overlay"
                  onClick={() =>
                    this.handleImageClick(images, "image", timestamp)
                  }
                >
                  +{images.length - 4}
                </Box>
              )}
            </Box>
          ))}
        </ImageContainer>
        <img src={downLoadIcon} alt="download-icon" />
      </Box>
    );
  };

  renderVideoGallery = (videos: [], timestamp: string) => {
    return videos.map((video, index) => (
      <>
        <Box key={index} style={{ display: "flex", alignItems: "flex-end" }}>
          <Box
            className="video-container"
            onClick={() => this.handleImageClick(video, "video", timestamp)}
            data-test-id="video-gallery-container"
          >
            <video
              src={video}
              className="gallery-image"
              style={{ width: "100%", height: "100%" }}
            ></video>
          </Box>
          <img src={downLoadIcon} alt="download-icon" />
        </Box>
        <Box className="msg-timestamp-container">
          <Typography className="msg-timestamp">{timestamp}</Typography>
          <Typography
            onClick={() => this.handleReplyMessageClick("", video, "video")}
            className="msg-reply-link"
            data-test-id="reply-video"
          >
            Reply
          </Typography>
        </Box>
      </>
    ));
  };

  renderChatDate = () => {
    return (
      <TimeContainer>
        <Box className="line" />
        <span className="msg-day">{this.state.dummyMsgDate}</span>
        <Box className="line" />
      </TimeContainer>
    );
  };

  renderReplyBox = () => {
    if (!this.state.isReplyMessage) return null;
    return (
      <MessageReplyContainer>
        <Box className="reply-message-box">
          <Typography className="reply-message-text">
            {this.state.replyMessageData.message}
          </Typography>
          {this.state.replyMessageData.image &&
            (this.state.replyMessageData.type === "image" ? (
              <img
                className="reply-message-image"
                src={this.state.replyMessageData.image}
              />
            ) : (
              <video
                src={this.state.replyMessageData.image}
                className="reply-message-image"
              ></video>
            ))}
        </Box>
        <IconButton onClick={this.handleReplyClose} data-test-id="reply-close">
          <img src={closeIcon} alt="close-icon" />
        </IconButton>
      </MessageReplyContainer>
    );
  };

  renderViewImgVideo = () => {
    return (
      <CustomViewImage
        handleNextClick={this.handleImageDialogNext}
        handlePrevClick={this.handleImageDialogPrev}
        handleDialogClose={this.handleImageCloseDialog}
        open={this.state.viewImageDialog.isImageDialogOpen}
        userName={this.state.dummyUserName}
        viewImageDialog={this.state.viewImageDialog}
        data-test-id="image-model"
      />
    );
  };
  renderUploadDocument = () => {
    return (
      <CustomDialog
        open={this.state.isUploadDocumentDialogOpen}
        onClose={this.handleUploadDocumentCloseDialog}
        title="Upload Document"
        closeIconProp={closeImageIcon}
        className="chat-upload-document-title"
        data-test-id="upload-document-dialog"
      >
        <FileUploadContainer>
          <Dropzone onDrop={this.handleDrop} data-test-id="drop-zone">
            {({
              getRootProps,
              getInputProps,
            }: {
              getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
              getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
            }) => (
              <Box className="drop-zone-container" {...getRootProps()}>
                <input {...getInputProps()} accept=".png,.jpeg,.mp4" />
                <Box className="drop-zone-info">
                  <img
                    src={uploadDocumentIcon}
                    alt="upload icon"
                    className="upload-icon"
                  />
                  <Typography className="drag-drop-text">
                    Drag Files to Upload
                  </Typography>
                  <Typography className="support-file">
                    Supported Format : PNG, JPEG, MP4
                  </Typography>
                </Box>
              </Box>
            )}
          </Dropzone>
          {this.state.uploadDocumentTypeError && <Typography className="file-error">{this.state.uploadDocumentTypeError}</Typography>}
          <Box className="file-list-container">
            {this.state.uploadDocumentData.uploadFiles.length > 0 && (
              <Typography className="upload-text">Uploading</Typography>
            )}
            {this.state.uploadDocumentData.uploadFiles.map(
              (uploadFile, index) => (
                <Box className="upload-progress-container">
                  <Box className="upload-progress-file">
                    <Typography className="upload-progress-file-name">
                      {uploadFile.file.name}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        this.handleUploadDocumentCloseClick(uploadFile.file)
                      }
                      data-test-id="uploading-file-close"
                    >
                      <img className="upload-file-close-icon" src={purpleCloseIcon} alt="Attach-File-Icon" />
                    </IconButton>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={uploadFile.progress}
                    className="upload-file-progressbar"
                    color="secondary"
                  />
                </Box>
              )
            )}
          </Box>

          <Box className="file-list-container">
            {this.state.uploadDocumentData.completedFiles.length > 0 && (
              <Typography className="upload-text">Uploaded</Typography>
            )}
            {this.state.uploadDocumentData.completedFiles.length > 0 &&
              this.state.uploadDocumentData.completedFiles.map(
                (file, index) => (
                  <Box className="upload-progress-container">
                    <Box className="upload-progress-file">
                      <Typography className="upload-progress-file-name">
                        {file.name}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          this.handleUploadDocumentCloseClick(file)
                        }
                        data-test-id="uploaded-file-close"
                      >
                        <img className="upload-file-close-icon" src={purpleCloseIcon} alt="Attach-File-Icon" />
                      </IconButton>
                    </Box>
                    <LinearProgress color="secondary" variant="determinate" value={100} />
                  </Box>
                )
              )}
          </Box>

          {this.state.uploadDocumentData.completedFiles.length > 0 && (
            <Box className="upload-file-btn-container">
              <Button className="upload-file-btn" onClick={this.handleUploadFileClick} data-test-id="upload-file-btn">
                Upload Files
              </Button>
              </Box>
            )}
        </FileUploadContainer>
      </CustomDialog>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <ViewChatContainer>
          {this.renderUserNameWithChat()}
          <Box
            style={{
              padding: "10px 35px",
              display: "flex",
              flexDirection: "column",
              height: "800px",
              overflowY: "auto",
            }}
          >
            {this.renderChatDate()}
            {this.renderMessageList()}
            {this.renderViewImgVideo()}
            {this.renderUploadDocument()}
            <ReplyWriteContainer>
              {this.renderReplyBox()}
              <MessageTypingContainer>
                <Box className="message-input">
                  <TextField
                    fullWidth
                    id="email"
                    placeholder="Type Message..."
                    type="text"
                    data-test-id="Input-message"
                    variant="outlined"
                    onChange={this.handleInputChange}
                  />
                </Box>
                <IconButton
                  onClick={this.handleInsertImage}
                  className="attach-file-icon-btn"
                  data-test-id="attach-file-icon"
                >
                  <img src={attachFileIcon} alt="Attach-File-Icon" />
                </IconButton>
                <IconButton
                  onClick={this.dummyHandleSendMessage}
                  data-test-id="send-icon"
                >
                  <img src={sendIcon} alt="send-icon" />
                </IconButton>
              </MessageTypingContainer>
            </ReplyWriteContainer>
          </Box>
        </ViewChatContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End