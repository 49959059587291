import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Popover,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  headerLogo,
  languageIcon,
} from "../../components/src/appHeader-assets";
import { styled } from "@material-ui/core/styles";
import { userAvatarImg } from "../../blocks/cfentrepriseaccount/src/assets";
import { headerDocumentIcon, headerEstablishmentSelectIcon, headerFootprintIcon, headerFootprintSelectIcon, headerLogoutIcon, headerLogoutSelectIcon, headerManagementIcon } from "./assets";

const documentIcon = require("./document-icon.svg");
const establishmentIcon = require("./establishment-icon.svg");
const managementIcon = require("./management-icon.svg");
const logoutIcon = require("./logout-icon.svg");

interface AppVendorHeaderProps {
  onMenuSelect: (selected: string) => void;
}

const BoxStyle = styled(Box)(({ theme }) => ({
  padding: "42px 152px",
  display: "flex",
  flexDirection: "row",
  background: "linear-gradient(#6928F2, #884FFF)",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "22px 60px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "14px 0px",
    justifyContent: "space-around",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "12px 42px",
    justifyContent: "space-between",
  },
  "& .Header-container": {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  "& .Header-Name": {
    display: "flex",
    gap: "8px",
  },
  "& .Header-language": {
    fontSize: "20px",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-Register": {
    color: "#FFFFFF",
    fontSize: "20px",
    padding: "9.69px 40.36px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    "& .MuiTypography-colorPrimary": {
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-btn-container": {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
  "& .header-user-name": {
    color: "#fff",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  "& .Header-Btn": {
    borderRadius: "35px",
    padding: "10px 45px",
    backgroundColor: "#FFFFFF",
    color: "#6200EA",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      padding: "0px 35px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
  },
  "& .MuiIconButton-root": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      color: "#FFFFFF",
    },
  },
  "& .Header-menu": {
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      gap: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .header-logo": {
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20px",
    },
  },
  "& .user-profile-img": {
    width: "42px",
    height: "42px",
    borderRadius: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "36px",
      height: "36px",
    },
  },
}));

const MenuContainer = styled(Menu)({
  "& .MuiListItem-root.Mui-selected": {
    background: "linear-gradient(#6928F2, #884FFF)",
    "& .MuiTypography-root": {
      color: "#fff",
    },
  },
  "& .MuiListItem-root.Mui-focusVisible": {
    color: "#fff",
  },
});

const PopoverContainer = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    top:"75px !important",
    marginLeft: "-30px !important",
    [theme.breakpoints.down("md")]: {
      // marginLeft: "-2px !important",
    },
    [theme.breakpoints.down("md")]: {
      // marginLeft: "-80px !important",
    },
    [theme.breakpoints.down("xs")]: {
      // marginLeft: "0px !important",
    },
  },
  "& .MuiMenuItem-root": {
    padding: "8px 30px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
  },
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    background: "linear-gradient(to right,#6928F2, #884FFF)",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
}));

export const AppVendorHeader: React.FC<AppVendorHeaderProps> = ({
  onMenuSelect,
}) => {
  const [selectMenu, setSelectMenu] = useState<string>("Establishment");
  const [open,setOpen] = useState<boolean>(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open);
  };

  const handleSelect = (selectValue: string) => {
    setSelectMenu(selectValue);
    onMenuSelect(selectValue);
    setOpen(!open);
  };

  return (
    <BoxStyle>
      <Box>
        <img className="header-logo" src={headerLogo} alt="Logo" />
      </Box>
      <Box className="Header-container">
        <Box className="Header-Name">
          <img src={languageIcon} alt="Language Icon" />
          <Typography className="Header-language">EN</Typography>
        </Box>
        <Box >
          <Box className="Header-btn-container" onClick={handleMenuOpen}>
            <img
              src={localStorage.getItem("login_user_profile") as string}
              alt=""
              className="user-profile-img"
            />
            <Typography className="header-user-name Header-menu">
              {localStorage.getItem("login_user_name")}
            </Typography>
            <PopoverContainer
              open={open}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }} 
            >
              <MenuItem
                onClick={(event) => handleSelect("Establishment")}
                selected={selectMenu === "Establishment" ? true : false}
              >
                <ListItemIcon>
                  <img
                    src={
                      selectMenu === "Establishment"
                        ? establishmentIcon 
                        : headerEstablishmentSelectIcon
                    }
                    alt="document"
                    className="header-menu-img"
                  />
                </ListItemIcon>
                <Typography variant="inherit">Establishment</Typography>
              </MenuItem>
              <MenuItem
                onClick={(event) => handleSelect("Management")}
                selected={selectMenu === "Management" ? true : false}
              >
                <ListItemIcon>
                  <img
                    src={ managementIcon }
                    alt="my pets"
                    className="header-menu-img"
                  />
                </ListItemIcon>
                <Typography variant="inherit">Management</Typography>
              </MenuItem>
              <MenuItem
                onClick={(event) => handleSelect("Document")}
                selected={selectMenu === "Document" ? true : false}
              >
                <ListItemIcon>
                  <img
                    src={documentIcon}
                    alt="settings"
                    className="header-menu-img"
                  />
                </ListItemIcon>
                <Typography variant="inherit">Document</Typography>
              </MenuItem>
              <MenuItem
                onClick={(event) => handleSelect("Logout")}
                selected={selectMenu === "Logout" ? true : false}
              >
                <ListItemIcon>
                  <img
                    src={
                      selectMenu === "Logout"
                        ? headerLogoutSelectIcon
                        : headerLogoutIcon
                    }
                    alt="document"
                    className="header-menu-img"
                  />
                </ListItemIcon>
                <Typography variant="inherit">Logout</Typography>
              </MenuItem>
            </PopoverContainer>
          </Box>
        </Box>
      </Box>
    </BoxStyle>
  );
};
