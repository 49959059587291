// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Settings2 from '../../blocks/settings2/src/Settings2';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web"
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web"
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web"
import Contactus from "../../blocks/contactus/src/Contactus.web"
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions.web';
import UserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web';
import EmailAccountVendorRegistration from "../../blocks/email-account-registration/src/EmailAccountVendorRegistration.web"
import Cfentrepriseaccount from '../../blocks/cfentrepriseaccount/src/Cfentrepriseaccount.web';
import Maps from '../../blocks/maps/src/Maps.web'
import Chat from '../../blocks/chat/src/Chat.web';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SearchHeaderProvider } from '../../components/src/context/SearchHeaderContext.tsx';
import LandingPageWeb from '../../blocks/landingpage/src/LandingPage.web';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch.web';
import CustomisableUserProfilesVender from '../../blocks/customisableuserprofiles/src/CustomisableUserProfilesVender.web';

import CustomisableUserDashboardVendor from '../../blocks/customisableuserprofiles/src/CustomisableUserDashboardVendor.web.tsx'
import LandingPageBookingWeb from '../../blocks/landingpage/src/LandingPageBooking.web';
import VendorPhotoLibraryWeb from '../../blocks/photolibrary/src/VendorBookingPhotoLibrary.web'
import CustomisableUserGallary from '../../blocks/customisableuserprofiles/src/CustomisableUserGallary.web';
import RequestDetails from '../../blocks/customisableuserprofiles/src/RequestDetails.web.tsx';
import CustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web';

import CustomisableUserCalendarVendorWeb from '../../blocks/customisableuserprofiles/src/CustomisableUserCalendarVendor.web.tsx';
import SettingGallary from '../../blocks/settings2/src/SettingGallary.web.tsx';
import RequestManagement from '../../blocks/requestmanagement/src/RequestManagement.web.tsx';
import GeneralRequestTab from '../../blocks/requestmanagement/src/GeneralRequestTab.web.tsx'
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu.web.tsx'
import ViewEmployee from '../../blocks/settings2/src/EmployeeManagement/ViewEmployee.web.tsx';

const routeMap = {
  Settings2: {
    component: Settings2,
    path: '/Settings2',
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu',
  },
  
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement',
  },
  GeneralRequestTab: {
    component: GeneralRequestTab,
    path: '/GeneralRequestTab',
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/Login',
    exact: true,
  },
  CustomisableUserProfilesVender: {
    component: CustomisableUserProfilesVender,
    path: '/UserProfilesVender',
    exact: true,
  },
  EmailAccountRegistration:{
    component: EmailAccountRegistration,
    path: '/Signup',
    exact: true,
  },
  TermsConditions: {
    component:TermsConditions,
    path:'/Termscondition',
    exact:true
  },
  ForgotPassword:{
    component: ForgotPassword,
    path: '/ForgotPassword',
    exact: true,
  },
  NewPassword:{
    component: NewPassword,
    path: '/newPassword',
    exact: true,
  },
  EmailAccountVendorRegistration:{
    component: EmailAccountVendorRegistration,
    path: '/VendorSignUp',
    exact: true,
  },
  Cfentrepriseaccount:{
    component: Cfentrepriseaccount,
    path: '/Cfentrepriseaccount',
    exact: true,
  },
  ChatWeb:{
    component: Chat,
    path: '/chat',
    exact: true,
  },
  Maps:{
    component: Maps,
    path: '/Maps',
  },
  CustomisableUserDashboardVendor:{
    component: CustomisableUserDashboardVendor,
    path: '/CustomisableUserDashboardVendor',
  },
  CustomisableUserGallary:{
    component: CustomisableUserGallary,
    path: '/CustomisableUserGallary',
  },
  CustomisableUserProfiles:{
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles',
  },
  RequestDetails:{
    component:RequestDetails,
    path:'/RequestDetails'
  },
  SettingGallary:{
    component:SettingGallary,
    path:'/SettingGallary'
  },
  LandingPageWeb: {
    component: LandingPageWeb,
    path: '/LandingPageWeb',
    exact: true,
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  UserProfile: {
    component: UserProfiles,
    path: '/Profile',
    exact: true
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus',
    exact: true,
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch',
    exact: true,
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true,
  },
  LandingPageBooking:{
    component: LandingPageBookingWeb,
    path: '/LandingPageBooking',
    exact: true,
  },
  VendorPhotoLibraryWeb:{
    component: VendorPhotoLibraryWeb,
    path: '/VendorPhotoLibraryWeb',
    exact: true,
  },
  CustomisableUserCalendarVendorWeb:{
    component: CustomisableUserCalendarVendorWeb,
    path: '/CalendarVendorWeb',
    exact: true,
  },
  ViewEmployee:{
    component: ViewEmployee,
    path: '/ViewEmployee',
    exact: true,
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <SearchHeaderProvider>
        <View style={{ height: "100%"}}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </SearchHeaderProvider>
    );
  }
}

export default App;
