import { Box, Typography } from '@material-ui/core';
import React from 'react'

const CalendarHeader = ({ label }: { label: string }) => {
    const [dayNumber, dayText] = label.split(" ");
  
    return (
      <Box className="custom-header">
        <Typography className='c-header-text'>{dayText}</Typography>
        <Typography className='c-header-text'>{dayNumber}</Typography>
      </Box>
    );
  };

export default CalendarHeader