import React, { ReactNode } from "react";
import {
  Backdrop,
} from "@material-ui/core";

interface Props {
  onClick?: (e: React.ChangeEvent<any>) => void;
  open: boolean;
  children: ReactNode;
}

const styles = {
  backdrop: {
    zIndex: 1300, // Adjust the z-index according to your needs
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the background color and transparency
    color: "#fff",
  },
};

const CommonBackdrop = (props: Props) => {
  const { onClick, open, children } = props;
  return (
    <>
      <Backdrop style={styles.backdrop} open={open} onClick={onClick} className="back-drop">
        {children}
      </Backdrop>
    </>
  );
};

export default CommonBackdrop;
