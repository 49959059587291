import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { ICommoditiesOption } from "./Commodities.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import { IFilteredService } from "../../../../components/src/interfaces.web";

interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}

interface IAttributes {
  id: number;
  service_type: string;
  about: string;
  dog: boolean;
  fish: boolean;
  cats: boolean;
  bird: boolean;
  rabbit: boolean;
  reptile: boolean;
  rodents: boolean;
  schedule_information: string;
  lunch_time: null | string;
  pool: null | boolean;
  outdoor_hotel: null | boolean;
  veterinary_support: null | boolean;
  indoor_hotel: null | boolean;
  toys_at_display: null | boolean;
  litter_boxes: null | boolean;
  fresh_water: null | boolean;
  natural_food: null | boolean;
  poop_spaces: null | boolean;
  comfortable_beds: null | boolean;
  individual_room: null | boolean;
  cat_trees: null | boolean;
  daily_walks: null | boolean;
  group_rooms: null | boolean;
  catio: null | boolean;
  garden: null | boolean;
  socializing_activities: null | boolean;
  account_id: number;
  establishment_id: number;
  cancellation_policy: null | string;
  created_at: string;
  updated_at: string;
  service_galleries_urls: null | string;
  establishment: {
    id: number;
    establishment_name: string;
    address: string;
    country: string;
    city: string;
    zipcode: string;
    email: string;
    phone_number: number;
    facebook_url: string;
    instagram_url: string;
    linkedin_url: string;
    tiktok_url: string;
    account_id: number;
    created_at: string;
    updated_at: string;
    activated: false;
    latitude: number;
    longitude: number;
  };
  sub_services_with_prices: [];
}

interface IErrors{
  token?:string
}

interface IListCommoditiesResponse {
  data: {
    id: string;
    type: string;
    attributes: IAttributes;
  };
  errors?:string
}
interface IUpdateListCommoditiesResponse {
  data: {
    id: string;
    type: string;
    attributes: IAttributes;
  };
  errors?:IErrors[]
}

interface ICommoditiesValue {
  outdoor_hotel: null | boolean;
  pool: null | boolean;
  veterinary_support: null | boolean;
  indoor_hotel: null | boolean;
  litter_boxes: null | boolean;
  fresh_water: null | boolean;
  poop_spaces: null | boolean;
  individual_room: null | boolean;
  comfortable_beds: null | boolean;
  natural_food: null | boolean;
  daily_walks: null | boolean;
  toys_at_display: null | boolean;
  cat_trees: null | boolean;
  catio: null | boolean;
  group_rooms: null | boolean;
  socializing_activities: null | boolean;
  garden: null | boolean;
}

const commoditiesData = [
  {
    key: "pool",
    label: "Pool",
    value:false,
    id:1
  },
  {
    key: "outdoor_hotel",
    label: "Outdoor Hotel",
    value:false,
    id:2
  },
  {
    key: "veterinary_support",
    label: "Veterinary Support",
    value:false,
    id:3
  },
  {
    key: "indoor_hotel",
    label: "Indoor Hotel",
    value:false,
    id:4
  },

  {
    key: "toys_at_display",
    label: "Toys At Display",
    value:false,
    id:5
  },
  {
    key: "litter_boxes",
    label: "Litter Boxes",
    value:false,
    id:6
  },
  {
    key: "fresh_water",
    label: "Fresh Water",
    value:false,
    id:7
  },

  {
    key: "natural_food",
    label: "Natural Food",
    value:false,
    id:8
  },
  {
    key: "poop_spaces",
    label: "Poop Spaces",
    value:false,
    id:9
  },

  {
    key: "comfortable_beds",
    label: "Comfortable Beds",
    value:false,
    id:10
  },
  {
    key: "individual_room",
    label: "Individual Room",
    value:false,
    id:11
  },

  {
    key: "cat_trees",
    label: "Cat Trees",
    value:false,
    id:12
  },
  {
    key: "daily_walks",
    label: "Daily Walks",
    value:false,
    id:13
  },

  {
    key: "group_rooms",
    label: "Group Rooms",
    value:false,
    id:14
  },
  {
    key: "catio",
    label: "Catio",
    value:false,
    id:15
  },
  {
    key: "garden",
    label: "Garden",
    value:false,
    id:16
  },
  {
    key: "socializing_activities",
    label: "Socializing Activities",
    value:false,
    id:17
  },
]


export const configJSON = require("../config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedService:IFilteredService;
  getServicesList:(id:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEdit: boolean;
  selectedCommoditiesList: ICommoditiesOption[];
  commoditiesList: ICommoditiesOption[];
  commoditiesListForCancel: ICommoditiesOption[];
  serviceId:number;
  commoditiesListLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CommoditiesWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCommoditiesApiCallId: string = "";
  updateCommoditiesApiCallId: string = "";
  addCommoditiesApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
      isEdit: false,
      selectedCommoditiesList: [],
      commoditiesList: commoditiesData,
      commoditiesListForCancel:[],
      serviceId:0,
      commoditiesListLoading:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getCommoditiesApiCallId:
            this.handleGetCommoditiesListResponse(responseJson);
            break;
          case this.updateCommoditiesApiCallId:
            this.handleUpdateCommoditiesListResponse(responseJson);
            break;
          case this.addCommoditiesApiCallId:
            this.handleAddCommoditiesListResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleEditClick = () => {
    this.setState({ isEdit: true });
  };

  handleCancelClick = () => {
    if(this.props.selectedService.service_id){
    this.setState({ isEdit: false, commoditiesList: this.state.commoditiesListForCancel});
    }else{
      this.setState({ isEdit: false, commoditiesList: commoditiesData});
    }
  };

  handleCheckboxChange = (item: ICommoditiesOption) => {
    this.setState((prevState) => ({
      commoditiesList: prevState.commoditiesList.map((commodity) =>
        commodity.key === item.key
          ? { ...commodity, value: !commodity.value }
          : commodity
      ),
    }));
  };

  handleSaveClick = () => {
    if(this.props.selectedService.service_id){
      this.updateCommoditiesList();
    }else{
      this.addCommoditiesList();
    }
    this.setState({ isEdit: false });
  };

  async componentDidMount() {
    super.componentDidMount();
    if(this.props.selectedService.service_id){
    this.getCommoditiesList();
    }
  }

  apiCall = async (data: IApiModel) => {
    const {
      contentType,
      method,
      endPoint,
      body,
      token,
      isJsonStringify,
    } = data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getCommoditiesList = async () => {
    const establishment_ID = await getStorageData("establishment_ID");
    const token = await getStorageData("login_token");
    this.setState({commoditiesListLoading:true});
    if (token) {
      this.getCommoditiesApiCallId = await this.apiCall({
        method: configJSON.httpGetMethod,
        token: token,
        endPoint: `${
          configJSON.getCommoditiesEndPoint
        }service[service_type]=${this.props.selectedService.name}&service[establishment_id]=${establishment_ID}`,
      });
    }
  };

  convertAttributes = (
    attributes: ICommoditiesValue,
  ): ICommoditiesOption[] => {
    return Object.entries(attributes).map(([key, value], index) => ({
      id: index + 1,
      label: key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()),
      key:key,
      value: value ?? false,
    }));
  };

  handleGetCommoditiesListResponse = (
    responseJson: IListCommoditiesResponse
  ) => {
    this.setState({commoditiesListLoading:false});
    if(responseJson && responseJson.data && responseJson.data.attributes && !responseJson.errors){
    const {
      pool,
      outdoor_hotel,
      veterinary_support,
      indoor_hotel,
      toys_at_display,
      litter_boxes,
      fresh_water,
      natural_food,
      poop_spaces,
      comfortable_beds,
      individual_room,
      cat_trees,
      daily_walks,
      group_rooms,
      catio,
      garden,
      socializing_activities,
    } = responseJson.data.attributes;

    const data: ICommoditiesValue = {
      pool,
      outdoor_hotel,
      veterinary_support,
      indoor_hotel,
      toys_at_display,
      litter_boxes,
      fresh_water,
      natural_food,
      poop_spaces,
      comfortable_beds,
      individual_room,
      cat_trees,
      daily_walks,
      group_rooms,
      catio,
      garden,
      socializing_activities,
    };
    const convertedAttributes = this.convertAttributes(
      data
    );
    this.setState({commoditiesList:convertedAttributes, commoditiesListForCancel:convertedAttributes});
  }
  else{
    this.setState({commoditiesList:commoditiesData, commoditiesListForCancel:commoditiesData});
  }
  };

  updateCommoditiesList = async () => {
    let formData = new FormData();
    this.state.commoditiesList.forEach((item) => {
      formData.append(`service[${item.key}]`, (item.value ? item.value : false).toString());
    });
    const token = await getStorageData("login_token");
    
    if (token) {
      this.updateCommoditiesApiCallId = await this.apiCall({
        method: configJSON.httpPatchMethod,
        token:token,
        endPoint: `${configJSON.updateCommoditiesEndPoint}${this.props.selectedService.service_id}`,
        body: formData,
        isJsonStringify: false,
      });
    }
  };

  handleUpdateCommoditiesListResponse = (responseJson:IUpdateListCommoditiesResponse) => {
    if(responseJson && responseJson.data && responseJson.data.attributes && !responseJson.errors){
      this.getCommoditiesList();
    }
    else{
      this.setState({commoditiesList:commoditiesData, commoditiesListForCancel:commoditiesData});
    }
  };

  
  addCommoditiesList = async () => {
    const establishment_ID = await getStorageData("establishment_ID");
    const account_ID =  await getStorageData("login_user_id");
    let formData = new FormData();
    this.state.commoditiesList.forEach((item) => {
      formData.append(`service[${item.key}]`, (item.value ? item.value : false).toString());
    });
    formData.append("service[establishment_id]",establishment_ID);
    formData.append("service[account_id]",account_ID);
    formData.append("service[service_type]", this.props.selectedService.name);
    const token = await getStorageData("login_token")
    if (token) {
      this.addCommoditiesApiCallId = await this.apiCall({
        method: configJSON.httpPostMethod,
        token:token,
        endPoint: `${configJSON.addCommoditiesEndPoint}`,
        body: formData,
        isJsonStringify: false,
      });
    }
  };

  handleAddCommoditiesListResponse = async (responseJson:IUpdateListCommoditiesResponse) => {
    const establishment_ID = await getStorageData("establishment_ID");
    if(responseJson && responseJson.data && responseJson.data.attributes && !responseJson.errors){
      this.props.getServicesList(establishment_ID);
      this.getCommoditiesList();
    }
  };
  // Customizable Area End
}
