import React from "react";
// Customizable Area Start
import {
    Box, styled, List, ListItem, ListItemIcon, ListItemText,
    Typography, Button, Radio, RadioGroup, FormControlLabel, FormControl, Avatar
} from '@material-ui/core';
import {
    DayCareBlack,
    DogWalkingBlack,
    HotelsBlack,
    GroomingBlack,
    PetSittingBlack,
    TrainingBlack,
    DayCareBlue,
    DogWalkingBlue,
    HotelsBlue,
    GroomingBlue,
    PetSittingBlue,
    TrainingBlue,
    AddServicesBlue,
    checkedRadio,
    unCheckedRadio,
    SwitchEstablishment,
    EstablishmentSetting
} from "./assets";
import Dialog from '@material-ui/core/Dialog';
import { returnTruthyString } from "../../../components/src/HelperUtils";

type ServiceIcons = {
    black_icon: string;
    blue_icon: string;
    label: string;
};
const ServicesList: Record<string, ServiceIcons> = {
    "Day Care": {
        black_icon: DayCareBlack,
        blue_icon: DayCareBlue,
        label: "Day Care",
    },
    "Dog Walking": {
        black_icon: DogWalkingBlack,
        blue_icon: DogWalkingBlue,
        label: "Dog Walking",

    },
    "Hotels": {
        black_icon: HotelsBlack,
        blue_icon: HotelsBlue,
        label: "Hotels",
    },
    "Grooming": {
        black_icon: GroomingBlack,
        blue_icon: GroomingBlue,
        label: "Grooming",
    },
    "Grooming (Coming Soon)": {
        black_icon: GroomingBlack,
        blue_icon: GroomingBlue,
        label: "Grooming",
    },
    "Pet Sitting": {
        black_icon: PetSittingBlack,
        blue_icon: PetSittingBlue,
        label: "Pet Sitting",
    },
    "Training": {
        black_icon: TrainingBlack,
        blue_icon: TrainingBlue,
        label: "Training",
    }
}

import SidebarServicesController, { Props } from "./SidebarServicesController.web";
// Customizable Area End

export default class SidebarServices extends SidebarServicesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderDynamicMenus = () => {
        return (
            <>
                <ListWrapper style={style.listMargin}>
                    {this.props.filtredServicesList.map((service, index) => {
                        const imageURL = this.props.selectedService.name == service.name ? ServicesList[service.name]?.blue_icon : ServicesList[service.name]?.black_icon
                        const fontClass = this.props.selectedService.name == service.name ? "blue-font" : ""
                        const menuName = ServicesList[service.name]?.label
                        return (
                            <StyledList button key={index} data-testid={`menu`} onClick={() => this.selectListItem(service)}>
                                <ListIcon>
                                    <img src={imageURL} />
                                </ListIcon>
                                <ListLabel primary={menuName} className={fontClass} />
                            </StyledList>
                        )
                    })}
                    <StyledList button data-testid="add-service-tab" onClick={this.props.openServiceModalFn}>
                        <ListIcon>
                            <img src={AddServicesBlue} />
                        </ListIcon>
                        <ListLabel primary={"Add Service"} className="blue-font" />
                    </StyledList>
                </ListWrapper>
            </>
        )
    }
    renderEstablishment = () => {
        const stringObj = localStorage.getItem("establishmentInfo");
        const establishmentDetails = stringObj ? JSON.parse(stringObj) : {};
        const avatarUrl = returnTruthyString(establishmentDetails?.attributes?.establishment_photo)
        const establishment_name = returnTruthyString(establishmentDetails?.attributes?.establishment_name)
        return (
            <>
                <ListWrapper style={style.establishmentWrapper}>
                    <EstablishmentDetails>
                        <EstablishmentAvatar src={avatarUrl} />
                        <EstablishmentTitle noWrap>
                            <span title={establishment_name}>{establishment_name}</span>
                        </EstablishmentTitle>
                    </EstablishmentDetails>
                    <EstablishmentTab button id="switch-establishment" data-test-id="navigation-Id" onClick={this.GotoSettingScreen}  >
                        <ListIcon><img src={SwitchEstablishment} /></ListIcon>
                        <ListLabel primary={"Switch Establishment"} className="black-font" />
                    </EstablishmentTab>
                    {/* <EstablishmentTab button id="establishment-setting" data-test-id="navigation-Button" onClick={this.GotoSettingScreen}> */}
                    <EstablishmentTab button id="establishment-setting" data-test-id="navigation-Button" onClick={this.props.showsettingFn}>
                        <ListIcon><img src={EstablishmentSetting}/></ListIcon>
                        <ListLabel primary={"Establishment Settings"} className="black-font" />
                    </EstablishmentTab>
                </ListWrapper>
            </>
        )
    }
    renderAddServiceModal = () => {
        return (
            <AddServicesModal
                open={this.props.addServiceModalOpen}
                fullWidth
                maxWidth={"md"}
                aria-labelledby="add-services-id"
                data-testid="add-services-modal"
            >
                <ModalTitle>Select Service Type</ModalTitle>
                <FormWrapper>
                    <RadioWrapper>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name="position" defaultValue="top">
                                {this.state.addServicesList.map((service) => {
                                    const isGrooming = service.name == "Grooming"
                                    const label = isGrooming ? "Grooming (Coming Soon)" : ServicesList[service.name].label
                                    return (
                                        <StyledRadioControl
                                            disabled={isGrooming}
                                            value={service.name}
                                            onChange={(event) => this.handleRadioChange(service.name)}
                                            checked={this.state.selectedAddService == service.name}
                                            control={
                                                <Radio color="primary"
                                                    checkedIcon={<img src={checkedRadio} />}
                                                    icon={<img src={unCheckedRadio} />} />
                                            }
                                            label={
                                                <LabelBox style={{ opacity: isGrooming ? 0.5 : 1 }}>
                                                    <img src={ServicesList[service.name].black_icon} /> {label}
                                                </LabelBox>
                                            }
                                            labelPlacement="end"
                                            data-testid="add-service-menus"
                                        />
                                    )
                                })}

                            </RadioGroup>
                        </FormControl>

                    </RadioWrapper>

                    <ButtonWrapper>
                        <CustomButton
                            style={style.blackBtn}
                            onClick={this.closeAddServiceModal}
                            data-testid="cancel-btn"
                        >
                            Cancel
                        </CustomButton>
                        <CustomButton
                            disabled={!this.state.selectedAddService}
                            style={style.blueBtn}
                            onClick={this.handleNextBtnClick}
                            data-testid="next-btn"
                        >
                            Next
                        </CustomButton>
                    </ButtonWrapper>
                </FormWrapper>
            </AddServicesModal >
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <MainWrapper>
                    {this.renderDynamicMenus()}
                    {this.renderEstablishment()}
                    {this.renderAddServiceModal()}
                </MainWrapper>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const ListWrapper = styled(List)({
    padding: "0px !important"
})

const MainWrapper = styled(Box)({
    padding: "24px !important"
})

const StyledList = styled(ListItem)({
    height: 48,
    padding: "12px !important",
    marginBottom: "20px",
    borderRadius: "8px !important",
})
const EstablishmentTab = styled(ListItem)({
    height: 48,
    padding: "12px !important",
    marginBottom: "8px",
    borderRadius: "8px !important",
})

const ListIcon = styled(ListItemIcon)({
    minWidth: 0,
    marginRight: 12
})
const ListLabel = styled(ListItemText)({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#0F172A",
    "& span": {
        fontFamily: 'Montserrat',
    },
    "&.blue-font": {
        color: "#6F05EA !important",
    },
    "&.black-font": {
        color: "#000 !important",
    }
})

const AddServicesModal = styled(Dialog)({
    "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
            maxWidth: 768,
            padding: "40px",
            borderRadius: "8px"
        },
    },
})
const ModalTitle = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "27px",
    color: "#6F05EA",
})
const FormWrapper = styled(`form`)({
    margin: "0"
})
const RadioWrapper = styled(Box)({
    margin: "40px 0"
})
const ButtonWrapper = styled(Box)({
    display: "flex",
    justifyContent: "end",
    columnGap: "20px",
})
const CustomButton = styled(Button)({
    padding: "8px 0",
    height: "40px",
    width: "140px",
    borderRadius: "100px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "none"
})

const LabelBox = styled(Box)({
    marginLeft: "22px",
    display: "flex",
    flexDirection: "row",
    columnGap: "12px",
    alignItems: "center",

    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000",
})
const StyledRadioControl = styled(FormControlLabel)({
    margin: "0px 0px 20 0px"
})

const EstablishmentDetails = styled(Box)({
    marginBottom: "16px",
    display: "flex",
    flexDirection: "row",
    columnGap: "20px",
    alignItems: "center",
})
const EstablishmentAvatar = styled(Avatar)({
    height: 56,
    width: 56
})
const EstablishmentTitle = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "20px",
    color: "#000000",
})
const style = {
    listMargin: {
        marginTop: 108
    } as React.CSSProperties,
    blueBtn: {
        color: "#6F05EA",
        border: "1px solid #6F05EA",
    } as React.CSSProperties,
    blackBtn: {
        color: "#4B4B4B",
        border: "1px solid #4B4B4B",
    } as React.CSSProperties,
    establishmentWrapper: {
        marginTop: 200
    } as React.CSSProperties,
}
// Customizable Area End
