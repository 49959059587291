import React from "react";
// Customizable Area Start
import { StyleSheet, Dimensions, Platform } from "react-native";
import {
  Box,
  Container,
  Modal

} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  RupeeIcon,smallarrow,crossButton
} from "./assets";
import WebMapView from "../../../components/src/MapViewModal";
import Filters from "./components/FilterSection";
import ListItem from "./components/ListItem";

// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEmailStartAdornment = () => <img src={RupeeIcon} />
  ;
  // Customizable Area End

  render() {
    // Customizable Area Start
    const ContainerBox = styled("div")({
      display: "flex",
      flexWrap: "wrap",
      width:'103%',
      gap:'10px'
    });
    const Column = styled("div")(({ theme }) => ({
      flex: "1 1 33%", // 3 columns
      padding: theme.spacing(2),
      boxSizing: "border-box",
      [theme.breakpoints.down(960)]: {
        flex: "1 1 100%", // 1 column on small screens
      },
    }));
    const PageWrapper = styled(Container)(({ theme }) => ({

      maxHeight:' 90%',
      width:'85%',
      display:'flex',
      background:'#F6F4F9',
      flexDirection: "column",
      overflow: 'scroll',
      top:'5%',
      left:'5%',
      marginTop:54,
      alignItems: 'flex-start',
      borderRadius:4,
    
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 532px)",
      },
      [theme.breakpoints.up(962)]: {
        width: "calc(100% - 300px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% - 40px)",
      },
      "& *": {
        boxSizing: "border-box",
      },
    
      "& .MuiTypography-root.MuiTypography-body1": {
        fontFamily: "Montserrat",
      },
   
      "& .filters": {
        width: "100%",
        maxWidth:'315px',
        background: "#fff",
        display: "flex",
        borderRadius: "8px",
        height: '531px',
        boxShadow:' 0px 4px 8px 0px #00000008',
        marginTop:30
      },
      "& .sort-wrapper": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .result-text": {
        color: "#6F05EA",
        fontWeight: 700,
        fontSize: "16px",
      },
      "& .view-map-btn": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#fff",
        padding: "10px 16px",
        borderRadius: "8px",
        color: "#6F05EA",
        fontSize: "16px",
        fontWeight: 600,
        cursor: "pointer",
      },
    
      "& .map-filter-block": {
        width: "103%",
        flexDirection: "row",
        gap: "24px",
      },
    
      "& .map-wrapper": {
        position: "relative",
        width:'100%',
        marginLeft:-22,
        marginTop:-17
      },
      "& .content":{
      width:'103%',
      height:'100%',
      background:'#F6F4F9',
      flexDirection: "column",
      overflow: 'scroll',
      alignItems: 'flex-start',
      borderRadius:4,
    },

      "& .list-block": {
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        marginTop:20,
        maxWidth:'305px'
      },
      "& .Modal-container": {
        background:'#F6F4F9',
        width:'80%'
       },
       "& .smallArrow-box":{
        position:'absolute',
  width: '44px',
  height: '44px',
  marginLeft: 563,
  zIndex: 1,
  marginTop: '-9px',
 
  [theme.breakpoints.down(963)]: {
    visibility: 'collapse'
      },
       },
       "& .crossButton-box":{
        position:'absolute',
  width: '44px',
  height: '44px',
  marginLeft: 563,
  zIndex: 1,
  marginTop: '-9px',
  right:0,
 marginRight: 112,
  
    [theme.breakpoints.up(1900)]: {
      marginRight: 137,
        },
        [theme.breakpoints.up(1240)]: {
          marginRight:158,
          marginTop:-23
            },
            [theme.breakpoints.down(1040)]: {
              visibility: 'collapse'
                },
                [theme.breakpoints.up(1040)]: {
                 marginTop:-26,
                 marginRight:145
                    },
       },
       crossButton
    }));
    return (
      <>
      <button style={{
        fontFamily: 'Montserrat',
        padding: '0 28px',
        fontWeight: 700,
        fontSize: '12px',
        color: '#909090',
        textDecoration: 'underline',
        background: 'transparent',
        border: 'none'
      }} 
      data-test-id="viewmap" 
      onClick={() => this.handleClose()}>
        Show in map
      </button>
      {this.state.enableModal && (
      <Modal
      style={{
     }}
      open={this.state.enableModal}
      onClose={() => this.handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-test-id="handleModal"
      hideBackdrop={false}
      >
      <PageWrapper maxWidth="xl" data-test-id="advanced-search-wrapper">
        <ContainerBox>
        <Column className="filters">
            <Filters
              type={this.state.searchTab}
              values={this.state.filters}
              onChange={this.changeFilterValue}
            />
          </Column>
          <Column className="list-block">
          {this.state.searchItems.map((item) => (
            <ListItem item={item} key={item.id} />
          ))}
        </Column>
       <Box className="smallArrow-box"><img src={smallarrow}/></Box>
          <Column className="map-wrapper">
            <WebMapView
              center={{
                lat: this.state.region.latitude,
                lng: this.state.region.longitude,
              }}
              markers={this.getMarkersList()}
            />
          </Column>
          <Box onClick={()=>{this.handleClose()}} data-test-id="closemap" className="crossButton-box"><img src={crossButton}/></Box>
          </ContainerBox>
    </PageWrapper>
    </Modal>
      )}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  map: {
    width:' 749px',
    height: '2114px',
    position: "absolute",
    top: 0,
    bottom: 0,
    right:0
  },
}
// Customizable Area End
