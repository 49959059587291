import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { LandingPageHeader } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import {
  carouselNextArrowIcon,
  carouselPrevArrowIcon,
  LandingPageFooterImg,
  LocationIcon,
  NextArrowIcon,
  RecentlyBookCardImg,
} from "./assets";
import Slider from "react-slick";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

// Customizable Area Start
interface IRecentBookServiceModel{
  title: string,
  location: string,
  price: string,
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ width: "100%" }}>{children}</Box>}
    </div>
  );
}

const LandingPageContainer = styled(Box)(({ theme }) => ({
  "& .discount-banner-container": {
    width: "100%",
    marginTop: "100px",
  },
  "& .discount-banner-img": {
    width: "100%",
    display:"none",
    [theme.breakpoints.down("sm")]: {
      height:"230px",
    },
    [theme.breakpoints.down("xs")]: {
      height:"250px",
    },
  },
  "& .footer-container": {
    position: "relative",
    width: "100%",
    height: "550px",
    overflow: "hidden",
    marginTop:"50px"
  },
  "& .footer-img": {
    height: "100%",
    width: "100%",
  },
  "& .footer-content": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    textAlign: "center",
  },
  "& .footer-text-part-a": {
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "1.2",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  "& .footer-text-part-b": {
    fontWeight: "400",
    fontSize: "18px",
    textAlign: "center",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  "& .footer-btn": {
    border: "1px solid #fff",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6F05EA",
    backgroundColor: "#fff",
    boxShadow: "none",
    marginTop: "20px",
    "&:hover": {
      color: "#6F05EA",
      backgroundColor: "#fff",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 35px",
      fontSize: "14px",
    },
  },
}));
const TabPanelContainer = styled(Box)(({ theme }) => ({
  padding: "0px 160px",
  [theme.breakpoints.down("md")]: {
    padding: "0px 80px",
  },
  [theme.breakpoints.between(601,780)]: {
    padding: "0px 50px",
  },
  [theme.breakpoints.down(480)]: {
    padding: "0px 25px",
  },
}));
const SliderContainer = styled(Box)(({ theme }) => ({
  marginTop: "150px",
  [theme.breakpoints.down("sm")]: {
    marginTop:"50px"
  },
  "& .recently-book-title": {
    fontWeight: 600,
    fontSize: "24px",
    marginBottom: "30px",
  },
  "& .slick-prev": {
    left: "0",
    zIndex: 10,
    height:"50px",
    [theme.breakpoints.down("xs")]: {
      height:"20px"
    },
  },
  "& .slick-next": {
    right: "0",
    height:"50px",
    [theme.breakpoints.down("xs")]: {
      height:"20px"
    },
  },
  "& .slick-slider": {
    height: "430px",
  },
  "& .card-location-info": {
    display: "flex",
    gap: "5px",
  },
  "& .card-location-icon":{
    height:"14px",
    width:"14px"
  },
  "& .card-container": {
    overflow: "hidden",
    margin: "0px 25px",
    borderRadius: "25px",
    boxShadow: "0px 0px 5px rgba(0,0,0,25%)",
  },
  "& .card-img": {
    width: "100%",
    height: "191px",
  },
  "& .card-data-container": {
    padding: "16px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .card-price": {
    color: "#6F05EA",
    fontWeight: 800,
    fontSize: "16px",
    height:"25px"
  },
  "& .card-price-span": {
    color: "#6F05EA",
    fontWeight: 400,
    height:"25px"
  },
  "& .card-title": {
    fontSize: "16px",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    MozBoxOrient: "vertical",
    height: "48px",
  },
  "& .card-location": {
    fontSize: "12px",
    color: "#A6A6A6",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    MozBoxOrient: "vertical",
    height: "36px",
  },
  "& .book-btn-container": {
    display: "flex",
    marginTop: "10px",
    justifyContent: "center",
    height:"44px",
  },
  "& .book-btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#6F05EA",
    boxShadow: "none",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6F05EA",
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px 35px",
      fontSize: "14px",
    },
  },
  "& .no-data-found":{
    color:"rgb(183, 183, 183)",
    textAlign:"center",
    fontWeight:600,
    fontSize:22
  }
}));
const FindServiceSliderContainer = styled(Box)(({theme}) => ({
  marginTop:"60px",
"& .find-service-title":{
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "30px",
},
  "& .slick-prev": {
    height:"50px",
    zIndex: 10,
    left: "0",
    [theme.breakpoints.down("xs")]: {
      height:"20px"
    },
  },
  "& .slick-next": {
    height:"50px",
    right: "0",
    [theme.breakpoints.down("xs")]: {
      height:"20px"
    },
  },
  "& .slick-slider": {
    height: "245px",
  },
  "& .find-service-container":{
    flexDirection: "column", 
    alignItems:"center",
    gap:"15px", 
    display: "flex", 
  },
  "& .find-service-img":{
    height:"180px", 
    borderRadius:"50%",
    width:"180px", 
  },
  "& .find-service-card-title":{
    height: "48px",
    fontWeight:"700", 
    color:"#6F05EA", 
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign:"center",
    WebkitLineClamp: 2,
    MozBoxOrient: "vertical",
  }
}))
// Customizable Area End

export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHotelTabPanel = () => {
    return (
      <TabPanelContainer data-test-id="tab-panel-container">
        <TabPanel value={this.state.tabValue} index={0}>
        {this.renderRecentlyFindService()}
        </TabPanel>
      </TabPanelContainer>
    );
  };
  renderDogWakingTabPanel = () => {
    return (
      <TabPanelContainer>
        <TabPanel value={this.state.tabValue} index={1}>
        {this.renderRecentlyFindService()}
        </TabPanel>
      </TabPanelContainer>
    );
  };
  renderGroomingTabPanel = () => {
    return (
      <TabPanelContainer>
        <TabPanel value={this.state.tabValue} index={2} data-test-id="tab-panel-grooming">
        {this.renderRecentlyFindService()}
        </TabPanel>
      </TabPanelContainer>
    );
  };
  renderPetSettingTabPanel = () => {
    return (
      <TabPanelContainer>
        <TabPanel value={this.state.tabValue} index={3}>
        {this.renderRecentlyFindService()}
        </TabPanel>
      </TabPanelContainer>
    );
  };
  renderDayCareTabPanel = () => {
    return (
      <TabPanelContainer>
        <TabPanel value={this.state.tabValue} index={4}>
        {this.renderRecentlyFindService()}
        </TabPanel>
      </TabPanelContainer>
    );
  };
  renderTrainingTabPanel = () => {
    return (
      <TabPanelContainer>
        <TabPanel value={this.state.tabValue} index={5}>
        {this.renderRecentlyFindService()}
        </TabPanel>
      </TabPanelContainer>
    );
  };
  renderDiscountContainer = () => {
    return (
      this.state.discountBannerData.data.length > 0 && <Box className="discount-banner-container">
        <img
          src={this.state.discountBannerData.data[0].attributes.image.url}
          alt="discount-banner"
          className="discount-banner-img"
        />
      </Box>
    );
  };
  renderFooterContainer = () => {
    return (
      <>
        <Box className="footer-container">
          <img
            src={LandingPageFooterImg}
            alt="landing-page-banner"
            className="footer-img"
          />
          <Box className="footer-content">
            <Typography className="footer-text-part-a">
              Register your service to Petzai and join the future of the pet
              community
            </Typography>
            <Typography className="footer-text-part-b">
              It takes two minutes for your registration to be complete, so why
              wait? Start sharing and selling your services with the world
              today!
            </Typography>
            <Button
              onClick={this.goToRegisterPage}
              className="footer-btn"
              endIcon={<img src={NextArrowIcon} alt="next-arrow" />}
              data-test-id="get-start-btn"
            >
              Get Start
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  renderRecentlyBookedService = () => {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: this.state.recentlyBookedList?.length > 4 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
      prevArrow: this.state.recentlyBookedList?.length > 4 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: this.state.recentlyBookedList?.length > 3 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: this.state.recentlyBookedList?.length > 3 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
            
          },
        },
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: this.state.recentlyBookedList?.length > 2 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: this.state.recentlyBookedList?.length > 2 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
            
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: this.state.recentlyBookedList?.length > 1 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: this.state.recentlyBookedList?.length > 1 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
            
          },
        },
      ],
    };
    return (
      
      <SliderContainer>
        <Typography className="recently-book-title">Recently Booked</Typography>
        {this.state.recentlyBookedList?.length > 0 &&  <Slider {...settings}>
          {this.state.recentlyBookedList.map((card) => (
            <Box key={card.sub_service_id}>
              <Box className="card-container">
                <img
                  src={RecentlyBookCardImg}
                  alt={`card-image-${card.sub_service_id}`}
                  className="card-img"
                />
                <Box className="card-data-container">
                  <Typography className="card-title">{card.establishment_name}</Typography>
                  <Box className="card-location-info">
                    <img src={LocationIcon} alt="location-icon" className="card-location-icon" />
                    <Typography className="card-location">
                      {card.establishment_address}
                    </Typography>
                  </Box>
                  <Typography className="card-price">
                    from {card.sub_services_price}€
                    <span className="card-price-span">/night</span>
                  </Typography>
                  <Box className="book-btn-container">
                    <Button
                      onClick={() =>  this.goTOBookingPage(card)}
                      className="book-btn"
                      style={{ backgroundColor: "#8000ff" }}
                      data-test-id="book-btn"
                    >
                      Book
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>}
        {this.state.recentlyBookedList?.length === 0 && <Typography className="no-data-found">No results found!</Typography>}
      </SliderContainer>
    );
  };

  renderFindService = () => {
    const setting = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      nextArrow: this.state.findServiceDataList?.length > 5 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
      prevArrow: this.state.findServiceDataList?.length > 5 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: this.state.findServiceDataList?.length > 4 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: this.state.findServiceDataList?.length > 4? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
          },
        },
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: this.state.findServiceDataList?.length > 3 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: this.state.findServiceDataList?.length > 3 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: this.state.findServiceDataList?.length > 2 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: this.state.findServiceDataList?.length > 2 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: this.state.findServiceDataList?.length > 1 ? <img src={carouselNextArrowIcon} alt="carousel-next-arrow" /> : null,
            prevArrow: this.state.findServiceDataList?.length > 1 ? <img src={carouselPrevArrowIcon} alt="carousel-next-arrow" /> : null,
          },
        },
      ],
    };
    return (
      <FindServiceSliderContainer data-test-id="find-service-slider">
        <Typography className="find-service-title">Find the service</Typography>
        <Slider {...setting} data-test-id="find-service-container">
          {this.state.findServiceDataList.length > 0 && this.state.findServiceDataList.map((card, index:number) => (
            <Box key={card.id} data-test-id="find-service">
              <Box className="find-service-container">
                <img
                  src={card.image_url}
                  alt={`card-image-${index}`}
                  className="find-service-img"
                />
                <Typography className="find-service-card-title">{card.service_type}</Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </FindServiceSliderContainer>
    );
  };

  renderRecentlyFindService = () => {
    return(
      <>
      {this.renderRecentlyBookedService()}
      {this.renderFindService()}
      </>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
        <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>
        <LandingPageContainer data-test-id="landing-page-container">
          <LandingPageHeader
            tabValue={this.state.tabValue}
            onTabChange={this.handleTabChange}
            SearchDataFromHeader={(data) => this.goToAdvancedSearchPage(data)}
            data-test-id="landing-page-header"
            isRepeatShow={true}
          />
          {this.renderDiscountContainer()}
          {this.renderHotelTabPanel()}
          {this.renderDogWakingTabPanel()}
          {this.renderGroomingTabPanel()}
          {this.renderDayCareTabPanel()}
          {this.renderPetSettingTabPanel()}
          {this.renderTrainingTabPanel()}
          {this.renderFooterContainer()}
        </LandingPageContainer>
        <AppFooter navigation={this.props.navigation} />
        </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
