import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {
  createTheme,
  styled,
  ThemeProvider,
} from "@material-ui/core/styles";
import { SearchIcon, UserIcon } from "./assets";
import { AppHeaderChat } from "../../../components/src/AppHeaderChat.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import ViewChat from "./ViewChat.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const ChatGridContainer = styled(Grid)(({ theme }) => ({
  "& .user-search-container": {
    width: "100%",
    marginTop: "24px",
  },
  "& .user-info-container": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "fit-content",
  },
  "& .user-name": {
    fontSize: "24px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  "& .total-msg-badge": {
    borderRadius: "24px",
    padding: "2px 8px 2px 8px",
    backgroundColor: "#6F05EA",
    color: "#fff",
    fontSize: "10px",
    height: "12px",
  },
  "& .user-chat-list": {
    overflowY: "auto",
    marginTop: "32px",
  },
  "& .user-chat-list-container": {
    height: "112px",
    display: "flex",
    borderBottom: "1px solid #00000012",
    padding: "0px 12.5%",
    [theme.breakpoints.down("md")]: {
      padding: "0px 9.5%",
    },
  },
  "& .user-chat-list-container-active": {
    height: "112px",
    display: "flex",
    borderBottom: "1px solid #00000012",
    padding: "0px 12.5%",
    backgroundColor: "#F0E5FF",
    [theme.breakpoints.down("md")]: {
      padding: "0px 9.5%",
    },
  },
  "& .user-chat-container": {
    display: "flex",
    gap: 10,
    alignItems: "center",
    width: "100%",
  },
  "& .user-chat-img": {
    width: "45px",
    height: "45px",
    [theme.breakpoints.down("md")]: {
      width: "40px",
      height: "40px",
    },
  },
  "& .user-msg-info-container": {
    display: "flex",
    gap: "20px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gap: "10px",
    },
  },
  "& .user-name-msg": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "& .list-user-name": {
    fontSize: "19px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  "& .list-user-msg": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#00000099",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  "& .msg-time-container": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    height: "50px",
  },
  "& .list-msg-time": {
    fontSize: "12px",
    fontWeight: 600,
    color: "#00000099",
  },
  "& .list-msg-badge": {
    borderRadius: "50%",
    padding: "2px 6px",
    backgroundColor: "#6F05EA",
    color: "#fff",
    fontSize: "10px",
  },
  "& .view-chat-container": {
    borderLeft: "1px solid #00000012",
    height:"800px",
    overflowY:"auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .chat-container": {
    borderLeft: "1px solid #00000012",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .display-none": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .display-block": {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const messages = [
      {
        id: 1,
        name: "Benjamin Thompson",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, rem ex magni tempore atque cupiditate placeat quod vero expedita, aliquid odio!",
        time: "12m",
        unread: true,
        avatar: UserIcon,
      },
      {
        id: 2,
        name: "Elmer Laverty",
        text: "Lorem Ipsum is simply dummy text...",
        time: "12m",
        unread: false,
        avatar: UserIcon,
      },
    ];
    return (
      <ThemeProvider theme={theme}>
        <AppHeaderChat userAvatar={UserIcon} username="Joana Esteves" />
        <ChatGridContainer container>
          <Grid
            item
            md={4}
            lg={3}
            className={`${
              this.state.isChatListShow ? "display-block" : "display-none"
            } view-chat-container`}
          >
            <Box className="user-search-container">
              <Box style={{ marginLeft: "12.5%" }}>
                <Box className="user-info-container">
                  <img src={UserIcon} alt="userImg" className="user-chat-img" />
                  <Typography className="user-name">Joana Esteves</Typography>
                  <Box className="total-msg-badge">{12}</Box>
                </Box>
                <TextField
                  // value={this.state.searchText}
                  name="search"
                  placeholder="Search"
                  style={{ marginTop: "10px" }}
                  data-test-id="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} alt="search-icon" />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  // onChange={(event) => this.handleSearch(event)}
                />
              </Box>
            </Box>
            <Box className="user-chat-list">
              {messages.length > 0 && messages.map((message) => (
                <Box
                  className={`${
                    this.state.chatUserId === message.id
                      ? "user-chat-list-container-active"
                      : "user-chat-list-container"
                  }`}
                  onClick={() => this.handleChatListUserClick(message.id)}
                  data-test-id="user-chat-list"
                >
                  <Box className="user-chat-container">
                    <img src={message.avatar} className="user-chat-img" />
                    <Box className="user-msg-info-container">
                      <Box className="user-name-msg">
                        <Typography className="list-user-name">
                          {message.name}
                        </Typography>
                        <Typography className="list-user-msg">
                          {message.text}
                        </Typography>
                      </Box>
                      <Box className="msg-time-container">
                        <Typography className="list-msg-time">
                          {message.time}
                        </Typography>
                        <Box className="list-msg-badge">{2}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            md={8}
            lg={9}
            className={`${
              this.state.isChatListShow ? "display-none" : "display-block"
            } chat-container`}
          >
            <ViewChat
              handleBackListClick={this.handleClickBackChatList}
              userId={this.state.chatUserId}
              navigation=""
              id="chat-view"
            />
          </Grid>
        </ChatGridContainer>
        <AppFooter />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
