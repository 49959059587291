import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { returnTruthyString } from "../../../components/src/HelperUtils";
interface IApiModels {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
interface IAddBookingModel{
    email:string
}
export interface EstablishmentAttributes {
    id: number,
    status:string
  request_type: string
    start_date: string | null,
    end_date:string  | null,
    shift:  string  | null,
    duration:string  | null,
    created_by: string
    schedule_detail: {
        id: number,
      start_date: string
        end_date:string
        shift: string
    },
    customer: {
        customer_detail: {
            id: number,
            first_name: string,
            last_name:string,
            full_phone_number: string,
            country_code: string  | null,
            phone_number: number,
            activated: boolean,
            email: string,
            
            device_id: null,
            unique_auth_id: string,
            password_digest: string,
            created_at: string,
            platform: null,
            user_type: null,
            updated_at:string,
            user_name: null,
            
            app_language_id: null,
            last_visit_at: null,
            is_blacklisted: boolean,
            suspend_until: null,
            status: string,
            role_id:number,
            gender: null,
            date_of_birth: null,
            age: null,
            gpa: null,
            country: string,
            city: string,
            language: string,
            nationality: string,
            confirm_email: null,
            stripe_id: string,
            vendor_type: string,
            address: string,
            zipcode: string,
            tax_number: string,
            company_name: string,
            services_type: string[],
            facebook_url:  string | null,
            instagram_url: string | null,
            linkedin_url: string | null,
            tiktok_url: string | null,
            client_id: string,
            iban: null
        },
        image_url: string,
    },
    pet: {
        pet_detail: {
            id: number,
            pet_category: string,
            name:string,
            gender: string,
            size: string,
            breed: string,
            birthdate: string,
            weight: string,
            fur:string,
            species: string,
            account_id: number,
            created_at:string,
            updated_at: string,
        },
        image_url: string,
    },
    service_provider: {
        service_provider_detail: {
            id: number,
            first_name: string,
            country_code: number,
            phone_number: number,
            last_name:string,
            full_phone_number: string,
            email: string,
            activated: boolean,
            device_id: null,
            unique_auth_id:string,
            password_digest:string,
            user_name: null,
            created_at: string,
            updated_at:string,
            platform: null,
            user_type: null,
            app_language_id: null,
            last_visit_at: null,
            is_blacklisted: boolean,
            suspend_until: null,
            status:string,
            role_id: number,
            gender: null,
            date_of_birth: null,
            age: null,
            gpa: null,
            country:string,
            city: string,
            language: string,
            nationality:string,
            address: string,
            zipcode:string,
            confirm_email: null,
            stripe_id: string,
            vendor_type:string,
            tax_number: string,
            facebook_url: string,
            instagram_url: string,
            linkedin_url: string,
            tiktok_url: string,
            company_name: string,
            services_type: string[],
            client_id: string,
            iban: string,
        },
        image_url: string,
    }
}
const EstablishmentListErrorDetails=[{
  account: "Email invalid",
  token: "Invalid token",
  detail: "Invalid value",
  error: 'Invalid Data',
}]
interface ListEstablishment {
  id: string;
  type: string;
  attributes: EstablishmentAttributes;
}
export interface EstablishmentListErrorDetail {
  detail?: string;
  token?: string;
  account?: string;
  error?:string;
}
interface MetaMessage{
  message:string
}
 interface RequestDataList{
  data: ListEstablishment[];
  meta:MetaMessage;
  errors: EstablishmentListErrorDetail[];
  error: EstablishmentListErrorDetail[];
}
const defaultRequestArrayListData={
  data:[{
   id: "",
   type: "",
   attributes: {
       id: 0,
       status: "",
       start_date: "",
     request_type: "",
       end_date: "",
       shift: "",
       duration: "",
       created_by: "customer",
       schedule_detail: {
           id: 19,
         start_date: "2024-09-05",
           end_date: "2024-09-14",
           shift: "12.30"
       },
       customer: {
           customer_detail: {
               id: 366,
               first_name: "Deepika Sept",
               last_name: "",
               full_phone_number: "8754219632",
               country_code: "",
               phone_number: 8754219632,
               email: "test6@yopmail.com",
               device_id: null,
               activated: true,
               unique_auth_id: "WRIU92WFa6cPC3sPd651gQtt",
               password_digest: "",
               created_at: "2024-09-05T06:37:06.668Z",
               updated_at: "2024-09-05T06:40:24.544Z",
               user_name: null,
               platform: null,
               user_type: null,
               app_language_id: null,
               last_visit_at: null,
               is_blacklisted: false,
               suspend_until: null,
               status: "regular",
               role_id: 2,
               gender: null,
               date_of_birth: null,
               age: null,
               gpa: null,
               country: "AF",
               city: "indore",
               language: "portuguese",
               nationality: "portuguese",
               address: "indore",
               zipcode: "5555",
               confirm_email: null,
               stripe_id: "cus_Qn9cnLZZJmiuuR",
               vendor_type: "company",
               tax_number: "778",
               company_name: "RK Tech",
               services_type: [
                   '',
                   ''
               ],
               facebook_url: "",
               instagram_url: "",
               linkedin_url:"",
               tiktok_url: "",
               client_id: "",
               iban: null
           },
           image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/nwhxbvfzf7hy6089f0esb753qavn?response-content-disposition=inline%3B%20filename%3D%22image_product-image.png%22%3B%20filename%2A%3DUTF-8%27%27image_product-image.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=ae80338e7b3e90a5cb2ba626f1d73f8c2b13ddfc1a0c47c05005c16c4118e967"
       },
       pet: {
           pet_detail: {
               id: 156,
               pet_category: "",
               name: "",
               birthdate: "",
               breed: "",
               gender: "",
               size: "",
               weight: "",
               fur: '',
               species: "",
               account_id: 0,
               created_at: "",
               updated_at: ""
           },
           image_url: ""
       },
       service_provider: {
           service_provider_detail: {
               id: 0,
               first_name: "",
               full_phone_number: "",
               last_name: "",
               country_code: 56895,
               phone_number: 17234567,
               email: "",
               activated: true,
               device_id: null,
               unique_auth_id: "",
               password_digest: "",
               created_at: "",
               updated_at: "",
               user_name: null,
               platform: null,
               user_type: null,
               app_language_id: null,
               last_visit_at: null,
               is_blacklisted: false,
               suspend_until: null,
               status: "",
               role_id: 1,
               gender: null,
               date_of_birth: null,
               age: null,
               gpa: null,
               country: "Albania",
               city: "Bajram Curri",
               language: "portuguese",
               nationality: "portuguese",
               address: "202-Maple-St,-Gotham,-IL-61550",
               zipcode: "54567",
               confirm_email: null,
               stripe_id: "cus_QcgdgE0kBiyGoC",
               vendor_type: "company",
               tax_number: "-",
               company_name: "-",
               services_type: [
                   '',
                   ""
               ],
               facebook_url: "",
               instagram_url: "",
               tiktok_url: "",
               linkedin_url: "",
               client_id: "",
               iban: ""
           },
           image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/uatqex3qf4zyosf1hyiy79ugchw1?response-content-disposition=inline%3B%20filename%3D%2213-gallery%202.png%22%3B%20filename%2A%3DUTF-8%27%2713-gallery%25202.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=bb29dc419ff2cfeadc9e87be412534652286d76ff94b31ad7e775c407aa78ba3"
       }
   
 }
  }],
  meta:{
  message: "Request retrieved successfully"
  },
  error:EstablishmentListErrorDetails,
  errors:EstablishmentListErrorDetails
 }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isShowrequestscreen :boolean,
  showRequestScreen :() => void,
  selectedRequestId: {
    name: string,
    service_id: string,
},
handleSetRequestId: (selectedRequestId: string) => void,

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue:number,
  isAddBookingOpen:boolean,
  isShowSuccessfullyBookingEmail:boolean,
  AppointmentArrayList:any[];
  RequestArrayList:RequestDataList
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableUserCalendarVendorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Starts
  getAppointmentListApi: string = "";
  getRequestListApi: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        tabValue:0,
        isAddBookingOpen:false,
        isShowSuccessfullyBookingEmail:false,
        AppointmentArrayList:[],
        RequestArrayList:defaultRequestArrayListData,

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (apiRequestCallId === this.getAppointmentListApi) {
      this.handleAppointmentList(responseJson)
    }if (apiRequestCallId === this.getRequestListApi) {
      this.handleRequestList(responseJson)
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabValue: Number(newValue) });
  };
  handleAppointmentList(responseJson:any){
    if(responseJson.data){
      this.setState({AppointmentArrayList:responseJson.data})
    }
  }
  handleRequestList(responseJson:RequestDataList){
    if(responseJson){
      this.setState({RequestArrayList:responseJson})
    }
    console.log(this.state.RequestArrayList)
  }
  handleAddBookingClick = () => {
    this.setState({isAddBookingOpen:true})
  }
  async componentDidMount() {
    this.getAppointmentListArray();
    this.getRequestListArray();
  }
  getAppointmentListArray = async () => {

    const TokenForAddress = sessionStorage.getItem("login_token");
    this.getAppointmentListApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.GetapponitmentList}=month&start_date=25-07-2024&end_date=25-07-2024`,
      token: TokenForAddress,
    });
  };
  getRequestListArray = async () => {
    const TokenForAddress = sessionStorage.getItem("login_token");
    this.getRequestListApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getRequestListEndpoint}`,
      token: TokenForAddress,
    });
  };
  apiCall = async (data: IApiModels) => {
    const {endPoint, contentType, method, body, token, isJsonStringify } =
      data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    body &&
      requestMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );

    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return requestMessaged.messageId;
  };
  handleAddBookingClose = () => {
    this.setState({isAddBookingOpen:false, isShowSuccessfullyBookingEmail:false})
  }

  handleAddBookingDialogSave = (value:IAddBookingModel) =>{
    this.setState({isShowSuccessfullyBookingEmail:true})
  }
  selectListItem = (service: any) => {
    const {  id } = service
    const serviceId = returnTruthyString(id)
    this.props.handleSetRequestId(serviceId)
}
  // Customizable Area End
}
