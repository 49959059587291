import React from "react";
// Customizable Area Start
import { Box, Typography,styled, OutlinedInput,Select,FormControl,
  MenuItem, } from "@material-ui/core";
import "./DateRangeSelector.css";
import  {AppVendorHeader}  from "../../../components/src/AppHeaderVendor.web";
import DatePicker from "react-datepicker";
const StyledDatePickerContainer = styled(Box)(({ theme }) => ({
  "& .react-datepicker__input-container": {
    opacity: "1 !important",
    top: "40px !important",
    position: "unset",
  },
  "& .react-datepicker__input-container input": {
    border: "none",
    width: "170px",
    fontSize: "18px",
    fontWeight: "600",
    [theme.breakpoints.down(1281)]: {
      width: "165px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "6px 0px 5px 19px;",
      fontSize: "14px",
      width: "117px",
    },
  },
  "& .react-datepicker__input-container input::placeholder": {
    fontSize: "12px",
    color: "#A6A6A6",
    fontWeight: "400",
  },
  "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
    padding: "0.5rem 0px",
  },
  "& .react-datepicker__day--keyboard-selected,.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range":
    {
      backgroundColor: "#E1CCFF",
      color: "#6F05EA",
    },
  "& .react-datepicker__day--selected::after": {
    content: "none",
  },
}));
import {CalendarIcon} from './assets'
// Customizable Area End
import CustomisableUserDashboardVendorController, {
  Props
} from "./CustomisableUserDashboardVendorController";

export default class CustomisableUserDashboardVendor extends CustomisableUserDashboardVendorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const Container = styled(Box)({
      display: "grid",
      gap: "40px", // Adjust gap as needed
      gridTemplateColumns: "repeat(2, 1fr)", // Default to 2 columns
      gridAutoRows: "minmax(151px, auto)", // Adjust row height as needed
      "@media (max-width: 360px)": {
        gridTemplateColumns: "1fr", // 1 column on small screens
      },
    
      "@media (min-width: 360px) and (max-width: 960px)": {
        gridTemplateColumns: "repeat(3, 1fr)", // 2 columns
        width:'90%'
      },
    
      "@media (min-width: 960px) and (max-width: 1024px)": {
        width:'90%',
        gridTemplateColumns: "repeat(4, 1fr)", // 2 columns
      },
    
      "@media (min-width: 1024px) and (max-width: 1440px)": {
        gridTemplateColumns: "repeat(5, 1fr)", // 4 columns
        width:'90%'
      },
    
      "@media (min-width: 1440px)": {
        width:'90%',
        gridTemplateColumns: "repeat(5, 1fr)", // 4 columns
      }
    });
    const Column = styled("div")(({ theme }) => ({
      padding: theme.spacing(0),
      boxSizing: "border-box",
      gap:'40px',
      background: "#fff", // Just for visual separation
      border: '1px solid #E2E8F0',
      borderRadius:8,
      width:'100%',maxWidth:226,
      
      "& .column-box":{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',textAlign:'center',marginTop:50,marginLeft:0},
      "& .title-content": {
        fontSize:16,
        fontWeight:400,
        fontFamily:'Montserrat',
        lineHeight:'19px',
        color:'#000'
       },
       "& .title-count": {
        fontSize:26,
        fontWeight:400,
        fontFamily:'Montserrat',
        lineHeight:'19px',
        color:'#000',
        marginTop:10
       },
       "& .title-count-red": {
        fontSize:26,
        fontWeight:400,
        fontFamily:'Montserrat',
        lineHeight:'19px',
        color:'#C10000',
        marginTop:10
       }
    }));
    const MenuProps = {
      PaperProps: {
        style: {
          opacity: 1,
  transform: 'none',
  width: '229px',
  transition: 'opacity 289ms cubic-bezier(0.4, 0, 0.2, 1), transform 192ms cubic-bezier(0.4, 0, 0.2, 1)',
  marginTop: 129,
  left:' 213px',
  padding: '1px 0px 20px 0px',
  border:' 1px solid #EEEEEE',
  boxShadow: '0px 4px 4px 0px #0000001A',
  '& .MuiSelect-select': {
    border: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiSelect-icon': {
    color: 'black',
  },
        },
      },
    };
    const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
      '&.Mui-selected': {
        background: 'linear-gradient(94.72deg, #6928F2 2.25%, #884FFF 97.5%)',
        color: '#fff',
        fontSize:14,
        fontWeight:600,
        fontFamily:'Montserrat',
        gap:'15px',
        padding:'8.79px 30px 8.79px 30px',
      },
      backgroundColor: '#fff',
      color: '#6F05EA',
      padding:'8.79px 30px 8.79px 30px',
      fontSize:14,
      fontWeight:600,
      fontFamily:'Montserrat',
      width: '229px',
      gap:'15px',
  height: '65px',
  "& .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "gray",
    }
     }
    }));
    return (
      //Merge Engine DefaultContainer
     
      <Box style={{
        height: '100%',
        width:'100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  
      }}>
      <AppVendorHeader onMenuSelect={function (selected: string): void {} }/>
        <Box style={webStyles.container}>
      <Box style={{display:'flex', width: "100%",height:'100%',justifyContent:'center',
      flexDirection: 'column',
      boxSizing: 'border-box',
      gap: '20px',
      background:'#F6F4F9',overflow:'hidden'}}>
        <Box >
      <Container>
      <Column style={{background:'#F1F5F9',border:'none'}}>
        <FormControl
              variant="filled"
              style={{
                width: "550px",
                lineHeight: 1.1876,
                border:'none',
                marginTop:100
              }}
            >
              
              <Select
                value={this.state.selectedRange}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                name="selectedRange"
                style={{border:'none', height: 46,width:229,background:'#F1F5F9',padding:'10px, 19px, 10px, 19px',borderRadius:8,gap:'8px',color:'#94A3B8',fontSize:16,fontWeight:500,fontFamily:'Montserrat' }}
                displayEmpty
              >
                 
                 <StyledMenuItem value="" disabled style={{color:'#94A3B8',background:'#fff'}}>Select Date Range</StyledMenuItem>
                 <StyledMenuItem value="Today">Today</StyledMenuItem>
            <StyledMenuItem value="LastWeek">Last Week</StyledMenuItem>
            <StyledMenuItem value="LastMonth">Last Month</StyledMenuItem>
            <StyledMenuItem value="LastYear">Last Year</StyledMenuItem>
            <StyledMenuItem value="Custom">Custom Date Range</StyledMenuItem>
              </Select>
            </FormControl>
            <DatePicker
           open={true}
             selected={this.state.customStartDate}
             icon={
               <img
                 className="widgetImage"
                 src={CalendarIcon}
                 alt="calendar-icon"
               />
             }
             showIcon
             placeholderText="Choose a Date"
             onChange={(date:any) => this.setState({customEndDate:date})}
             selectsStart
             startDate={this.state.customStartDate}
             endDate={this.state.customEndDate}
             withPortal
             dateFormat="EEE dd/MM/yy"
           />
        {this.state.selectedRange === "Custom" && (
           <StyledDatePickerContainer>
           <DatePicker
           open={true}
             selected={this.state.customStartDate}
             icon={
               <img
                 className="widgetImage"
                 src={CalendarIcon}
                 alt="calendar-icon"
               />
             }
             showIcon
             placeholderText="Choose a Date"
             onChange={(date:any) => this.setState({customEndDate:date})}
             selectsStart
             startDate={this.state.customStartDate}
             endDate={this.state.customEndDate}
             withPortal
             dateFormat="EEE dd/MM/yy"
           />
         </StyledDatePickerContainer>
          )
        }
      </Column>
      </Container>
      <Box style={{
        marginLeft: 25,
        marginTop: 38,
        marginBottom: 55
      }}><Typography style={webStyles.mainTitle1}>Reservation Statistics</Typography></Box>
      <Container>
          <Column>
          <Box className="column-box">
          <Typography className="title-content">Past Appointments</Typography>
          <Typography className="title-count">20</Typography>
            </Box>
            </Column>
          <Column><Box className="column-box">
          <Typography className="title-content">Upcoming Appointments</Typography>
          <Typography className="title-count">40</Typography>
            </Box></Column>
         
        </Container>
        <Box 
        style={{
          marginLeft: 25,
          marginTop: 80,
          marginBottom: 20
        }}><Typography  style={webStyles.mainTitle2}>Total Revenue</Typography></Box>
        <Container>
        <Column>
        <Box className="column-box">
          <Typography className="title-content">Previous Year</Typography>
          <Typography className="title-count">€ 49,206</Typography>
            </Box></Column>
          <Column><Box className="column-box">
          <Typography className="title-content">Changes</Typography>
          <Typography className="title-count-red">-12%</Typography>
            </Box></Column>
          <Column><Box className="column-box">
          <Typography className="title-content">Bookings</Typography>
          <Typography className="title-count">298</Typography>
            </Box></Column>
          <Column><Box className="column-box">
          <Typography className="title-content">Total Number of Clients</Typography>
          <Typography className="title-count">31</Typography>
            </Box></Column>
        </Container>
        </Box>
      </Box>
      </Box>
      
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    width: "100%",
    background:'#F6F4F9',
  height:'100%',
 
  
  },
  mainTitle1:{
    fontSize:26,
        fontWeight:600,
        fontFamily:'Montserrat',
        color:'#6F05EA',
        marginBottom: -35
  },
  mainTitle2:{
    fontSize:26,
        fontWeight:600,
        fontFamily:'Montserrat',
        color:'#6F05EA',
        marginTop: -35
  },
  columnBox:{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:63},
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};
// Customizable Area End
