import React from 'react';
import { SearchItem } from '../MapsController';
import { Box, Button, Typography, styled } from '@material-ui/core';
import { MarkerSmall } from '../assets';


type Props = {
    item: SearchItem;
}

const ItemWrapper = styled(Box)(({theme}) => ({
    boxShadow: "0px 0px 9.5px -1px rgba(0, 0, 0, 0.25)",
    borderTopLeftRadius:16,
    borderBottomLeftRadius:16,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width:'305px',
    "& .image-container": {
        width: '100%',
        height: '250px',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    "& .image": {
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        minWidth: '100%',
        minHeight: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    "& .main-content": {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',

        // [theme.breakpoints.up(1600)]: {
        //     padding: '40px',
        // },
    },
    "& .title": {
        color: '#000',
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'Montserrat'
    },
    "& .content-line": {
        display: 'flex',
        gap: '12px',
        fontSize: '12px',
        fontWeight: 300,
        color: '#000',
        alignItems: 'flex-start',
        fontFamily: 'Montserrat'

    },
    "& .address-style": {
        fontSize: '12px',
        fontWeight: 300,
        color: '#000',
        fontFamily: 'Montserrat'
    },
    "& .link": {
        color: '#909090',
        fontSize: '12px',
        fontWeight: 700,
        textDecoration: 'underline',
    },
    "& .warning-text": {
        color: '#FFCE00',
        fontSize: '14px',
        fontWeight: 600
    },
    "& .price-block": {
        marginTop:-10,
        marginBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
 alignItems:'center',
      
    },
    "& .offer-btn": {
        color: '#fff',
        textTransform: 'none',
        backgroundColor: '#6F05EA',
        backround: '#6F05EA',
        padding: '10px 36px',
        borderRadius: '35px',
        fontSize: '18px',
        lineHeight: '14px',
        fontWeight: 700,
        fontFamily: 'Montserrat',
        letterSpacing: 0,
        border: '1px solid #6F05EA',
        whiteSpace: 'nowrap',
        width: '188px',
  height:' 53px'
    },
    "& .price-text": {
        color: '#777777',
        fontSize: '14px',
        fontWeight: 500,
        marginBottom: '21px'
    },
    "& .price-section": {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up(1600)]: {
            alignItems: 'end',
        },
    },
    "& .price-list": {
        display: 'flex',
        alignItems: 'center',
    },
    "& .no-tax-price": {
        color: '#777777',
        fontSize: '14px',
        fontWeight: 400,
        whiteSpace: 'nowrap',
    },

    "& .main-price": {
        color: '#6F05EA',
        fontSize: '16px',
        fontWeight: 500,
        marginLeft: '10px',
        whiteSpace: 'nowrap',
        fontFamily:'Montserrat'
    },
    "& .from-tag": {
        color: '#B7B7B7',
        fontSize: '16px',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        fontFamily:'Montserrat'
    },
    "& .fees-text": {
        marginTop: '8px',
        marginBottom: '20px',
        color: '#777777',
        fontSize: '12px',
        fontWeight: 500
    }

}))

const ListItem = ({ item }: Props) => {
    return (
        <ItemWrapper>
            <Box className='image-container' style={{ maxWidth: '100%', backgroundImage: `url(${item.image})` }} >
            </Box>
            <Box className='main-content'>
                <Typography className='title'>{item.title}</Typography>
                <Box className='content-line'>
                    <img src={MarkerSmall}/>
                    <Typography className='address-style'>{item.address}</Typography>
                </Box>
                <Box className='price-list'>
                        <Typography className='from-tag'>from</Typography><Typography className='main-price'>{item.price}€/night</Typography>
                    </Box>
            </Box>
                <Box className='price-block'>
                <Button className='offer-btn'>Book</Button>
            </Box>
        </ItemWrapper>
    )
}

export default ListItem;