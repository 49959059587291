import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getDeltaValues } from "../../../components/src/helpers";

export type SearchItem = {
  id: string;
  title: string;
  image: string;
  address: string;
  rooms: number;
  price: number;
  noTaxPrice: number;
  latitude: number;
  longitude: number;
};
const mockItems = {
  data: [
    {
      id: "1",
      title: "VillaDog - Hotel Canino",
      image: "https://picsum.photos/1600/900",
      address: "R. Vieira Pinto 433, 4430-255 Vila Nova de Gaia",
      rooms: 2,
      price: 160,
      noTaxPrice: 120,
      latitude: 41.132441,
      longitude: -8.59561,
    },
    {
      id: "2",
      title: "Pet Hotel Gaia",
      image: "https://picsum.photos/1600/900",
      address:
        "R. Azevedo Magalhães 161 165, 4430-024 Vila Nova de Gaia, Portugal",
      rooms: 1,
      price: 200,
      noTaxPrice: 180,
      latitude: 41.1330534,
      longitude: -8.6008566,
    },
    {
      id: "3",
      title: "The Castle Dog Hotel",
      image: "https://picsum.photos/1600/900",
      address:
        "4431-952, R. Azevedo Magalhães 314, 4431-952 Vila Nova de Gaia, Portugal",
      rooms: 10,
      price: 540,
      noTaxPrice: 475,
      latitude: 41.1330534,
      longitude: -8.6008566,
    },
  ],
};

export type Filters = {
  price: {
    max: string;
    min: string;
  };
  transport: boolean;
  commodities: string[];
  hotelType: string[];
  grooming: string[];
  dayCare: string[];
  training: string[];
};
type Region = {
  latitude: number;
  longitude: number;
  latitudeDelta?: number;
  longitudeDelta?: number;
};
type SearchResultItem = {
  id: number,
  establishment_name: string,
  address: string,
  country: string,
  city: string,
  zipcode: string,
  longitude: number,
  latitude: number,
  email: string,
  phone_number: number,
  facebook_url: string,
  instagram_url: string,
  linkedin_url: string,
  tiktok_url: string,
  activated: boolean,
  created_at: string,
  updated_at: string,
  vandor_name: string,
  service_price: number,
  establishment_photo: string | null,
  galleries_urls: string[]
};
import {Group} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  searchItems: SearchResultItem[];
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  minPrice: any,
  maxPrice: any,
  daily:boolean,
  pool:boolean,
  garden:boolean;
  enableModal:boolean;
  markers:any;
  filters: Filters;
  searchItems:SearchItem[];
  region: Region;
  searchTab: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetSearchResults: string = "";
  results: SearchResultItem[] = [];
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];
    this.results = props.searchItems;
    this.state = {
      minPrice: '',
      maxPrice: '',
      daily:false,
  pool:false,
  garden:false,
  enableModal:false,
  markers: [
    { id: 1, position: [51.505, -0.09],title:'VillaDog - Hotel Canino', description: 'Vila Nova de Gaia, Porto',price:'14€/nigh',imageUrl: Group, },
    { id: 2, position: [51.51, -0.1], title:'VillaDog - Hotel Canino',description: 'Vila Nova de Gaia, Porto',price:'14€/nigh',imageUrl: Group, },
    { id: 3, position: [51.515, -0.12],title:'VillaDog - Hotel Canino', description: 'Vila Nova de Gaia, Porto',price:'14€/nigh',imageUrl: "https://picsum.photos/200/300", },
  ],
  filters: {
    price: {
      max: "",
      min: "",
    },
    transport: false,
    commodities: [],
    hotelType: [],
    grooming: [],
    dayCare: [],
    training: [],
  },
  searchItems: [
    { id: '1', latitude:51.505,longitude:-0.09,title:'VillaDog - Hotel Canino', address: 'Vila Nova de Gaia, Porto',price:14,image: Group,rooms:10,noTaxPrice:1},
    { id: '2', latitude:51.51, longitude:-0.1, title:'VillaDog - Hotel Canino',address: 'Vila Nova de Gaia, Porto',price:14,image: Group,rooms:10,noTaxPrice:1 },
    { id: '3', latitude:51.51, longitude: -0.12,title:'VillaDog - Hotel Canino', address: 'Vila Nova de Gaia, Porto',price:14,image: "https://picsum.photos/200/300",rooms:10,noTaxPrice:1 },
  ],
  region: {
    latitude: 38.719567,
    longitude: -9.139996,
    latitudeDelta: getDeltaValues(50, 38.719567).latDelta,
    longitudeDelta: getDeltaValues(50, 38.719567).longDelta,
  },
  searchTab: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleClose(){
    this.setState({enableModal:!this.state.enableModal})
  }
  changeFilterValue = (type: keyof Filters, field: string, value?: string) => {
    if (type === "price") {
      this.setState({
        filters: {
          ...this.state.filters,
          price: {
            ...this.state.filters.price,
            [field]: value,
          },
        },
      });
    } else if (type === "transport") {
      this.setState({
        filters: {
          ...this.state.filters,
          transport: !this.state.filters.transport,
        },
      });
    } else {
      this.setState((prevState) => {
        return {
          filters: {
            ...prevState.filters,
            [type]: prevState.filters[type].includes(field)
              ? prevState.filters[type].filter((i) => i !== field)
              : [...prevState.filters[type], field],
          },
        };
      });
    }
  };
  getMarkersList = () => {
    if (this.state.searchItems) {
      return this.state.searchItems.map((item) => ({
        id: item.id,
        position: { lat: item.latitude, lng: item.longitude },
        title:item.title,
        address:item.address,
        image:item.image,
        price:item.price
      }));
    }
    return [];
  };
  makeSearch = () => {
    return true;
  };
  // Customizable Area End
}
