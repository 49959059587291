import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { Box, Button, Link, Typography } from "@material-ui/core";
import { Formik } from "formik";
import InputField from "../../../components/src/InputField";
import { AppHeader } from "../../../components/src/AppHeader.web";
import * as Yup from "yup";
import {
  EmailIcon,
  LoginImage,
  loginForwardArrow,
} from "./assets";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";

// Customizable Area Start
const ForgotPasswordPageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height:"100%",
  "@media(max-width:960px)": {
    flexDirection: "column",
    marginTop:"40px"
  },
  "& .ForgotPwd-Info-Container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginBottom: "20px",
      width: "90%",
    },
  },
  "& .Verify-Text-Container": {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  "& .Verify-Body": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  "& .forget-pwd":{
    display:"none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      fontWeight: 700,
      color: "#6F05EA",
      fontFamily: "Montserrat",
      display:"contents",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  }
}));

const FormikContainer = styled(Box)(({ theme }) => ({
  maxWidth: "500px",
  width: "100%",
  paddingTop: "40px",
  [theme.breakpoints.between("md",1150)]: {
    width: "unset",
    margin:"auto"
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px 0px",
  flexDirection: "column",
  "& .ForgotPwd-Btn": {
    textTransform: "none",
    borderRadius: "100px",
    backgroundColor: "transparent",
    border: "1px solid #6F05EA",
    height: "53px",
    fontWeight: "700",
    padding: "10px 78px",
    color: "#6200EA",
    gap: "10px",
    fontSize: "24px",
    boxShadow: "none",
    [theme.breakpoints.down(450)]: {
      fontSize:"20px"
    },
  },
}));
const ImageTextContainer = styled(Box)({
  position: "absolute",
});
const ImageContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  alignItems: "center",
  display: "flex",
  right: "0",
  top: "0",
  justifyContent: "center",
  height:"100%",
  "& .Image": {
    width: "100%",
    height:"100%"
  },
  [theme.breakpoints.down("sm")]: { display: "none" },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("*Please enter your email to proceed.")
    .trim(),
});
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>
        <ForgotPasswordPageWrapper>
          {!this.state.isVerifyBlockShow ? (
            <>
            <Typography className="forget-pwd"> Forget Password</Typography>
            <Box className="ForgotPwd-Info-Container">
              <FormikContainer>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={forgotPasswordSchema}
                  enableReinitialize
                  data-test-id="Formik"
                  onSubmit={(value) => {
                    this.setState({userEmail:value.email})
                    this.forgotPasswordAPI();
                  }}
                >
                  {({ touched, errors, getFieldProps, handleSubmit }) => {
                    return (
                      <>
                        <form onSubmit={handleSubmit}>
                          <InputField
                            {...getFieldProps("email")}
                            id="email"
                            style={{ marginBottom: "40px" }}
                            label="Email"
                            type="text"
                            placeholder="Your Email"
                            fullWidth
                            error={Boolean(errors.email) && touched.email}
                            data-test-id="Input-Email"
                            startAdornment={<img src={EmailIcon} />}
                            helperText={(
                              touched.email && errors.email
                            )?.toString()}
                          />
                          <ButtonContainer>
                            {this.state.forgotPwdError && (
                              <Typography
                                style={{
                                  color: "#EF4444",
                                  marginBottom: "20px",
                                  textAlign: "center",
                                }}
                                data-test-id="signUp-error"
                              >
                                {this.state.forgotPwdError}
                              </Typography>
                            )}
                            <Button
                              data-test-id={"Btn-EmailForgotPwd"}
                              className="ForgotPwd-Btn"
                              fullWidth
                              type="submit"
                              variant="contained"
                              endIcon={
                                <img
                                  alt="forward-arrow"
                                  src={loginForwardArrow}
                                />
                              }
                            >
                              Send Email
                            </Button>
                          </ButtonContainer>
                        </form>
                      </>
                    );
                  }}
                </Formik>
              </FormikContainer>
            </Box>
            </>
          ) : (
            <Box className="Verify-Text-Container">
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#6F05EA",
                  fontFamily: "Montserrat",
                }}
              >
                Please verify your email
              </Typography>
              <Box className="Verify-Body">
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: "50px",
                  }}
                >
                  We have sent the verification link to
                </Typography>
                <Link
                  style={{
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    color: "#000000",
                  }}
                  underline="always"
                  className="forgot-link"
                >
                  {this.state.userEmail}.
                </Link>
                <Typography
                  style={{
                    color: "#6F05EA",
                    fontSize: "16px",
                    marginTop: "21px",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  }}
                >
                  {this.state.timerSecond} Sec
                </Typography>{" "}
                {this.state.timerSecond === 0 && (
                  <Link
                    style={{
                      fontWeight: 500,
                      color: "#6F05EA",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      marginTop: "21px",
                    }}
                    data-test-id="resend-link"
                    onClick={() => {
                      this.handleTimer();
                      this.forgotPasswordAPI();
                    }}
                    underline="always"
                  >
                    Resend Email
                  </Link>
                )}
              </Box>
            </Box>
          )}
          <ImageContainer>
            <img src={LoginImage} className="Image" />
            <ImageTextContainer data-test-id="Img-Container">
              <Typography
                className="WelCome-Text"
                style={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                Welcome back!
              </Typography>
              <Typography
                className="Not-Account-Text"
                style={{ fontSize: "20px", marginTop: "40px", color: "white" }}
              >
                You don't have an account yet?{" "}
              </Typography>
              <Box
                className="Register-Here"
                data-test-id="Register-Link"
                style={{
                  fontSize: "20px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center"
                }}
                onClick={() => {this.goToRegisterPage()}
                }
              >
                Register<span style={{fontWeight:"700", paddingLeft:"5px"}}> here</span>.
              </Box>
            </ImageTextContainer>
          </ImageContainer>
        </ForgotPasswordPageWrapper>
        <AppFooter navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
