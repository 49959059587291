import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import React, { CSSProperties } from "react";

import { Formik } from "formik";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import PageLoader from "../../../components/src/PageLoader.web";
import { AppHeader } from "../../../components/src/AppHeader.web";

import {
  AddIcon,
  RemoveIcon,
  SuccessIcon,
  avatar,
  headerLogo,
  languageIcon,
} from "./assets";

export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  render() {
    return (
      <PageLoader loading={!this.state.token}>
        <AppHeader navigation={this.props.navigation}/>
        <ContactUsPageWrapper maxWidth="md">
          <Box
            className="ContactUs-Page-Conteiner"
            data-test-id="page-container"
          >
            <Typography className="ContactUs-Page-Title">
              {configJSON.pageTitle}
            </Typography>
            <Formik
              initialValues={{
                subject: "",
                details: "",
                files: [],
              }}
              validationSchema={this.state.validationShema}
              enableReinitialize
              data-test-id="Formik-ContactUs-Form"
              onSubmit={(value) => {
                this.sendFormData(value);
              }}
            >
              {({
                touched,
                errors,
                getFieldProps,
                handleSubmit,
                setFieldValue,
                values,
                setFieldError,
                resetForm,
              }) => {
                this.resetForm = resetForm;
                return (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="ContactUs-Page-Content"
                    >
                      <Box>
                        <InputLabel className="ContactUs-Input-Label">
                          {configJSON.labelSubject}
                        </InputLabel>
                        <StyledInputField
                          {...getFieldProps("subject")}
                          {...this.subjectInputProps}
                          error={touched.subject && Boolean(errors.subject)}
                          helperText={
                            touched.subject && errors.subject && errors.subject
                          }
                        />
                      </Box>
                      <Box>
                        <InputLabel className="ContactUs-Input-Label">
                          {configJSON.labelDetails}
                        </InputLabel>
                        <StyledInputField
                          {...getFieldProps("details")}
                          {...this.detailsInputProps}
                          error={touched.details && Boolean(errors.details)}
                          helperText={
                            touched.details && errors.details && errors.details
                          }
                        />
                        <Box className="ContactUs-Counter-Box">
                          <Typography>{`${values.details.length}/1000`}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <InputLabel className="ContactUs-Input-Label">
                          {configJSON.labelAttachFiles}
                        </InputLabel>
                        <Box className="ContactUs-Attachments-Box">
                          <input
                            {...this.attachmentInputProps}
                            ref={this.fileInputRef}
                            onChange={(event) =>
                              this.setFiles(
                                event.currentTarget.files,
                                setFieldValue,
                                setFieldError
                              )
                            }
                          />
                          <Box className="ContactUs-AddFile-Wrapper">
                            <Box
                              id="file-click-box"
                              data-test-id="file-box"
                              className="ContactUs-AddFile-Box"
                              onClick={this.handleFileClick}
                            >
                              <img src={AddIcon} />
                              <Typography
                                style={{
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                {configJSON.uploadBoxText}
                                <br />
                                {configJSON.uploadBoxExt}
                              </Typography>
                            </Box>
                            {values.files.map((file: File, index) => (
                              <Box
                                style={{ position: "relative" }}
                                key={`${file.name}-${file.size}`}
                                data-test-id={`preview-file-${index}`}
                              >
                                <img {...this.imageInputProps(file)} />
                                <Box
                                  className="ContactUs-Delete-Icon"
                                  onClick={() =>
                                    setFieldValue(
                                      "files",
                                      values.files.filter(
                                        (el, idx) => idx !== index
                                      )
                                    )
                                  }
                                >
                                  <img src={RemoveIcon} />
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        {errors.files ? (
                          <Typography className="ContactUs-Errors-File">
                            {errors.files[0]}
                          </Typography>
                        ) : (
                          <Typography className="ContactUs-Tip">
                            {configJSON.tipMessage}
                          </Typography>
                        )}
                      </Box>
                      <Button
                        {...this.btnSendProps}
                        type="submit"
                        variant="contained"
                      >
                        {configJSON.btnSend}
                      </Button>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Box>
        </ContactUsPageWrapper>
        <Modal
          id="success-modal"
          open={this.state.isShowModal}
          onClose={() => this.setState({ isShowModal: false })}
          style={modalStyles.modal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div style={modalStyles.paper}>
            <div style={modalStyles.header}>
              <Typography
                id="modal-title"
                style={{
                  fontSize: "32px",
                  fontWeight: 700,
                  fontFamily: "Montserrat",
                }}
              >
                {configJSON.modalTitle}
              </Typography>
              <IconButton
                data-test-id="close-icon"
                onClick={() => this.setState({ isShowModal: false })}
                aria-label="close"
                style={{ padding: 0 }}
              >
                <Close />
              </IconButton>
            </div>
            <div id="modal-description" style={modalStyles.description}>
              <img src={SuccessIcon} />
              <Typography
                style={{
                  maxWidth: "400px",
                  fontSize: "20px",
                  fontWeight: 600,
                  fontFamily: "Montserrat",
                  marginBottom: "80px",
                }}
              >
                {configJSON.modalDescription}
              </Typography>
              <Button
                data-test-id="done-btn"
                onClick={() => this.setState({ isShowModal: false })}
                fullWidth
                style={modalStyles.button}
                disabled={this.state.isLoading}
              >
                {configJSON.modalDoneBtn}
              </Button>
            </div>
          </div>
        </Modal>
        <AppFooter />
      </PageLoader>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const modalStyles: Record<any, CSSProperties> = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Montserrat",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "32px 40px",
    maxWidth: "1000px",
    maxHeight: "554px",
    overflow: "auto",
    borderRadius: 4,
    width: "1000px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  description: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    alignItems: "center",
    textAlign: "center",
    paddingTop: "30px",
  },
  text: {
    maxWidth: "400px",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  button: {
    border: "1px solid #6F05EA",
    height: "56px",
    color: "#6F05EA",
    borderRadius: "100px",
    padding: "10px 0",
    background: "#fff",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 500,
    maxWidth: "586px",
  },
};

const headerStyles: Record<any, CSSProperties> = {
  wrapper: {
    minHeight: "127px",
    paddingLeft: "16px",
    paddingRight: "16px",
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(#6928F2, #884FFF)",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  user: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    gap: "19px",
  },
  language: {
    display: "flex",
    gap: "18px",
    alignItems: "center",
    paddingRight: "12px",
  },
};

const ContactUsPageWrapper = styled(Container)({
  display: "flex",
  padding: '0 36px',
  flex: 1,
  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: "Montserrat",
  },
  "& .ContactUs-Page-Conteiner": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    paddingTop: "96px",
    paddingBottom: "80px",
    fontFamily: "Montserrat",
  },
  "& .ContactUs-Page-Title": {
    fontSize: "40px",
    lineHeight: "106px",
    fontWeight: 600,
  },
  "& .ContactUs-Attachments-Box": {
    paddingTop: "12px",
  },
  "& .ContactUs-AddFile-Wrapper": {
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
  },
  "& .ContactUs-AddFile-Box": {
    width: "88px",
    height: "88px",
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  "& .ContactUs-Img-Preview": {
    width: "88px",
    height: "88px",
    objectFit: "cover",
  },
  "& .ContactUs-Delete-Icon": {
    position: "absolute",
    right: "-8",
    top: "-8",
  },
  "& .ContactUs-Counter-Box": {
    marginTop: "15px",
    textAlign: "end",

    "& .MuiTypography-root.MuiTypography-body1": {
      color: "#6F05EA",
      fontSize: "13px",
    },
  },
  "& .ContactUs-Page-Content": {
    display: "flex",
    flexDirection: "column",
    gap: "44px",
  },
  "& .ContactUs-Submit-Btn": {
    border: "1px solid #6F05EA",
    width: "146px",
    height: "40px",
    color: "#6F05EA",
    borderRadius: "100px",
    padding: "10px 0",
    background: "#fff",
    alignSelf: "end",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
  },
  "& .ContactUs-Errors-File": {
    color: "#FF2828",
    marginTop: "10px",
    fontSize: "16px",
  },
  "& .ContactUs-Tip": {
    color: "#B7B7B7",
    marginTop: "12px",
    fontSize: "12px",
  },
  "& .ContactUs-Input-Label": {
    fontFamily: "Montserrat",
    color: "#6F05EA",
    fontSize: "13px",
    fontWeight: 400,
    marginBottom: "4px",
  },
});

const StyledInputField = styled(TextField)({
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiInputBase-input.MuiInput-input": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    paddingLeft: "8px",
    color: "#7D7D7D",

    "&::placeholder": {
      color: "#7D7D7D",
      fontSize: "18px",
      opacity: 1,
    },
  },
  "& .MuiInput-underline::after": {
    borderBottom: "2px solid #6F05EA",
  },
  "& .MuiInput-underline.Mui-error::after": {
    borderBottom: "2px solid #EF4444",
  },
  "& .MuiInput-underline::after:hover": {
    borderBottom: "2px solid #6F05EA",
  },
});


// Customizable Area End
