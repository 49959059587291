import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Container,
  ListItemIcon,
  Popover,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import {
  headerLogo,
  languageIcon,
} from "../../components/src/appHeader-assets";
import { styled } from "@material-ui/core/styles";
import {
  chatIcon,
} from "../../blocks/customisableuserprofiles/src/assets";
import { CalendarIcon } from "./LandingPageHeaderWeb/assets";
import { removeStorageData, setStorageData } from "../../framework/src/Utilities";
import { headerCalendarIcon, headerCalendarSelectIcon, headerFootprintIcon, headerFootprintSelectIcon, headerLogoutIcon, headerLogoutSelectIcon, headerSettingIcon, headerSettingSelectIcon, headerWalletIcon, headerWalletSelectIcon } from "./assets";

interface IAppHeaderProp {
  navigation?: any;
  handleCustomerHeaderSelect?: (selectedValue: string) => void;
  isProfile?: boolean;
}

const BoxStyle = styled(Box)(({ theme }) => ({
  padding: "42px 152px",
  boxSizing:"border-box",
  display: "flex",
  flexDirection: "row",
  background: "linear-gradient(to right, #6928F2, #884FFF)",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    padding: "22px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "22px 60px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "14px 0px",
    justifyContent: "space-around",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "12px 42px",
    justifyContent: "space-between",
  },
  "& .Header-container": {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  "& .Header-Name": {
    display: "flex",
    gap: "8px",
  },
  "& .Header-language": {
    fontSize: "20px",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-Register": {
    color: "#FFFFFF",
    fontSize: "20px",
    padding: "9.69px 40.36px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    "& .MuiTypography-colorPrimary": {
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-btn-container": {
    display: "flex",
    gap: "20px",
  },
  "& .Header-Btn": {
    borderRadius: "35px",
    padding: "10px 45px",
    backgroundColor: "#FFFFFF",
    color: "#6200EA",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 700,
    [theme.breakpoints.down("lg")]: {
      padding: "0px 35px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 35px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
  },
  "& .MuiIconButton-root": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      color: "#FFFFFF",
    },
  },
  "& .Header-menu": {
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      gap: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .header-logo": {
    [theme.breakpoints.down("lg")]: {
      height: "30px",
    },
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20px",
    },
  },
}));
const StylesBoxLoggedIn = styled(Box)(({ theme }) => ({
  "& .header-profile-name": {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  "& .header-pts": {
    backgroundColor: "#FFCE00",
    padding: "10px 36px",
    borderRadius: "35px",
    fontWeight: "600",
    fontSize: "14px",
    [theme.breakpoints.down(700)]: {
      padding: "10px 16px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 16px",
      fontSize: "12px",
    },
    [theme.breakpoints.down(450)]: {
      display: "none",
    },
  },
  "& .header-logo": {
    [theme.breakpoints.down("lg")]: {
      height: "30px",
    },
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20px",
    },
  },

  "& .header-user": {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    [theme.breakpoints.down(700)]: {
      gap: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(450)]: {
      gap: "10px",
    },
  },
  "& .user-profile-img": {
    width: "42px",
    height: "42px",
    borderRadius: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "36px",
      height: "36px",
    },
  },
  "& .Header-language": {
    fontSize: "20px !important",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px !important",
    },
  },
  "& .container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 125px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 85px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 70px !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 5px !important",
    },
  },
}));

const PopoverContainer = styled(Popover)(({ theme }) => ({
  marginTop: "96px",
  "& .MuiPopover-paper": {
    top: "0 !important",
    marginLeft: "-125px !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "-2px !important",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "-80px !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px !important",
    },
  },
  "& .MuiMenuItem-root": {
    padding: "8px 30px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
  },
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    background: "linear-gradient(to right,#6928F2, #884FFF)",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
}));

export function AppHeader(props: IAppHeaderProp) {
  const baseURL = new URL(document.URL).href.split('/').pop();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectMenuForLoggedInUser, setSelectMenuForLoggedInUser] =
    useState<string>("");
  const [openForLoggedInUser, setOpenForLoggedInUser] =
    useState<boolean>(false);

  const handleMenuOpenForLoggedInUser =  () => {
    setOpenForLoggedInUser(!openForLoggedInUser);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    props.navigation.navigate("Home");
  }
  
  const handleSelectForLoggedInUser = (selectValue: string) => {
    let selectedValue:string;
    switch (selectValue) {
      case "Calendar":
        setStorageData("selectedValueCustomer", 4);
        selectedValue = "0";
        break;
      case "My pets":
        setStorageData("selectedValueCustomer", 1);
        selectedValue = "1";
        break;
      case "Wallet":
        setStorageData("selectedValueCustomer", 2);
        selectedValue = "2";
        break;
      case "Settings":
        setStorageData("selectedValueCustomer", 3);
        selectedValue = "3";
        break;
      case "Logout": 
        handleLogout();
        selectedValue = "0";
        break;
      default:
        setStorageData("selectedValueCustomer", 4);
        selectedValue = "0";
        break;
    }
    setSelectMenuForLoggedInUser(selectValue);
    if (props.isProfile) {
      props.handleCustomerHeaderSelect === undefined
        ? undefined
        : props.handleCustomerHeaderSelect(selectedValue);
    } else {
      props.navigation.navigate("UserProfile");
    }
  };

  useEffect(() => {
    setSelectMenuForLoggedInUser(dropDownValue());
  },[localStorage.getItem("selectedValueCustomer")]);

  useEffect(() => {
    if(baseURL?.toLocaleLowerCase() !== "profile")
    {
      removeStorageData("selectedValueCustomer")
    }
  },[])

  const dropDownValue  = () => {
    switch (localStorage.getItem("selectedValueCustomer")) {
      case "4":
        return "Calendar";
      case "1":
        return "My pets";
      case "2":
        return "Wallet";
      case "3":
        return "Settings";
      default:
        return "";
    }
  }
  const isLoggedIn = localStorage.getItem("isUserLogin");
  const pathList = ["login", "signup", "forgotpassword", "newpassword", "vendorsignup"];
  let pathName = baseURL;
  if(baseURL?.toLocaleLowerCase().includes("newpassword")){
    pathName = baseURL?.split('?')[0]
  }
  const isShowLoginHeader = !pathList.includes(pathName?.toLocaleLowerCase() || "");
  const isVendor = localStorage.getItem("login_user_role") === "vendor";

  return (
    <>
      {isLoggedIn !== "true" || !isShowLoginHeader ? (
        <BoxStyle>
          <Box>
            <img onClick={() => {props.navigation.navigate("Home")}} className="header-logo" src={headerLogo} alt="Logo" />
          </Box>
          <Box className="Header-container">
            <Box className="Header-Name">
              <img src={languageIcon} alt="Language Icon" />
              <Typography className="Header-language">EN</Typography>
            </Box>
            <Box className="Header-menu">
              <Typography className="Header-Register">
                <Box onClick={() =>  props.navigation.navigate("EmailAccountVendorRegistration")}>
                  Register your business
                </Box>
              </Typography>
              <Box className="Header-btn-container">
                <Box className="Header-Btn" onClick={() =>  props.navigation.navigate("EmailAccountRegistration")}>
                  Sign up
                </Box>
                <Box className="Header-Btn" onClick={() =>  props.navigation.navigate("EmailAccountLoginBlock")}>
                  Login
                </Box>
              </Box>
            </Box>
            <IconButton
              aria-controls={open ? "header-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="header-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              keepMounted
            >
              <MenuItem onClick={handleClose}>
                <Box onClick={() =>  props.navigation.navigate("EmailAccountVendorRegistration")}>
                  Register your business
                </Box>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Box onClick={() =>  props.navigation.navigate("EmailAccountRegistration")}>
                  Sign up
                </Box>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Box onClick={() =>  props.navigation.navigate("EmailAccountLoginBlock")}>
                  Login
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        </BoxStyle>
      ) : (
        <StylesBoxLoggedIn style={styles.header.wrapper}>
          <Container maxWidth="xl" className="container">
            <Box>
              <img onClick={isVendor ? (() => props?.navigation?.navigate("Cfentrepriseaccount")) :() => props?.navigation?.navigate("Home")} className="header-logo" src={headerLogo} />
            </Box>
            <Box className="header-user">
              <Box style={styles.header.languageContainer}>
                <img src={languageIcon} alt="Language Icon" />
                <Typography
                  style={styles.header.languageName}
                  className="Header-language"
                >
                  EN
                </Typography>
              </Box>
              <img
                src={chatIcon}
                onClick={() => {props.navigation.navigate("ChatWeb")}}
              />
              <Box className="header-pts">125 pts</Box>
              <Box>
                <Box
                  style={styles.header.profile}
                  onClick={handleMenuOpenForLoggedInUser}
                >
                  <img
                    src={localStorage.getItem("login_user_profile") as string}
                    alt=""
                    className="user-profile-img"
                  />
                  <Typography className="header-profile-name">
                    {localStorage.getItem("login_user_name")}
                  </Typography>
                  <PopoverContainer
                    open={openForLoggedInUser}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      onClick={(event) =>
                        handleSelectForLoggedInUser("Calendar")
                      }
                      selected={
                        selectMenuForLoggedInUser === "Calendar" ? true : false
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={
                            selectMenuForLoggedInUser === "Calendar"
                              ? headerCalendarSelectIcon
                              : headerCalendarIcon
                          }
                          alt="document"
                          className="header-menu-img"
                        />
                      </ListItemIcon>
                      <Typography variant="inherit">Calendar</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) =>
                        handleSelectForLoggedInUser("My pets")
                      }
                      selected={
                        selectMenuForLoggedInUser === "My pets" ? true : false
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={
                            selectMenuForLoggedInUser === "My pets"
                              ? headerFootprintSelectIcon
                              : headerFootprintIcon
                          }
                          alt="my pets"
                          className="header-menu-img"
                        />
                      </ListItemIcon>
                      <Typography variant="inherit">My pets</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => handleSelectForLoggedInUser("Wallet")}
                      selected={
                        selectMenuForLoggedInUser === "Wallet" ? true : false
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={
                            selectMenuForLoggedInUser === "Wallet"
                              ? headerWalletSelectIcon
                              : headerWalletIcon
                          }
                          alt="Wallet"
                          className="header-menu-img"
                        />
                      </ListItemIcon>
                      <Typography variant="inherit">Wallet</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) =>
                        handleSelectForLoggedInUser("Settings")
                      }
                      selected={
                        selectMenuForLoggedInUser === "Settings" ? true : false
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={
                            selectMenuForLoggedInUser === "Settings"
                              ? headerSettingSelectIcon
                              : headerSettingIcon
                          }
                          alt="settings"
                          className="header-menu-img"
                        />
                      </ListItemIcon>
                      <Typography variant="inherit">Settings</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => handleSelectForLoggedInUser("Logout")}
                      selected={
                        selectMenuForLoggedInUser === "Logout" ? true : false
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={
                            selectMenuForLoggedInUser === "Logout"
                              ? headerLogoutSelectIcon
                              : headerLogoutIcon
                          }
                          alt="document"
                          className="header-menu-img"
                        />
                      </ListItemIcon>
                      <Typography variant="inherit">Logout</Typography>
                    </MenuItem>
                  </PopoverContainer>
                </Box>
              </Box>
            </Box>
          </Container>
        </StylesBoxLoggedIn>
      )}
    </>
  );
}

const styles = {
  header: {
    wrapper: {
      minHeight: "127px",
      paddingLeft: "16px",
      paddingRight: "16px",
      display: "flex",
      alignItems: "center",
      background: "linear-gradient(to right,#6928F2, #884FFF)",
    },
    // container: {
    //   display: "flex",
    //   justifyContent: "space-between",
    //   alignItems: "center",
    //   padding:"0 125px"
    // },
    user: {
      display: "flex",
      alignItems: "center",
      gap: "30px",
    },
    profile: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      color: "#fff",
    },
    languageContainer: {
      display: "flex",
      gap: "8px",
    },
    languageName: {
      fontSize: "20px",
      fontWeight: 700,
      color: "#FFFFFF",
    },
  },
};
