// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilteredService } from "../../../components/src/interfaces.web";
import { returnTruthyString } from "../../../components/src/HelperUtils";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

const ServicesList = [
    {
        name: "Day Care",
        service_id: "",
    },
    {
        name: "Dog Walking",
        service_id: "",
    },
    {
        name: "Hotels",
        service_id: "",
    },
    {
        name: "Grooming",
        service_id: "",
    },
    {
        name: "Pet Sitting",
        service_id: "",
    },
    {
        name: "Training",
        service_id: "",
    }
]
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    filtredServicesList: IFilteredService[]
    selectedService: {
        name: string,
        service_id: string,
        
    }
    handleSetService: (service_name: string, service_id: string) => void,
    addServiceModalOpen: boolean
    openServiceModalFn: () => void,
    closeServiceModalFn: () => void,
    nextButtonModalFn: (tempService: IFilteredService) => void
    showsetting :boolean,
    showsettingFn :() => void,
    navigation: any;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    addServicesList: IFilteredService[]
    selectedAddService: string
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class GeneralController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
         getName(MessageEnum.NavigationPropsMessage),
        ];

        this.state = {
            addServicesList: [],
            selectedAddService: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount(){
        this.setAddServiceList()
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.filtredServicesList !== this.props.filtredServicesList) {
            this.setAddServiceList()
        }
    }
    setAddServiceList = () => {
        if(!this.props.filtredServicesList.length){
            this.setState({
                addServicesList: ServicesList
            })
            return;
        }
        const filtredList = ServicesList.filter(service =>
            !this.props.filtredServicesList.some(item => item.name === service.name)
        );
        this.setState({
            addServicesList: filtredList
        })
    }
    selectListItem = (service: IFilteredService) => {
        const { name, service_id } = service
        const serviceId = returnTruthyString(service_id)
        const service_name = returnTruthyString(name)
        this.props.handleSetService(service_name, serviceId)
    }
    handleRadioChange = (value: string) => {
        this.setState({
            selectedAddService: value
        })
    };

    closeAddServiceModal = () => {
        this.setState({
            selectedAddService: ""
        })
        this.props.closeServiceModalFn()
    }
    handleNextBtnClick = () => {
        if (!this.state.selectedAddService) return
        const tempService = {
            name: this.state.selectedAddService,
            service_id: "",
        }
        this.props.nextButtonModalFn(tempService)
        this.closeAddServiceModal()
    }
    GotoSettingScreen  = async ()=>{
        this.props.navigation.navigate("Cfentrepriseaccount");
      }
    // Customizable Area End
}

