import { styled } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { MarkerIcon,Cellout } from './assets';
import { useLoadScript } from '@react-google-maps/api';

const MapWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',

    [theme.breakpoints.up(960)]: {
        width: '100%',
        height:'100%'
    },
}))

type Props = {
    center: {
        lat: number,
        lng: number
    },
    markers: Array<{
        id: string;
        position: {
          lat: number;
          lng: number;
        };
        icon?: string;
    }>
}

const WebMapView = ({ center, markers }: Props) => {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if(mapRef.current) {
        const map = new window.google.maps.Map(mapRef.current, {
            center: center,
            zoom: 12,
            disableDefaultUI: true,
            zoomControl: true
        });

        markers.forEach(marker => {
            new window.google.maps.Marker({
              position: center,
              map,
              icon: marker.icon === 'CellOut' ? Cellout : Cellout 
            });
            
        });
    }
  }, []);

  return <MapWrapper ref={mapRef}></MapWrapper>;
}


export default WebMapView;