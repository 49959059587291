import React, { Component } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText ,StyleRules,Typography} from '@material-ui/core';
import { withStyles, WithStyles, createStyles,Theme } from '@material-ui/core/styles';
import { Grooming, pet, DogWalking, Hotel, Training, Daycare, addServices,group_switch,settings,avatar } from "./assets";

const styles = (theme: Theme): StyleRules => createStyles({
  drawer: {
    maxWidth: 313,
    flexShrink: 0,
    height:'100%',
    width:'100%',
    marginTop: '10px'
  },
  drawerList:{ position: "absolute", backgroundColor: "#fff", height:'100%',
  "@media screen and (min-width:1180px)": {
   maxwidth: '313px',
    width: '100%'
               },

   },
  drawerPaper: {
    
    marginTop: '100px'
  },
  toolbar: theme.mixins.toolbar,
  listItemText: {
    color: '#0F172A',
    fontSize:16,
    fontWeight:400,
    fontFamily:'Montserrat'

  },
  profileName:{
    display: 'flex',
  alignSelf: 'center'
  },
  profileTitle:{
    marginLeft:15,
    clor:'#000',
    fontWeight:600,
    fontSize:20
  },
  addItem: {
    color: 'purple',
  },
  selectedItem: {
    backgroundColor: '#f3e5f5',
    '&:hover': {
      backgroundColor: '#e1bee7',
    },
  },
  Profilelogo:{
    display:'flex',
    flexDirection:'row'
   }
});

interface SidebarProps extends WithStyles<typeof styles> {}
class Sidebar extends Component<SidebarProps> {

  render() {    
  const { classes } = this.props;
    return (
      <>
        <Drawer
          style={{ position: "relative",background: "white" , width: "240px" , height: "100vh "}}
          className={classes.drawer}
          variant="permanent"
          anchor="left"
        >
        </Drawer>
        <List style={{position: "absolute", backgroundColor: "#fff", height:'100%',maxWidth:295,width:'100%'}}>
          <ListItem button className={classes.drawerPaper}>
            <ListItemIcon><Box><img src={Daycare} alt="Daycare" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.listItemText}>Day Care</span>} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><Box><img src={DogWalking} alt="Dog Walking" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.listItemText}>Dog Walking</span>} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><Box><img src={Hotel} alt="Hotels" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.listItemText}>Hotels</span>} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><Box><img src={Grooming} alt="Grooming" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.listItemText}>Grooming</span>} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><Box><img src={pet} alt="Pet Sitting" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.listItemText}>Pet Sitting</span>} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><Box><img src={Training} alt="Training" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.listItemText}>Training</span>} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><Box><img src={addServices} alt="Add Service" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.addItem}>Add Service</span>} />
          </ListItem>
          <ListItem /> 
          <ListItem /> 
          <ListItem /> 
          <ListItem /> 
          <ListItem /> 
          <ListItem /> 
          <ListItem /> 
       <ListItem>
       <Box style={{ backgroundColor: "#fff", height:'100%',maxWidth:295,width:'100%'}}>
       <Box className={classes.Profilelogo}>
    <Box><img style={{width:'56px',height:'56px'}} src={avatar}/></Box>
    <Box className={classes.profileName}><Typography className={classes.profileTitle}>Maria</Typography></Box>
    </Box>
        </Box>
       </ListItem>
       <ListItem button>
            <ListItemIcon><Box><img src={group_switch} alt="Add Service" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.listItemText}>Switch Establishment</span>} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><Box><img src={settings} alt="Add Service" /></Box></ListItemIcon>
            <ListItemText primary={<span className={classes.listItemText}>Establishment Settings</span>} />
          </ListItem>
        </List>
       
        
      </>
    );
  }
}

export default withStyles(styles)(Sidebar);
