import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface NewPasswordModal {
  password:string
  password_confirmation:string
}
interface APIModal{
  body:object,
  token?:string,
  contentType:string,
  method:string, 
  endPoint:string
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  isTimerActive?:boolean;
  isVerifyBlockShow:boolean;
  timerSecond:number;
  forgotPwdError:string;
  newPwdError:string;
  userEmail:string;
  token:string;
  isSuccessfulBlockShow:boolean
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordApiCallId:string = "";
  newPasswordApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      isTimerActive:false,
      isVerifyBlockShow:false,
      timerSecond:60,
      forgotPwdError:"",
      newPwdError:"",
      userEmail:"",
      token:"",
      isSuccessfulBlockShow:false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const forgotPasswordApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
        );

      let forgotPasswordResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let forgotPasswordErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
        
      if (forgotPasswordApiRequestCallId && forgotPasswordResponseJson) {
        switch (forgotPasswordApiRequestCallId) {
          case this.forgotPasswordApiCallId:
           this.handleForgotPasswordResponse(forgotPasswordResponseJson)
            break;
            case this.newPasswordApiCallId:
           this.handleNewPasswordResponse(forgotPasswordResponseJson)
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const pathName = location.pathname
    if(pathName === "/newpassword"){
      this.getToken()
    }
  }
  getToken = () => {
    const token = location.search.split('=')[1];
    if(!token){
      this.goToLoginPage();
    }
    else{
      this.setState({token:token})
    }
  }
  //response handler
  handleForgotPasswordResponse = (responseJson:any) => {
    this.setState({ forgotPwdError: "" });
    if (responseJson.error) {
      this.setState({ forgotPwdError: responseJson?.error});
    } else {
      this.setState({
        isTimerActive: true,
        isVerifyBlockShow: true,
      });
      this.handleTimer();
    }
  }
  handleNewPasswordResponse = (responseJson:any) => {
    this.setState({ newPwdError: "" });
    if (responseJson.errors) {
      this.setState({ newPwdError: responseJson?.errors[0].token});
    } 
    else{
      this.setState({isSuccessfulBlockShow:true})
    }
  }
  //end response handler
  //for api call
  apiCalled = async (data:APIModal) => {
    const { contentType, method, endPoint, body, token } = data;
    let apiHeader;
    if (token) {
      apiHeader = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      apiHeader = {
        "Content-Type": contentType,
      };
    }
    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(apiHeader)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };
  //end for api call
  //forgot password api call
  forgotPasswordAPI = async () => {
    const forgetPwdData = {
      data: {
        type: "email_account",
        attributes: {
          email:this.state.userEmail
        },
      },
    };
    this.forgotPasswordApiCallId = await this.apiCalled({
      contentType: configJSON.forgotPasswordAPiContentType,
      method: configJSON.httpPostMethod,
      body: forgetPwdData,
      endPoint: configJSON.forgotPwdEndPoint,
    });
  };
  //end forgot password api call
   //new password api call
   newPasswordAPI = async (values:NewPasswordModal) => {
    const newPedData = {
          password_confirmation: values.password_confirmation,
          password:values.password,
          token: this.state.token
    }
    this.newPasswordApiCallId = await this.apiCalled({
      contentType: configJSON.forgotPasswordAPiContentType,
      method: configJSON.httpPostMethod,
      body: newPedData,
      endPoint: configJSON.newPwdEndPoint,
    });
  };
  //end new password api call
  //password toggle
  handleClickShowPassword = (btnType: string) => {
    if (btnType === "password") {
      this.setState({
        enablePasswordField: !this.state.enablePasswordField,
      });
    } else {
      this.setState({
        enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
      });
    }
  };
  //end password toggle
  //handle timer
  handleTimer = () => {
    this.setState({
      timerSecond:60
    });
    let timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timerSecond > 0) {
          return { ...prevState, timerSecond: prevState.timerSecond - 1};
        } else {
          clearInterval(timer);
          return {...prevState, isActive: false};
        }
      });
    }, 1000);
  }
  //end handle timer
  //navigate to register
  navigateToPage = (targetPage: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), targetPage);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  
  // Navigate to registration page
  goToRegisterPage = () => {
    this.navigateToPage("EmailAccountRegistration");
  };
  
  // Navigate to login page
  goToLoginPage = () => {
    this.navigateToPage("EmailAccountLoginBlock");
  };
  //end navigate to register
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
