import { Box, IconButton, styled } from "@material-ui/core";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { nextArrowIcon, previousArrowIcon } from "./assets";

const StyledDatePickerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  "& .react-datepicker__month-container": {
    width: "100%",
  },
  "& .react-datepicker__header": {
    backgroundColor: "transparent",
    fontSize: "14px",
    marginBottom: "20px",
  },
  "& .react-datepicker__navigation--previous, & .react-datepicker__navigation--next":
    {
      top: "16px",
      border: "0.45rem solid transparent",
    },
  "& .react-datepicker__navigation--previous": {
    borderRightColor: "#6200ee",
    left: "0",
  },
  "& .react-datepicker__navigation--next": {
    borderLeftColor: "#6200ee",
    right: "306px",
  },
  "& .react-datepicker__current-month": {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#6200ee",
    borderBottom: "none",
    paddingBottom: "0",
  },
  "& .react-datepicker__day-name, & .react-datepicker__day": {
    color: "#000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .react-datepicker__day": {
    lineHeight: "0",
    "@media (max-width: 400px)": {
      margin: "0px",
    },
  },
  "& .react-datepicker__day-name": {
    fontWeight: "bold",
    "@media (max-width: 400px)": {
      margin: "0px",
    },
  },
  "& .react-datepicker__day--selected": {
    border: "1px solid #6200EA",
    borderRadius: "10px",
  },
  "& .react-datepicker__day-content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
  },
  "& .react-datepicker__day-number": {
    fontWeight: "bold",
    margin: "2px 0",
  },
  "& .react-datepicker__day-booked-dot": {
    fontSize: "20px",
    color: "#34D399",
    marginBottom: "5px",
  },
  "& .react-datepicker__day-total-dot": {
    fontSize: "20px",
    color: "#6F05EA",
    marginBottom: "5px",
  },
  "& .react-datepicker__day-booked": {
    color: "#34D399",
    fontSize: "10px",
    "@media (max-width: 600px)": {
      fontSize: "8px",
    },
  },
  "& .react-datepicker__day-total": {
    color: "#78716C",
    fontSize: "10px",
    "@media (max-width: 600px)": {
      fontSize: "9px",
    },
  },
  "& .month-header": {
    borderBottom: "1px solid #B7B7B7",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .month-year-info": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .month-week-container": {
    display: "flex",
    borderRadius: "50px",
    alignItems: "center",
    height: "fit-content",
    backgroundColor: "rgba(183, 183, 183, 0.28)",
  },
  "& .week-month-label-active": {
    borderRadius: "50px",
    backgroundColor: "#E1CCFF",
    color: "#6200EA",
    fontSize: "14px",
    padding: "6px 24px",
    fontWeight: 700,
    "@media (max-width: 600px)": {
      fontSize: "12px",
      padding: "6px 14px",
    },
  },
  "& .week-month-label": {
    borderRadius: "50px",
    color: "#64748B",
    fontSize: "14px",
    padding: "6px 24px",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      padding: "6px 14px",
    },
  },
  "& .react-datepicker__day--selected:after": {
    content: "none !important",
  },
  "& .react-datepicker__day:hover": {
    backgroundColor: "transparent",
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "transparent",
    border: "none",
  },
  "& .react-datepicker__week, .react-datepicker__day-names": {
    justifyContent: "space-between",
  },
  "& .react-datepicker": {
    width: "100%",
  },
  "&  .react-datepicker__week":{
    alignItems:"baseline",
  },
  "& .react-datepicker__day--outside-month":{
    color:"#B7B7B7"
  }
}));
type DayDetails = {
  [key: number]: string;
};

const dayDetails: DayDetails = {
  1: "12",
  11: "Booked",
  12: "10",
  13: "12",
  28: "Booked",
};

export function CommonMonthCalendar() {
  const [startDate, setStartDate] = useState(new Date());
  const [isWeek, setIsWeek] = useState(false);
  const handleWeekMonthClick = (type: string) => {
    if (type === "week") {
      setIsWeek(true);
    } else {
      setIsWeek(false);
    }
  };

  return (
    <StyledDatePickerContainer>
      <DatePicker
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        inline
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <div className="react-datepicker__header month-header">
            <div className="month-year-info">
              <IconButton onClick={decreaseMonth}>
                <img src={previousArrowIcon} alt="prev-arrow" />
              </IconButton>
              <span className="react-datepicker__current-month">
                {date.toLocaleString("default", { month: "long" })}{" "}
                <span
                  style={{ color: "black", borderBottom: "2px solid black" }}
                >
                  {date.getFullYear()}
                </span>
              </span>
              <IconButton onClick={increaseMonth}>
                <img src={nextArrowIcon} alt="prev-arrow" />
              </IconButton>
            </div>
            <div className="month-week-container">
              <span
                className={
                  isWeek ? "week-month-label-active" : "week-month-label"
                }
                onClick={() => handleWeekMonthClick("week")}
              >
                Week
              </span>
              <span
                className={
                  !isWeek ? "week-month-label-active" : "week-month-label"
                }
                onClick={() => handleWeekMonthClick("month")}
              >
                Month
              </span>
            </div>
          </div>
        )}
        renderDayContents={(day: any, date: any) =>
            <div className="react-datepicker__day-content">
              <span className="react-datepicker__day-number">{day}</span>
              {dayDetails[day] && (
                <span
                  className={
                    dayDetails[day] === "Booked"
                      ? "react-datepicker__day-booked-dot"
                      : "react-datepicker__day-total-dot"
                  }
                >
                  .
                </span>
              )}
              {dayDetails[day] && (
                <span
                  className={
                    dayDetails[day] === "Booked"
                      ? "react-datepicker__day-booked"
                      : "react-datepicker__day-total"
                  }
                >
                  {dayDetails[day]}
                </span>
              )}
            </div>
        }
      />
    </StyledDatePickerContainer>
  );
}
