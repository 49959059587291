import { configJSON } from "../../blocks/email-account-login/src/EmailAccountLoginController";

export const getDeviceLocation = async () => {
    const API_KEY = configJSON.googleMapAPIKey; // Replace with your actual API key
    const geolocationUrl = `${configJSON.geolocationUrl}${API_KEY}`;
    const geocodingUrl = `${configJSON.geocodingUrl}${API_KEY}`;
    const geoResponse = await fetch(geolocationUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ considerIp: "true" }),
    });

    if (!geoResponse.ok) {
      throw new Error("Network response was not ok");
    }
    const geoData = await geoResponse.json();
    const { lat, lng } = geoData.location;

    const response = await fetch(`${geocodingUrl}&latlng=${lat},${lng}`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    if (data.status === "OK") {
      const locationName = data.results[0].formatted_address;
      return locationName;
    } else {
      throw new Error("Error fetching location name");
    }
  };