import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Checkbox,
  Tooltip, InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import {
  BookingImg1,
  BookingImg2,
  BookingImg3,
  BookingLogoImg,
  CalendarIcon,
  errorIcon,
  LocationIcon,
  FootPrintIcon
} from "./assets";
export const configJSON = require("./config");
import CommonSelect from "../../../components/src/SelectField";
import CommonSelectCustomer from "../../../components/src/SelectFieldCustomer";
import DatePicker from "react-datepicker";
import BookingWebMap from "../../../components/src/landingpage/BookingWebMap";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { LandingPageHeader } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

// Customizable Area End

import LandingPageBookingController, {
  Props,
} from "./LandingPageBookingController";
import { TabPanel } from "./LandingPage.web";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#6928F2",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#ede1ff",
        color:"#000000",
        fontSize:"16px",
        fontFamily: "Montserrat",
        padding:"10px 20px",
        "@media (max-width: 600px)": {
          fontSize:"12px",
          padding:"5px 10px",
        },
      },
      arrow:{
        color: "#ede1ff",
        fontSize:"40px"
      }
    }
  }
});
const ImageGalleryContainer = styled(Box)({
  marginBottom: "40px",
  "& .booking-single-img": {
    width: "100%",
    height: "624px",
    "@media (max-width: 1280px)": {
      height: "494px",
    },
    "@media (max-width: 960px)": {
      height: "434px",
    },
    "@media (max-width: 600px)": {
      height: "242px",
    },
    "@media (max-width: 430px)": {
      height: "176px",
    },
  },
  "& .booking-group-img": {
    width: "100%",
    height: "308px",
    "@media (max-width: 1280px)": {
      height: "243px",
    },
    "@media (max-width: 960px)": {
      height: "213px",
    },
    "@media (max-width: 600px)": {
      height: "117px",
    },
    "@media (max-width: 430px)": {
      height: "84px",
    },
  },
  "& .view-more-btn": {
    position: "absolute",
    right: 5,
    bottom: 10,
    "& button":{
      border: "1px solid #6F05EA",
      borderRadius: "100px",
      padding: "8px 36px",
      fontSize: "13px",
      fontWeight: 700,
      textTransform: "none",
      color: "#6200EA",
      backgroundColor: "#ffffff80",
      boxShadow: "none",
      "&:hover": {
        color: "#6200EA",
        backgroundColor: "#ffffff80",
        boxShadow: "none",
      },
      "@media (max-width: 960px)": {
        padding: "8px 16px",
        fontSize: "11px",
      },
      "@media (max-width: 600px)": {
        padding: "4px 8px",
        fontSize: "9px",
      },
    }
  },
});
const BookingPageContainer = styled(Box)({
  padding: "80px 150px",
  "& .booking-title": {
    fontSize: "36px",
    fontWeight: "600",
    marginBottom: "20px",
    marginTop: "50px",
    "@media (max-width: 1280px)": {
      fontSize: "30px",
    },
    "@media (max-width: 960px)": {
      fontSize: "24px",
      marginTop: "30px",
    },
    "@media (max-width: 600px)": {
      fontSize: "18px",
      marginTop: "15px",
    },
  },
  "& .location-container": {
    display: "flex",
    gap: "10px",
    marginBottom: "15px",
    alignItems: "center",
  },
  "& .location-img": {
    height: "20px",
    width: "16px",
  },
  "& .location-text": {
    fontSize: "20px",
    color: "#909090",
    fontWeight: 500,
    "@media (max-width: 1280px)": {
      fontSize: "18px",
    },
    "@media (max-width: 960px)": {
      fontSize: "16px",
    },
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
  },
  "& .booking-brand-info-container": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  "& .booking-brand-name": {
    fontSize: "20px",
    fontWeight: 600,
    "@media (max-width: 960px)": {
      fontSize: "18px",
    },
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  "& .booking-logo": {
    width: "95px",
    height: "95px",
    borderRadius: "50%",
    "@media (max-width: 960px)": {
      width: "80px",
      height: "80px",
    },
    "@media (max-width: 600px)": {
      width: "75px",
      height: "75px",
    },
    "@media (max-width: 450px)": {
      width: "55px",
      height: "55px",
    },
  },
  "& .booking-description-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
  },
  "& .booking-about-text": {
    fontSize: "26px",
    fontWeight: 600,
    "@media (max-width: 960px)": {
      fontSize: "22px",
    },
    "@media (max-width: 600px)": {
      fontSize: "18px",
    },
    "@media (max-width: 450px)": {
      fontSize: "16px",
    },
  },
  "& .booking-description-text": {
    fontSize: "16px",
    "@media (max-width: 800px)": {
      fontSize: "14px",
    },
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
  },
  "& .service-book-container": {
    marginTop: "26px",
    height: "650px",
    "@media (max-width: 959px)": {
      height: "unset",
    },
  },
  "& .service-book-now-container": {
    boxShadow: "0px 0px 7.5px 0px rgba(0, 0, 0, 0.2)",
    padding: "32px 32px",
  },
  "& .service-book-now-box": {
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
    
  "& .select-label": {
    fontSize:16
                          
   },
   "& .select-pet-list": {
    height:'80px',
    borderRadius:'8px',
    padding:20,
    gap:'16px',
    marginTop:5
   },
  "& .select-pet-container": {
    display: "grid",
    width:'100%',
    gap: "16px",
    marginBottom:37,
    "@media (max-width: 1460px) and (min-width: 960px)": {
      flexWrap: "wrap",
    },
    "@media (max-width: 700px)": {
      flexWrap: "wrap",
    },
  },
  
  "& .widgetImage": {
    width:25,
    height:25
   },

  "& .start-end-date-container": {
    display: "flex",
    gap: "16px",
    "@media (max-width: 1460px) and (min-width: 960px)": {
      flexWrap: "wrap",
    },
    "@media (max-width: 700px)": {
      flexWrap: "wrap",
    },
  },
  "& .start-date-container": {
    display: "flex",
    flexDirection: "column",
    height: "55px",
    border: "1px solid #7777",
    padding: "14px 20px",
    width: "50%",
    gap: "5px",
    borderRadius: "8px",
    "& .react-datepicker__input-container": {
      opacity: "1 !important",
      top: "unset",
      width: "inherit",
    },
    "& .react-datepicker__input-container input": {
      width: "200px",
      fontWeight: 600,
      fontSize: "18px",
      border: "none",
      marginLeft: "5px",
    },
    "& .date-label": {
      fontSize: "13px",
      color: "#A6A6A6",
    },
    "@media (max-width: 1460px) and (min-width: 960px)": {
      width: "100%",
    },
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },
  "& .service-booked-btn-container": {
    marginTop: "25px",
    "@media (max-width: 1280px) and (min-width: 701px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  "& .service-booked-btn": {
    borderRadius: "100px",
    padding: "8px 36px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff",
    background: "linear-gradient(to right, #6928F2, #884FFF)",
    boxShadow: "none",
    "&:hover": {
      color: "#fff",
      background: "linear-gradient(to right, #6928F2, #884FFF)",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      padding: "5px 85px",
    },
    "@media (max-width: 1280px) and (min-width: 701px)": {
      width: "50%",
      fontSize: "14px",
    },
  },
  "& .service-book-charged-text": {
    fontSize: "14px",
    color: "#777777",
    textAlign: "center",
    marginTop: "10px",
  },
  "& .service-book-total-cal-container": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .total-cal-text": {
    fontSize: "16px",
  },
  "& .total-text": {
    fontWeight: 600,
    fontSize: "20px",
  },
  "& .total-value": {
    fontWeight: 700,
    fontSize: "26px",
    color: "#6F05EA",
  },
  "& .service-book-price-text": {
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "25px",
  },
  "& .commodity-policy-container": {
    margin: "50px 0px",
    display: "flex",
    gap:"30px",
    "@media (max-width: 960px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
  "& .commodity-dog-policy-container":{
    margin: "50px 0px",
    display: "flex",
    alignItems:"flex-start",
    gap:"50px",
    "@media (max-width: 960px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
  "& .commodity-title": {
    fontSize: "22px",
    fontWeight: 600,
    marginBottom: "15px",
  },
  "& .cancellation-title": {
    fontSize: "22px",
    fontWeight: 600,
    marginBottom: "15px",
    "@media (max-width: 960px)": {
      marginTop: "30px",
    },
  },
  "& .commodity-content-container": {
    display: "flex",
    gap: "10px",
    marginBottom: "15px",
  },
  "& .commodity-img": {
    height: "25px",
    width: "25px",
  },
  "& .commodity-content-text": {
    fontSize: "16px",
  },
  "& .cancellation-policy-content-container": {
    backgroundColor: "#d5d2d242",
    borderRadius: "5px",
    padding: "20px 24px ",
  },
  "& .complementary-services-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  "& .card": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "26px",
    textAlign: "center",
    position: "relative",
    boxShadow: "0px 0px 7.5px 0px rgba(0, 0, 0, 0.2)",
  },
  "& .icon": {
    width: "35px",
    height: "35px",
  },
  "& .price": {
    marginTop: "8px",
    fontWeight: "bold",
  },
  "& .checkbox": {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  "& .view-map-btn": {
    position: "absolute",
    right: 18,
    top: 18,
    padding: "8px 36px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6200EA",
    backgroundColor: "#fff",
    boxShadow: "none",
    "&:hover": {
      color: "#6200EA",
      backgroundColor: "#fff",
      boxShadow: "none",
    },
    "@media (max-width: 960px)": {
      padding: "8px 26px",
      fontSize: "14px",
    },
    "@media (max-width: 600px)": {
      padding: "8px 16px",
      fontSize: "12px",
    },
  },
  "& .select-time-container":{
    justifyContent:"center",
    "& .MuiInput-underline::after":{
      border:"none"
    },
    "& .MuiInput-underline::before":{
      border:"none"
    },
    "& .MuiInputBase-root":{
      fontWeight:600,
      fontSize:"18px"
    },
    "& .MuiTypography-body1":{
      fontSize:"18px !important",
      fontWeight:"600 !important",
      opacity:"1 !important"
    }
  },
  "& .MuiIconButton-colorSecondary .checkbox": {
    color: "#6F05EA",
  },
  "& .booking-location-container": {
    marginTop: "30px",
  },
  "@media (max-width: 1280px)": {
    padding: "80px 120px",
  },
  "@media (max-width: 960px)": {
    padding: "80px 70px",
  },
  "@media (max-width: 600px)": {
    padding: "80px 30px",
  },
  "@media (max-width: 430px)": {
    padding: "80px 20px",
  },
  "& .complementary-services-title":{
    marginTop:"20px",
    fontSize:"12px"
  },
});
const ArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
  }
})
const StyledTableContainer = styled(TableContainer)({
  height: "100%",
  "@media (max-width: 960px)": {
    height: "550px",
  },
  "& .MuiTable-root": {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    height:"100%",
    overflowY: 'auto',
    maxHeight: '100%',
    '&::-webkit-scrollbar': {
      width: '0px', 
    },
    scrollbarWidth: 'none',
  },
  "& .MuiTable-root td:first-child": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  "& .MuiTable-root td:last-child": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  "& .MuiTableCell-root": {
    fontSize: "14px",
    padding: "8px 15px",
    lineHeight: "1.83",
    borderBottom:"none"
  },
  "& .MuiTableCell-body": {
    fontWeight: 600,
    width:180
  },
  "& tableRow": {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  "& .select-btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "8px 36px",
    fontSize: "13px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6200EA",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      color: "#6200EA",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      padding: "5px 85px",
    },
  },
  "& .service-type-header": {
    width: "167px",
  },
  "& .service-type-body": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    border: "none",
  },
  "& .capacity-body": {
    width: "75px",
  },
  "& .price-body": {
  },
  "& .recurrence-menu": {
    "& .MuiInputBase-root": {
      backgroundColor: "#F0E5FF",
      borderRadius: "8px",
    },
    "& .MuiTypography-body1": {
      color: "#6200EA !important",
      opacity: "1 !important",
      fontSize:"14px !important"
    },
    "& .MuiSelect-icon": {
      color: "#6200EA",
    },
    "& .MuiInput-underline::after": {
      border: "none",
    },
    "& .MuiInput-underline::before": {
      border: "none",
    },
  },
  "& .room-name":{
    color:"#6200EA"
  },
});
const StyledTableHead = styled(TableHead)({
  backgroundColor: "#F6F6F6",
  display:'table-caption',
  height:50,
  borderRadius: "8px",
  marginBottom: "10px",
  "& .MuiTableCell-root": {
    border: "none",
  },
});
const StyledTableBody = styled(TableBody)({
  display: 'table',
  "& .MuiTableRow-root": {
    boxShadow: "0px 0px 2.7px 0px rgba(0, 0, 0, 0.3)",
  },
});

// Customizable Area End

export default class LandingPageBookingWeb extends LandingPageBookingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderTrainingTable = () => {
    return (
      <StyledTableContainer data-test-id="training-booking-table">
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell className="service-type-header">
                Service Type
              </TableCell>
              <TableCell>Capacity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell />
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {configJSON.dummyBookingTableData.map((row: any) => (
              <TableRow key={row.Id}>
                <TableCell>
                  <Tooltip title={row.ServiceType} arrow>
                    <span className="service-type-body">{row.ServiceType}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className="capacity-body">{row.Capacity}</TableCell>
                <TableCell className="price-body">{row.Price}</TableCell>
                <TableCell>
                  <Button className="select-btn" >Select</Button>
                </TableCell>
              </TableRow>
            ))}
          </StyledTableBody>
        </Table>
      </StyledTableContainer>
    );
  };

  renderHotelTable = () => {
    return (
      <StyledTableContainer>
        <Table stickyHeader >
          <StyledTableHead>
            <TableRow>
              <TableCell className="service-type-header">
                Room Type
              </TableCell>
              <TableCell>Capacity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {this.state.HotelSubcategories.map((row: any) => (
              <TableRow key={row.Id}>
                <TableCell>
                  <Tooltip title="Deluxe room" arrow>
                    <span className="service-type-body room-name">{row.title}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className="capacity-body">{row.capacity}</TableCell>
                <TableCell className="price-body">{row.prices[0]?.price}</TableCell>
                <TableCell>
                  <Button data-test-id='select-price' onClick={()=>this.selectPrice(row?.prices[0])} className="select-btn">Select</Button>
                </TableCell>
              </TableRow>
            ))}
          </StyledTableBody>
        </Table>
      </StyledTableContainer>
    );
  }

  renderTable = () => {
    switch (this.state.tabValue) {
      case 0:
        return this.renderHotelTable()
      case 1:
        return this.renderHotelTable()
      case 3:
        return this.renderHotelTable()
      case 4:
        return this.renderHotelTable()
      case 5:
        return this.renderTrainingTable()
      default:
        break;
    }
  }

  renderTitleLocation = () => {
    return (
      <>
        <Typography className="booking-title">
          {this.state.findServiceDataList.attributes.establishment.establishment_name}
        </Typography>
        <Box className="location-container">
          <img
            className="location-img"
            src={LocationIcon}
            alt="location-icon"
          />
          <Typography className="location-text">
            {this.state.findServiceDataList.attributes.establishment.address + this.state.findServiceDataList.attributes.establishment.country}
          </Typography>
        </Box>
      </>
    );
  };
  renderImageGallery = () => {
    const { uploadedImagePreview } = this.state;
    return (
      <ImageGalleryContainer>
         {!this.state.referenceImage && ( 
    <Box className="UploadedImage-section">
                      <Grid container spacing={2}>
        {uploadedImagePreview?.map((media: { url: string ; }) => {
          return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{ position: "relative" }}
          >
            <Box
              component={"image"}
              sx={{
                width: "100%",
                height: "auto",
                borderRadius:20
              }}
              {...( { src: media.url, controls: true })}
            />
          </Grid>
           )
         })}
      </Grid>
                        </Box>
        )}
       
      </ImageGalleryContainer>
    );
  };
  renderDescription = () => {
    return (
      <Grid container spacing={3}>
        <Grid item sm={4} md={3} lg={2} xl={1}>
          <Box className="booking-brand-info-container">
            <img src={BookingLogoImg} alt="logo" className="booking-logo" />
            <Typography className="booking-brand-name">VillaDog</Typography>
          </Box>
        </Grid>
        <Grid item sm={8} md={9} lg={10} xl={11}>
          <Box className="booking-description-container">
            <Typography className="booking-about-text">About</Typography>
            <Typography className="booking-description-text">
              {this.state.findServiceDataList.attributes.about}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };
  renderServiceBook = () => {
    const totalPrice = this.state.complementaryServices.reduce((total, service) => total + (+ service.price), (this.state.selectedValue*5));
    return (
      <Grid container spacing={6} className="service-book-container">
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          style={{ height: "100%", width: "100%" }}
        >
          {this.renderTable()}
        </Grid>
        <Grid item lg={6} md={6} sm={12} className="service-book-now-box">
          <Box className="service-book-now-container">
            <Typography className="service-book-price-text">
              € {this.state.selectedValue}/night
            </Typography>
            <Box className="select-pet-container">
            <FormControl
                          className="allowedPetSelect"
                        >
                          <InputLabel className="date-label" style={{marginTop:11,marginLeft:20}}>Select Pet</InputLabel>
                          <Select
                           data-test-id='selectpetlist'
                          variant="outlined"
                            id="allowedPet"
                            startAdornment={<img className='widgetImage' src={FootPrintIcon} />}
                            IconComponent={ArrowIcon}
                            placeholder="Select Pet"
                            className="select-pet-list"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            onChange={(event)=>{this.selectPet(event)}}
                          >
                            <MenuItem value="" disabled>
                              <span className="selectPlaceholder">
                                Select Pet
                              </span>
                            </MenuItem>
                            {this.state.PetArrayList.map((pet) => (
            <MenuItem className="selectMenu" key={pet.attributes.id} value={pet.attributes.id}>
              {pet.attributes.name}
            </MenuItem>
          ))}
                          </Select>
                        </FormControl>
            </Box>
            <Box className="start-end-date-container">
                        
              <Box className="start-date-container">
                <Typography className="date-label">Start Date</Typography>
                <DatePicker
                  selected={this.state.startdate}
                  data-test-id='startdate'
                  icon={
                    <img
                      
                      src={CalendarIcon}
                      alt="calendar-icon"
                    />
                  }
                  showIcon
                  placeholderText="Choose a Date"
                  onChange={(event) => {this.setDate('startdate',event)}}
                  selectsStart
                  withPortal
                  dateFormat="EEE dd/MM/yy"
                  minDate={new Date()}
                />
              </Box>
              {this.state.tabValue !== 5 && (
                <Box
                  className={`${
                    this.state.tabValue === 1 ? "select-time-container" : ""
                  } start-date-container`}
                >
                  {this.state.tabValue === 1 ? (
                    <CommonSelect
                      id="select time"
                      name="select time"
                      data-test-id='selecttime'
                      placeholder="Select Time"
                      list={[
                        { label: "Morning", value: "morning" },
                        { label: "Evening", value: "evening" },
                      ]}
                      onChange={(event) => {
                        this.handleselectTime(event);
                      }}
                    />
                  ) : (
                    <>
                      <Typography className="date-label">End Date</Typography>
                      <DatePicker
                        selected={this.state.enddate}
                        icon={
                          <img
                            
                            src={CalendarIcon}
                            alt="calendar-icon"
                          />
                        }
                        showIcon
                        placeholderText="Choose a Date"
                        data-test-id='enddate'
                        onChange={(event) => {this.setDate('enddate',event)}}
                        withPortal
                        dateFormat="EEE dd/MM/yy"
                        minDate={new Date()}
                      />
                    </>
                  )}
                </Box>
              )}
            </Box>
            <Box className="service-booked-btn-container">
              <Button fullWidth data-test-id='booknow' className="service-booked-btn" onClick={()=>this.BookNow()}>
                Book now
              </Button>
            </Box>
            <Typography className="service-book-charged-text">
              You will not be charged yet
            </Typography>
            <Box className="service-book-total-cal-container">
              <Typography className="total-cal-text">
                € {this.state.selectedValue} x 5 nights{" "}
              </Typography>
              <Typography>€ {this.state.selectedValue * 5}</Typography>
            </Box>
            {this.state.complementaryServices.length > 0 && <Typography className="complementary-services-title">Complementary services</Typography>}
            {this.state.complementaryServices.map((item) => (
              <Box className="service-book-total-cal-container">
                <Typography className="total-cal-text">{item.name}</Typography>
                <Typography>{item.price}€</Typography>
              </Box>
            ))}
            <Box
              style={{ borderBottom: "1px solid #B7B7B7", margin: "10px 0px" }}
            ></Box>
            <Box className="service-book-total-cal-container">
              <Typography className="total-text">Total </Typography>
              <Typography className="total-value">€ {totalPrice}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };
  renderCommodity = () => {
    return (
      <Grid container>
        <Grid xs={12} className="commodity-title">
          Commodities
        </Grid>
        {this.CommoditiesList.map((items, index) => (
          <Grid xs={6} md={6} sm={4} key={index}>
            <Box className="commodity-content-container">
              <img src={items.icon} className="commodity-img" />
              <Typography className="commodity-content-text">
                {items.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };
  renderCancellation = () => {
    return (
      <Grid container>
        <Grid xs={12} className="cancellation-title">
          Cancellation policy
        </Grid>
        <Grid xs={12} className="cancellation-policy-content-container">
          <Typography className="commodity-content-text">
            {this.state.findServiceDataList.attributes.cancellation_policy}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  renderComplementaryServices = () => {
    return (
      <Grid container>
        <Grid item xs={12} className="commodity-title">
          Complementary services
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {this.ComplementaryServicesList.map((items) => (
             <Grid key={items.id} item xs={6} sm={4} md={this.state.tabValue === 1 ? 6 : 3} lg={this.state.tabValue === 1 ? 4 : 2}>
                <Box className="card">
                  <Checkbox className="checkbox" data-test-id="complementary-services" onClick={() => this.handleClickComplementaryServices({name:items.name,price:items.price})}/>
                  <img className="icon" src={items.icon} />
                  <Typography>{items.name}</Typography>
                  <Typography className="price">{items.price}€</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          {this.state.complementaryServices.length > 0 && 
              <Box style={{display:"flex",gap:"10px", marginTop:"30px"}}>
              <img src={errorIcon} />
              <Typography style={{color:"#F87171", fontSize:"12px"}}>Transportation will be confirmed by the vendor.</Typography>
          </Box>
          }
        </Grid>
      </Grid>
    );
  };

  renderLocation = () => {
    const center = {
      lat: this.state.findServiceDataList.attributes.establishment.latitude,
      lng: this.state.findServiceDataList.attributes.establishment.longitude,
    };
    const markers = {
      position: {
        lat: this.state.findServiceDataList.attributes.establishment.latitude,
        lng: this.state.findServiceDataList.attributes.establishment.longitude,
      },
    };
    return (
      <Grid container className="booking-location-container">
        <Grid item xs={12}>
          <Typography className="commodity-title">Location</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Box style={{ position: "relative", width: "100%" }}>
            <BookingWebMap center={center} markers={markers}></BookingWebMap>
            <Button className="view-map-btn">View Map</Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  renderTabPanelContent = () => {
    return (
      <>
        {this.renderTitleLocation()}
        {this.renderImageGallery()}
        {this.renderDescription()}
        {this.renderServiceBook()}
        <Box className="commodity-policy-container">
          {this.renderCommodity()}
          {this.renderCancellation()}
        </Box>
        {this.renderComplementaryServices()}
        {this.renderLocation()}
      </>
    );
  }

  renderTrainingBooking = () => {
    return (
      <TabPanel value={this.state.tabValue} index={5}>
        {this.renderTabPanelContent()}
        </TabPanel>
    )
  };

  renderHotelBooking = () => {
    return (
      <TabPanel value={this.state.tabValue} index={0}>
        {this.renderTabPanelContent()}
      </TabPanel>
    );
  };

  renderDogWalking = () => {
    return (
      <TabPanel value={this.state.tabValue} index={1}>
        {this.renderTitleLocation()}
        {this.renderImageGallery()}
        {this.renderDescription()}
        {this.renderServiceBook()}
        <Box className="commodity-dog-policy-container">
        {this.renderComplementaryServices()}
          {this.renderCancellation()}
        </Box>
        {this.renderLocation()}
      </TabPanel>
    );
  }

  renderPetSitting = () => {
    return (
      <TabPanel value={this.state.tabValue} index={3}>
        {this.renderTabPanelContent()}
        </TabPanel>
    )
  }

  renderDayCare = () => {
    return (
      <TabPanel value={this.state.tabValue} index={4}>
        {this.renderTabPanelContent()}
        </TabPanel>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>
        <LandingPageHeader
          tabValue={this.state.tabValue}
          onTabChange={this.handleTabChange}
          SearchDataFromHeader={(data) => this.goToAdvancedSearchPage(data)}
          isRepeatShow={false}
          data-test-id="landing-booking-page-header"
        />
        <BookingPageContainer>
          {this.renderTrainingBooking()}
          {this.renderHotelBooking()}
          {this.renderDogWalking()}
          {this.renderPetSitting()}
          {this.renderDayCare()}
        </BookingPageContainer>
        <AppFooter navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
