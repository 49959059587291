export function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const now = new Date();
    const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formatTime = (hours: number, minutes: number): string => {
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    if (diffInDays === 0) {
        return `Today at ${formatTime(hours, minutes)}`;
    } else if (diffInDays === 1) {
        return `Yesterday at ${formatTime(hours, minutes)}`;
    } else {
        return `${day}/${month}/${year} at ${formatTime(hours, minutes)}`;
    }
}