import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { closeIcon } from "./assets";
import VendorImageSlider from "../../../components/src/photolibrary/VendorImageSlider";
import VendorImageVideoGallery from "../../../components/src/photolibrary/VenodrImageGallery";
// Customizable Area End

import VendorPhotoLibraryController, {
  Props,
} from "./VendorBookingPhotoLibraryController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "1024px",
    padding: "26px 100px",
    "@media (max-width: 960px)": {
      padding: "26px 50px",
    },
    "@media (max-width: 500px)": {
      padding: "26px 20px",
    },
  },
  "& .reserve-now-btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "8px 36px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#6F05EA",
    boxShadow: "none",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6F05EA",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      padding: "8px 14px",
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      padding: "8px 14px",
      fontSize: "12px",
    },
  },
  "& .close-btn": {
    borderRadius: "100px",
    fontSize: "18px",
    textTransform: "none",
    color: "#6F05EA",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      color: "#6F05EA",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      fontSize: "0px",
    },
  },
  "& .dialog-title-container": {
    display: "flex",
    alignItems: "center",
  },
  "& .reserve-now-container": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  "& .dialog-divider": {
    borderBottom: "1px solid #B7B7B7",
    margin: "25px 0px",
  },
  "& .dialog-content-container": {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "25px",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  "& .dialog-content-span": {
    fontSize: "16px",
    color: "#6F05EA",
    fontWeight: 500,
    "@media (max-width: 700px)": {
      fontSize: "15px",
    },
  },
  "& .dialog-content-span-des": {
    whiteSpace: "nowrap",
    maxWidth: "310px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  "& .dialog-content-title": {
    fontSize: "14px",
    fontWeight: 500,
    "@media (max-width: 700px)": {
      fontSize: "13px",
    },
  },
});
// Customizable Area End

export default class VendorPhotoLibraryWeb extends VendorPhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderViewImageVideo = () => {
    return (
      <VendorImageSlider
        imageVideoData={this.VendorImgVideoData}
        selectedImgVideoId={this.state.selectedImageVideoId}
        open={this.state.openViewImageVideo}
        handleImageVideoBack={this.handleBackViewImgVideo}
        data-test-id="view-img-video-dialog"
      />
    );
  };
  renderImageGallery = () => {
    return(
      <StyledDialog open={this.state.isOpenImgGallery} data-test-id="img-gallery-dialog">
          <Box className="dialog-title-container">
            <Box className="reserve-now-container">
              <Button className="reserve-now-btn">Reserve Now</Button>
            </Box>
            <Button className="close-btn" endIcon={<img src={closeIcon} />} onClick={this.handleCloseClick} data-test-id="img-gallery-dialog-close-btn">
              Close
            </Button>
          </Box>
          <Box className="dialog-divider"></Box>
          <Box className="dialog-content-container">
            <Typography className="dialog-content-title">
              Capacity: <span className="dialog-content-span">10</span>
            </Typography>
            <Typography className="dialog-content-title">
              Price: <span className="dialog-content-span">37€</span>
            </Typography>
            <Typography className="dialog-content-title dialog-content-span-des">
              Description:{" "}
              <span className="dialog-content-span">Lorem ipsum dolor sit</span>
            </Typography>
          </Box>
          <VendorImageVideoGallery imageVideoArray={this.VendorImgVideoData} handleSelectedImgVideo={this.handleImageClick} data-test-id="img-gallery"/>
          {this.state.openViewImageVideo && this.renderViewImageVideo()}
        </StyledDialog>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start

    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Button onClick={this.handleViewMoreClick} data-test-id="view-more-btn">View More</Button>
        {this.state.isOpenImgGallery && this.renderImageGallery()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
