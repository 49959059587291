import { Box, styled } from '@material-ui/core';
import React from 'react';

type FieldRowProps = {
    left: React.ReactNode,
    marginBottom?: string,
    gap?: string 
}

const FieldsRowVendor = ({left, marginBottom = '34px', gap = '36px'}: FieldRowProps) => {
    return (
        <ResponsiveBox style={{ width: '100%', display: 'flex', gap, marginBottom }}>
            <Box style={{width: '100%'}}>
                {left}
            </Box>
            
        </ResponsiveBox>
    )
}


const ResponsiveBox = styled(Box)(({ theme }) => ({
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  }))

export default FieldsRowVendor;
