export const instaLogo = require("./instagram_logo.svg");
export const applePayLogo = require("./apple_pay_logo.svg");
export const appStoreLogo = require("./download_app_store_logo.svg");
// export const appStoreLogoBackground =
//   require("./download_app_store_background.svg");
export const facebookLogo = require("./facebook_logo.svg");
export const linkedInLogo = require("./linkedIn_logo.svg");
export const youtubeLogo = require("./youtube_logo.svg");
export const masterPayLogo = require("./master_pay_logo.svg");
export const mbWayPayLogo = require("./mb_way_pay_logo.svg");
export const payPalPayLogo = require("./pay_pal_pay_logo.svg");
export const visaPayLogo = require("./visa_pay_logo.svg");
export const googleSafeBrowserLogo =
  require("./google_safe_browser_logo.svg");
