export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const signUpImage = require("../assets/sign-up-web.png");
export const headerLogo = require("../assets/logo.svg");
export const emailIcon = require("../assets/email.svg");
export const cityIcon = require("../assets/city-icon.svg");
export const countryIcon = require("../assets/country-icon.svg");
export const nameIcon = require("../assets/name-icon.svg");
export const passwordIcon = require("../assets/password.svg");
export const forwardArrowIcon = require("../assets/login-forward-arrow.svg");
export const languageIcon = require("../assets/language-icon.svg");
export const dogIcon = require("../assets/dog-icon.svg");
export const greyDogIcon = require("../assets/grey-dog-icon.svg");
export const catIcon = require("../assets/cat-icon.svg");
export const greyCatIcon = require("../assets/grey-cat-icon.svg");
export const otherIcon = require("../assets/other-icon.svg");
export const greyOtherIcon = require("../assets/grey-other-icon.svg");
export const greyFishIcon = require("../assets/grey-fish-icon.svg");
export const fishIcon = require("../assets/fish-icon.svg");
export const greyRabbitIcon = require("../assets/grey-rabbit-icon.svg");
export const rabbitIcon = require("../assets/rabbit-icon.svg");
export const greyBirdIcon = require("../assets/grey-bird-icon.svg");
export const birdIcon = require("../assets/bird-icon.svg");
export const greyReptileIcon = require("../assets/grey-reptile-icon.svg");
export const reptileIcon = require("../assets/reptile-icon.svg");
export const petNameIcon = require("../assets/pet-name-icon.svg");
export const FurIcon = require("../assets/fur-icon.svg");
export const sizeIcon = require("../assets/size-icon.svg");
export const weightIcon = require("../assets/weight-icon.svg");
export const genderIcon = require("../assets/gender-icon.svg");
export const vendorS1Image = require("../assets/vendor-s1.png");
export const vendorS2Image = require("../assets/vendor-s2.png");
export const vendorS3Image = require("../assets/vendor-s3.png");
export const vendorS2GreyImage = require("../assets/vendor-s2-grey.png");
export const vendorS3GreyImage = require("../assets/vendor-s3-grey.png");
export const vendorS4GreyImage = require("../assets/vendor-s4-grey.png");
export const vendorS1ActiveImage = require("../assets/vendor-s1-active.png");
export const vendorS2ActiveImage = require("../assets/vendor-s2-active.png");
export const vendorS3ActiveImage = require("../assets/vendor-s3-active.png");
export const vendorS4ActiveImage = require("../assets/vendor-s4-active.png");
export const individualService = require("../assets/individual-service.svg");
export const companyService = require("../assets/company-service.svg");
export const AddressIcon = require("../assets/address-grey-icon.svg");
export const ZipCodeIcon = require("../assets/zip-icon.svg");
export const trainingIcon = require("../assets/training-icon.svg");
export const petSittingIcon = require("../assets/pet-sitting-icon.svg");
export const petHotelIcon = require("../assets/pet-hotel-icon.svg");
export const groomingIcon = require("../assets/grooming-icon.svg");
export const dogCareIcon = require("../assets/dog-care-icon.svg");
export const dogWalkingIcon = require("../assets/dog-walking-icon.svg");
export const TaxIcon = require("../assets/tax-icon.svg");
export const CompanyIcon = require("../assets/company-icon.svg");