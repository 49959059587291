import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography, styled } from '@material-ui/core';
import moment from 'moment';
import Appointment from './Appointment';
import { groupAppointmentsByDate } from '../../../../components/src/helpers';
import { EmptyAppointments } from '../assets';
import { Shedule } from '../CustomisableUserProfilesController';


type Props = {
    appointments: Shedule[];
    onEventSelect: (event: Shedule | null) => void;
}

const Wrapper = styled(Box)({
    width: '100%',
    textAlign: 'left',

    "& .apointment-tab": {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: '#B7B7B7',
        fontWeight: 700,
        flex: 1,
        textTransform: 'none'
    },
    "& .apointment-tab.Mui-selected": {
        color: '#6F05EA',
        fontWeight: 700,
    },
    "& .content-wrapper": {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '36px'
    },
    "& .appointment-wrapper": {
        display: 'flex',
        flexDirection: 'column',
        gap: '50px'
    },
    "& .appointment-list": {
        display: 'flex', flexDirection: 'column', gap: '20px'
    },
    "& .header-color": {
        color: '#000', fontSize: '20px', fontWeight: 600
    },
    "& .group-list": {
        display: 'flex', flexDirection: 'column', gap: '13px'
    },
    "& .empty-wrapper": {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '235px', gap: '8px'
    },
    "& .empty-text": {
        color: '#B7B7B7', fontSize: '19px', fontWeight: 600
    }
})

const ApointmentsList = ({ appointments, onEventSelect }: Props) => {
    const [currentTab, setCurrentTab] = useState(0);


    const renderApointments = () => {
        const now = moment();
        const past = appointments.filter(appointment => moment(appointment.end_date).isBefore(now));
        const future = appointments.filter(appointment => moment(appointment.start_date).isSameOrAfter(now));

        const grouped = groupAppointmentsByDate(future);

        return (
            <Box className='appointment-wrapper'>
                <Box className='appointment-list'>
                    {grouped.map(group => (
                        <Box className='group-list'>
                            <Typography className='header-color'>
                                {moment(group.date).format("dddd")}, <span style={{color: '#B7B7B7'}}>{moment(group.date).format("DD")}</span>
                            </Typography>
                            {group.appointments.map(appointment => <Appointment event={appointment} key={appointment.id} onEventSelect={onEventSelect} />)}
                        </Box>
                    ))}
                </Box>
                <Box className='appointment-list'>
                    <Typography className='header-color'>Past Appointments</Typography>
                    {past.map(appointment => (<Appointment event={appointment} key={appointment.id} onEventSelect={onEventSelect} />))}
                    {!past.length && 
                        <Box className='empty-wrapper'>
                            <Box><img src={EmptyAppointments} /></Box>
                            <Typography className='empty-text'>No past appointments.</Typography>
                        </Box>
                    }
                </Box>
            </Box>
            

        ) 
    }


    return (
        <Wrapper>
            <Tabs
                value={currentTab}
                data-test-id="apointment-tabs"
                onChange={(_, idx) => setCurrentTab(idx)}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: '#6F05EA',
                    },
                }}
                >
                <Tab label="Appointments" className="apointment-tab" />
            </Tabs>
            <Box className='content-wrapper'>
                {currentTab === 0 && renderApointments()}
                {currentTab === 1 && "Not released"}
            </Box>
        </Wrapper>
    )
}


export default ApointmentsList
