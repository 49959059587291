import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

interface LoaderProps {
    loading: boolean;
    children: React.ReactNode;
  }

const useStyles = makeStyles(() => ({
    root: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#fff",
      zIndex: 1,
    },
    circularContainer: {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
    },
  }));
  
  export default function PageLoader({ children, loading }: LoaderProps) {
    const classes = useStyles();
    return loading ? (
      <div className={classes.root} data-test-id='page-loader'>
        <div className={classes.circularContainer}>
          <CircularProgress />
        </div>
      </div>
    ) : <>{children}</>;
  }