import { Box, Checkbox, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { checked, unchecked } from '../assets';
import { Filters } from '../AdvancedSearchController';

type Commodity = {
    label: string,
    value: string
}

type Props = {
    list: Commodity[]
    title: string,
    values: string[],
    field: keyof Filters,
    onToggle: (title: keyof Filters, val: string) => void;
}

const styles = {
    item: {
        display: 'flex',
        alignItems: 'center', 
        gap: '8px',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600
    },
    filterLabel: {
        color: '#6F05EA',
        fontSize: '16px',
        fontWeight: 600
    },
    box: {
        display: 'flex', 
        flexDirection: 'column', 
        gap: '12px', 
        paddingTop: '20px',
        borderTop: '1px solid #C4C4C4'
    }
}

const CheckboxList = ({ list, title, values, onToggle, field }: Props) => {
    return(
        <Box style={styles.box as CSSProperties}>
            <Typography style={styles.filterLabel}>{title}</Typography>
            {list.map((item, idx) => {
                const isActive = values.includes(item.value);
                return (
                    <Box 
                        id={`ch-list-${item.label}`}
                        key={item.value} 
                        style={{...styles.item, color: isActive ? '#6F05EA' : '#000'}} 
                        onClick={() => onToggle(field, item.value)}
                    >
                        <Checkbox
                            disableRipple
                            icon={ <img src={unchecked} alt="unchecked" /> }
                            checkedIcon={ <img src={checked} alt="checkedIcon" /> }
                            style={{ padding: 0 }}
                            checked={isActive}
                        />
                        <Typography>{item.label}</Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

export default CheckboxList;