import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
} from "@material-ui/core";
import React, { ReactNode } from "react";
interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: ReactNode;
  children: ReactNode;
  className?: string;
  closeIconProp?: string;
}

const CustomCalendarDialog: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  children,
}) => {
  return (
    <>
      <StyledDialogContainer
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="responsive-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </StyledDialogContainer>
    </>
  );
};
export default CustomCalendarDialog;

const StyledDialogContainer = styled(Dialog)({
    "& .add-booking-dialog-btn-container":{
        display:"flex",
        justifyContent:"flex-end",
        gap:"10px",
        marginTop:"30px"
      },
      "& .dialog-cancel-btn":{
        border: "1px solid #4B4B4B",
        borderRadius: "50px",
        fontSize: "14px",
        fontWeight: 700,
        height: "fit-content",
        padding: "8px 36px",
        textTransform: "none",
        color: "#4B4B4B",
        backgroundColor: "transparent",
        boxShadow: "none",
        "&:hover": {
          color: "#4B4B4B",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
      "& .dialog-save-btn":{
        border: "1px solid #6F05EA",
        borderRadius: "50px",
        fontSize: "14px",
        fontWeight: 700,
        height: "fit-content",
        padding: "8px 36px",
        textTransform: "none",
        color: "#6F05EA",
        backgroundColor: "transparent",
        boxShadow: "none",
        "&:hover": {
          color: "#6F05EA",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
      "& .dialog-title-container":{
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center"
      },
      "& .dialog-title":{
        fontSize: "24px", 
        fontWeight: "600", 
        color: "#6F05EA"
      },
      "& .dialog-close-icon":{
        height:"20px", 
        width:"20px"
      },
      "& .sent-successfully-email-info-text":{
        fontSize: "24px", 
        fontWeight: "600", 
        color: "#6F05EA",
        padding:"20px 0px 60px 0px"
      },
      "& .dialog-successfully-title-container":{
        display:"flex",
        justifyContent:"flex-end"
      },
      "& .dialog-close-btn:hover":{
        backgroundColor:"#fff"
      }
})