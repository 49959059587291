import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { DailyCareIcon, DailyWalkIcon, FreshWaterIcon, FunZoneIcon, GreenZoneIcon, IndoorRoomIcon, MedicationIcon, NatureContactIcon, OutDoorRoomIcon, TransportationIcon } from "./assets";
import { ISearchModel } from "./LandingPageController";
import { SearchData } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";

interface IComplementaryServices {
  name: string;
  price: string;
}
interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
interface GallaryArray{
  url:string;
}
interface PriceList{
  id: number,
  title: string;
  price: number,
  sub_service_id: number,
  created_at: string;
  updated_at:string;
}
interface PetDeatils{
            
}
interface Subservices{
  
    id: number,
    title: string;
    capacity:number,
    allows_pet:string;
    description: string;
    service_id: number,
    duration: null,
    shift: null,
    created_at:string;
    updated_at:string;
    prices: PriceList[]

}
export interface GallaryImageErrorResponse {
  detail?: string;
  account?: string;
  token?: string;
  error?:string;
}
interface ServiceType{
  sub_services_with_prices:Subservices[]
}
const sub_services_with_prices = [
  {
    id: 0,
    title: "Sub-Service 1",
    capacity: 5,
    allows_pet: "Yes",
    description: "A description",
    service_id: 1,
    duration: null,
    shift: null,
    created_at: "2024-08-28T09:00:00.000Z",
    updated_at: "2024-08-28T09:00:00.000Z",
    prices: [
      {
        id: 35,
        title: "Once a week",
        price: 11.0,
        sub_service_id: 20,
        created_at: "2024-08-28T09:49:07.270Z",
        updated_at: "2024-08-28T09:49:07.270Z"
      },
      {
        id: 36,
        title: "Two times a week",
        price: 12.0,
        sub_service_id: 20,
        created_at: "2024-08-28T09:49:07.281Z",
        updated_at: "2024-08-28T09:49:07.281Z"
      }
    ]
  }
];
interface IFindServiceList {
  id: string;
  type: string;
  attributes: {
    id: number;
    service_type: string;
    about: string;
    dog: boolean;
    fish: boolean;
    cats: boolean;
    bird: boolean;
    rabbit: boolean;
    reptile: boolean;
    rodents: boolean;
    schedule_information: string;
    lunch_time: string;
    pool: boolean;
    outdoor_hotel: boolean;
    veterinary_support: boolean;
    indoor_hotel: boolean;
    toys_at_display: boolean;
    litter_boxes: boolean;
    fresh_water: boolean;
    natural_food: boolean;
    poop_spaces: boolean;
    individual_room: boolean;
    cat_trees: boolean;
    daily_walks: boolean;
    group_rooms: boolean;
    catio: boolean;
    garden: boolean;
    socializing_activities: boolean;
    account_id: number;
    establishment_id: number,
    cancellation_policy: string,
    created_at: string;
    updated_at: string;
    service_galleries_urls:GallaryArray[];
    establishment: {
      id: number,
      establishment_name: string;
      address: string;
      country: string;
      city:string;
      zipcode: string;
      email: string;
      phone_number: number;
      facebook_url: string;
      instagram_url: string;
      linkedin_url: string;
      tiktok_url: string;
      account_id: number;
      created_at: string;
      updated_at: string;
      activated: boolean,
      latitude: number;
      longitude: number;
  },
   },
  };
  interface AllowedPet{
    id: string,
    type:string,
    attributes: {
        pet_category: string,
        id: number,
        name: string,
        gender: string,
        breed: string,
        birthdate: string,
        weight: string,
        size: string,
        account_id: number,
        fur: string | null;
        species: string,
        created_at: string,
        updated_at: string,
        pet_photo: string | null;
         }
  }
const defaultMedical = {
    id: '',
    type: '',
    attributes: {
      id: 0,
      service_type:'',
      about:'',
      dog: false,
      fish: false,
      cats: false,
      bird: false,
      rabbit: false,
      reptile: false,
      rodents: false,
      schedule_information: "",
      lunch_time: "",
      pool: false,
      outdoor_hotel: false,
      veterinary_support:false,
      indoor_hotel: false,
      toys_at_display: false,
      litter_boxes: false,
      fresh_water: false,
      natural_food: false,
      poop_spaces: false,
      individual_room: false,
      cat_trees:false,
      daily_walks:false,
      group_rooms: false,
      catio: false,
      garden: false,
      socializing_activities:false,
      account_id: 0,
      establishment_id: 0,
            cancellation_policy: "",
      created_at: '',
      updated_at: '',
      service_galleries_urls:[],
      establishment: {
        id: 64,
        establishment_name: '',
        address: '',
        country: '',
        city:'',
        zipcode: '',
        email: '',
        phone_number: 0,
        facebook_url: '',
        instagram_url:'',
        linkedin_url: '',
        tiktok_url: '',
        account_id: 0,
        created_at: '',
        updated_at: '',
        activated: false,
        latitude: 0,
        longitude: 0
    }
     }, 
    };
    const defaultServiceType={
      sub_services_with_prices: [
        {
            id: 0,
            title: "",
            capacity: 0,
            allows_pet: "",
            description: "",
            service_id: 0,
            duration: null,
            shift: null,
            created_at: "",
            updated_at: "",
            
        }
    ]
    }
  
    const defaultPet1= [
        {
          id: '0',
      type: "animal_type",
      attributes: {
          pet_category: "",
          id: 0,
          name: "",
          gender: "",
          breed: "",
          birthdate: "",
          weight: "",
          size: '',
          account_id: 0,
          fur: null,
          species: "",
          created_at: "",
          updated_at: "",
          pet_photo: null
      }
        }
      ]
    const defaultComdata=[
      {
        name: '',
        price: ''
      }
    ]
    const defaultpriceList={
      prices: [{
        id: 0,
                        title: "",
                        price: 0, 
                        sub_service_id: 0,
                        created_at: "",
                        updated_at: ""
      }]
    }
 
  interface IFindServiceApiSuccessResponse {
    data: IFindServiceList;
  }
  interface ListErrorDetail {
    detail?: string;
    account?: string;
    token?: string;
    error?: string;
  }
  interface AllowedPetSuccessResponse {
    data: AllowedPet[];
    errors?: ListErrorDetail[];
  }
  interface IPriceListModal {
    label: string;
    value: string;
    key:string
  }
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tabValue: number,
  searchData:ISearchModel,
  landingPageTabValue:number
  complementaryServices:IComplementaryServices[]
  uploadedImagePreview:any[];
  findServiceDataList: IFindServiceList;
  referenceImage:boolean
serviceTypeArray:any;
pricelistarray: {
  label: string;
  value: string | number | undefined;
  key: string | number | undefined;

}[];
selectedValue:any;
shift:string;
startdate:any;
enddate:any;
HotelSubcategories:any;
bookedStatus:string
PetArrayList:AllowedPet[];selectedpetId:string | Blob
selectedPriceId:any
serviceid: number,

  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageBookingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetBookingDetailsApi: string = "";
  booknowAPiCall: string = "";
  getAllowedPet: string = "";
  
  CommoditiesList = [
    {
      name:"Daily care",
      icon:DailyCareIcon
    },
    {
      name:"Daily walk",
      icon:DailyWalkIcon
    },
    {
      name:"Fresh water",
      icon:FreshWaterIcon
    },
    {
      name:"Indoor rooms",
      icon:IndoorRoomIcon
    },
    {
      name:"Fun zone",
      icon:FunZoneIcon
    },
    {
      name:"Outdoor rooms",
      icon:OutDoorRoomIcon
    },
    {
      name:"Nature contact",
      icon:NatureContactIcon
    },
    {
      name:"Green Zone",
      icon:GreenZoneIcon
    },
  ]
  ComplementaryServicesList = [
    {
      id:1,
      name:"Transportation",
      icon:TransportationIcon,
      price:"121"
    },
    {
      id:2,
      name:"Medication",
      icon:MedicationIcon,
      price:"12"
    },
  ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.RestAPIResponceDataMessage),
        getName(MessageEnum.RestAPIResponceSuccessMessage)
  
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tabValue:0,
      searchData:{},
      landingPageTabValue:0,
      complementaryServices:[],
      uploadedImagePreview:[],
      findServiceDataList:defaultMedical,
      serviceTypeArray:[],
      referenceImage:false,
      pricelistarray: [],
      selectedValue:'',
      shift:'',
      startdate:new Date(),
      enddate:new Date(),
      HotelSubcategories:[],
      bookedStatus:'',
      PetArrayList:defaultPet1,
      selectedpetId:'',
      selectedPriceId:0,
      serviceid:54

      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const tabValue = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({tabValue:tabValue.landingPageTabValue ? tabValue.landingPageTabValue : 0})
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId===this.GetBookingDetailsApi) {
        this.handlebookingDetails(responseJson);
       }
       if (apiRequestCallId===this.booknowAPiCall) {
        this.handleresponse(responseJson);
       }
       if (apiRequestCallId===this.getAllowedPet) {
        this.handlePetData(responseJson);
       }
       
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getUserGeneralInfo();
    this.getAllowedPetList()
    // Customizable Area End
  }
  handlebookingDetails(responseJson:any){
      if (responseJson.data) {
        this.setState({ findServiceDataList: responseJson.data });
        this.setState({uploadedImagePreview:responseJson.data.attributes.service_galleries_urls,
          referenceImage:true,
          serviceTypeArray:responseJson.data.attributes.sub_services_with_prices,HotelSubcategories:responseJson.data.attributes.sub_services_with_prices
          })
          if(responseJson.data.attributes.sub_services_with_prices.length>0){
            const newMediaArray = responseJson.data.attributes.sub_services_with_prices.flatMap((subService:any,index:number) => 
              subService.prices.map((price:any) => ({
                label: price.title,
                value: price.id,
                key:price.price
              }))
          );
          const PriceListA: IPriceListModal[] = newMediaArray
            this.setState({ pricelistarray: PriceListA });
          }
      }

  }
  selectPet=(event:any)=>{
this.setState({selectedpetId:event.target.value})
  }
  handleresponse(responseJson:any){
    if(responseJson.data){
      this.setState({bookedStatus:'Booked Successfully'})
    } if(responseJson.meta){
      this.showAlert('!Alert',responseJson.meta.message)
    }
  }
  handlePetData(responseJson:AllowedPetSuccessResponse){
    if(responseJson.data){
      this.setState({PetArrayList:responseJson.data})
    }
  }
  
  handleselectTime(data:any){
this.setState({shift:data})
  }
  selectPrice(price:any){
    this.setState({selectedValue:price.price,selectedPriceId:price.id})
  }
  setDate(type:string,date:Date | null ){
if(type=='startdate'){
  this.setState({startdate:date})
}else if(type=='enddate'){
  this.setState({enddate:date})
}
  }
  BookNow=async() =>{
    const userToken = sessionStorage.getItem("login_token");
    let formData = new FormData();
 
    formData.append("schedule[sub_services_price_id]", this.state.selectedPriceId);
    formData.append("schedule[pet_id]", this.state.selectedpetId);
    formData.append("schedule[start_date]", this.state.startdate);
    formData.append("schedule[end_date]", this.state.enddate);
    formData.append("schedule[shift]", this.state.shift);
    formData.append("schedule[transportation]", this.state.complementaryServices[0]?.price);
    formData.append("schedule[medication]", this.state.complementaryServices[1]?.price);
    
    this.booknowAPiCall = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.BookNow,
      token: userToken,
      body: formData,
      isJsonStringify: false,
    });
  }
   handleSelectChange = (value:any) => {
    const foundItem=this.state.pricelistarray.find(item => item.value === value.target.value);
   this.setState({selectedValue:foundItem?.key,selectedPriceId:value.target.value})
  };
  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabValue: Number(newValue) });
  };

  goToAdvancedSearchPage = (data: SearchData) => {
    const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
    NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), 'AdvancedSearch');
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);
    NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    NavigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(NavigateMsg);
  }

  handleClickComplementaryServices = (item:{name:string,price:string}) => {
    this.setState((prevState) => {
      const { complementaryServices } = prevState;
      const itemExists = complementaryServices.some(service => service.name === item.name);
    
      if (itemExists) {
        return {
          complementaryServices: complementaryServices.filter((service) => service.name !== item.name)
        };
      } else {
        return {
          complementaryServices: [...complementaryServices, item]
        };
      }
    });
  }

  getUserGeneralInfo = async () => {
    const userInfoToken = sessionStorage.getItem("login_token");
    const serviceid = localStorage.getItem("service_id");
    this.GetBookingDetailsApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.showbooking}/${serviceid}`,
      token: userInfoToken,
    });
  };
  getAllowedPetList = async () => {
    const userInfoToken = sessionStorage.getItem("login_token");
    this.getAllowedPet = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getPetendpoint}`,
      token: userInfoToken,
    });
  };
  apiCall = async (data: IApiModel) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isJsonStringify ? JSON.stringify(body) : body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
