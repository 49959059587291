export const insertPhoto = require("../assets/insert_photo.png");
export const SearchIcon = require("../assets/search-icon.svg");
export const UserIcon = require("../assets/user-icon.svg");
export const attachFileIcon = require("../assets/upload-icon.svg");
export const sendIcon = require("../assets/send-icon.svg");
export const backIcon = require("../assets/back-icon.svg");
export const downLoadIcon = require("../assets/download-icon.svg");
export const dogImage = require("../assets/dog-img.svg");
export const closeIcon = require("../assets/close-icon.svg");
export const closeImageIcon = require("../assets/close-image-icon.svg");
export const backArrowIcon = require("../assets/backword-arrow-icon.svg");
export const forwardArrowIcon = require("../assets/forword-arrow-icon.svg");
export const video = require("../assets/video.mp4");
export const pauseIcon = require("../assets/pause-icon.svg");
export const volumeIcon = require("../assets/volume-icon.svg");
export const volumeOffIcon = require("../assets/volume-off-icon.svg");
export const playIcon = require("../assets/play-icn.svg");
export const uploadDocumentIcon = require("../assets/upload-document-icon.svg");
export const purpleCloseIcon =require("../assets/close-purple-icon.svg");



