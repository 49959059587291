import { Box, styled } from '@material-ui/core';
import React from 'react';

type FieldRowProps = {
    left: React.ReactNode,
    right: React.ReactNode,
    marginBottom?: string,
    gap?: string 
}

const FieldsRow = ({left, right, marginBottom = '34px', gap = '36px'}: FieldRowProps) => {
    return (
        <ResponsiveBox style={{ width: '100%', display: 'flex', gap, marginBottom, alignItems: 'flex-end' }}>
            <Box style={{width: '100%'}}>
                {left}
            </Box>
            <Box style={{width: '100%'}}>
                {right}
            </Box>
        </ResponsiveBox>
    )
}


const ResponsiveBox = styled(Box)(({ theme }) => ({
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  }))

export default FieldsRow;
