import {
  Box,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  headerLogo,
  languageIcon,
} from "./appHeader-assets";
import { styled } from "@material-ui/core/styles";

const chatIcon = require("./chat-icon.svg")
interface IAppHeader {
  userAvatar: string;
  username: string;
}

const BoxStyle = styled(Box)(({ theme }) => ({
  padding: "42px 152px",
  display: "flex",
  flexDirection: "row",
  background: "linear-gradient(#6928F2, #884FFF)",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "22px 60px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "14px 0px",
    justifyContent: "space-around",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "12px 42px",
    justifyContent: "space-between",
  },
  "& .Header-container": {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    gap: "40px",
    [theme.breakpoints.down("md")]: {
      gap: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
    },
  },
  "& .Header-Name": {
    display: "flex",
    gap: "8px",
  },
  "& .Header-language": {
    fontSize: "20px",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-Register": {
    color: "#FFFFFF",
    fontSize: "20px",
    padding: "9.69px 40.36px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    "& .MuiTypography-colorPrimary": {
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-btn-container": {
    display: "flex",
    gap: "40px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      gap: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
    }
  },
  "& .Header-user-info-container":{
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  "& .Header-User-Avatar":{
    width:"42px",
    height:"42px",
    borderRadius:"50px",
    [theme.breakpoints.down("sm")]: {
      width:"36px",
      height:"36px",
    },
  },
  "& .header-user-name": {
    color: "#fff",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      display:"none",
    },
  },
  "& .Header-Btn": {
    borderRadius: "35px",
    padding: "10px 45px",
    backgroundColor: "#FFFFFF",
    color: "#6200EA",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      padding: "0px 35px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
  },
  "& .MuiIconButton-root": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      color: "#FFFFFF",
    },
  },
  "& .Header-menu": {
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      gap: "15px",
    },
  },
  "& .header-logo": {
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20px",
    },
  },
}));

export function AppHeaderChat(props: IAppHeader) {
  return (
    <BoxStyle>
      <Box>
        <img className="header-logo" src={headerLogo} alt="Logo" />
      </Box>
      <Box className="Header-container">
        <Box className="Header-Name">
          <img src={languageIcon} alt="Language Icon" />
          <Typography className="Header-language">EN</Typography>
        </Box>
        <Box className="Header-menu">
          <Box className="Header-btn-container">
          <Box className="Header-user-info-container">
            <img className="Header-User-Avatar" src={localStorage.getItem("login_user_profile") as string} alt="" />
            <Typography className="header-user-name">
            {localStorage.getItem("login_user_name")}
            </Typography>
            </Box>
            <img src={chatIcon} alt="chat-icon"/>
          </Box>
        </Box>
      </Box>
    </BoxStyle>
  );
}
