import { Box, Button, Dialog, IconButton, styled } from "@material-ui/core";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import Slider, { Settings } from "react-slick";
import {
  backArrowIcon,
  forwardArrowIcon,
  pauseIcon,
  playIcon,
  volumeIcon,
  volumeOffIcon,
} from "../../../blocks/chat/src/assets";
import { BackIcon } from "./assets";
type MediaData = {
  id: number;
  url: string;
};

type Props = {
  imageVideoData: MediaData[];
  selectedImgVideoId: number | string;
  open: boolean;
  handleImageVideoBack: () => void;
};

const StyledSliderContainer = styled(Box)(({ theme }) => ({
  padding: "10px 25px",
}));
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paperWidthSm": {
    background: "#999",
    maxWidth: "700px",
    maxHeight: "unset",
  },
  "& .slick-prev": {
    zIndex: 1,
    width: "70px",
    height: "70px",
  },
  "& .slick-next": {
    width: "70px",
    height: "70px",
  },
  "& .slick-slide img": {
    width: "100%",
    height: "100%",
    maxWidth: "940px",
    aspectRatio: 1,
  },
  "& .slick-slide video": {
    width: "100%",
    height: "100%",
  },
  "& .slick-dots": {
    height: "40px",
    bottom: "-50px",
    display: "flex !important",
    justifyContent: "space-between",
  },
  "& .slick-dots li": {
    width: "45px",
    height: "100%",
    margin: "0px",
  },
  "& .progress-bar": {
    flexGrow: 1,
    height: "5px",
    backgroundColor: "#ccc",
    cursor: "pointer",
    position: "relative",
    margin: "0 10px",
  },
  "& .progress": {
    height: " 100%",
    backgroundColor: "#fff",
    position: "absolute",
    top: "0",
    left: "0",
    width: "10%",
  },
  "& .video-container": {
    position: "relative",
  },
  "& .play-pause-main-btn": {
    position: "absolute",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    background: "#00000030",
    borderRadius: "50px",
  },
  "& .play-pause-control-icon": {
    height: "20px !important",
    width: "20px !important",
  },
  "& .controls": {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    position: "absolute",
    top: "65%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      top: "60%",
    },
  },
  "& .main-image-video": {
    height: "450px",
    width: "450px",
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      height: "370px",
      width: "370px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "280px",
      width: "280px",
    },
    [theme.breakpoints.down(420)]: {
      height: "240px",
      width: "240px",
    },
  },
  "& .back-btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "5px 36px",
    margin: "10px 0px 0px 10px",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#6F05EA",
    boxShadow: "none",
    position: "absolute",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6F05EA",
      boxShadow: "none",
    },
    "@media (max-width: 600px)": {
      padding: "8px 14px",
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      padding: "8px 14px",
      fontSize: "12px",
    },
  },
  "& .back-btn-video": {
    zIndex: 2,
  },
}));

const StyledSecondSlider = styled(Box)({
  "& .slick-prev": {
    display: "none !important",
  },
  "& .slick-next": {
    display: "none !important",
  },
  "& .slick-slider": {
    padding: "10px 25px",
  },
  "& .slick-slide": {
    height: "100px",
    opacity: 0.6,
    padding: "0px 7px",
    "@media (max-width: 960px)": {
      height: "80px",
      padding: "0px 6px",
    },
    "@media (max-width: 700px)": {
      height: "70px",
      padding: "0px 6px",
    },
    "@media (max-width: 600px)": {
      height: "60px",
      padding: "0px 6px",
    },
    "@media (max-width: 450px)": {
      height: "40px",
      padding: "0px 3px",
    },
  },
  "& .slick-slide>div": {
    height: "100%",
    "& div": {
      height: "100%",
    },
  },
  "& .slick-track": {
    display: "flex",
  },
  "& .slick-slide img": {
    width: "100%",
    height: "100%",
  },
  "& .slick-current":{
    opacity:"1 !important"
  },
});

const RenderVideo = (item: { id: number; url: string }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video) {
      const progress = (video.currentTime / video.duration) * 100;
      setProgress(progress);
    }
  };

  const handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const video = videoRef.current;
    const progressBar = event.currentTarget;
    const clickPosition = event.nativeEvent.offsetX;
    const progressBarWidth = progressBar.clientWidth;
    const clickRatio = clickPosition / progressBarWidth;

    if (video) {
      video.currentTime = clickRatio * video.duration;
    }
  };
  const handleVideoVolume = () => {
    setIsMuted(!isMuted);
  };
  return (
    <Box className="video-container">
      <video
        id={`video-${item.id}`}
        ref={videoRef}
        className="main-image-video"
        src={item.url}
        muted={isMuted}
        onTimeUpdate={handleTimeUpdate}
        data-test-id="model-video"
      ></video>
      <IconButton onClick={handlePlayPause} className="play-pause-main-btn">
        <img
          className="play-pause-main-icon"
          src={isPlaying ? pauseIcon : playIcon}
          alt="play-pause-icon"
        />
      </IconButton>
      <div className="controls">
        <IconButton onClick={handlePlayPause}>
          <img
            className="play-pause-control-icon"
            src={isPlaying ? pauseIcon : playIcon}
            alt="play-pause-icon"
          />
        </IconButton>
        <div className="progress-bar" onClick={handleProgressClick}>
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
        <IconButton onClick={handleVideoVolume}>
          <img
            className="play-pause-control-icon"
            src={isMuted ? volumeOffIcon : volumeIcon}
            alt="volume-icon"
          />
        </IconButton>
      </div>
    </Box>
  );
};

const getMediaType = (url: string) => {
  if (/\.(jpg|jpeg|png|gif)$/i.test(url)) {
    return "image";
  } else if (/\.(mp4|webm|ogg)$/i.test(url)) {
    return "video";
  } else {
    return "unknown";
  }
};

const VendorImageSlider = ({
  imageVideoData,
  selectedImgVideoId,
  open,
  handleImageVideoBack,
}: Props) => {
  const initialSlideIndex = imageVideoData.findIndex(
    (item) => item.id === selectedImgVideoId
  );

  const sliderRef1 = useRef<Slider>(null);
  const sliderRef2 = useRef<Slider>(null);

  const settings: Settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlideIndex >= 0 ? initialSlideIndex : 0,
    nextArrow: <img src={forwardArrowIcon} />,
    prevArrow: <img src={backArrowIcon} />,
    asNavFor: sliderRef2.current || undefined,
    afterChange: (current: number) => {
      if (sliderRef2.current) {
        sliderRef2.current.slickGoTo(current);
      }
    },
  };

  const settings2: Settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    asNavFor: sliderRef1.current || undefined,
    afterChange: (current: number) => {
      if (sliderRef1.current) {
        sliderRef1.current.slickGoTo(current);
      }
    },
  };

  useEffect(() => {
    if (sliderRef1.current && sliderRef2.current) {
      sliderRef1.current.slickGoTo(initialSlideIndex, true);
      sliderRef2.current.slickGoTo(initialSlideIndex, true);
    }
  }, [initialSlideIndex]);

  const handleClick2 = (index: number) => {
    if (sliderRef1.current) {
      sliderRef1.current.slickGoTo(index);
    }
  };
  return (
    <StyledDialog open={open} aria-labelledby="simple-dialog-title">
      <StyledSliderContainer>
        <Slider {...settings} ref={sliderRef1}>
          {imageVideoData.map((item, index: number) => (
            <Box key={index}>
              {getMediaType(item.url) === "image" ? (
                <>
                  <Button
                    startIcon={<img src={BackIcon} alt="back-icon" />}
                    className="back-btn"
                    onClick={handleImageVideoBack}
                    data-test-id="view-img-back-btn"
                  >
                    Back
                  </Button>
                  <img src={item.url} alt={`card-image-${index}`} />
                </>
              ) : (
                <>
                  <Button
                    startIcon={<img src={BackIcon} alt="back-icon" />}
                    className="back-btn back-btn-video"
                    onClick={handleImageVideoBack}
                    data-test-id="view-img-back-btn"
                  >
                    Back
                  </Button>
                  <RenderVideo id={item.id} url={item.url} />
                </>
              )}
            </Box>
          ))}
        </Slider>
      </StyledSliderContainer>
      <StyledSecondSlider>
        <Slider {...settings2} ref={sliderRef2}>
          {imageVideoData.map((item, index: number) => (
            <Box key={index} onClick={() => handleClick2(index)}>
              {getMediaType(item.url) === "image" ? (
                <>
                  <img src={item.url} alt={`card-image-${index}`} />
                </>
              ) : (
                <>
                  <video src={item.url} />
                </>
              )}
            </Box>
          ))}
        </Slider>
      </StyledSecondSlider>
    </StyledDialog>
  );
};

export default VendorImageSlider;
