import React from "react";

// Customizable Area Start
import { styled, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import {
  CompanyIcon,
  TaxIcon,
  ZipCodeIcon,
  cityIcon,
  companyService,
  countryIcon,
  emailIcon,
  forwardArrowIcon,
  individualService,
  nameIcon,
  passwordIcon,
} from "./assets";
import InputField from "../../../components/src/InputField";
import PhoneInput from "react-phone-input-2";
import CommonSelect from "../../../components/src/SelectField";
import * as Yup from "yup";
import { Formik } from "formik";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddressAutocomplete from "../../../components/src/AddressAutocomplete";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
// Customizable Area End

import EmailAccountVendorRegistrationController, {
  Props,
} from "./EmailAccountVendorRegistrationController";

// Customizable Area Start
export interface Step2Modal {
  first_name: string;
  country: string;
  city: string;
  tax_number: string;
  full_phone_number: string;
  zipcode: string;
  address: string;
  country_code: string;
  company_name: string;
}

export interface VendorBaseErrorDetail {
  detail?: string;
  account?: string;
}

export interface Step3Modal {
  email: string;
  password: string;
  password_confirmation: string;
}

export interface FormError {
  [key: string]: string;
}

export interface FormErrorTouched {
  [key: string]: boolean;
}

const VendorRegisterContainer = styled(Box)(({ theme }) => ({
  margin: "65px 0px 25px 0px",
  "& .registerTitle": {
    fontSize: "40px",
    fontWeight: 700,
    color: "#6F05EA",
    textAlign: "center",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  [theme.breakpoints.down("md")]: {
    margin: "65px 0px 25px 0px",
  },
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  fontWeight: 600,
  color: "#000000",
  textAlign: "center",
  margin: "40px 0px",
  fontFamily: "Montserrat",
  [theme.breakpoints.down("xs")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down(450)]: {
    fontSize: "20px",
  },
}));

const ColorLibConnector = withStyles({
  alternativeLabel: {
    top: "auto",
    bottom: "auto",
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const StepperContainer = styled(Stepper)(({ theme }) => ({
  alignItems: "center",
  "& .MuiStep-completed": {
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiStep-alternativeLabel": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
 "& .MuiStepLabel-root.MuiStepLabel-alternativeLabel":{
    flexDirection: "column",
    background: "white",
    padding: "0px 20px",
    zIndex: 2,
  },
  "& .MuiStep-completed > span": {
    background:"white",
    zIndex:2,
  },
  "& .MuiStepLabel-iconContainer":{
    cursor:"pointer"
  },
  [theme.breakpoints.down(500)]: {
    "& .MuiStep-completed > span > span > img": {
      height: "50px",
    },
    "& .step-label > span > img": {
      height: "30px",
    },
    "& .MuiStepLabel-root.MuiStepLabel-alternativeLabel":{
      padding: "0px",
    },
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "60px",
  "& .btn": {
    padding: "12px 164px",
    borderRadius: "44px",
    height: "53px",
    fontSize: "24px",
    fontWeight: 700,
    textTransform: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      padding: "12px 124px",
    },
  },
  "& .btn-grey": {
    border: "1px solid #B7B7B7",
    color: "#B7B7B7",
  },
  "& .btn-active": {
    border: "1px solid #6F05EA",
    color: "#6F05EA",
  },
}));

const Step1FormContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "0px auto",
  "& .grid-container": {
    justifyContent: "center",
    gap: "20px",
    display: "flex",
  },
  "& .service-img-container": {
    height: "346px",
    maxWidth: "444px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    borderRadius: "20px",
    cursor:"pointer",
    [theme.breakpoints.down(860)]: {
      height: "280px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "180px",
    },
  },
  "& .service-img-container-grey": {
    border: "1px solid #E4E4E4",
  },
  "& .service-img-container-active": {
    border: "1px solid #6F05EA",
    background: "linear-gradient(#6928F2, #884FFF)",
  },
  "& .service-img": {
    width: "50%",
    maxWidth: "154px",
    maxHeight: "189px",
    height: "50%",
  },
}));

const Step2FormContainer = styled(Box)(
  ({
    isAddressError,
  }: {
    isAddressError?: boolean;
  }) =>({
  margin: "0px auto",
  width: "80%",
  "& .grid-item": {
    marginBottom: "20px",
    "& .MuiSelect-selectMenu": {
      height: "0px",
    },
  },
  "& .address-select> div > div > div ":{
    "& .MuiInput-underline::after": {
      borderBottom: "2px solid #6F05EA",
    },
    "& .MuiInput-underline::before": {
      borderBottom: isAddressError ? "2px solid red" : "1px solid rgba(0, 0, 0, 0.42)",
    },
    "& .MuiInput-underline.Mui-error::after": {
      borderBottom: "2px solid #EF4444",
    },
    "& .MuiInput-underline::after:hover": {
      borderBottom: "2px solid #6F05EA",
    },
    "& .MuiInput-root.Mui-focused .MuiInputAdornment-positionStart img": {
      filter: "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)"
    },
    "& .MuiSvgIcon-root":{
      display:"none"
    },
    "& .MuiAutocomplete-input":{
      padding:"6px"
    },
  }
}));

const Step3FormContainer = styled(Box)(({ theme }) => ({
  margin: "0px auto",
  width: "80%",
  "& .grid-item": {
    marginBottom: "20px",
    "& .MuiSelect-selectMenu": {
      height: "0px",
    },
  },
  [theme.breakpoints.between("md", "lg")]: {
    width: "40%",
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: "60%",
  },
}));

const Step4FormContainer = styled(Box)(({ theme }) => ({
  margin: "0px auto",
  width: "80%",
  "& .grid-item": {
    marginBottom: "20px",
    "& .MuiSelect-selectMenu": {
      height: "0px",
    },
  },
  "& .card-container": {
    display: "flex",
    alignItems: "center",
    gap: "25px",
    padding: "41px 127px 41px 20px",
  },
  "& .card-container-grey": {
    border: "1px solid #E4E4E4",
  },
  "& .card-container-active": {
    border: "1px solid #6200EA",
  },
  "& .card-title": {
    color: "#B7B7B7",
    fontWeight: "700",
    fontFamily: "Montserrat",
    fontSize: "20px",
    [theme.breakpoints.down(450)]: {
      fontSize: "16px",
    },
  },
  "& .card-title-grey": {
    color: "#B7B7B7",
  },
  "& .card-title-active": {
    color: "#6200EA",
  },
  "& .card-img": {
    width: "35px",
    height: "35px",
  },
  "& .termCondition": {
    display: "flex",
    justifyContent: "center",
  },

}));

const ApprovalContainer = styled(Box)(({ theme }) => ({
  margin: "0px auto",
  width: "80%",
  "& .MuiTypography-root": {
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  "& .thank-pet-zai": {
    fontWeight: "500",
    fontSize: "20px",
    marginTop: "102px",
  },
  "& .register-pending": {
    fontWeight: "700",
    fontSize: "40px",
    color: "#6F05EA",
    margin: "32px 0px 12px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  "& .receive-email": {
    fontWeight: "700",
    fontSize: "20px",
    color: "#6F05EA",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  "& .info-box": {
    display: "flex",
    justifyContent: "center",
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "14px",
      maxWidth: "434px",
      marginTop: "24px",
    },
  },
}));

const step2Schema = Yup.object().shape({
  first_name: Yup.string()
    .required("*Please Enter Name")
    .matches(/^[A-Za-z\s]*$/, "User Name should only contain letters")
    .trim(),
  city: Yup.string()
    .required("*Please Select city")
    .trim(),
  country: Yup.string()
    .required("*Please Select Country")
    .trim(),
  full_phone_number: Yup.string()
    .required("*Your Enter Phone")
    .trim(),
  address: Yup.string()
    .required("*Please select Address.")
    .trim(),
  zipcode: Yup.string()
    .required("*Please Enter Zip Code"),
  tax_number: Yup.number()
    .typeError("*Tax number must be a number")
    .required("*Please Enter Tax Number"),
});

const withCompanyNameSchema = step2Schema.concat(
  Yup.object().shape({
    company_name: Yup.string().required("*Please Enter Company name"),
  })
);

const step3Schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("*Please enter email")
    .trim(),
  password: Yup.string()
    .required("*Please enter password.")
    .test(
      "password-complexity",
      "Password should include at least 8 characters, one number, and one letter.",
      (value) => {
        if (!value) return false;
        return /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(value);
      }
    )
    .trim(),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password")], "*Passwords does not match.")
    .required("*Please enter confirm password."),
});
// Customizable Area End

export default class EmailAccountVendorRegistration extends EmailAccountVendorRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  renderStep1Form = () => {
    return (
      <Step1FormContainer data-test-id="step1-form-container">
        <FormTitle>{this.getStepContent(this.state.activeStep)}</FormTitle>
        <Box className="grid-container">
          <Box
            className={`service-img-container ${
              this.state.businessType === "individual"
                ? "service-img-container-active"
                : "service-img-container-grey"
            } `}
            onClick={() => this.handleFirstStep("individual")}
            data-test-id="individual-box"
          >
            <img
              src={individualService}
              alt="individual-service-img"
              className="service-img"
            />
          </Box>
          <Box
            data-test-id="company-box"
            className={`service-img-container ${
              this.state.businessType === "company"
                ? "service-img-container-active"
                : "service-img-container-grey"
            } `}
            onClick={() => this.handleFirstStep("company")}
          >
            <img
              src={companyService}
              alt="company-service-img"
              className="service-img"
            />
          </Box>
        </Box>
        <ButtonContainer>
          <Button
            data-test-id="step1-next"
            className={
              this.state.businessType !== "" ? "btn btn-active" : "btn btn-grey"
            }
            disabled={this.state.businessType === ""}
            onClick={this.handleNext}
          >
            Next
          </Button>
        </ButtonContainer>
      </Step1FormContainer>
    );
  };

  renderStep2Form = () => {
    return (
      <Formik
        initialValues={this.state.step2Value}
        validationSchema={
          this.state.businessType === "company"
            ? withCompanyNameSchema
            : step2Schema
        }
        enableReinitialize
        onSubmit={(values) => {
          this.handleStep2(values);
        }}
        data-test-id="step2-form"
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          getFieldProps,
          setFieldValue,
          values,
        }) => {
          const handleAddressSelect = (selectedCity: string, selectedCountry: string, selectedZipCode: string) => {
            setFieldValue("city",selectedCity);
            setFieldValue("country",selectedCountry);
            setFieldValue("zipcode",selectedZipCode);
           
            this.handleAddressIsoCountry(selectedCountry);
            if(selectedCountry === ""){
              this.handleEmptyCountry();
            }
            this.handleCountryChange(selectedCountry);
            
        };
          return (
            <form onSubmit={handleSubmit}>
              <Step2FormContainer isAddressError={Boolean(errors.address && touched.address)}>
                <FormTitle>
                  {this.getStepContent(this.state.activeStep)}
                </FormTitle>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={12} className="grid-item">
                    <InputField
                      {...getFieldProps("first_name")}
                      fullWidth
                      id="first_name"
                      placeholder="Your name"
                      label="Full Name"
                      type="text"
                      error={
                        this.getErrorAndHelperText(
                          "first_name",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      startAdornment={<img src={nameIcon} />}
                      helperText={
                        this.getErrorAndHelperText(
                          "first_name",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="Input-UserName"
                    />
                  </Grid>
                  {this.state.businessType === "company" && (
                    <Grid item md={6} sm={6} xs={12} className="grid-item">
                      <InputField
                        {...getFieldProps("company_name")}
                        fullWidth
                        id="company_name"
                        placeholder="Company name"
                        label="Company Name"
                        type="text"
                        helperText={
                          this.getErrorAndHelperText(
                            "company_name",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        error={
                          this.getErrorAndHelperText(
                            "company_name",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        startAdornment={<img src={CompanyIcon} />}
                        data-test-id="Input-Tax"
                      />
                    </Grid>
                  )}
                  <Grid item md={6} sm={6} xs={12} className="grid-item">
                    <InputField
                      {...getFieldProps("tax_number")}
                      fullWidth
                      id="tax_number"
                      placeholder="Tax number"
                      label={this.getTaxLabel()}
                      type="text"
                      helperText={
                        this.getErrorAndHelperText(
                          "tax_number",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      startAdornment={<img src={TaxIcon} />}
                      data-test-id="Input-Tax"
                      error={
                        this.getErrorAndHelperText(
                          "tax_number",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className="grid-item">
                    
                  <InputLabel
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "22px",
                      color: "rgb(51, 65, 85)",
                    }}
                  >
                    Address
                  </InputLabel>
                  <Box className="address-select">
                    <AddressAutocomplete data-test-id="address-auto-complete" field={{ name: 'address', value: values.address, onChange: handleChange, onBlur: handleBlur }} onAddressSelect={handleAddressSelect} />
                    </Box>
                        <FormHelperText style={{ color: "#f44336" }}>
                        {this.getErrorAndHelperText(
                          "address",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText}
                          </FormHelperText>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className="grid-item" style={this.state.businessType === "company" ? {padding:4} : {padding:8}}>
                    <InputField
                      {...getFieldProps("zipcode")}
                      fullWidth
                      id="zipcode"
                      placeholder="Your zip code"
                      label="Zip Code"
                      type="text"
                      startAdornment={<img src={ZipCodeIcon} />}
                      error={
                        this.getErrorAndHelperText(
                          "zipcode",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      data-test-id="Input-Zip"
                      helperText={
                        this.getErrorAndHelperText(
                          "zipcode",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className="grid-item">
                    <>
                  <InputLabel
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "22px",
                      color: "rgb(51, 65, 85)",
                    }}
                  >
                    Country
                  </InputLabel>
                  <Autocomplete
                    disableClearable
                    id="country"
                    className="country-city-select"
                    data-test-id="step2-country"
                    value={values.country || this.state.step2Value.country}
                    options={this.state.countries.map(
                      (options) => options.label
                    )}
                    onBlur={handleBlur}
                    getOptionLabel={(option) => option}
                    onChange={(_event, newValue) => {
                      setFieldValue("country",newValue);
                      setFieldValue("city","");
                        this.handleCountryChange(newValue);
                    }}
                    popupIcon={<ExpandMoreRoundedIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Your country"
                        data-test-id="step2-country"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={countryIcon} />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={
                          this.getErrorAndHelperText(
                            "country",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "country",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                      />
                    )}
                  />
                </>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className="grid-item">
                     <>
                <InputLabel
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "22px",
                      color: "rgb(51, 65, 85)",
                    }}
                  >
                    City
                  </InputLabel>
                  <Autocomplete
                    disableClearable
                    id="city"
                    data-test-id="step2-city"
                    className="country-city-select"
                    value={values.city || this.state.step2Value.city}
                    options={this.state.cities.map(
                      (options) => options.label
                    )}
                    getOptionLabel={(option) => option}
                    onBlur={handleBlur}
                    onChange={(_event, newValue) => {
                      setFieldValue("city", newValue);
                    }}
                    popupIcon={<ExpandMoreRoundedIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Your city"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={cityIcon} />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={
                          this.getErrorAndHelperText(
                            "city",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "city",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                      />
                    )}
                  />
                </>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} className="grid-item">
                    <Box>
                      <InputLabel
                        style={{
                          color: "#334155",
                          fontSize: "18px",
                          lineHeight: "22px",
                          fontWeight: 600,
                        }}
                      >
                        {"Phone"}
                      </InputLabel>
                      <PhoneInput
                        {...getFieldProps("full_phone_number")}
                        country={this.state.isoCountryName.toString().toLowerCase() || "pt"}
                        buttonStyle={{
                          border: "none",
                          background: "transparent",
                        }}
                        placeholder="Your contact number"
                        inputStyle={{
                          width: "100%",
                          height: "32px",
                          border: "none",
                          borderBottom:
                            Boolean(errors.full_phone_number) &&
                            touched.full_phone_number
                              ? "2px solid #EF4444"
                              : "1px solid #B7B7B7",
                          borderRadius: "0px",
                          paddingLeft: "40px",
                        }}
                        dropdownStyle={{ fontSize: "13px" }}
                        value={values.country_code + values.full_phone_number}
                        onChange={(phone, data: { dialCode: string }) => {
                          const phoneNumber = phone.substring(
                            data.dialCode.length
                          );
                          setFieldValue("full_phone_number", phoneNumber);
                          setFieldValue("country_code", data.dialCode);
                        }}
                        data-test-id="userinfo-phone"
                      />
                         <FormHelperText style={{ color: "#f44336" }}>
                        {this.getErrorAndHelperText(
                          "full_phone_number",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText}
                          </FormHelperText>
                    </Box>
                  </Grid>
                </Grid>
                <ButtonContainer>
                  <Button
                    className="btn btn-active"
                    type="submit"
                    endIcon={<img src={forwardArrowIcon} alt="forward-arrow" />}
                  >
                    Next
                  </Button>
                </ButtonContainer>
              </Step2FormContainer>
            </form>
          );
        }}
      </Formik>
    );
  };

  renderStep3Form = () => {
    return (
      <Formik
        initialValues={this.state.step3Value}
        validationSchema={step3Schema}
        enableReinitialize
        onSubmit={(values) => {
          this.handleStep3(values);
        }}
        data-test-id="step3-form"
      >
        {({ errors, touched, handleSubmit, getFieldProps, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Step3FormContainer>
                <FormTitle>
                  {this.getStepContent(this.state.activeStep)}
                </FormTitle>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="grid-item">
                    <InputField
                      {...getFieldProps("email")}
                      fullWidth
                      id="email"
                      placeholder="Your email"
                      label="Email"
                      type="text"
                      error={
                        this.getErrorAndHelperText(
                          "email",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      startAdornment={<img src={emailIcon} />}
                      helperText={
                        this.getErrorAndHelperText(
                          "email",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="Input-Email"
                    />
                  </Grid>
                  <Grid item xs={12} className="grid-item">
                    <InputField
                      {...getFieldProps("password")}
                      label="Password"
                      fullWidth
                      type={
                        this.state.enablePasswordField ? "password" : "text"
                      }
                      startAdornment={<img src={passwordIcon} />}
                      endAdornment={this.renderEndAdornment(
                        "password",
                        this.state.enablePasswordField
                      )}
                      helperText={
                        this.getErrorAndHelperText(
                          "password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="Input-Password"
                      placeholder={"Password"}
                      error={
                        this.getErrorAndHelperText(
                          "password",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className="grid-item">
                    <InputField
                      {...getFieldProps("password_confirmation")}
                      type={
                        this.state.enableConfirmPasswordField
                          ? "password"
                          : "text"
                      }
                      id="password_confirmation"
                      name="password_confirmation"
                      placeholder={"Confirm Password"}
                      label="Confirm Password"
                      startAdornment={<img src={passwordIcon} />}
                      fullWidth
                      error={
                        this.getErrorAndHelperText(
                          "password_confirmation",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      endAdornment={this.renderEndAdornment(
                        "confirmPassword",
                        this.state.enableConfirmPasswordField
                      )}
                      helperText={
                        this.getErrorAndHelperText(
                          "password_confirmation",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="Input-Password-Con"
                    />
                  </Grid>
                </Grid>
                <ButtonContainer>
                  <Button
                    className="btn btn-active"
                    type="submit"
                    endIcon={<img src={forwardArrowIcon} alt="forward-arrow" />}
                  >
                    Next
                  </Button>
                </ButtonContainer>
              </Step3FormContainer>
            </form>
          );
        }}
      </Formik>
    );
  };

  renderStep4Form = () => {
    return (
      <Box data-test-id="step4-form">
        <FormTitle>{this.getStepContent(this.state.activeStep)}</FormTitle>
        <Step4FormContainer>
          <Grid container spacing={2}>
            {this.cardData.map((item) => {
              const isSelected = this.state.selectedCards.includes(item.label);
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  className="grid-item"
                  onClick={() => this.handleCardClick(item)}
                  key={item.label}
                  data-test-id="step4-card-container"
                >
                  <Box
                    className={`card-container ${
                      isSelected
                        ? "card-container-active"
                        : "card-container-grey"
                    }`}
                  >
                    <img src={item.icon} className="card-img" />
                    <Typography
                      className={`card-title ${
                        isSelected ? "card-title-active" : "card-title-grey"
                      }`}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box className="termCondition">
            <TermsConditions navigation="" id="term-condition-vendor" />
          </Box>
        </Step4FormContainer>
        {this.state.vendorError &&
          this.state.vendorError.map(
            (error: VendorBaseErrorDetail, index: number) => (
              <Typography
                style={{
                  color: "#EF4444",
                  marginTop: "20px",
                  textAlign: "center",
                }}
                data-test-id="vendor-sign-up-error"
                key={index}
              >
                {error.detail || error.account}
              </Typography>
            )
          )}
        <ButtonContainer>
          <Button
            className="btn btn-active"
            onClick={() => this.handleStep4()}
            type="submit"
            endIcon={<img src={forwardArrowIcon} alt="forward-arrow" />}
            data-test-id="step4-next"
          >
            Next
          </Button>
        </ButtonContainer>
      </Box>
    );
  };

  renderApprovalInfo = () => {
    return (
      <ApprovalContainer>
        <Typography className="thank-pet-zai">
          Thank you for trusting Petzai!
        </Typography>
        <Typography className="register-pending">
          Your business registration is now pending approval.
        </Typography>
        <Typography className="receive-email">
          You will receive an email in the next 1 to 2 business days.
        </Typography>
        <Box
          className="info-box"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography>
            We also sent a copy of the filled form to {this.state.step3Value.email}, please make
            sure to validate if the information is correct.
          </Typography>
        </Box>
      </ApprovalContainer>
    );
  };

  renderFormContent = () => {
    switch (this.state.activeStep) {
      case 1:
        return this.renderStep1Form();
      case 2:
        return this.renderStep2Form();
      case 3:
        return this.renderStep3Form();
      case 4:
        return this.renderStep4Form();
      default:
        return null;
    }
  };

  renderStepper = () => {
    return (
      <>
        <StepperContainer
          alternativeLabel
          activeStep={this.state.activeStep}
          connector={<ColorLibConnector />}
        >
          {this.state.steps.map((item) => (
            <Step key={item.step}>
              <StepLabel
                onClick={
                  item.step < this.state.activeStep
                    ? () => {
                        this.handleBack();
                      }
                    : () => {}
                }
                data-test-id="step-label-id"
                className={`${
                  item.step < this.state.activeStep ? "" : "step-label"
                }`}
                StepIconComponent={this.getStepIconComponent(item)}
              ></StepLabel>
            </Step>
          ))}
        </StepperContainer>
        {this.renderFormContent()}
      </>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <AppHeader navigation={this.props.navigation}/>
        <VendorRegisterContainer>
          <Typography className="registerTitle">
            Register your business
          </Typography>
          {!this.state.isShowApproval
            ? this.renderStepper()
            : this.renderApprovalInfo()}
        </VendorRegisterContainer>
        <AppFooter navigation={this.props.navigation}/>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
