import { Formik, FormikErrors, FormikTouched } from "formik";
import GenericModal from "../../../../components/src/GenericModal";
import * as Yup from "yup";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import InputField from "../../../../components/src/InputField";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FormWrapper } from "./FormWrapper";

interface IProps {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    onSubmit: (values: PasswordFormValues) => void;
    goToForgotPassword: () => void;
    getInputProps: <T extends object>(
        field: keyof T,
        errors: FormikErrors<T>,
        touched: FormikTouched<T>,
        values: T
    ) => any;
    error?: string;
}

export type PasswordFormValues = {
    current_password: string;
    password: string;
    password_confirmation: string;
}

const initialValues = {
    current_password: '',
    password: '',
    password_confirmation: ''
}

const validationSchema = Yup.object().shape({
    current_password: Yup.string()
      .required('*Current password is required'),
    password: Yup.string()
      .required('*New password is required')
      .notOneOf([Yup.ref('current_password')], '*Your password must be different from the last password '),
    password_confirmation: Yup.string()
      .when('password', {
        is: (password) => (password && password.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], '*Passwords do not match.')
      })
      .required('*Confirm password is required')
});

const ResetPasswordModal = (props: IProps) => {
    const { isOpen, title = "", onClose, onSubmit, getInputProps, goToForgotPassword, error  } = props;
    const [currentVisible, setCurrentVisible] = useState(true);
    const [newPassword, setNewVisible] = useState(true);
    const [repeat, setRepeatVisible] = useState(true);

    const toggleButton = (field: keyof PasswordFormValues) => {
        switch (field) {
            case 'current_password':
                setCurrentVisible(!currentVisible);
                break;
            case 'password': 
                setNewVisible(!newPassword);
                break;
            case 'password_confirmation':
                setRepeatVisible(!repeat)
                break;
            default:
                break;
        }
    }

    const getFieldVisibility = (field: keyof PasswordFormValues) => {
        switch (field) {
          case 'current_password':
            return currentVisible;
          case 'password':
            return newPassword;
          case 'password_confirmation':
            return repeat;
          default:
            return false;
        }
      };

    const renderEndAdornment = (field: keyof PasswordFormValues) => (
        <IconButton
          aria-label="toggle password visibility"
          data-test-id={`adornment-${field}`}
          onClick={() => toggleButton(field)}
          edge="end"
        >
          {getFieldVisibility(field) ? <Visibility /> : <VisibilityOff />}
        </IconButton>
    );

    return (
        <GenericModal 
            id='password-reset' 
            isOpen={isOpen} 
            title={title} 
            onClose={onClose} 
            bodyStyle={{ padding: '30px 50px' }} 
            headerStyle={{ color: '#6F05EA'}} 
            withoutCloseBtn
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            data-test-id="reset-password-form"
          >
            {({
                values,
                errors,
                touched,
                dirty,
                handleSubmit,
                getFieldProps,
                resetForm,
              }) => {
                return (
                    <FormWrapper>
                        {error && <Box className="error-box">{error}</Box>}
                        <form onSubmit={handleSubmit} className="form">
                            <Box>
                                <InputField
                                    {...getFieldProps("current_password")}
                                    type={
                                        currentVisible
                                        ? "password"
                                        : "text"
                                    }
                                    {...getInputProps("current_password", errors, touched, values)}
                                    placeholder="Current password"
                                    label="Current password"
                                    endAdornment={renderEndAdornment('current_password')}
                                />
                                <Typography className="hint">
                                    Forgot your password? Click <span onClick={() => goToForgotPassword()} className="redirect-link">here.</span>.
                                </Typography>
                            </Box>
                            <Box>
                                <InputField
                                    {...getFieldProps("password")}
                                    type={
                                        newPassword
                                        ? "password"
                                        : "text"
                                    }
                                    {...getInputProps("password", errors, touched, values)}
                                    placeholder="New password"
                                    label="New password"
                                    endAdornment={renderEndAdornment('password')}
                                />
                            </Box>
                            <Box>
                                <InputField
                                    {...getFieldProps("password_confirmation")}
                                    type={
                                        repeat
                                        ? "password"
                                        : "text"
                                    }
                                    {...getInputProps("password_confirmation", errors, touched, values)}
                                    placeholder="Confirm New password"
                                    label="Confirm New password"
                                    endAdornment={renderEndAdornment('password_confirmation')}
                                />
                            </Box>
                            <Box className="box-wrapper">
                                {dirty 
                                    ? <Button className="secondary-btn" onClick={() => resetForm()}>Clear</Button> 
                                    : <Button className="secondary-btn" onClick={() => onClose()}>Cancel</Button>
                                }
                                <Button className="main-btn" type="submit">Save</Button>
                            </Box>
                        </form>
                    </FormWrapper>
                )
              }}
          </Formik>
        </GenericModal>
    )
}

export default ResetPasswordModal;