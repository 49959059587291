type FilterTypes = 'lettersSpaces' | 'cardNumber' | 'valid';

class InputFilter {

    filterLettersAndSpaces(value: string) {
        return value.replace(/[^a-zA-Z\s]/g, ''); 
    }

    formatCardNumber(value: string) {
        let inputValue = value.replace(/\D/g, ''); 

        if (inputValue.length > 4) {
            inputValue = inputValue.match(/.{1,4}/g)?.join(' ') || '';
        }

        if (inputValue.length > 19) {
            inputValue = inputValue.slice(0, 19);
        }

        return inputValue;
    }

    formatValidTill(value:string) {
        let input = value.replace(/\D/g, ''); 
        let month = '';
        let year = '';
        let formattedValue = '';

        if (input.length === 1) {
            month = input;
            formattedValue = month;
        } else if (input.length === 2) {
            month = input.substring(0, 2);
            if (parseInt(month, 10) > 12) {
                month = '0' + month.charAt(0);
                year = month.charAt(1);
                formattedValue = `${month} / ${year}`;
            } else {
                formattedValue = `${month}`;
            }
        } else if (input.length > 2) {
            month = input.substring(0, 2);
            year = input.substring(2);
            if (parseInt(month, 10) > 12) {
                month = '0' + month.charAt(0);
                year = input.substring(1);
            }
            formattedValue = `${month} / ${year}`;
        }

        return formattedValue;
    }

    applyFilter(filterType: FilterTypes, value: string) {
        switch (filterType) {
            case 'lettersSpaces':
                return this.filterLettersAndSpaces(value);
            case 'cardNumber':
                return this.formatCardNumber(value);
            case 'valid':
                return this.formatValidTill(value);
            default:
                throw new Error('Unknown filter type');
        }
    }
}

export const inputFilter = new InputFilter();