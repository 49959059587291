import React from 'react';
import * as Yup from "yup";
import { CardFields } from '../CustomisableUserProfilesController';
import GenericModal from '../../../../components/src/GenericModal';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import InputField from '../../../../components/src/InputField';
import { inputFilter } from '../../../../components/src/helpers';
import { Box, Button, Typography, styled } from '@material-ui/core';
import { Visa, Amex, Mastercard, Card } from "../assets";
import { defaultButton } from './FormWrapper';


const initialValues = {
    number: '',
    valid_till: '',
    name: ''
}

const validationSchema = Yup.object().shape({
    number: Yup.string().trim()
        .min(18, 'Enter full card number')
        .required('Card number is required'),
    valid_till: Yup.string().trim(),
    name: Yup.string().trim(),
});

const styles = {
    input: {
        height: '34px', 
        marginTop: '4px',
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Montserrat',
    },
    filledLabel: {
        fontSize: "13px",
        fontWeight: 400,
        fontFamily: "Montserrat",
        color: "#6F05EA",
    },
    secondaryLabel: {
        fontWeight: 600,
        fontSize: '18px',
        color: '#777777',
        fontFamily: "Montserrat",
    },
}

type IProps = {
    isOpen: boolean;
    error: string;
    clearError: () => void;
    title?: string;
    onClose: () => void;
    onSubmit: (values: CardFields) => void;
    inputProps: <T extends object>(
        field: keyof T,
        errors: FormikErrors<T>,
        touched: FormikTouched<T>,
        values: T
    ) => any;
}

const AddCardModal = (props: IProps) => {
    const { isOpen, onClose, onSubmit, title = "", inputProps, error, clearError } = props;

    const getCardProvider = (number: string) => {
        const cardNumber = number.replace(/\s/g, ''); // Remove spaces for provider detection
        if (cardNumber.startsWith('4')) return Visa;
        if (/^5[1-5]/.test(cardNumber)) return Mastercard;
        if (/^3[47]/.test(cardNumber)) return Amex;
        return Card;
    };

    return(
        <GenericModal
            id='add-card' 
            isOpen={isOpen} 
            title={title} 
            onClose={onClose}
            bodyStyle={{ padding: '26px 60px', width: 'auto' }} 
            headerStyle={{ color: '#6F05EA', fontSize: '24px' }}
            withoutCloseBtn
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values) => onSubmit(values)}
                data-test-id="reset-email-form"
            >
                {({
                    values,
                    errors,
                    touched,
                    dirty,
                    handleSubmit,
                    getFieldProps,
                    setFieldValue,
                    resetForm,
                }) => {
                    return(
                        <FormWrapper onSubmit={handleSubmit}>
                            <Typography className='description'>Update your payment cards</Typography>
                            <Box className='fields'>
                                {error && <Box className="error-box">{error}</Box>}
                                <Box>
                                    <InputField
                                        {...getFieldProps("name")}
                                        label={'Name'}
                                        placeholder={'The name on the card'}
                                        inputProps={{ style: styles.input }}
                                        {...inputProps("name", errors, touched, values)}
                                        onChange={(event) => {
                                            setFieldValue('name', inputFilter.applyFilter('lettersSpaces', event.target.value))
                                            clearError();
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <InputField
                                        {...getFieldProps("number")}
                                        label={'Card Number'}
                                        placeholder={'XXXX XXXX XXXX XXXX'}
                                        inputProps={{ style: styles.input }}
                                        {...inputProps("number", errors, touched, values)}
                                        onChange={(event) => {
                                            setFieldValue('number', inputFilter.applyFilter('cardNumber', event.target.value))
                                            clearError();
                                        }}
                                        endAdornment={<img src={getCardProvider(values.number)} />}
                                    />
                                </Box>
                                <Box style={{ width: '150px'}}>
                                    <InputField
                                        {...getFieldProps("valid_till")}
                                        label={'Valid Till'}
                                        placeholder={'Month / Year'}
                                        inputProps={{ style: styles.input, maxLength: "9" }}
                                        {...inputProps("valid_till", errors, touched, values)}
                                        onChange={(event) => {
                                            setFieldValue('valid_till', inputFilter.applyFilter('valid', event.target.value))
                                            clearError();
                                        }}
                                        fullWidth={false}
                                    />
                                </Box>
                                <Box className="box-wrapper">
                                    <Button className="secondary-btn" onClick={() => onClose()}>Cancel</Button> 
                                    <Button className="main-btn" type='submit'>Save</Button>
                                </Box>
                            </Box>
                        </FormWrapper>
                    )
                }}
            </Formik>
        </GenericModal>
    )
}

const FormWrapper = styled('form')({
    "& .description": {
        color: '#000',
        fontSize: '18px',
        fontWeight: 500,
        marginBottom: '30px'
    },
    "& .box-wrapper": {
        marginTop: '15px',
        display: "flex",
        gap: "26px",
        alignItems: 'center',
        justifyContent: 'center'
    },
    "& .fields": {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px'
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #4B4B4B",
        color: '#4B4B4B',
        width: '100%'
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #6F05EA",
        color: '#6F05EA',
        width: '100%',
        fontWeight: 700
    },
    "& .error-box": {
        display: 'flex',
        padding: '18px 16px',
        backgroundColor: '#FEE2E2',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        borderLeft: '3px solid #DC2626',
        fontWeight: 400,
        color: '#DC2626',
    }
})


export default AddCardModal;