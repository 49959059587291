import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@material-ui/core";
import { EmployeeAvatar, EmployeeDeleteIcon, EmployeeEditIcon, ListActionIcon, NewEmployeeIcon } from "../assets";
// Customizable Area End

import EmployeeManagementController, {
  Props,
} from "./EmployeeManagementController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});
// Customizable Area End

export default class EmployeeManagementWeb extends EmployeeManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDefaultList = () => {
    return (
      <Box className="defaultListContainer">
        <img
          className="defaultNewEmpIcon"
          src={NewEmployeeIcon}
          alt="new-employee-icon"
        />
        <Box>
          <Typography className="defaultNewEmpTypo">
            The employee list is empty.
          </Typography>
          <Typography className="defaultNewEmpTypo">
            Please click 'Add New Employee' to get started.
          </Typography>
        </Box>
      </Box>
    );
  };

  renderDeleteModal = () => {
    return(
      <DeleteDialogContainer
      fullWidth
      maxWidth="md"
      open={this.state.isDeleteOpen}
      onClose={this.handleDeleteClose}
      aria-labelledby="max-width-dialog-title"
      data-test-id="delete-dialog"
    >
      <Box className="deleteModalContent">
        <Typography className="deleteModalTypo">Are you sure you want to permanently remove this employee? </Typography>
        <Box className="deleteActionContainer">
          <Button onClick={this.handleDeleteClose} className="deleteModalBtn sureBtn" data-test-id="sure-btn">Yes, I'm sure</Button>
          <Button onClick={this.handleDeleteClose} className="deleteModalBtn removeBtn" data-test-id="remove-btn">No, don't Remove</Button>
        </Box>
      </Box>
      </DeleteDialogContainer>
    )
  }

  renderEmployeeList = () => {
  
    return (
      <Box className="empList">
        {this.state.getEmployeeList.map((item) => (
          <Box className="empListContainer" key={item.id} data-test-id="emp-list">
            <Box className="empDataContainer">
              <Box className="infoContainer">
                <img
                  className="empAvatarImg"
                  src={EmployeeAvatar}
                  alt="avatar"
                />
                <Box
                  style={{
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      gap: "12px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography className="empName" data-test-id="emp-list-name">{item.name}</Typography>
                    <Typography className="empEmail">
                      Email id: {item.email}
                    </Typography>
                  </Box>
                  <Box className="empServiceType">{item.serviceType}</Box>
                </Box>
              </Box>
              <Box className="actionContainer">
                <IconButton
                  onClick={(event) => this.handleMoreVertClick(event, item.id)}
                  data-test-id="more-vert-icon"
                >
                  <img src={ListActionIcon} alt="action-icon" />
                </IconButton>
                <StyledMenu
                  id="simple-menu"
                  anchorEl={this.state.anchorElMoreVert}
                  keepMounted
                  open={Boolean(this.state.anchorElMoreVert)}
                  onClose={this.handleMoreVertClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  data-test-id="emp-list-action-option"
                >
                  <MenuItem
                    data-test-id="editEventBtn"
                    className="menuItem"
                  >
                    <img src={EmployeeEditIcon} alt="edit-icon" className="menuActionIcon" />
                    <Typography component="span">Edit</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={this.handleDeleteOpen}
                    data-test-id="delete-event-btn"
                    className="menuItem"
                  >
                    <img src={EmployeeDeleteIcon} alt="edit-icon" className="menuActionIcon" />
                    <Typography component="span">Delete</Typography>
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <EmployeeManagementContainer>
          <Box className="titleBtnContainer">
            <Typography className="empListTitle">Employees list</Typography>
            <Button className="addNewEmpBtn">Add New Employee</Button>
          </Box>
          {this.renderEmployeeList()}
          {this.state.isDeleteOpen && this.renderDeleteModal()}
        </EmployeeManagementContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const EmployeeManagementContainer = styled(Box)({
  backgroundColor: "#ffffff",
  borderRadius: "16px",
  "& .titleBtnContainer": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "51px",
    alignItems: "center",
    padding: "58px 30px 0 30px",
    "@media (max-width: 600px)": {
      alignItems: "center",
      gap: "12px",
      padding: "58px 20px 0 20px",
    },
    "@media (max-width: 450px)": {
      padding: "58px 15px 0 15px",
    },
  },
  "& .empListTitle": {
    fontSize: "20px",
    fontWeight: 700,
    "@media (max-width: 600px)": {
      fontSize: "18px",
    },
    "@media (max-width: 450px)": {
      fontSize: "16px",
    },
  },
  "& .addNewEmpBtn": {
    width: "253px",
    fontSize: "18px",
    fontWeight: 700,
    color: "#6F05EA",
    textTransform: "none",
    borderRadius: "100px",
    border: "1px solid #6F05EA",
    "@media (max-width: 600px)": {
      fontSize: "16px",
      maxWidth:"253px",
      padding:"5px 12px",
      width:"unset"
    },
    "@media (max-width: 450px)": {
      fontSize: "14px",
      padding:"5px 8px"
    },
  },
  "& .defaultListContainer": {
    display: "flex",
    alignItems: "center",
    padding: "58px 10px",
    flexDirection: "column",
    gap: "23px",
  },
  "& .defaultNewEmpIcon": {
    height: "68px",
    width: "68px",
  },
  "& .defaultNewEmpTypo": {
    color: "#B7B7B7",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
  },
  "& .empListContainer": {
    padding: "32px 62px 35px 62px",
    "@media (max-width: 860px)": {
      padding: "32px 42px 35px 42px",
    },
    "@media (max-width: 821px)": {
      padding: "32px 32px 35px 32px",
    },
  },
  "& .empDataContainer": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .infoContainer": {
    display: "flex",
    gap: "32px",
    "@media (max-width: 600px)": {
      fontSize: "26px",
    },
    "@media (max-width: 450px)": {
      gap: "20px",
    },
  },
  "& .actionContainer": {
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },
  "& .empName": {
    fontSize: "24px",
    fontWeight: 600,
    color: "#0F172A",
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
    "@media (max-width: 450px)": {
      fontSize: "16px",
    },
  },
  "& .empEmail": {
    color: "#B7B7B7",
    fontSize: "16px",
    fontWeight: 600,
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
    "@media (max-width: 450px)": {
      fontSize: "12px",
    },
  },
  "& .empServiceType": {
    backgroundColor: "#E2CDFB",
    color: "#6F05EA",
    fontSize: "12px",
    fontWeight: 400,
    borderRadius: 40,
    textAlign: "center",
    width: "86px",
  },
  "& .empAvatarImg": {
    width: "100px",
    height: "100px",
    borderRadius: "136px",
    "@media (max-width: 600px)": {
      width: "70px",
      height: "70px",
    },
    "@media (max-width: 450px)": {
      width: "50px",
      height: "50px",
    },
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    width: "147px !important",
    marginTop: "45px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px 0px #00000008",
  },
  "& .menuItem": {
    fontSize: "12px !important",
    fontWeight:400,
    height:44,
    cursor:"pointer"
  },
  "& .MuiList-root":{
    padding:"0px"
  },
  "& .MuiListItem-button:hover":{
    backgroundColor:"#F3EBFF",
    color:"#6F05EA",
    "& img":{
      filter: "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)"
    }
  },
  "& .menuActionIcon":{
    height:"16px",
    width:"16px",
    marginRight:"13px",
  },
});

const DeleteDialogContainer = styled(Dialog)({
  "& .MuiDialog-paperWidthMd":{
    maxWidth:"850px",
    alignItems:"center"
  },
  "& .deleteModalContent":{
    padding:"48px 116px",
    "@media (max-width: 960px)": {
      padding:"48px 75px",
    },
    "@media (max-width: 600px)": {
      padding:"36px",
    },
  },
  "& .deleteModalTypo":{
    fontSize:"23px",
    fontWeight:700,
    textAlign:"center",
    maxWidth:"586px",
    "@media (max-width: 600px)": {
      fontSize:"20px",
    },
  },
  "& .deleteActionContainer":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    gap:"24px",
    marginTop:"44px",
    "@media (max-width: 960px)": {
      gap:"20px",
    },
    "@media (max-width: 600px)": {
      flexDirection:"column"
    },
  },
  "& .deleteModalBtn":{
    fontSize:"24px",
    fontWeight:"600",
    borderRadius:"40px",
    textTransform:"none",
    border:"1px solid",
    "@media (max-width: 960px)": {
      fontSize:"18px",
    },
  },
  "& .sureBtn":{
    color:"#B7B7B7",
    borderColor:"#B7B7B7",
    maxWidth:"265px",
    width:"100%"
  },
  "& .removeBtn":{
    color:"#6F05EA",
    borderColor:"#6F05EA",
    maxWidth:"274px",
    width:"100%"
  }
})
// Customizable Area End
