import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import {getImageColumn, capitalizeFirstLetter} from "../../../../components/src/HelperUtils";
import SpacesController, { Props } from "./SpacesController.web";
import {
  NextArrowIcon,
  removeGalleryIcon,
  uploadGalleryIcon,
  ViewClose,
} from "../assets";
import { Formik } from "formik";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const SpacesContainer = styled(Box)({
  width:"100%",
  "& .newSpacesBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "72px",
  },
  "& .newSpacesBtn": {
    fontSize: "18px",
    fontWeight: 700,
    color: "#6F05EA",
    borderRadius: "100px",
    border: "1px solid #6F05EA",
    padding: "8px 36px",
    textTransform: "none",
    height: "40px",
    lineHeight: "24px",
  },
  "& .disableNewSpacesBtn": {
    fontSize: "18px",
    fontWeight: 700,
    color: "#78716C",
    borderRadius: "100px",
    border: "1px solid #78716C",
    padding: "8px 36px",
    textTransform: "none",
    height: "40px",
    lineHeight: "24px",
  },
});

const DialogContainer = styled(Dialog)({
  "& .MuiDialog-container": {
    "& .MuiDialog-paper": {
      "@media (min-width: 1280px)": {
        minWidth: 1236,
      },
      "@media (min-width:960px) and (max-width: 1280px)": {
        minWidth: 927,
      },
    },
    "& .dialogTitle": {
      padding: "40px 60px",
      "& h2":{
      fontSize: "32px",
      fontWeight: 700,
      color: "#6F05EA",
      }
    },
    "& .MuiInputLabel-formControl": {
      fontSize: "18px",
      fontWeight: 400,
      color: "#6F05EA",
    },
    "& .MuiInputBase-input": {
      marginLeft: "8px",
      marginTop: "4px",
      fontSize: "12px",
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "12px",
      fontFamily: "Montserrat",
      fontStyle: "italic",
    },
    "& .MuiInput-underline::before": {
      borderBottom: "1px solid #D9D9D9",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px solid #D9D9D9",
    },
    "& .selectPlaceholder": {
      fontSize: "12px",
      color: "rgba(166, 166, 166, 1)",
      fontFamily: "Montserrat",
      fontStyle: "italic",
    },
    "& .selectMenu": {
      fontSize: "16px",
      color: "#0F172A",
      fontFamily: "Montserrat",
    },
    "& .spaceTitleInput": {
      width: "361px",
      marginRight: "52px",
      "@media (max-width: 600px)": {
        width: "100%",
        marginRight: "0px",
      },
    },
    "& .capacityInput": {
      width: "124px",
      "@media (max-width: 959px)": {
        marginTop:"34px"
      },
      "@media (max-width: 600px)": {
        minWidth: "100%",
      },
    },
    "& .allowedPetSelect": {
      width: "222px",
      marginTop: "42px",
      "& .MuiSelect-select:focus":{
        background:"transparent"
      },
      "@media (max-width: 600px)": {
        minWidth: "100%",
      },
    },
    "& .descriptionInput": {
      width: "361px",
      marginTop: "42px",
      "@media (max-width: 600px)": {
        width: "100%",
      },
      "& textarea": {
        resize: "none",
        height: "83px",
        fontFamily: "Montserrat",
      },
      "& .MuiInputBase-multiline": {
        marginTop: "25px",
        border: "1px solid #D9D9D9",
        borderRadius: "8px",
      },
      "& .MuiInput-underline::before": {
        borderBottom: "none",
      },
      "& MuiInputBase-multiline::placeholder": {
        resize: "none",
        height: "83px",
        fontFamily: "Montserrat",
      },
    },
    "& .spaceGalleryContainer": {
      marginTop: "32px",
    },
    "& .spaceGalleryTitle": {
      fontSize: "14px",
      color: "#6F05EA",
      fontWeight: 400,
      marginBottom: "10px",
    },
    "& .uploadInputContainer": {
      height: "82px",
      width: "82px",
      border: "1px solid #D9D9D9",
      borderRadius: "9px",
      textAlign: "center",
      textTransform: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
      "& .MuiButton-label": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
      },
    },
    "& .uploadIcon": {
      height: "24px",
      width: "24px",
    },
    "& .uploadInputTitle": {
      fontSize: "10px",
      width: 54,
    },
    "& .uploadFileInput": {
      height: "82px",
      width: 83,
      opacity: 0,
    },
    "& .previewContainer": {
      display: "grid",
      gap: 12,
      gridTemplateColumns: "repeat(6,1fr)",
      flexWrap: "wrap",
      width: "560px",
      "@media (max-width: 959px)": {
        gridTemplateColumns: "repeat(4,1fr)",
        width: "400px",
      },
      "@media (max-width: 600px)": {
        gridTemplateColumns: "repeat(3,1fr)",
        width: "280px",
      },
      "@media (max-width: 480px)": {
        gridTemplateColumns: "repeat(2,1fr)",
        width: "180px",
      },
      "@media (max-width: 400px)": {
        gridTemplateColumns: "repeat(1,1fr)",
        width: "130px",
        overflow: "auto",
        maxHeight: "210px",
        marginTop: "-10px",
        paddingTop: "10px",
      },
      "& .file-preview": {
        position: "relative",
        height: 83,
        width: 83,
        "& .preview-image": {
          width: 83,
          height: 83,
          objectFit: "cover",
          borderRadius: 6,
        },
        "& .remove-btn": {
          position: "absolute",
          right: 0,
          left: "69px",
          bottom: "70px",
        },
      },
    },
    "& .uploadContainer": {
      display: "flex",
      gap: "12px",
    },
    "& .dialogActionContainer": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "26px",
      marginTop: "70px",
      "@media (max-width: 600px)": {
        flexDirection: "column",
      },
    },
    "& .cancelButton": {
      borderColor: "#78716C",
      color: "#78716C",
      fontSize: 18,
      fontWeight: "700",
      padding: "0px 87.5px",
    },
    "& .actionButton": {
      height: "56px",
      border: "1px solid",
      textTransform: "none",
      borderRadius: "100px",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    "& .saveButton": {
      borderColor: "#6F05EA",
      color: "#6F05EA",
      fontSize: 18,
      fontWeight: "700",
      padding: "0px 87.5px",
    },
    "& .errorText": {
      color: "#f44336",
      fontSize: "0.75rem",
    },
  },
});

const SpaceWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  minHeight: "48px",
  height: "100%",
  alignItems: "center",
  padding: "8px 24px",
  rowGap: "8px",
  justifyContent: "space-between",
  flexWrap: "wrap",
});

const TitleWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  width:"225px",
  flexWrap:"wrap"
});

const CapacityWrapper = styled(Box)({
  minWidth: 120,
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  width:"150px",
  alignItems:"center",
  flexWrap:"wrap",
});

const DescWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "8px",
  alignContent:"center",
  width:"370px",
  justifyContent:"space-between"
});

const SpaceListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  "& .no-data-found":{
    color:"rgb(183, 183, 183)",
    fontSize:18,
    textAlign:"center",
    fontWeight:600,
  },
  "& .progressBarContainer":{
    display:"flex",
    justifyContent:"center",
    "& .MuiCircularProgress-colorPrimary":{
      color:"#6F05EA"
    }
  }
});

const LabelFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#0F172A",
});

const ValueFont = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6F05EA",
});
const ValueFontDec = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#6F05EA",
  maxWidth: "230px !important",
    "@media (max-width: 600px)": {
      maxWidth: "200px !important",
    },
});

const StyledViewDialogContainer = styled(Dialog)({
  "& .MuiPaper-root":{
    "& ::-webkit-scrollbar": {
      width: "0px",
      borderRadius: "6px"
    },
    "& ::-webkit-scrollbar-track":{
      background: "#CBD5E1",
      borderRadius: "6px",
    },
    "& ::-webkit-scrollbar-thumb":{
      background: "#6F05EA",
      borderRadius: "4px", 
      width: "6px", 
    },
    "& ::-webkit-scrollbar-thumb:hover":{
      background: "#6F05EA",
    }
  },
  "& .viewDialogContainer": {
    padding: "36px 47px 64px 47px",
    height:"100%",
    "@media (max-width: 600px)": {
      padding:"18px 23px 32px 23px"
  },
  },
  "& .closeImageContainer": {
    display: "flex",
    justifyContent: "flex-end",
    "& img":{
      "@media (max-width: 600px)": {
        height:"16px",
        width:"16px"
    },
    }
  },
  "& .titleTypo": {
    color: "#6F05EA",
    fontSize: "32px",
    fontWeight: 600,
    textAlign: "center",
  },
  "& .infoContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    marginTop: 20,
    flexWrap: "wrap",
  },
  "& .infoTypo": {
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    "& span": {
      color: "#6F05EA",
      textWrap: "wrap",
    },
  },
  "& .descriptionTypo": {
    fontSize: "11px",
    fontWeight: 400,
    textAlign: "justify",
    whiteSpace:"pre-wrap"
  },
  "& .descriptionContainer": {
    marginTop: "28px",
    backgroundColor: "#FBFBFB",
    padding: 8,
    borderRadius: 8,
  },
  "& .imgGalleryContainer": {
    marginTop: "25px",
  },
  "& .card": {
    border: "none",
    boxShadow: "none",
    height: "100%",
  },
  "& .media": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  "& .viewDialogBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop:"62px"
  },
  "& .viewEditBtn": {
    padding: "8px 0",
    height: "40px",
    width: "140px",
    borderRadius: "100px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "none",
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  },
  "& .imageGalleryContainer":{
    display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      "& .MuiImageList-root":{
        overflowY:"hidden"
      }
  },
  "& .imageGalleryImage":{
    height:"100%",
    width:"100%"
  }
});

const ResponsiveImageList = styled(ImageList)({
  maxWidth:"750px",
  "& .MuiImageListItem-root":{
    height:"100% !important",
    maxHeight:"250px !important",
  },
  "@media (max-width: 600px)": {
    "& .MuiImageListItem-root":{
      width:"50% !important",
    }
  },
  "@media (max-width: 450px)": {
    "& .MuiImageListItem-root":{
      width:"50% !important",
    }
  },
});

const ArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
  }
});



const spaceSchema = Yup.object().shape({
  spaceTitle: Yup.string()
    .required("*Space Title is required")
    .trim(),
  capacity: Yup.number()
    .typeError("*Capacity must be a number")
    .required("*Capacity is required"),
  allowedPet: Yup.string()
    .required("*AllowedPet is required")
    .trim(),
  description: Yup.string()
    .required("*Description is required")
    .trim(),
  images: Yup.array()
    .min(1, "*One image is required"),
});
// Customizable Area End

export default class SpacesWeb extends SpacesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSpaceCard = () => {
    return (
      <Box>
        <SpaceListWrapper>
          {this.state.getSpaceList.length && !this.state.getSpaceListLoading   ? (this.state.getSpaceList.map((item) => {
            return (
              <>
                <SpaceWrapper key={item.id}>
                    <TitleWrapper>
                      <LabelFont>Space Title :</LabelFont>
                      <ValueFont noWrap>
                        <span title={item.attributes.title}>{item.attributes.title}</span>
                      </ValueFont>
                    </TitleWrapper>
                    <CapacityWrapper>
                      <LabelFont>Capacity :</LabelFont>
                      <ValueFont style={{ fontWeight: 600 }} noWrap>
                        {item.attributes.capacity}
                      </ValueFont>
                    </CapacityWrapper>
                  <DescWrapper>
                    <Box style={{display:"flex", columnGap:"8px"}}>
                     <LabelFont>Description :</LabelFont>
                     <ValueFontDec className="value-dec" noWrap>
                       <span title={item.attributes.description}>{item.attributes.description}</span>
                      </ValueFontDec>
                    </Box>
                    <img
                    src={NextArrowIcon}
                    onClick={() => this.handleViewClick(item.id)}
                    data-test-id="view-data-btn"
                  />
                  </DescWrapper>
                </SpaceWrapper>
              </>
            )
          })): (
            <></>
          )}
          {!this.state.getSpaceList.length && this.state.getSpaceListLoading ? 
          <Box className="progressBarContainer"><CircularProgress/></Box>
            : <></>}
          {!this.state.getSpaceList.length && !this.state.getSpaceListLoading ? 
          <Typography className="no-data-found">No results found!</Typography>
          : <></>} 
        </SpaceListWrapper>
      </Box>
    );
  };

  renderAddNewSpaceButton = () => {
    const isDisable = this.props.selectedService.service_id === ""
    return (
      <Box className="newSpacesBtnContainer">
        <Button
          className={isDisable ? "disableNewSpacesBtn" : "newSpacesBtn"}
          onClick={this.handleAddNewSpacesClick}
          data-test-id="add-space-btn"
          disabled={isDisable}
        >
           Add New Spaces
        </Button>
      </Box>
    );
  };

  renderAddSpacesDialog = () => {
    return (
      <>
        <DialogContainer
          fullWidth
          open={this.state.isDialogOpen}
          data-test-id="spaceDialog"
        >
            <DialogTitle className="dialogTitle">{this.state.isEdit ? "Edit Spaces" : "Add Spaces"}</DialogTitle>

            <DialogContent style={{ padding: "0px 60px"}}>
              <Formik
                initialValues={this.state.spaceFormInitialValue}
                validationSchema={spaceSchema}
                enableReinitialize
                onSubmit={this.handleFormSubmit}
              >
                {({ errors, touched, handleSubmit, setFieldValue, getFieldProps, values }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <TextField
                          {...getFieldProps("spaceTitle")}
                          id="spaceTitle"
                          label="Space title"
                          placeholder="Space title"
                          fullWidth
                          className="spaceTitleInput"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            Boolean(errors.spaceTitle) && touched.spaceTitle
                          }
                          helperText={(
                            touched.spaceTitle && errors.spaceTitle
                          )?.toString()}
                        />
                        <TextField
                          {...getFieldProps("capacity")}
                          id="capacity"
                          label="Capacity"
                          placeholder="Enter Capacity"
                          fullWidth
                          margin="normal"
                          className="capacityInput"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(errors.capacity) && touched.capacity}
                          helperText={(
                            touched.capacity && errors.capacity
                          )?.toString()}
                        />
                      </Box>
                      <Box>
                        <FormControl
                          className="allowedPetSelect"
                          error={
                            Boolean(errors.allowedPet) && touched.allowedPet
                          }
                        >
                          <InputLabel shrink>Allowed Pet</InputLabel>
                          <Select
                            {...getFieldProps("allowedPet")}
                            id="allowedPet"
                            displayEmpty
                            IconComponent={ArrowIcon}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem value="" disabled>
                              <span className="selectPlaceholder">
                                Select Pet
                              </span>
                            </MenuItem>
                            <MenuItem className="selectMenu" value="dog">
                              Dogs
                            </MenuItem>
                            <MenuItem className="selectMenu" value="cats">
                              Cats
                            </MenuItem>
                            <MenuItem className="selectMenu" value="fish">
                              Fish
                            </MenuItem>
                            <MenuItem className="selectMenu" value="bird">
                              Bird
                            </MenuItem>
                            <MenuItem className="selectMenu" value="rabbit">
                              Rabbit
                            </MenuItem>
                            <MenuItem className="selectMenu" value="reptile">
                              Reptile
                            </MenuItem>
                            <MenuItem className="selectMenu" value="rodents">
                              Rodents
                            </MenuItem>
                          </Select>
                          {errors.allowedPet && touched.allowedPet && (
                            <FormHelperText
                              data-test-id="upload-error"
                              className="errorText"
                            >
                              {errors.allowedPet}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>
                      <Box>
                        <TextField
                          {...getFieldProps("description")}
                          id="description"
                          label="Description"
                          placeholder="Enter Description"
                          fullWidth
                          multiline
                          minRows={4}
                          className="descriptionInput"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            Boolean(errors.description) && touched.description
                          }
                          helperText={(
                            touched.description && errors.description
                          )?.toString()}
                        />
                      </Box>
                      <Box className="spaceGalleryContainer">
                        <Typography className="spaceGalleryTitle">
                          Space Gallery
                        </Typography>
                        <Box className="uploadContainer">
                          <Button
                            className="uploadInputContainer"
                            variant="contained"
                            component="label"
                          >
                            <img src={uploadGalleryIcon} alt="upload" />
                            <Typography className="uploadInputTitle">
                              upload file (png,jpg)
                            </Typography>
                            <input
                              type="file"
                              hidden
                              multiple
                              data-test-id="upload-img"
                              onChange={(event) => {
                                const fileList = event.currentTarget.files;
                                if (fileList) {
                                  const files = Array.from(fileList);
                                  const newFiles = [...values.images, ...files];
                                  setFieldValue('images', newFiles);
                                }
                              }}
                              accept=".png, .jpg"
                            />
                          </Button>
                          <Box className="previewContainer">
                            {values.images.map((file, index) => (
                              <Box key={index} className="file-preview">
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`preview ${index}`}
                                  className="preview-image"
                                />
                                <img
                                  onClick={() => {
                                    const updatedFiles = values.images.filter((_, i) => i !== index);
                                    setFieldValue('images', updatedFiles);
                                  }}
                                  data-test-id="remove-btn"
                                  className="remove-btn"
                                  src={removeGalleryIcon}
                                  alt="remove-icon"
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        {
                          errors.images && touched.images && (
                            <FormHelperText
                              className="errorText"
                              data-test-id="upload-error"
                            >
                              {errors.images}
                            </FormHelperText>
                          )
                        }
                      </Box>
                      <Box className="dialogActionContainer">
                        <Button
                          onClick={this.handleDialogClose}
                          className="cancelButton actionButton"
                          data-test-id="cancel-btn"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="saveButton actionButton"
                          type="submit"
                          data-test-id="save-btn"
                        >
                          Save
                        </Button>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
        </DialogContainer>
      </>
    );
  };

  renderImageGallery = () => {
    return (
          <Box className="imageGalleryContainer">
          <ResponsiveImageList cols={4}>
            {this.state.selectedViewData.attributes.images_urls.map((item,index) => (
              <ImageListItem key={item.id} cols={getImageColumn(this.state.selectedViewData.attributes.images_urls.length,3,index)}>
                <img src={item.file_url} className="imageGalleryImage"/>
              </ImageListItem>
            ))}
          </ResponsiveImageList>
        </Box>
        );
  };

  renderViewDialog = () => {
    return (
      <StyledViewDialogContainer
        fullWidth
        open={this.state.isViewDialogOpen}
        onClose={() => this.handleViewDialogClose()}
        maxWidth={"md"}
        data-test-id="view-data-modal"
      >
        <Box className="viewDialogContainer">
          <Box className="closeImageContainer">
            <img src={ViewClose} onClick={this.handleViewDialogClose} data-test-id="view-close-btn" />
          </Box>
          <Box>
            <Typography className="titleTypo">{this.state.selectedViewData.attributes.title}</Typography>
            <Box className="infoContainer">
              <Typography className="infoTypo">
                Capacity : <span>{this.state.selectedViewData.attributes.capacity}</span>{" "}
              </Typography>
              <Typography className="infoTypo">
                Allowed Pets: <span>{capitalizeFirstLetter(this.state.selectedViewData.attributes.allows_pet)}</span>
              </Typography>
            </Box>
            <Box className="descriptionContainer">
              <span className="descriptionTypo">
               {this.state.selectedViewData.attributes.description}
              </span>
            </Box>
          </Box>
          <Box className="imgGalleryContainer">
              {this.renderImageGallery()}
            </Box>
            <Box className="viewDialogBtnContainer">
              <Button
                className="viewEditBtn"
                onClick={this.handleViewEditClick}
                data-test-id="edit-btn"
              >
                Edit
              </Button>
              <Button
                onClick={this.handleHiddenClick}
                data-test-id="hidden-btn"
                style={{display:"none"}}
              ></Button>
            </Box>
        </Box>
      </StyledViewDialogContainer>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <SpacesContainer>
          <Box>
            {this.renderSpaceCard()}
            {this.renderAddNewSpaceButton()}
            {this.renderAddSpacesDialog()}
            {this.state.selectedViewData.attributes && this.renderViewDialog()}
          </Box>
        </SpacesContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
