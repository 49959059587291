import React, { useState, useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, createFilterOptions } from '@material-ui/lab/Autocomplete';
import { InputAdornment } from '@material-ui/core';
import { AddressIcon } from '../../blocks/email-account-registration/src/assets';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface PlaceType {
    description: string;
    place_id: string;
}
interface AddressAutocompleteProps {
    field: any;
    onAddressSelect: (city: string, country: string, zipCode: string) => void; 
    disable?:boolean;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ field,onAddressSelect,disable}) => {
    let { name, value, onChange, onBlur } = field;

    value= value??"";
    
    const [options, setOptions] = useState<PlaceType[]>([]);
    const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

    useEffect(() => {
        if (!searchBoxRef.current && window.google) {
            const input = document.getElementById('address-input') as HTMLInputElement;
            searchBoxRef.current = new window.google.maps.places.SearchBox(input);
            searchBoxRef.current.addListener('places_changed', handlePlacesChanged);
        }
       
    }, []);

    const handlePlacesChanged = () => {
        const places = searchBoxRef.current?.getPlaces();
        if (places && places.length > 0) {
            setOptions(
                places.map(place => ({
                    description: place.formatted_address || '',
                    place_id: place.place_id || '',
                }))
            );
        }
    };

    const handleInputChange = (event: any, value: string) => {
        onChange({ target: { name, value }});
        if (value === '') {
            onAddressSelect('', '', '');
        }
        if (value && value.length > 0 && window.google) {
            const service = new window.google.maps.places.AutocompleteService();
            service.getPlacePredictions({ input: value }, (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setOptions(
                        predictions.map(prediction => ({
                            description: prediction.description,
                            place_id: prediction.place_id,
                        }))
                    );
                } else {
                    setOptions([]);
                }
            });
        } else {
            setOptions([]);
        }
    };

    const handleSelect = (
        event: React.ChangeEvent<{}>,
        value: string | PlaceType | null,
        reason: string | AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<PlaceType> | undefined
    ) => {
        if (value && typeof value === 'object') {
            onChange({ target: { name, value: value.description }});
            fetchPlaceDetails(value.place_id);
        } else if (typeof value === 'string') {
            onChange({ target: { name, value }});
        }
    };

    const fetchPlaceDetails = (placeId: string) => {
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({ placeId }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
                const addressComponents = place.address_components || [];
                let city = '';
                let country = '';
                let zipCode = '';

                addressComponents.forEach(component => {
                    if (component.types.includes('locality')) {
                        city = component.long_name;
                    }
                    if (component.types.includes('country')) {
                        country = component.long_name;
                    }
                    if (component.types.includes('postal_code')) {
                        zipCode = component.long_name;
                    }
                });
                onAddressSelect(city, country, zipCode);
            }
        });
    };

    return (
        <div>
            <Autocomplete
                freeSolo
                options={options}
                getOptionLabel={(option) => option.description}
                onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
                onChange={handleSelect}
                disabled={disable}
                onBlur={onBlur("address")}
                inputValue={value}
                filterOptions={createFilterOptions({
                    limit: 10,
                })}
                popupIcon={<KeyboardArrowDownIcon />}
                noOptionsText="" 
                renderInput={(params) => (
                    <TextField
                        {...params}
                        value={value}
                        variant="standard"
                        name='address'
                        onChange={onChange}
                        fullWidth
                        onBlur={onBlur("address")}
                        placeholder="Your address"
                        id="address-input"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">{<img src={AddressIcon} />}</InputAdornment>
                            )}}
                    />
                )}
            />
        </div>
    );
};

export default AddressAutocomplete;