import React from "react";
// Customizable Area Start
import {
  Box, styled, Typography, TextField, Button, FormControlLabel, Checkbox,
  Select, MenuItem, CircularProgress, FormHelperText
} from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { CheckedCheckBox, UnCheckedCheckBox } from "../assets";
import { checkCondition, disableEndTime, generateTimeRange, getCheckboxLabelClass } from "../../../../components/src/HelperUtils";
interface IDayList {
  label: string,
  key: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday"
}
interface IFormAcceptedAnimals {
  dog: boolean
  cats: boolean
  fish: boolean
  bird: boolean
  rabbit: boolean
  reptile: boolean
  rodents: boolean
}

const DaysList: IDayList[] = [
  {
    label: "Monday",
    key: "Monday",
  },
  {
    label: "Tuesday",
    key: "Tuesday",
  },
  {
    label: "Wednesday",
    key: "Wednesday",
  },
  {
    label: "Thursday",
    key: "Thursday",
  },
  {
    label: "Friday",
    key: "Friday",
  },
  {
    label: "Saturday",
    key: "Saturday",
  },
  {
    label: "Sunday",
    key: "Sunday",
  }
]
// Customizable Area End

import GeneralController, {
  Props, webConfigJSON
} from "./GeneralController.web";

export default class General extends GeneralController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderScheduleSection = () => {
    const menuRange = generateTimeRange("00:00", "23:30", 30)
    const { selectAllCheckbox, form, daysCheckbox } = this.state
    return (
      <ScheduleWrapper>
        <WrapperTitle>Schedule Information</WrapperTitle>
        <Box style={style.subTitleWrapper}>
          <SubTitle style={style.blackColor}>Opening & Closing Days/Hours</SubTitle>
        </Box>
        {this.state.isEditOn ?
          <Box style={{ marginTop: "16px" }}>
            <SelectAllCheckbox
              value={selectAllCheckbox}
              checked={selectAllCheckbox}
              control={
                <Checkbox
                  onChange={(event) => this.SetSelectAllCheckbox(event.target.checked)}
                  checkedIcon={<img src={CheckedCheckBox} />}
                  icon={<img src={UnCheckedCheckBox} />}
                />
              }
              label="Select all"
              labelPlacement="start"
            />
          </Box>
          : <></>}
        <DaysWrapper>
          {DaysList.map((dayItem, index) => {
            const checkBoxValue = daysCheckbox[dayItem.key]
            const start_time = form.schedule_information[dayItem.key].start_time
            const end_time = form.schedule_information[dayItem.key].end_time
            return (
              <DayBoxWrapper key={index}>
                <DayBox>
                  <DayCheckbox
                    className={getCheckboxLabelClass(checkBoxValue, this.state.isEditOn)}
                    label={dayItem.label}
                    value={checkBoxValue}
                    checked={checkBoxValue}
                    labelPlacement="end"
                    control={this.state.isEditOn ?
                      <Checkbox
                        checkedIcon={<img src={CheckedCheckBox} />}
                        icon={<img src={UnCheckedCheckBox} />}
                        onChange={(event) => this.SetScheduleDayCheckbox(event.target.checked, dayItem.key)}
                      />
                      : <></>
                    }
                    data-testid={`day-checkbox-${index}`}
                  />
                </DayBox>

                {checkBoxValue ?
                  <>
                    <Box>
                      <TimeBox>
                        <AccessTimeIcon
                          style={{
                            width: 24,
                            height: 24,
                            color: checkCondition(this.state.isEditOn, "#6F05EA", "#000000") as string
                          }}
                        />
                        <CustomSelect
                          className={checkCondition(this.state.isEditOn, "edit-select", "default-selects") as string}
                          id="start-range-day"
                          variant="outlined"
                          disabled={!this.state.isEditOn}
                          displayEmpty
                          IconComponent={ArrowIcon}
                          value={start_time}
                          onChange={(event) => this.SetScheduleTime(event.target.value as string, dayItem.key, "start_time")}
                          data-testid={`day-start-time-${index}`}
                        >
                          <MenuFont value={""} disabled>00:00</MenuFont>
                          {menuRange.map((each, index) => {
                            return (
                              <MenuFont value={each.value} key={index}>{each.name}</MenuFont>
                            )
                          })}
                        </CustomSelect>
                        <CustomSelect
                          className={checkCondition(this.state.isEditOn, "edit-select", "default-selects") as string}
                          id="end-range-day"
                          variant="outlined"
                          disabled={!this.state.isEditOn}
                          displayEmpty
                          IconComponent={ArrowIcon}
                          value={end_time}
                          onChange={(event) => this.SetScheduleTime(event.target.value as string, dayItem.key, "end_time")}
                          data-testid={`day-end-time-${index}`}
                        >
                          <MenuFont value={""} disabled>00:00</MenuFont>
                          {menuRange.map((each, index) => {
                            return (
                              <MenuFont value={each.value} key={index}
                                disabled={disableEndTime(index, start_time)}
                              >
                                {each.name}
                              </MenuFont>
                            )
                          })}
                        </CustomSelect>
                      </TimeBox>
                      {<FormHelperText error>{this.state.formErrors.schedule_information[dayItem.key]}</FormHelperText>}
                    </Box>
                  </>
                  : <></>}
              </DayBoxWrapper>
            )
          })}

        </DaysWrapper>
        {<FormHelperText error>{this.state.formErrors.schedule_information.main}</FormHelperText>}
      </ScheduleWrapper>
    )
  }
  renderAcceptedAnimals = () => {
    const isShowAnimal = this.props.selectedService.name !== webConfigJSON.SERVICES_ENUM.DOG_WALKING
    const { animalList, formErrors } = this.state
    return (
      <>
        {isShowAnimal ?
          <AcceptedWrapper>
            <WrapperTitle>Accepted Animals</WrapperTitle>
            <AnimalWrapper>
              {animalList.map((each, index) => {
                const checkBoxValue = this.state.form.accepted_animals[each.key as keyof IFormAcceptedAnimals]
                return (
                  <LabelBox key={index}>
                    <CustomCheckbox
                      className={checkBoxValue ? "checked-label" : "default-label"}
                      label={each.label}
                      value={checkBoxValue}
                      checked={checkBoxValue}
                      labelPlacement="end"
                      control={this.state.isEditOn ?
                        <Checkbox
                          onChange={(event) => this.SetAnimalsCheckbox(event.target.checked, each.key)}
                          checkedIcon={<img src={CheckedCheckBox} />}
                          icon={<img src={UnCheckedCheckBox} />}
                        />
                        : <></>
                      }
                      data-testid={`animal-checkbox-${index}`}
                    />
                  </LabelBox>
                )
              })}
            </AnimalWrapper>
            {<FormHelperText error data-testid="error-text">{formErrors.accepted_animals}</FormHelperText>}
          </AcceptedWrapper>
          : <></>}

      </>
    )
  }
  renderLunchBreak = () => {
    const servicesNames = [webConfigJSON.SERVICES_ENUM.TRAINING, webConfigJSON.SERVICES_ENUM.GROOMING]
    const isShowLunchSection = servicesNames.includes(this.props.selectedService.name)
    const menuRange = generateTimeRange("00:00", "23:30", 30)
    const { lunch_break } = this.state.form
    return (
      <>
        {isShowLunchSection ?
          <LunchWrapper>
            <WrapperTitle>Lunch Break</WrapperTitle>
            <LunchCheckbox>
              <TimeWrapper>
                <AccessTimeIcon style={{ width: 24, height: 24, color: this.state.isEditOn ? "#6F05EA" : "#000000" }} />
                <CustomSelect
                  className={this.state.isEditOn ? "edit-select" : "default-selects"}
                  id="start-range"
                  variant="outlined"
                  disabled={!this.state.isEditOn}
                  displayEmpty
                  IconComponent={ArrowIcon}
                  value={lunch_break.start_time}
                  onChange={(event) => this.SetLunchBreak(event.target.value as string, "start_time")}
                  data-testid="lunch-start-time"
                >
                  <MenuFont value={""} disabled>00:00</MenuFont>
                  {menuRange.map((each, index) => {
                    return (
                      <MenuFont value={each.value} key={index}>{each.name}</MenuFont>
                    )
                  })}
                </CustomSelect>
                <CustomSelect
                  className={this.state.isEditOn ? "edit-select" : "default-selects"}
                  id="end-range"
                  variant="outlined"
                  disabled={!this.state.isEditOn}
                  displayEmpty
                  IconComponent={ArrowIcon}
                  value={lunch_break.end_time}
                  onChange={(event) => this.SetLunchBreak(event.target.value as string, "end_time")}
                  data-testid="lunch-end-time"
                >
                  <MenuFont value={""} disabled>00:00</MenuFont>
                  {menuRange.map((each, index) => {
                    return (
                      <MenuFont value={each.value} key={index}
                        disabled={disableEndTime(index, lunch_break.start_time)}
                      >{each.name}</MenuFont>
                    )
                  })}
                </CustomSelect>
              </TimeWrapper>
              {<FormHelperText error>{this.state.formErrors.lunch_break}</FormHelperText>}
            </LunchCheckbox>
          </LunchWrapper>
          : <></>}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { form, generalDetailsLoading, generalDetails, formErrors } = this.state
    return (
      <MainWrapper>

        {generalDetailsLoading && !generalDetails ?
          <LoadingBox><Loader /></LoadingBox> :
          <>
            <BioWrapper>
              <FormLabelFont>Bio/About</FormLabelFont>
              <CustomTextField
                disabled={!this.state.isEditOn}
                multiline
                minRows={6}
                data-testid="bio-input-fiels"
                variant="outlined"
                placeholder="Write a description from your establishment."
                inputProps={{
                  maxLength: 500
                }}
                value={form.bio_about}
                onChange={(event) => this.SetFormValue(event.target.value, "bio_about")}
                error={!!formErrors.bio_about}
                helperText={formErrors.bio_about}
              />
              <LengthBox>{form.bio_about.length}/500</LengthBox>
            </BioWrapper>

            <PolicyWrapper>
              <FormLabelFont>Cancellation Policy</FormLabelFont>
              <CustomTextField
                disabled={!this.state.isEditOn}
                multiline
                minRows={3}
                data-testid="cancellation-input-fiels"
                variant="outlined"
                placeholder="Write a Cancellation Policy."
                value={form.cancellation_policy}
                onChange={(event) => this.SetFormValue(event.target.value, "cancellation_policy")}
                error={!!formErrors.cancellation_policy}
                helperText={formErrors.cancellation_policy}
              />
            </PolicyWrapper>

            <Section3Wrapper>
              {this.renderScheduleSection()}
              {this.renderAcceptedAnimals()}
            </Section3Wrapper>
            {this.renderLunchBreak()}

            <ButtonWrapper>
              {this.state.isEditOn ?
                <>
                  <CustomButton
                    style={style.blackBtn}
                    onClick={this.handleCancelClick}
                    data-testid="cancel-btn"
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    style={style.blueBtn}
                    onClick={this.handleSaveClick}
                    data-testid="save-btn"
                  >
                    Save
                  </CustomButton>
                </>
                :
                <CustomButton
                  style={style.blueBtn}
                  onClick={this.handleEditClick}
                  data-testid="edit-btn"
                >
                  Edit
                </CustomButton>
              }
            </ButtonWrapper>
          </>
        }

      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  padding: "0px",
  width: "100%"
})
const BioWrapper = styled(Box)({
  padding: "0px"
})
const LengthBox = styled(Typography)({
  textAlign: "end",
  fontSize: "10px",
  fontWeight: 500,
  lineHeight: "22px",
  color: "#A6A6A6",
  fontFamily: 'Montserrat'
})
const PolicyWrapper = styled(Box)({
  padding: "0px",
  marginTop: "48px"
})

const Section3Wrapper = styled(Box)({
  marginTop: "48px",
  display: "flex",
  flexDirection: "row",
  columnGap: "32px"
})

const ScheduleWrapper = styled(Box)({
  padding: "0px",
  width: "100%",
  "& .MuiFormHelperText-root": {
    fontFamily: 'Montserrat',
    margin: 0
  }
})
const SelectAllCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "& .MuiFormControlLabel-label": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
    fontFamily: 'Montserrat'
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px"
  }
})
const CustomCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "&.checked-label .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
  },
  "&.default-label .MuiFormControlLabel-label": {
    color: "#A8A29E",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
  },
  "& .MuiFormControlLabel-label": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
    fontFamily: 'Montserrat'
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px"
  },
  "& .MuiCheckbox-root": {
    padding: "0px",
    marginRight: "16px"
  },
})
const DayCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "&.blue-label .MuiFormControlLabel-label": {
    color: "#6F05EA",
  },
  "&.grey-lebel .MuiFormControlLabel-label": {
    color: "#A8A29E",
  },
  "&.black-label .MuiFormControlLabel-label": {
    color: "#000000",
  },
  "&.nevy-label .MuiFormControlLabel-label": {
    color: "#0F172A",
  },
  "& .MuiFormControlLabel-label": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
    fontFamily: 'Montserrat'
  },
  "& .Mui-checked + .MuiFormControlLabel-label": {
    color: "#6F05EA",
  },
  "& .MuiCheckbox-root": {
    padding: "0px",
    marginRight: "16px"
  },
})
const AcceptedWrapper = styled(Box)({
  padding: "0px 24px",
  width: "100%",
  "& .MuiFormHelperText-root": {
    fontFamily: 'Montserrat',
    margin: 0
  }
})

const LunchWrapper = styled(Box)({
  marginTop: "32px",
})
const LunchCheckbox = styled(Box)({
  marginTop: "32px",
  height: "60px",
  padding: "0 20px",
  display: "flex",
  flexDirection: "column",
  "& .MuiFormHelperText-root": {
    fontFamily: 'Montserrat',
    margin: 0
  }
})

const ButtonWrapper = styled(Box)({
  padding: "0px",
  display: "flex",
  justifyContent: "end",
  columnGap: "12px",
  marginTop: "32px"
})

const CustomButton = styled(Button)({
  padding: "8px 0",
  height: "40px",
  width: "140px",
  borderRadius: "100px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  textTransform: "none",
  fontFamily: 'Montserrat'
})


const FormLabelFont = styled(Typography)({
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#6F05EA",
  fontFamily: 'Montserrat'
})

const WrapperTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: "20px",
  color: "#6F05EA",
  fontFamily: 'Montserrat'
})
const SubTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "18px",
  fontFamily: 'Montserrat'
})

const CustomTextField = styled(TextField)({
  marginTop: "12px",
  width: "100%",
  "& .MuiOutlinedInput-multiline": {
    padding: "0"
  },
  "& textarea": {
    resize: "none",
    padding: " 8px 8px 10px 8px",
    fontFamily: 'Montserrat',
    fontSize: "12px"
  },
  "& .MuiFormHelperText-root": {
    fontFamily: 'Montserrat',
    margin: 0
  }
})
const LabelBox = styled(Box)({
  height: "60px",
  width: "138px",
  minWidth: "138px",
  padding: "0 20px",
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
  backgroundColor: "#F8F8F8",
})

const DayBoxWrapper = styled(Box)({
  display: "flex"
})
const DayBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  minWidth: "200px",
  height: "60px",
  padding: "0 20px",
  backgroundColor: "#F8F8F8",
  borderTopLeftRadius: "4px",
  borderBottomLeftRadius: "4px"
})

const TimeBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "60px",
  padding: "0 8px",
  backgroundColor: "#F8F8F8",
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px"
})

const AnimalWrapper = styled(Box)({
  marginTop: "52px",
  display: "flex",
  flexWrap: "wrap",
  rowGap: "10px",
  columnGap: "16px"
})

const TimeWrapper = styled(Box)({
  display: "flex",
  alignItems: "center"
})

const CustomSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400
  },
  "&.edit-select .MuiSelect-select": {
    color: '#6F05EA'
  },
  "&.edit-select .MuiSelect-icon": {
    color: '#6F05EA',
    marginTop: "-2px"
  },
});
const ArrowIcon = styled(KeyboardArrowDown)({
  "& .MuiSelect-icon": {
    color: "#000000",
    marginTop: "-2px"
  }
})
const MenuFont = styled(MenuItem)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400
})
const DaysWrapper = styled(Box)({
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  rowGap: "10px"
})
const Loader = styled(CircularProgress)({
  color: "#6F05EA",
})
const LoadingBox = styled(Box)({
  display: "flex",
  justifyContent: "center"
})
const style = {
  blueBtn: {
    color: "#6F05EA",
    border: "1px solid #6F05EA",
  } as React.CSSProperties,
  blackBtn: {
    color: "#78716C",
    border: "1px solid #78716C",
  } as React.CSSProperties,
  blueColor: {
    color: "#6F05EA",
  } as React.CSSProperties,
  blackColor: {
    color: "#000000",
  } as React.CSSProperties,
  greyColor: {
    color: "#A8A29E",
  } as React.CSSProperties,
  subTitleWrapper: {
    marginTop: "16px"
  } as React.CSSProperties,

}
// Customizable Area End
