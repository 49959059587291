import React from "react";
import { EventProps } from "react-big-calendar";
import { Box, styled } from "@material-ui/core";
import { FormattedEvent } from "../../../blocks/customisableuserprofiles/src/components/CustomerCalendar";

const MonthEventWrapper = styled('div')({
    padding: "16px 0",
    borderRadius: "4px",
    zIndex: 12,
    height: "70px",
    display: "flex",

    "& .content": {
        backgroundColor: "#E1CCFF", 
        flex: 1, 
        borderRadius: "18px"
    }
})

const MonthEvent: React.FC<EventProps<FormattedEvent>> = ({ event }) => {
  // if (!event.data?.hotel) {
  //   return null;
  // }

  return (
    <MonthEventWrapper>
        <Box className="content"></Box>
    </MonthEventWrapper>
  );
};

export default MonthEvent;
