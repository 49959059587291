import { Request } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";

export const getPriorRequest = ( requests: Request[] ): Request | undefined => {
    if(!requests.length) {
        return undefined;
    }

    const statusPriority = {
        'rejected': 3,
        'accepted': 2,
        'pending': 1
      };
    
      const typePriority = {
        'Cancellation Request': 3,
        'Reschedule Request': 2,
        'Transportation Request': 1
      };

      function compareRequests(a: Request, b: Request): number {
        if (typePriority[a.request_type as 'Cancellation Request'] !== typePriority[b.request_type as 'Cancellation Request']) {
          return typePriority[b.request_type as 'Cancellation Request'] - typePriority[a.request_type as 'Cancellation Request'];
        }
    
        return statusPriority[b.request_status as 'accepted'] - statusPriority[a.request_status as 'accepted'];
      }
      requests.sort(compareRequests);

      return requests[0];
}