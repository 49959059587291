export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upload = require("../assets/upload.png");
export const Cross = require("../assets/group_cross.png");


// EMPLOYEE DETAILS
export const CheckedCheckBox = require("../assets/checked_checkbox.svg");
export const UnCheckedCheckBox = require("../assets/unchecked_checkbox.svg");

export const UserIcon = require("../assets/user_icon.svg");
export const CommunityIcon = require("../assets/community_icon.svg");
export const EmailIcon = require("../assets/email_icon.svg");

export const DummyAvatar = require("../assets/avatar.svg");
export const DummyAvatarEdit = require("../assets/avatar_edit.svg");


export const NewEmployeeIcon = require("../assets/add-new-employee-icon.svg");
export const EmployeeAvatar = require("../assets/employee-avatar.jpeg");
export const ListActionIcon = require("../assets/employee-list-action-icon.svg");
export const EmployeeEditIcon = require("../assets/employee-edit-icon.svg");
export const EmployeeDeleteIcon = require("../assets/employee-delete-icon.svg");
