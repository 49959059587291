import React from "react";
import { Avatar, FormHelperText, MenuItem, Select, Typography, styled,OutlinedInput, InputAdornment } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Flag from 'react-world-flags';
import { countryIcon } from "./assets";

interface CustomSelectedFieldSetting {
    value?: string | number | undefined;
    fullWidth?: boolean;
    mb?: string;
    onChange?: (e:React.ChangeEvent<any>) => void;
    onBlur?:(e:React.ChangeEvent<any>) => void;
    list?: Array<{ label: string; value: string | number | undefined, flag?: string }>;
    asterisk?: boolean;
    placeholder?: string;
    label?: string;
    helperText?: string;
    error?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    startAdornment?: React.ReactNode;
    id:string;
    name:string;
    className?:string;
    dataTestId?: string;
    paperProps?: object;
}

const CustomIconComponent = ({ value }: { value: string | number | undefined}) => {
  if (!value) {
    return null;
  }
  return <ExpandMoreRoundedIcon style={{ color: '#6F05EA' }}/>;
};

export const CustomSelectedFieldSetting = (props: CustomSelectedFieldSetting) => {
    const {
        value,
        onChange,
        fullWidth = false,
        disabled = false,
        list,
        placeholder,
        label,
        style,
        error = false,
        helperText,
        startAdornment,
        onBlur,
        id,
        name,
        className,
        dataTestId,
        paperProps
    } = props;

    const isFlag = name === 'language';
    const flagCode = () => {
      switch (value) {
        case 'portuguese':
          return 'pt';
        case 'english':
          return 'gb';
        case 'spanish': 
          return 'es';
        case 'french':
          return 'fr'
        default:
          return 'pt';
      }
    }

    const renderLabel = (value: unknown) => {
        const filteredNode = list?.filter((element) => element.value === value);
        return filteredNode?.[0]?.label;
    };

    const listValues = list ? list.map(item => item.value): [''];

    return (
        <>
            {label && (
                <Typography style={ !!value ? WebStyles.customLabelSelected : WebStyles.customLabelDefault }>
                    {label} 
                </Typography>
            )}
            <CustomSelect
                data-test-id={dataTestId} 
                value={value}
                id={id}
                name={name}
                disabled={disabled}
                fullWidth={fullWidth}
                onChange={onChange}
                onBlur={onBlur}
                style={style}
                variant="standard" 
                startAdornment={<InputAdornment position="start">{startAdornment}</InputAdornment>}
                IconComponent={() => <CustomIconComponent value={value}/>}
                displayEmpty
                error={error}
                renderValue={(value) =>
                    typeof value === "undefined" || value === "" || value === 0 || value === null || !listValues.includes(value as string)  ? (
                        <Typography
                            style={{ color: "#7d7d7d", opacity: "0.38", fontSize: "18px", paddingLeft: '8px',fontWeight:500,fontFamily: 'Montserrat', }}
                        >
                          {placeholder}
                        </Typography>
                    ) : (
                        <Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 500, paddingLeft: '8px',color:'#7d7d7d !important'}}>{renderLabel(value)}</Typography>
                    )
                    }
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: {...paperProps}
                }}
            >
            {list && list?.length > 0 ? (
                list?.map((listItem) => {
                    return (
                    <CustomMenuItem key={listItem.label} value={listItem.value}>
                      {listItem.flag && 
                      <Avatar style={WebStyles.flagAvatar}>
                        <Flag code={listItem.flag} style={{ width: 'auto', height: '100%'}}/>
                      </Avatar>
                      }
                        {listItem.label}
                    </CustomMenuItem>
                    );
                })
                ) : (
                <CustomMenuItem value={""} disabled>
                    No Item
                </CustomMenuItem>
                )}
            </CustomSelect>
            {error && <FormHelperText style={{ color: '#f44336'}}> {helperText}</FormHelperText>}
        </>
    )
}


const WebStyles = {
    customLabelSelected: {
      color: '#6F05EA',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '16px',
      marginBottom: '4px'
    },
    customLabelDefault: {
      color: '#6F05EA',
      fontWeight: 400,
      fontSize: "13px",
      fontFamily: 'Montserrat'
    },
    flagAvatar: {
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      overflow: 'hidden',
      marginRight: '10px',
    }
  };

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '13px',

    '&:hover': {
      backgroundColor: 'rgba(111, 5, 234, 0.1)', 
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(111, 5, 234, 0.3)', 
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgba(111, 5, 234, 0.1)', 
    },
    '& img': {
      width: '100%',
      height: '100%'
    }
  }));
  
  const CustomSelect = styled(Select)(({ theme }) => ({

    '& .MuiSelect-select': {
      backgroundColor: 'transparent', 
    },
    '& .Mui-focused .MuiSelect-select': {
      backgroundColor: 'transparent', 
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent', 
    },
    '& .MuiList-padding': {
        padding: 0
    },
  }));