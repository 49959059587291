export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const signUpImage = require("../assets/sign-up-web.png");
export const loginForwardArrow = require("../assets/login-forword-arrow.svg");
export const EmailIcon = require("../assets/email.svg");
export const PasswordIcon = require("../assets/password.svg");
export const LoginImage = require("../assets/LoginImage.png");
export const unCheckedIcon = require('../assets/unCheckIcon.png');
export const checkedIcon = require('../assets/checkedIcon.png');
export const headerLogo = require('../assets/logo.svg');
export const LanguageIcon = require('../assets/LanIcon.svg');
export const defaultUserProfile = require('../assets/default-img.svg')