import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Tabs,Tab,Grid,FormHelperText,
  Avatar,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import  {AppVendorHeader}  from "../../../components/src/AppHeaderVendor.web";
import Sidebar  from "../../../components/src/NewSideBar/Sidebar.web";
import MenuIcon from '@material-ui/icons/Menu';
import {setting,employees,Gallery,profileavtar,countryIcon,zipIcon,cityIcon,editprofile} from '../../../components/src/assets/index'
import FieldsRow from "../../../components/src/FieldsRow.web";
import { createTheme, styled } from "@material-ui/core/styles";
import EmployeeManagementWeb from "./EmployeeManagement/EmployeeManagement.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import InputField from "../../../components/src/InputField";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { FieldInputProps, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import { CustomSelectedFieldSetting } from "../../../components/src/CustomSelectedFieldSetting";
import AddressAutocomplete from "../../../components/src/AddressAutocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

type EditFields = 'isEditMedical' | 'isEditTreats' | 'isEditGeneral' | 'isEditPersonal';

interface ICustomSelect  {
  field: string, 
  getFieldProps: (val: string) => FieldInputProps<string>, 
  handleBlur: (e:React.ChangeEvent<any>) => void,
  handleChange: (e: React.FormEvent<HTMLFormElement> | undefined) => void,
  errors: FormikErrors<any>,
  touched: FormikTouched<any>,
  values: FormikValues,
  disabledField: EditFields,
  list?: { label: string; value: string | number | undefined, flag?: string}[],
  placeholder:string,
  icon:any,
  labelfield:string
}

interface FormErrors {
  [key: string]: string;
}

interface FormTouched {
  [key: string]: boolean;
}

const personalValidationShema = Yup.object().shape({
  establishment_name: Yup.string().required('*Please enter establishment name').trim(),
  phone_number: Yup.string()
    .required('*Please enter phone_number')
    .trim(),
  city: Yup.string().required('*Please enter city').trim(),
  country: Yup.string().required('*Please enter country').trim(),
  zipcode: Yup.string().required('*Please enter zipcode').trim(),
  address: Yup.string().required('*Please enter address').trim(),
  email:Yup.string().required('*Please enter email').trim()
});

const RequestSectionBox = styled(Box)({
  display: "flex",
  height:"100%",
  width:'100%',
  flexDirection:'row',
  background:'#F6F4F9',
 boxSizing: 'border-box',
 
 "& .Cancel-Appointment-box": { 
marginLeft:20,
marginTop:30
 },
 "& .profile-date": {fontSize:16,fontWeight:500},
 "& .profile-title": {fontSize:30,fontWeight:600,fontFamily:'Montserrat',color:'#000',lineHeight:'36px'}
  ,"& .mainSection": {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "scroll",
    backgroundColor: "#F6F4F9",
    width:'100%'
  },
  "& .grid-main-content": {
    display: 'flex',width:'100%',flexDirection:'column',
    },
  "& .tabLogo": {
    display: "none",
    width: "100%",
    paddingTop: "20px"
  },
  "& .tabLogoBox": {
    width: "160px",
    height: "50px"
  },
  "@media screen and (max-width:1024px)": {
    "& .sideBar": {
      display: "none"
    },
    "& .sideBarOpen": {
      display: "block"
    },
    "& .tabLogo": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },


});
const PageWrapper = styled(Box)({
  width:'100%',
  "& *": {
    boxSizing: 'border-box',
  },

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: 'Montserrat',
  },

  '& .MuiInput-underline.Mui-disabled': {
    '&::before': {
      borderBottomStyle: 'solid'
    }
  },

  '& .MuiInputBase-root.Mui-disabled': {
    color: 'inherit'
  },

  '& .MuiInputBase-input.MuiInput-input': {
    "&::placeholder": {
      color: '#7D7D7D',
      fontSize: '18px',
      opacity: 1,
      fontWeight: 500,
      fontFamily: 'Montserrat',
    }
  },

  "& .custom-tab": {
    color: '#B7B7B7',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
    minHeight:"40px",
    maxHeight:"40px",
    padding:"6px 6px"
  },
  "& .MuiTabs-root":{
    minHeight:"40px",
    maxHeight:"40px",
    marginBottom:"50px",
  },
  "& .MuiTabScrollButton-root":{
    display:"none"
  },
  "& .tab-label":{
    display: 'flex',
    alignItems:"center",
    justifyContent:"center",
    gap:"8px"
  },
  "& .MuiTabs-scrollable":{
    height:"40px",
    maxHeight:"40px"
  },
  "& .tab-icon":{
    width: 26, 
    height: 26, 
  },
  "& .custom-tab.Mui-selected": {
    fontWeight: 400,
    color: '#000',
    fontSize:16,
    textTransform:"none"
  },
  "& .vertical-tab.Mui-selected": {
    color: 'red',
    fontWeight: 600,
  },
  "& .vertical-tab": {
    color: '#808080',
    fontSize: '14px',
    fontWeight: 600,
    flex: 1,
    textTransform: 'none',
    position: 'relative',
    fontFamily: 'Montserrat',

    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: 0,
        width: '1px', 
        backgroundColor: '#000',
    },
  },
 
  "& .MuiSwitch-thumb": {
    boxSizing: 'border-box',
    height: 22,
    width: 22,

  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transitionDuration: '300ms',
    margin: 2,
    '&.Mui-checked': {
      color: 'rgba(111, 5, 234, 1)',
      transform: 'translateX(27px)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgba(111, 5, 234, 0.2)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  "& .MuiListItem-button": {
    '&:hover': {
      textTransform: 'none',
      backgroundColor: 'rgba(111, 5, 234, 0.1)', 
    },
    fontFamily: 'Montserrat',
  },

  "& .MuiSwitch-root": {
    height: 26,
    width: 54,
    padding: 0,
  },

  "& .MuiSwitch-track": {
    backgroundColor: '#E9E9EA',
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },

  "& .main-menu-wrapper": {
    [theme.breakpoints.up("sm")]: {
      display: 'flex',
    },
    display: 'none',
  },
  "& .custom-svg.active": {
    "& path": {
      fill: '#fff'
    }
  },
  "& .content-box": {
    flex: 1,
    paddingTop: '96px',
    paddingBottom: '96px',
    paddingLeft: '50px',
    paddingRight: '50px',
    background: 'white',
    textAlign: 'center',

    "& .content": {
      maxWidth: '100%',
      [theme.breakpoints.up("sm")]: {
        maxWidth: '965px',
      },
    }
  },
});
const updatePhoneInitialValue = {
  current_password: "",
  full_phone_number: "",
  country_code: "",
  old_phone_number: "",
  old_country_code: "",
};
const DashboardBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "20px",
  justifyContent:"space-between",
  "& .menuIconBtn":{
    display: "none"
  },
  "& .dashboardHeading": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& .MuiTypography-body1": {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      fontFamily: "Inter",
      color: "#011342",
    },
  },
  "& .menuIcon": {
    display: "none",
  },
  "& .profileBox": {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    gap: "15px",
    "& .MuiButton-root": {
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Inter",
      textTransform: "none",
      color: "white",
      background: "#012275",
      borderRadius: "4px",
      paddingRight: "15px",
      paddingLeft: "15px",
      height: "49px",
    },
  },
  "& .addIcon": {
    paddingRight: "8px",
  },
  "& .bellIconBox":{
    boxShadow: "0px 0px 3px rgba(0,0,0,0.1)",
  },
  "& .profile": {
    boxShadow: "0px 0px 3px rgba(0,0,0,0.1)",
    padding:"8px 25px 8px 8px",
    "& .MuiTypography-body2": {
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#011342",
    },
    display: "flex",
    alignItems: "center",
    gap: "5px",
    order: 3,
  },
  "& .userImage": {
    width: "32px",
    height: "32px",
    "& img": {
      borderRadius: "50%",
    },
  },
  "@media screen and (max-width:1024px)": {
    "& .menuIconBtn":{
      display: "block"
    },
    "& .tabHeading":{
      display: "none"
    },
    "& .MuiButton-root": {
      fontSize: "14px",
      order: 2,
    },
    "& .profileBox": {
      "& img": {
        order: 1,
      },
    },
    "& .menuIcon": {
      display: "block",
    },
  },
  "@media screen and (max-width:599px)": {
    "& .dashboardHeading": {
      gap: "5px",
      "& .MuiTypography-body1": {
        fontSize: "20px !important",
        lineHeight: "20px !important",
      },
    },
    "& .profileBox": {
      padding: "5px",
      gap: "10px",
      "& .MuiButton-root": {
        fontSize: "10px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
        height: "30px !important",
      },
    },
    "& .addIcon": { display: "none" },
    padding: "10px",
    "& .profile": {
      gap: "2px",
      "& .MuiTypography-body2": { display: "none" },
    },
  },
});
const AboutSection = styled(Box)({
  width:'100%',
  height:'auto',
  borderRadius:16,
  padding:"24px",
  border:'0px 0px 0px 4px',
  gap:'34px',
  background:'#fff',
  boxShadow: '0px 2px 8px 0px #0000000F',
  display:'flex',
  flexDirection:'row',
  marginBottom:115,
  "& .column-one":{
    display:'flex',
    flexDirection:'column'
   },
   "& .column-two":{
    flexDirection:'column',
    display:'flex',
   },
   "& .contact-header-box":{marginTop:65,marginBottom:30}

});
const RequestContainer=styled(Box)({
  textAlign: 'left', marginTop: '10px',width:'100%',
  "& .about-lable-Title": {
    color:'#6F05EA',
    fontSize:12,
    fontFamily:'Montserrat',
    fontWeight:400
   },
   "& .lable-value":{ pointerEvents: 'none',fontSize:18,fontWeight:500,fontFamily:'Montserrat',marginTop:10,color:'#7d7d7d' },
   "& .about-details-container":{ display: 'flex', flexDirection: 'column',borderBottom:'1px solid #d9d9d9'},
   "& .profile-avtar":{width: 148,
    height: 148,
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius:'100%',
  },
  "& .establish-address-container, .city-country-container":{
    height:"60px",
    "& .MuiAutocomplete-input":{
      height: "34px",
      marginTop: "4px",
      fontSize: "18px",
      fontWeight: "500",
      fontFamily: "Montserrat",
      color: "rgb(125, 125, 125)"
    },
    "& .MuiInput-underline::after":{
      borderBottom: "2px solid #6F05EA"
    },
    "& .MuiAutocomplete-clearIndicator":{
      display:"none"
    },
    "& .MuiAutocomplete-popupIndicator":{
      display:"none"
    },
  },
  "& .establish-address-label":{
    lineHeight:"22px",
    fontSize: "13px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    color: "#6F05EA"
  },
  "& .establishment-name":{
    height:"60px",
  },
  "& .phone-input":{
    borderColor:"rgb(125, 125, 125) !important",
    color:"#7D7D7D",
    fontSize:"18px",
    fontWeight:500,
    borderWidth:"1px !important",
    height:"36px !important",
    marginTop:"10px !important"
  },
  "& .phone-input-error":{
    borderColor:"#EF4444 !important",
    height:"36px !important",
    marginTop:"10px !important"
  },
  "& .address-error > div":{
    "& .MuiInput-underline::after":{
      borderBottom: "2px solid #EF4444 !important"
    },
    "& .MuiInput-underline::before": {
      borderBottom: "2px solid #EF4444 !important",
    },
  },
  "& .contact-field-container":{
    height:"60px"
  },
});

const AboutTitle= styled(Box)({
  display:'flex',
  flexDirection:'row',
  gap:'10px',
  marginBottom:50,
  marginTop:25,
  "& .Request-content-logo":{
    display:'flex',
    flexDirection:'row',
    
   },
   "& .request-title":{
    fontSize:18,
    fontFamily:'Montserrat',
    color:'#6F05EA',
    fontWeight:600,
    marginBottom:30
   },
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import SettingGallary from "./SettingGallary.web";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorAndHelperText = (
    field: string,
    errors: FormErrors,
    touched: FormTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = isError ? errors[field] ?? "" : "";
    return { isError, helperText };
  };
  renderHeaderPart = () => {
    return (
      <DashboardBox>
        <Box className="dashboardHeading">
          <IconButton className="menuIconBtn" data-test-id='opensidebar' onClick={() => {this.openSideBar() } }><MenuIcon /></IconButton>
          <Typography className={this.state.isSideBarOpen ? "tabHeading" : "dashHeading"} variant="body1"></Typography></Box>
        
      </DashboardBox>
    )
  };
  renderSettingsBlock = () => {
    return (
      <Box style={{ width: '100%', textAlign: 'left'}}>
        <Box style={{width:"100%", overflowX:"auto"}}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={this.state.currentSettingsTab}
          data-test-id="settings-tabs"
          onChange={(_, idx) => this.setTabId(idx)}
          TabIndicatorProps={{
          style: {
              backgroundColor: '#6F05EA',
          },
          }}
          // style={{
          //   overflowX:"auto",
          //   width:"fit-content"
          // }}
        >
          <Tab 
          label={
            <div className="tab-label">
              <img src={setting} alt="Settings"  className="tab-icon" />
             General Information
            </div>
          }  
          className="custom-tab" />
          <Tab label={
            <div className="tab-label">
              <img src={Gallery} alt="Settings"  className="tab-icon" />
              Gallery
            </div>
          }   className="custom-tab" />
          <Tab  label={
            <div className="tab-label">
              <img src={employees} alt="Settings"  className="tab-icon" />
              Employee Managament
            </div>
          }   className="custom-tab" />
        </Tabs>
        </Box>
        {this.renderSettingsTabContent()}
     
      </Box>
    )
  }
  renderSettingsTabContent = () => {
    switch (this.state.currentSettingsTab) {
      case 0:
        return <AboutSection>
        {this.GeneralInformationTab() }
        </AboutSection>
      case 1:
        return this.GallaryTab();
      case 2:
        return this.EmployeeManagment();
      default:
        break;
    }
  }
  renderPhoneLabel = () => {
    return (
        <InputLabel style={{...styles.filledLabel, marginTop: '15px' }}>
          Phone
        </InputLabel>
    )
  }

  renderCustomSelectedFieldSetting = ( {
    disabledField,
    errors,
    field,
    labelfield,
    getFieldProps,
    handleBlur,
    handleChange,
    touched,
    values,
    list,
    placeholder,
    icon
  }: ICustomSelect) => {
    return (
      <Box style={{ width: '100%'}}>
        <CustomSelectedFieldSetting
          {...getFieldProps(field)} 
          id={field}
          data-test-id='country'
          name={field}
          onBlur={handleBlur}
          placeholder={placeholder}
          label={this.getLabel(labelfield)}
          onChange={handleChange}
          value={values[field]}
          startAdornment={<img src={icon}/>}
          disabled={false}
          list={list ? list : [
            { label: "None", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
          ]}
          fullWidth
          error={
            this.getErrorAndHelperText(
              field,
              errors as FormErrors,
              touched as FormTouched
            ).isError
          }
          helperText={
            this.getErrorAndHelperText(
              field,
              errors as FormErrors,
              touched as FormTouched
            ).helperText
          }
        />
      </Box>
    )
  }

  GallaryTab=()=>{
    return(
      <>
      <SettingGallary navigation={''} id=""/>
      </>
    );
      }
      EmployeeManagment=()=>{
        return(
          // <Typography>Upcoming</Typography>
          <EmployeeManagementWeb navigation={undefined} id={""}/>
        );
      }
      renderPhoneInput = <T,>(
        field: string,
        getFieldProps: (val: string) => FieldInputProps<string>, 
        errors: FormikErrors<T>,
        touched: FormikTouched<T>,
        values: FormikValues,
        setFieldValue: (field: string, val: string) => void,
        setFieldTouched: (field: string, val: boolean) => void,
        editField: EditFields,
      ) => {
        return (
          <Box style={{ width: '100%' }} className="contact-field-container">
            {this.renderPhoneLabel()}
            <PhoneInput
              {...getFieldProps(field)}
              country={
                this.state.isoCountryName.toString().toLowerCase() ||
                "pt"
              }
              buttonStyle={{ border: "none", background: "transparent" }}
              placeholder="Phone number"
              inputStyle={{
                ...styles.medicalFormPhone,
                borderBottom: this.getPhoneErrors(errors, touched, field).style
              }}
              dropdownStyle={{ fontSize: "18px",color:'#b7b7b7' }}
              value={values[field]}
              inputProps={{
                maxLength: 16,
              }}
              onChange={(phone, data: { dialCode: string }) => {
                setFieldValue("country_code", data.dialCode);
                setFieldValue("phone_number", phone);
              }}
              onBlur={() => {setFieldTouched(field, true)}}
              data-test-id="phone-input"
              disabled={!this.state.isEditPersonal}
              inputClass={`${!this.getPhoneErrors(errors, touched, field).childrens ? "phone-input" : "phone-input-error"}`}
            />
            {this.getPhoneErrors(errors, touched, field).childrens && (
              <FormHelperText style={{ color: "#f44336" }}>
                {this.getPhoneErrors(errors, touched, field).childrens}
              </FormHelperText>
            )}
          </Box>
        )
      }
      handleMediaSelect(event:any) {
        this.setState({ referenceImage: event.target.files?.[0] });
        let url=this.createBlobUrl(event.target.files?.[0])
        this.setState((prevState) => ({
            uploadedImagePreview: url,
            serverFile:event.target.files?.[0]
        }));
      }
      createBlobUrl = (file:any) => {
        const url = URL.createObjectURL(file);
        return url;
      };
      GeneralInformationTab=()=>{
        const defaultInputProps = {
          style: styles.inputStyleGeneral, readOnly: !this.state.isEditPersonal
        }
        return(
          <>
         <RequestContainer>
         <Formik
            initialValues={
              this.state.personal
            }
            enableReinitialize
            validationSchema={personalValidationShema}
            onSubmit={(values) => {
              this.updateUserProfile(values)
            }}
            data-test-id="personal-form"
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                getFieldProps,
                setFieldValue,
                setFieldTouched,
                resetForm,
              }) => {
                const handleAddressSelect = (selectedCity: string, selectedCountry: string, selectedZipCode: string) => {
                  setFieldValue("city",selectedCity);
                  setFieldValue("country",selectedCountry);
                  setFieldValue("zipcode",selectedZipCode);
                  setFieldValue("phone_number","");
                  this.handleCountryNameChange(selectedCountry);
                };
                return (
                  <>
                    <form onSubmit={handleSubmit}>
                      <AboutTitle className="Request-profile-logo">
                        {!this.state.isEditPersonal ? (
                          <Box>
                            <img
                              className="profile-avtar"
                              src={
                                !this.state.uploadedImagePreview
                                  ? profileavtar
                                  : this.state.uploadedImagePreview
                              }
                            />
                          </Box>
                        ) : (
                          <Button
                            component="label"
                            className="Login-Btn"
                            data-test-id="uploadBtn"
                          >
                            {!this.state.referenceImage && (
                              <>
                                <img
                                  className="profile-avtar"
                                  src={editprofile}
                                />
                              </>
                            )}
                            {this.state.referenceImage && (
                              <>
                                <Avatar
                                  variant="rounded"
                                  className="profile-avtar"
                                  src={this.state.uploadedImagePreview}
                                />
                              </>
                            )}
                            <input
                              style={{ display: "none" }}
                              type="file"
                              name="image"
                              data-test-id="uploadInputBtn"
                              multiple
                              accept="image/*"
                              onChange={(event) => {
                                this.handleMediaSelect(event);
                              }}
                            />
                          </Button>
                        )}
                      </AboutTitle>
                      
                      <FieldsRow
                        data-test-id="field-row-1"
                        left={
                          <Box className="establishment-name">
                            <InputField
                              {...getFieldProps("establishment_name")}
                              {...this.getInputProps(
                                "establishment_name",
                                errors,
                                touched,
                                values
                              )}
                              label={"Establishment Name"}
                              placeholder={"Establishment name"}
                              inputProps={defaultInputProps}
                              data-test-id="establishment_name"
                              disabled={!this.state.isEditPersonal}
                            />
                          </Box>
                        }
                        right={
                          <Box className="establish-address-container">
                            <InputLabel className="establish-address-label">
                              Address
                            </InputLabel>
                            <Box
                              className={
                                Boolean(errors.address && touched.address)
                                  ? "address-error"
                                  : ""
                              }
                            >
                              <AddressAutocomplete
                                data-test-id="address"
                                field={{
                                  name: "address",
                                  value: values.address,
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                }}
                                disable={!this.state.isEditPersonal}
                                onAddressSelect={handleAddressSelect}
                              />
                            </Box>
                            <FormHelperText style={{ color: "#f44336" }}>
                              {
                                this.getErrorAndHelperText(
                                  "address",
                                  errors as FormErrors,
                                  touched as FormTouched
                                ).helperText
                              }
                            </FormHelperText>
                          </Box>
                        }
                      />
                      <FieldsRow
                      data-test-id="field-row-2"
                        left={
                          <Box
                            className={`city-country-container
                            ${
                              Boolean(errors.country && touched.country)
                                ? "address-error"
                                : ""
                            }
                          `}
                          >
                            <InputLabel className="establish-address-label">
                              Country
                            </InputLabel>
                            <Autocomplete
                              disableClearable
                              id="country"
                              disabled={!this.state.isEditPersonal}
                              className="country-city-select"
                              data-test-id="country"
                              value={values.country}
                              options={this.state.countries.map(
                                (options) => options.label
                              )}
                              onBlur={handleBlur}
                              getOptionLabel={(option) => option}
                              onChange={(_event, newValue) => {
                                setFieldValue("country", newValue);
                                setFieldValue("city", "");
                                setFieldValue("phone_number","");
                                this.handleCountryNameChange(newValue);
                              }}
                              popupIcon={<ExpandMoreRoundedIcon />}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Your country"
                                  data-test-id="new-establish-country"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img src={countryIcon} />
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                  error={
                                    this.getErrorAndHelperText(
                                      "country",
                                      errors as FormErrors,
                                      touched as FormTouched
                                    ).isError
                                  }
                                  helperText={
                                    this.getErrorAndHelperText(
                                      "country",
                                      errors as FormErrors,
                                      touched as FormTouched
                                    ).helperText
                                  }
                                />
                              )}
                            />
                          </Box>
                        }
                        right={
                          <Box
                            className={`city-country-container
                          ${
                            Boolean(errors.city && touched.city)
                              ? "address-error"
                              : ""
                          }
                        `}
                          >
                            <InputLabel className="establish-address-label">
                              City
                            </InputLabel>
                            <Autocomplete
                              disableClearable
                              disabled={!this.state.isEditPersonal}
                              id="city"
                              data-test-id="new-establish-city"
                              className="country-city-select"
                              value={values.city}
                              options={this.state.cities.map(
                                (options) => options.label
                              )}
                              getOptionLabel={(option) => option}
                              onBlur={handleBlur}
                              onChange={(_event, newValue) => {
                                setFieldValue("city", newValue);
                              }}
                              popupIcon={<ExpandMoreRoundedIcon />}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Your city"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img src={cityIcon} />
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                  error={
                                    this.getErrorAndHelperText(
                                      "city",
                                      errors as FormErrors,
                                      touched as FormTouched
                                    ).isError
                                  }
                                  helperText={
                                    this.getErrorAndHelperText(
                                      "city",
                                      errors as FormErrors,
                                      touched as FormTouched
                                    ).helperText
                                  }
                                />
                              )}
                            />
                          </Box>
                        }
                      />
                      <FieldsRow
                        left={
                          <InputField
                            {...getFieldProps("zipcode")}
                            {...this.getInputProps(
                              "zipcode",
                              errors,
                              touched,
                              values
                            )}
                            id="zipcode"
                            label={"Zip Code"}
                            placeholder={"Zip Code"}
                            disabled={!this.state.isEditPersonal}
                            inputProps={defaultInputProps}
                            startAdornment={<img src={zipIcon} />}
                            data-test-id="zipcode"
                          />
                        }
                        right={<></>}
                      />
                      <Box className="Contact-header-box">
                        <Typography
                          style={{
                            fontSize: 22,
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                            color: "#000",
                          }}
                        >
                          Contacts
                        </Typography>
                      </Box>
                      <FieldsRow
                        data-test-id="field-row-4"
                        left={
                          <Box className="contact-field-container">
                          <InputField
                            {...getFieldProps("email")}
                            {...this.getInputProps(
                              "email",
                              errors,
                              touched,
                              values
                            )}
                            label={"Email"}
                            placeholder={"Email"}
                            disabled={!this.state.isEditPersonal}
                            inputProps={defaultInputProps}
                            data-test-id="email"
                          /></Box>
                        }
                        right={
                            this.renderPhoneInput(
                              "phone_number",
                              getFieldProps,
                              errors,
                              touched,
                              values,
                              setFieldValue,
                              setFieldTouched,
                              "isEditPersonal"
                            )
                        }
                      />
                      <Box className="Contact-header-box">
                        <Typography
                          style={{
                            fontSize: 22,
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                            color: "#000",
                          }}
                        >
                          Social Medial link
                        </Typography>
                      </Box>
                      <FieldsRow
                        left={
                          <InputField
                            {...getFieldProps("facebook")}
                            {...this.getInputProps(
                              "facebook",
                              errors,
                              touched,
                              values
                            )}
                            label={"Facebook"}
                            placeholder={"Facebook link"}
                            inputProps={defaultInputProps}
                            data-test-id="facebook"
                            disabled={!this.state.isEditPersonal}
                          />
                        }
                        right={
                          <InputField
                            {...getFieldProps("instagram")}
                            {...this.getInputProps(
                              "instagram",
                              errors,
                              touched,
                              values
                            )}
                            label={"Instagram"}
                            disabled={!this.state.isEditPersonal}
                            placeholder={"Instagram link"}
                            inputProps={defaultInputProps}
                            data-test-id="instagram"
                          />
                        }
                      />
                      <FieldsRow
                        left={
                          <InputField
                            {...getFieldProps("linkedin")}
                            disabled={!this.state.isEditPersonal}
                            {...this.getInputProps(
                              "linkedin",
                              errors,
                              touched,
                              values
                            )}
                            label={"LinkedIn"}
                            placeholder={"LinkedIn link"}
                            inputProps={defaultInputProps}
                            data-test-id="linkedin"
                          />
                        }
                        right={
                          <InputField
                            {...getFieldProps("tiktok")}
                            {...this.getInputProps(
                              "tiktok",
                              errors,
                              touched,
                              values
                            )}
                            label={"Tiktok"}
                            placeholder={"Tiktok link"}
                            inputProps={defaultInputProps}
                            data-test-id="tiktok"
                            disabled={!this.state.isEditPersonal}
                          />
                        }
                      />
                      <Box style={{ marginTop: "40px" }}>
                        {this.renderActionButtons("isEditPersonal", resetForm)}
                      </Box>
                    </form>
                  </>
                );
              }}
          </Formik>
        </RequestContainer>
            </>
        )
      }
      renderActionButtons = (editField: EditFields, resetCallback: () => void) => {
        return (
          <Box>
            {!this.state.isEditPersonal
              ? <Box style={{ display: 'flex', justifyContent: 'flex-end'}}>
                  <Button
                    data-test-id='edit-btn' 
                    style={{ 
                      ...styles.actionButtonDefault,
                      border: '1px solid #6F05EA', 
                      color: '#6F05EA', 
                    } as React.CSSProperties} 
                    onClick={() => this.setState({isEditPersonal: true})}>Edit</Button>
                </Box> 
              : <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '17px'}}>
                  <Button
                    data-test-id='cancel-btn'  
                    style={{
                      ...styles.actionButtonDefault, 
                      border: '1px solid #4B4B4B', 
                      color: '#4B4B4B',
                      } as React.CSSProperties} 
                    onClick={() => this.cancelEditForm(editField, resetCallback)}>Cancel</Button>
                  <Button
                    data-test-id='save-btn'  
                    style={{
                      ...styles.actionButtonDefault, 
                    border: '1px solid #6F05EA', 
                    color: '#6F05EA',
                    } as React.CSSProperties} 
                    type="submit">
                      Save
                    </Button>
                </Box>
            }
          </Box>
        )
      }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start 
        <> 
       <RequestSectionBox>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{width:'100%',display:'flex'}} >
      <PageWrapper data-test-id='page-wrapper'>
        {this.renderSettingsBlock()}
      </PageWrapper>
            </Grid>
        </RequestSectionBox>
            </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
type CSSProperties = React.CSSProperties;
type NestedCSSProperties = { [key: string]: CSSProperties | NestedCSSProperties };

const styles: {
  general: {
    flexCol: CSSProperties;
  };
  selectedPet: CSSProperties;
  notSelectedPet: CSSProperties;
  rangeSelectItem: CSSProperties;
  actionButtonDefault: CSSProperties;
  medicalFormPhone: CSSProperties;
  filledLabel: CSSProperties;
  secondaryLabel: CSSProperties;
  inputStyleGeneral: CSSProperties;
  fieldBox: CSSProperties;
  header: NestedCSSProperties;
  menu: NestedCSSProperties;
  pets: NestedCSSProperties & { addPetWrapper: CSSProperties; addText: CSSProperties };
  settings: NestedCSSProperties & { header: CSSProperties; modalText: CSSProperties; modalBody: CSSProperties };
}  = {
  general: {
    flexCol: {
      display: 'flex', flexDirection: 'column'
    },
  },
  settings: {
    header: {
      fontWeight: 600,
      fontSize: '40px',
      color: '#000000',
      marginBottom: '10px'
    },
    modalText: {
      fontSize: '24px', fontWeight: 700
    },
    modalBody: {
      width: '100%', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      textAlign: 'center', 
      flexDirection: 'column', 
      gap: '30px', 
      paddingTop: '20px',
      paddingBottom: '20px'
    }
  },
  selectedPet: {
    width: '75px',
    height: '75px',
    border: '2px solid rgba(111, 5, 234, 1)',
    transition: 'filter 0.3s ease, opacity 0.3s ease',
  },
  notSelectedPet: {
    width: '75px',
    height: '75px',
    filter: 'grayscale(100%)',
    opacity: 0.5,
    transition: 'filter 0.3s ease, opacity 0.3s ease',
  },
  rangeSelectItem: {
    display: 'flex', 
    width: '104px', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  actionButtonDefault: {
    height: '40px', 
    textTransform: 'none', 
    borderRadius: '100px', 
    width: '146px', 
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 700
  },
  medicalFormPhone: {
    width: "100%",
    height: "32px",
    border: "none",
    borderRadius: "0px",
    paddingLeft: "40px",
  },
  filledLabel: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#6F05EA',
    fontFamily: 'Montserrat',
  },
  secondaryLabel: {
    fontSize: '18px',
    color: '#6F05EA',
    fontWeight: 400,
    fontFamily: 'Montserrat',
  },
  inputStyleGeneral: {
    height: '34px', 
    marginTop: '4px',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    color:'#7D7D7D'
  },
  header: {
    wrapper: {
      minHeight: '127px',
      paddingLeft: '16px',
      paddingRight: '16px',
      display: 'flex',
      alignItems: 'center',
      background: "linear-gradient(#6928F2, #884FFF)",
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    user: {
      display: 'flex',
      alignItems: 'center',
      gap: '30px'
    },
    profile: {
      display: 'flex',
      alignItems: 'center', 
      gap: '20px',
      color: '#fff'
    }
  },
  menu: {
    box: {
      paddingTop: '96px',
      paddingBottom: '96px',
      border: '1px solid #E2E8F0',
      width: '30%',
      maxWidth: '30%',
      background: '#F6F6F6',
      justifyContent: 'flex-end',
      paddingLeft: '24px'
    },
    content: {
      width: '264px',
      minWidth: '264px',
      maxWidth: '264px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    avatar: {
      height: '90px', width: '90px', marginBottom: '18px'
    },
    name: {
      fontWeight: 600, fontSize: '20px', color: "#000", textAlign: 'center'
    },
    pointsBox: {
      padding: '0 11px', 
      borderRadius: '35px', 
      background: '#FFCE00', 
      height: '30px', 
      marginTop: '8px', 
      marginBottom: '20px', 
      display: 'flex', 
      alignItems: 'center'
    },
    pointsText: {
      fontWeight: 600, fontSize: '14px', color: '#000'
    },
    item: {
      display: 'flex',
      width: '100%', 
      gap: '10px',
      height: '50px',
      borderRadius: '8px',
      padding: '0 20px',
      alignItems: 'center', 
    },
    activeTitle: { fontWeight: 700, color: '#fff', fontSize: '18px'},
    title: { fontWeight: 400, color: '#0F172A',fontSize: '18px' }
  },
  fieldBox: {
    display: 'flex',
    gap: '27px',
    alignItems: 'flex-end'
  },
  pets: {
    content: {
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      gap: '32px', 
      textAlign: 'left'
    },
    headerBox: {
      display: 'flex', 
      flexDirection: 'column', 
      gap: '32px'
    },
    headerItems: {
      display: 'flex', 
      gap: '28px', 
      alignItems: 'center'
    },
    headerText: {
      display: 'flex', 
      flexDirection: 'column', 
      gap: '12px', 
      textAlign: 'left'
    },
    textLabel: {
      color: 'green', 
      fontWeight: 300, 
      fontSize: '13px'
    },
    notSelectedWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '35px',
      width: '100%'
    },
    petBox: {
      width: '297px',
      maxWidth: '297px',
      boxShadow: '0px 0px 7.5px 0px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      padding: '29px',
      alignItems: 'center',
      gap: '24px',
    },
    name: {
      fontWeight: 600, fontSize: '24px', color: '#000', background: 'white'
    },
    description: {
      fontWeight: 600, fontSize: '16px', color: '#B7B7B7'
    },
    photoBox: {
      display: 'flex', gap: '13px', width: '100%', justifyContent: 'center'
    },
    addPetWrapper: {
      background: "#F6F6F6", 
      justifyContent: 'center', 
      gap: '50px', 
      minHeight: '300px'
    },
    addText: {
      color: '#B7B7B7', fontSize: '24px', fontWeight: 600
    } }
  }
// Customizable Area End
