import React, { useRef, useState } from 'react';
import { IPet, configJSON } from '../CustomisableUserProfilesController';
import { Avatar, Box, ClickAwayListener, Paper, Popper, Typography, styled } from '@material-ui/core';
import { UploadSvg } from '../assets';
import PetName from './PetName';


type Props = {
    selectedPet: IPet;
    onUpdatePet: (pet: IPet, petPhoto?: File) => void;
    onUpdateField: (field: keyof IPet, value: IPet[keyof IPet]) => void;
}

const Wrapper = styled(Box)({
    display: 'flex', 
    flexDirection: 'column', 
    gap: '32px',

    '& .header-text': {
        fontSize: '40px', fontWeight: 600
    },
    '& .header-items': {
        display: 'flex', 
        gap: '28px', 
        alignItems: 'center'
    },
    '& .avatar': {
        width: '148px', height: '148px'
    },
    '& .upload-photo': {
        width: '148px', 
        height: '148px', 
        borderRadius: '50%', 
        background: '#F0E5FF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    '& .header-info-wrapper': {
        display: 'flex', 
        flexDirection: 'column', 
        gap: '12px', 
        textAlign: 'left',
        width: '100%'
    },
    '& .header-info-label': {
        color: '#A6A6A6', 
        fontWeight: 300, 
        fontSize: '13px'
    },
    '& .header-info-name': {
        fontWeight: 600, fontSize: '30px'
    },
    '& .paper-item': {
        padding: '16px',
        fontSize: '16px',
        fontWeight: 400,
        cursor: 'pointer'
    }
})

const PetInfoHeader = ({ selectedPet, onUpdatePet, onUpdateField }: Props) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const popperRef = useRef<HTMLDivElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if(file) {
            if(selectedPet.id) {
                onUpdatePet({ ...selectedPet, photo_blob: file })
            } else {
                onUpdateField('photo_blob', file);
            }
        }
      };

    const handleNameChange = (name: string) => {
        if(selectedPet.id) {
            onUpdatePet({...selectedPet, name });
        } else {
            onUpdateField('name', name);
        }
    }

    const handleOpenFileSelect = () => {
        if(fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <Wrapper>
            <input
                data-test-id="file-select"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
            />
            <Typography className='header-text'>{configJSON.myPets}</Typography>
            <Box className='header-items'>
                <div ref={anchorRef} onClick={() => setOpen((prev) => !prev)} data-test-id='text-section'>
                    {(selectedPet.pet_photo || selectedPet.photo_blob)
                        ? <Avatar className='avatar' src={selectedPet.pet_photo || URL.createObjectURL(selectedPet.photo_blob as Blob) || undefined} />
                        : <Box className='upload-photo'>
                            <img src={UploadSvg} />
                        </Box>
                    }
                </div>
                <Popper
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorRef.current}
                    placement="right"
                    ref={popperRef}
                >
                    <ClickAwayListener onClickAway={() => setOpen(false)} data-test-id='clickaway'>
                        <Paper style={{ padding: '16px'}}>
                            <Box onClick={handleOpenFileSelect} data-test-id="file-select-visible">
                                <Typography className='paper-item'>{selectedPet.pet_photo ? 'Change' : 'Choose'} photo</Typography>
                            </Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
                <Box className='header-info-wrapper'>
                    <Typography className='header-info-label'>{configJSON.name}</Typography>
                    <PetName name={selectedPet.name} onSaveName={handleNameChange} isNew={!selectedPet.id} />
                </Box>
            </Box>
        </Wrapper>
    )
}

export default PetInfoHeader;