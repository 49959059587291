import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  styled,
  TextField,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import PriceController, { Props, configJSON } from "./PriceController.web";
import { infoIcon, offSwitchIcon, onSwitchIcon } from "../assets";
import { priceColumnTitle } from "../../../../components/src/HelperUtils";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});

const PriceContainer = styled(Box)({
  width: "100%",
  "& .otherServiceSection": {
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  "& .otherServiceContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "200px",
  },
  "& .otherServiceBox": {
    display: "flex",
    gap: 32,
    height: "32px",
    alignItems: "center",
  },
  "& .otherServiceName": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#6F05EA",
    marginLeft: "8px",
  },
  "& .buttonContainer": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    alignItems: "center",
    "@media (max-width: 960px)": {
      flexDirection: "column",
      gap: "12px",
    },
  },
  "& .perDayPriceContainer": {
    display: "flex",
    gap: 8,
    marginLeft: "8px",
    alignItems: "center",
  },
  "& .perDayPriceTypo": {
    color: "#F87171",
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .infoIcon": {
    height: "20px",
    width: "20x",
  },
  "& .cancelButton": {
    borderColor: "#78716C",
    color: "#78716C",
    fontSize: 18,
    fontWeight: "700",
    padding: "0px 40px",
  },
  "& .actionButton": {
    height: "40px",
    border: "1px solid",
    textTransform: "none",
    borderRadius: "100px",
  },
  "& .saveButton": {
    borderColor: "#6F05EA",
    color: "#6F05EA",
    fontSize: 18,
    fontWeight: "700",
    padding: "0px 50px",
  },
  "& .editButtonContainer": {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 960px)": {
      justifyContent: "center",
      width: "70%",
    },
  },
  "& .editButton": {
    "@media (max-width: 960px)": {
      width: "100%",
    },
  },
  "& .saveCancelButtonContainer": {
    display: "flex",
    gap: 12,
    "@media (max-width: 600px)": {
      justifyContent: "center",
      flexDirection: "column",
      width: "70%",
    },
  },
  "& .otherServiceInput": {
    width: "180px",
  },
});

const PriceCardContainer = styled(Box)({
  "& .cardTitleContainer": {
    display: "flex",
    gap: 32,
    alignItems: "center",
    marginBottom: 32
  },
  "& .serviceNameTitleContainer": {
    width: "192px",
    paddingLeft: "8px",
  },
  "& .priceTitleContainer": {
    display: "flex",
    flexDirection: "column",
    width: "180px",
  },
  "& .priceTitleTypo": {
    fontSize: "18px",
    color: "#6F05EA",
    fontWeight: 600,
  },
  "& .priceTitleTypo2": {
    fontSize: "12px",
    fontWeight: 400,
    color: "#6F05EA",
    marginLeft: 5,
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "12px",
    fontFamily: "Montserrat",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .priceInput": {
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .serviceNameTypo": {
    fontSize: "14px",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  "& .serviceNameContainer": {
    display: "flex",
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    padding: "14px 12px",
    width: "200px",
    borderRadius: "8px",
  },
  "& .servicePriceContainer": {
    width: "180px",
  },
  "& .cardInfoContainer": {
    display: "flex",
    alignItems: "center",
    columnGap: "32px",
    rowGap: "16px"
  },
});
// Customizable Area End

export default class PriceWeb extends PriceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderLoader = () => {
    return (
      <Box style={style.noDataLoader}>
        <CustomLoader />
      </Box>
    )
  }
  renderNodataMsg = () => {
    return (
      <Box style={style.noDataLoader}>
        <Typography style={style.noDataText}>No results found!</Typography>
      </Box>
    )
  }
  renderPrices = () => {
    const { priceFormList } = this.state
    return (
      <>
        <PriceWrapper>
          {priceFormList.map((item, index) => (
            <Box className="cardInfoContainer" key={index}>
              <ChipBox>
                <ChipTitle>{item.title}</ChipTitle>
              </ChipBox>
              <RightCheckBox>
                <CustomInput
                  type="number"
                  id={`input-${index}`}
                  disabled={!this.state.isEdit}
                  value={item.price}
                  onChange={(event) => this.setPriceValue(event.target.value, item.title, item.sub_service_id)}
                  error={this.getError(item.title, item.sub_service_id, false).error}
                  helperText={this.getError(item.title, item.sub_service_id, false).errorMsg}
                  data-testid={`input-testid-${index}`}
                />
              </RightCheckBox>
            </Box>
          ))}
        </PriceWrapper>
      </>
    )
  }
  renderDynamicSections = () => {
    const { getPriceDetailsLoading, priceFormList } = this.state
    return (
      <>
        {getPriceDetailsLoading && !priceFormList.length ? this.renderLoader() : <></>}
        {!getPriceDetailsLoading && priceFormList.length ? this.renderPrices() : <></>}
        {!getPriceDetailsLoading && !priceFormList.length ? this.renderNodataMsg() : <></>}
      </>
    )
  }
  renderPriceCard = () => {
    const { serviceColumn, priceColumn } = priceColumnTitle(this.props.selectedService.name)
    return (
      <PriceCardContainer>
        <Box className="cardTitleContainer">
          <Box className="serviceNameTitleContainer">
            <Typography className="priceTitleTypo">{serviceColumn}</Typography>
          </Box>
          <Box className="priceTitleContainer">
            <Typography className="priceTitleTypo">{priceColumn}</Typography>
          </Box>
        </Box>
        {this.renderDynamicSections()}

      </PriceCardContainer>
    );
  };

  transportationSwitch = () => {
    const { transportation } = this.state
    const notAllowedServices = [configJSON.SERVICES_ENUM.PET_SITTING, configJSON.SERVICES_ENUM.DOG_WALKING]
    const isShowTransportation = !notAllowedServices.includes(this.props.selectedService.name)
    return (
      <>
        {isShowTransportation ?
          <RowCheckBox>
            <LeftCheckBox>
              <CustomCheckbox
                disabled={!this.state.isEdit}
                value={transportation.checked}
                checked={transportation.checked}
                control={
                  <Checkbox
                    name="transportation"
                    onChange={(event) => this.setOtherServiceCheck(event.target.checked, "transportation")}
                    value={transportation.checked}
                    className="transportation-switch"
                    disableRipple
                    checkedIcon={<img src={onSwitchIcon} />}
                    icon={<img src={offSwitchIcon} />}
                  />
                }
                label="Transportation"
                labelPlacement="start"
                data-testid="transportation-switch"
              />
            </LeftCheckBox>
            {transportation.checked ?
              <RightCheckBox>
                <CustomInput
                  type="number"
                  id={"transportation-input"}
                  disabled={!this.state.isEdit}
                  value={transportation.price}
                  onChange={(event) => this.setOtherServicePrice(event.target.value, "transportation")}
                  error={this.getError("transportation", transportation.sub_service_id, true).error}
                  helperText={this.getError("transportation", transportation.sub_service_id, true).errorMsg}
                />
              </RightCheckBox>
              : <></>}
          </RowCheckBox> : <></>
        }
      </>
    )
  }
  medicationSwitch = () => {
    const { medication } = this.state
    const notAllowedServices = [configJSON.SERVICES_ENUM.GROOMING]
    const isShowMedication = !notAllowedServices.includes(this.props.selectedService.name)
    return (
      <>
        {isShowMedication ?
          <RowCheckBox>
            <LeftCheckBox>
              <CustomCheckbox
                disabled={!this.state.isEdit}
                value={medication.checked}
                checked={medication.checked}
                control={
                  <Checkbox
                    name="medication"
                    onChange={(event) => this.setOtherServiceCheck(event.target.checked, "medication")}
                    value={medication.checked}
                    className="medication-switch"
                    disableRipple
                    checkedIcon={<img src={onSwitchIcon} />}
                    icon={<img src={offSwitchIcon} />}
                  />
                }
                label="Medication"
                labelPlacement="start"
                data-testid="medication-switch"
              />
            </LeftCheckBox>
            {medication.checked ?
              <RightCheckBox>
                <CustomInput
                  type="number"
                  id={"medication-input"}
                  disabled={!this.state.isEdit}
                  value={medication.price}
                  onChange={(event) => this.setOtherServicePrice(event.target.value, "medication")}
                  error={this.getError("medication", medication.sub_service_id, true).error}
                  helperText={this.getError("medication", medication.sub_service_id, true).errorMsg}
                />
              </RightCheckBox>
              : <></>}

          </RowCheckBox>
          : <></>
        }
      </>
    )
  }

  renderOtherService = () => {
    return (
      <>
        <Box style={style.switchWrapper}>
          {this.transportationSwitch()}
          {this.medicationSwitch()}
        </Box>

      </>
    )
  };

  renderButtonContainer = () => {
    const { getPriceDetailsLoading, priceFormList } = this.state
    const disableEditBtn = getPriceDetailsLoading || !priceFormList.length
    return (
      <Box className="buttonContainer">
        <Box className="perDayPriceContainer">
          <img src={infoIcon} className="infoIcon" />
          <Box className="perDayPriceTypo">All prices are per day.</Box>
        </Box>
        {!this.state.isEdit ? (
          <Box className="editButtonContainer">
            <Button
              className="saveButton actionButton editButton"
              onClick={this.handleEditClick}
              data-testid="edit-btn"
              disabled={disableEditBtn}
            >
              Edit
            </Button>
          </Box>
        ) : (
          <Box className="saveCancelButtonContainer">
            <Button
              className="cancelButton actionButton"
              onClick={this.handleCancelClick}
              data-test-id="cancel-btn"
            >
              Cancel
            </Button>
            <Button className="saveButton actionButton"
              onClick={this.handleSaveClick}
              data-testid="save-btn">
              Save
            </Button>
          </Box>
        )}
      </Box>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <PriceContainer>
          {this.renderPriceCard()}
          {this.renderOtherService()}
          {this.renderButtonContainer()}
        </PriceContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const CustomLoader = styled(CircularProgress)({
  color: "#6F05EA"
})
const ChipBox = styled(Box)({
  maxWidth: 176,
  width: "100%",
  padding: "14px 12px",
  borderRadius: "8px",
  backgroundColor: "#F8F8F8",
  boxShadow: "0px 2px 8px 0px #0000000F",
})
const ChipTitle = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16px",
  color: "#0F172A"
})
const RowCheckBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  columnGap: "32px",
})
const LeftCheckBox = styled(Box)({
  width: "100%",
  maxWidth: "200px"
})
const RightCheckBox = styled(Box)({
  width: "100%",
  maxWidth: "176px"
})
const CustomCheckbox = styled(FormControlLabel)({
  margin: "0px",
  "& .MuiFormControlLabel-label": {
    color: "#6F05EA",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    userSelect: "none",
    fontFamily: 'Montserrat',
  },
  "& .MuiButtonBase-root": {
    padding: 0,
  },
  "& .Mui-checked.medication-switch": {
    marginLeft: "52px !important"
  },
  "& .medication-switch": {
    marginLeft: "46px !important"
  },
  "& .Mui-checked.transportation-switch": {
    marginLeft: "26px !important"
  },
  "& .transportation-switch": {
    marginLeft: "20px !important"
  },

})
const CustomInput = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiInputBase-input": {
    padding: "8px !important",
    fontFamily: 'Montserrat',
    "&::placeholder": {
      color: "rgba(166, 166, 166, 1)",
      opacity: 1,
      fontStyle: "italic",
      fontFamily: 'Montserrat',
      fontSize: "12px",
      fontWeight: 400,
    },
  },
})
const PriceWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
})
const style = {
  noDataLoader: {
    padding: "32 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  } as React.CSSProperties,
  noDataText: {
    fontFamily: 'Montserrat',
    color: "rgb(183, 183, 183)",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 18
  } as React.CSSProperties,
  switchWrapper: {
    marginTop: 100,
    display: "flex",
    rowGap: "16px",
    flexDirection: "column",
  } as React.CSSProperties,
}
// Customizable Area End
