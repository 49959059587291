import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { classNames } from "react-select/src/utils";
import { styled } from "@material-ui/core/styles";

interface GenericStyle {
  [key: string]: string;
}

interface MenuProps {
  disableScrollLock: boolean,
  marginThreshold: undefined,
}

interface Props {
  value?: string | number | undefined;
  fullWidth?: boolean;
  mb?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  list?: Array<{ label: string; value: string | number | undefined }>;
  asterisk?: boolean;
  placeholder?: string;
  label?: string;
  helperText?: string;
  error?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  id: string;
  name: string;
  className?: string;
  labelStyle?: GenericStyle;
  menuProps?: MenuProps;
}

const CommonSelect = (props: Props) => {
  const {
    value,
    onChange,
    fullWidth = false,
    mb = "0px",
    disabled = false,
    list,
    asterisk,
    placeholder,
    label,
    style,
    error = false,
    helperText,
    startAdornment,
    menuProps,
    onBlur,
    id,
    name,
    className,
    labelStyle,
  } = props;
  const renderLabel = (value: unknown) => {
    const filteredNode = list?.filter((element) => element.value === value);
    return filteredNode?.[0]?.label;
  };
  return (
    <FormControl fullWidth>
      {label && (
        <Typography
          style={{
            color: "rgb(51, 65, 85)",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "22px",
            ...labelStyle,
          }}
        >
          {label} {asterisk && <span style={{ color: "red" }}>*</span>}
        </Typography>
      )}
      <StyledSelect
        value={value}
        id={id}
        name={name}
        disabled={disabled}
        fullWidth={fullWidth}
        variant="standard"
        onChange={onChange}
        onBlur={onBlur}
        style={{ marginBottom: mb, ...style }}
        IconComponent={ExpandMoreRoundedIcon}
        displayEmpty
        error={error}
        startAdornment={
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        }
        MenuProps={{ 
          className: className,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          ...menuProps
        }}
        renderValue={(value) =>
          typeof value === "undefined" || value === "" ? (
            <Typography
              style={{ color: error ? "#cc0000" : "#000", opacity: error ? "1" : "0.38", fontSize: "16px" }}
            >
              {placeholder}
            </Typography>
          ) : (
            <>{renderLabel(value)}</>
          )
        }
      >
        {list && list?.length > 0 ? (
          list?.map((listItem) => {
            return (
              <StyledMenuItem key={listItem.label} value={listItem.value}>
                {listItem.label}
              </StyledMenuItem>
            );
          })
        ) : (
          <StyledMenuItem value={""} disabled>
            No Item
          </StyledMenuItem>
        )}
      </StyledSelect>
      {error && (
        <FormHelperText
          style={{ color: "#f44336", margin: `-${mb} 0 ${mb} 0` }}
        >
          {" "}
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CommonSelect;

const StyledSelect = styled(Select)({
  "& .MuiMenu-paper":{
    top:"445px !important"
  },
  '& .MuiSelect-select': {
    backgroundColor: 'transparent', 
  },
  '& .Mui-focused .MuiSelect-select': {
    backgroundColor: 'transparent', 
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent', 
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #5B4595",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },
  "&.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: "1px solid #CBD5E1",
    },
  "&.Mui-focused .MuiInputAdornment-positionStart img": {
    filter:
    "brightness(0) saturate(100%) invert(13%) sepia(81%) saturate(6967%) hue-rotate(270deg) brightness(86%) contrast(117%)",
  },
  "& .MuiInput-root:not(.Mui-focused):not(:placeholder-shown) .MuiInputAdornment-positionStart img":
    {
      filter:
        "invert(14%) sepia(79%) saturate(6657%) hue-rotate(269deg) brightness(85%) contrast(120%)",
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover":{
      backgroundColor:"red"
    }
});

const StyledMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
  backgroundColor: '#F3EBFF',
  },
  '&:hover': {
  backgroundColor: '#F3EBFF',
  },
  '&.Mui-selected:hover': {
  backgroundColor: '#F3EBFF',
  },
  });