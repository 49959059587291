export function countNights(startDateStr: string, endDateStr: string): number {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
  
    if (endDate <= startDate) {
      return 0;
    }
  
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
  
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const differenceInDays = differenceInMilliseconds / millisecondsPerDay;
  
    const numberOfNights = Math.floor(differenceInDays) - 1;
  
    return Math.max(numberOfNights, 0);
  }