import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  dogCareIcon,
  dogWalkingIcon,
  groomingIcon,
  petHotelIcon,
  petSittingIcon,
  trainingIcon,
  vendorS1ActiveImage,
  vendorS1Image,
  vendorS2ActiveImage,
  vendorS2GreyImage,
  vendorS2Image,
  vendorS3ActiveImage,
  vendorS3GreyImage,
  vendorS3Image,
  vendorS4ActiveImage,
  vendorS4GreyImage,
} from "./assets";
import React from "react";
import { getCountryCode } from "countries-list";
import { ICountryModel } from "./EmailAccountRegistrationController";
import { setStorageData } from "../../../framework/src/Utilities";
import {
  FormError,
  FormErrorTouched,
  Step2Modal,
  Step3Modal,
  VendorBaseErrorDetail,
} from "./EmailAccountVendorRegistration.web";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface IStepperImageModal {
  step: number;
  activeImage?: string;
  completedImage?: string;
  beforeCompletionImage?: string;
}
interface CardData {
  label: string;
  icon: string;
}
interface VendorApiSuccessResponse{
  data: {
		id: string,
		type: string,
		attributes: {
			first_name: string,
			last_name: string,
			full_phone_number: string,
			country_code: string,
			phone_number: string,
			email: string,
			activated: string,
			country: string,
			city: string
		}
	}
  meta: {
		token: string
	}
  errors: VendorBaseErrorDetail[]
}
interface IVendorSignUpApiModal {
  first_name: string;
  full_phone_number: string;
  country_code: string;
  phone_number: string;
  email: string;
  password: string;
  password_confirmation: string;
  activated: boolean;
  country: string,
  city: string;
  vendor_type: string;
  tax_number: string;
  address: string;
  zipcode: string;
  services_type: string[];
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id:string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  businessType: string;
  countries: {
    label: string;
    value: string | number | undefined;
  }[];
  cities: {
    label: string;
    value: string | number | undefined;
  }[];
  isoCountryName: string | boolean;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  selectedCards: string[];
  isShowApproval: boolean;
  steps: IStepperImageModal[];
  step2Value: Step2Modal;
  step3Value: Step3Modal;
  vendorSignUpData: IVendorSignUpApiModal;
  vendorError:VendorBaseErrorDetail[];
  addressPlaceId:string
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountVendorRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cardData = [
    { label: "Pet hotel", icon: petHotelIcon },
    { label: "Dog care", icon: dogCareIcon },
    { label: "Dog walking", icon: dogWalkingIcon },
    { label: "Pet sitting", icon: petSittingIcon },
    { label: "Grooming", icon: groomingIcon },
    { label: "Training", icon: trainingIcon },
  ];
  vendorSignUpApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activeStep: 1,
      businessType: "",
      countries: [],
      cities: [],
      isoCountryName: "",
      enablePasswordField: false,
      enableConfirmPasswordField: false,
      selectedCards: [],
      isShowApproval: false,
      steps: this.getSteps(),
      step2Value: {
        first_name: "",
        country: "",
        city: "",
        tax_number: "",
        full_phone_number: "",
        zipcode: "",
        address: "",
        country_code: "",
        company_name: "",
      },
      step3Value: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      vendorSignUpData: {
        first_name: "",
        full_phone_number: "",
        country_code: "",
        phone_number: "",
        email: "",
        password: "",
        password_confirmation: "",
        activated: false,
        country: "",
        city: "",
        vendor_type: "",
        tax_number: "",
        address: "",
        zipcode: "",
        services_type: [],
      },
      vendorError: [],
      addressPlaceId:"",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.vendorSignUpApiCallId){

          this.handleVendorResponse(responseJson)
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleVendorResponse(responseJson:VendorApiSuccessResponse){
    if(responseJson.errors){
      this.setState({vendorError:responseJson.errors})
    }
    else{
      sessionStorage.setItem("vendor_singUp_token",responseJson.meta.token)
      this.setState({isShowApproval:true})
    }
  }

  getStepContent(step: number) {
    switch (step) {
      case 1:
        return "Select your type of register";
      case 2:
        return "Fill in your business info";
      case 3:
        return "Set your account";
      case 4:
        return "Set your service";
      default:
        return "Unknown step";
    }
  }

  getSteps() {
    return [
      {
        step: 1,
        activeImage: vendorS1ActiveImage,
        completedImage: vendorS1Image,
      },
      {
        step: 2,
        activeImage: vendorS2ActiveImage,
        completedImage: vendorS2Image,
        beforeCompletionImage: vendorS2GreyImage,
      },
      {
        step: 3,
        activeImage: vendorS3ActiveImage,
        completedImage: vendorS3Image,
        beforeCompletionImage: vendorS3GreyImage,
      },
      {
        step: 4,
        activeImage: vendorS4ActiveImage,
        beforeCompletionImage: vendorS4GreyImage,
      },
    ];
  }

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
      vendorError:[]
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  getStepIconComponent = (step: IStepperImageModal) => {
    switch (true) {
      case step.step === this.state.activeStep:
        return () => (
          <img
            src={step.activeImage}
            alt={`Step ${step.step + 1}`}
            data-test-id="active-img"
          />
        );
      case step.step < this.state.activeStep:
        return () => (
          <img
            src={step.completedImage}
            alt={`Step ${step.step + 1}`}
            data-test-id="completed-img"
          />
        );
      default:
        return () => (
          <img
            src={step.beforeCompletionImage}
            alt={`Step ${step.step + 1}`}
            data-test-id="not-completed-img"
          />
        );
    }
  };
  handleFirstStep = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isoCountryName:value !== prevState.businessType ? "pt" : prevState.isoCountryName,
      step2Value:value !== prevState.businessType ? {first_name: "",
      country: "",
      city: "",
      tax_number: "",
      full_phone_number: "",
      zipcode: "",
      address: "",
      country_code: "",
      company_name: "",} : prevState.step2Value ,
      businessType: value,
      vendorSignUpData: {
        ...prevState.vendorSignUpData,
        vendor_type: value,
      }
    }));
  };

  async componentDidMount(): Promise<void> {
    this.getCountryListData();
    this.handleCountryChange(configJSON.defaultCountry);
  }
  getCountryListData = () => {
    const countryWithCities: string[] = require("countries-cities").getCountries();
    const countryList: ICountryModel[] = countryWithCities
      .filter((country: string) => {
        const cities: string[] = require("countries-cities").getCities(country);
        return cities && cities.length > 0;
      })
      .map((country: string) => ({
        label: country,
        value: country,
      }));
    this.setState({ countries: countryList });
  };
  handleCountryChange = (country: string) => {
    const cityList = require("countries-cities")
      .getCities(country)
      .map((city: string) => ({
        label: city,
        value: city,
      }));
    this.setState({
      cities: cityList,
      isoCountryName: getCountryCode(country || "Portugal"),
    });
  };

  handleClickShowPassword = (btnType: string) => {
    if (btnType === "password") {
      this.setState({
        enablePasswordField: !this.state.enablePasswordField,
      });
    } else {
      this.setState({
        enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
      });
    }
  };

  handleCardClick = (item: CardData) => {
    this.setState((prevState) => {
      const { selectedCards } = prevState;
      if (selectedCards.includes(item.label)) {
        return {
          selectedCards: selectedCards.filter((label) => label !== item.label),
        };
      } else {
        return {
          selectedCards: [...selectedCards, item.label],
        };
      }
    });
    this.setState((prevState) => ({
      vendorSignUpData: {
        ...prevState.vendorSignUpData,
        services_type: this.state.selectedCards
      },
    }));
  };
  
  handleStep4 = () => {
    this.setState((prevState) => ({
      vendorSignUpData: {
        ...prevState.vendorSignUpData,
        services_type: this.state.selectedCards,
      },
    }), () => {
      this.vendorSignUpApiCall();  
    });
  };
  
  handleStep2 = (values: Step2Modal) => {
    this.setState({ step2Value: values });
    this.setState((prevState) => ({
      step2Value: values,
      vendorSignUpData: {
        ...prevState.vendorSignUpData,
        first_name:values.first_name,
        country: values.country,
        city: values.city,
        country_code: values.country_code,
        zipcode: values.zipcode,
        phone_number: values.full_phone_number,
        full_phone_number: values.full_phone_number,
        address: values.address,
        tax_number: values.tax_number,
      },
    }));
    this.handleNext();
  };

  handleStep3 = (values: Step3Modal) => {
    this.setState({ step3Value: values });
    setStorageData("vendorEmail",values.email)
    this.setState((prevState) => ({
      step3Value: values,
      vendorSignUpData: {
        ...prevState.vendorSignUpData,
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation
      },
    }));
    this.handleNext();
  };

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = isError ? errors[field] ?? "" : "";
    return { isError, helperText };
  };
  
  renderEndAdornment = (btnType: string, enableField: boolean) => (
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => this.handleClickShowPassword(btnType)}
      edge="end"
    >
      {enableField ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );
  apiCalledVendor = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
        "Content-Type": contentType,
      };
  
    const requestedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    requestedMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestedMessage.id, requestedMessage);
    return requestedMessage.messageId;
  };
  vendorSignUpApiCall = async () => {
    const vendorData = {
      data: {
        type: "email_account",
        role: "vendor",
        attributes: {
          ...this.state.vendorSignUpData,
          activated: true,
        },
      },
    };
    
    this.vendorSignUpApiCallId = await this.apiCalledVendor({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeAddDetail,
      body: vendorData,
      endPoint: configJSON.userSignUpAPIEndPoint,
    });
  };
  handleAddressIsoCountry = (country:string) => {
    this.setState({
      isoCountryName: getCountryCode(country || "Portugal"),
    });
  };

  handleEmptyCountry = () => {
    this.setState((prevState) => ({
      step2Value: {
          ...prevState.step2Value,  
         country:"",
         city:"",
         address:"",
         zipcode:""      
      },
  }));
  }

  getTaxLabel = () => {
    return this.state.businessType === "company" ? "Tax number" : "Individual Tax Number"
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
