import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { EmailIcon, PasswordIcon, defaultUserProfile, imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import { getDeviceLocation } from "../../../components/src/getDeviceLocation.web";
interface ILoginModel {
  data: {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      country_code: string;
      email: string;
      first_name: string;
      full_phone_number: "97517234567";
      last_name: string;
      phone_number: string;
      type: string;
      created_at: string;
      updated_at: string;
      device_id: null;
      unique_auth_id: string;
      nationality: string;
      address: string;
      zipcode: string;
      language: string;
      country: string;
      city: string;
      confirm_email: null;
      vendor_image: null;
      role: {
        id: 1;
        name: string;
        created_at: string;
        updated_at: string;
      };
    };
  };
}
interface ILoginResponseModel {
  account:ILoginModel,
  meta: { token: string; refresh_token: string; message: string };
  errors: { failed_login: string }[];
}
interface ILoggedInResponseModel {
  data:{
    id: string;
    type: string;
    attributes: object
  },
  errors: {token:string}[];
}
interface ILoggedDeviceModel {
  device_name: string;
  device_id: string;
  last_active: string;
  location: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  tabValue:number;
  loginApiError:string;
  deviceLocation:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  loggedInApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      tabValue: 0,
      loginApiError: "",
      deviceLocation: "",
    };
    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
   const deviceLocationName = await getDeviceLocation();
   this.setState({deviceLocation:deviceLocationName})
    if (
      localStorage.getItem("login_email") &&
      localStorage.getItem("login_password")
    ) {
      this.setEmail(localStorage.getItem("login_email") || "");
      this.setPassword(localStorage.getItem("login_password") || "");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  goToVendorSignUpPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Cfentrepriseaccount");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  //handle tab change
  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabValue: Number(newValue) });
  };
  //end tab change
  renderEndAdornment = () => (
    <IconButton
      aria-label="toggle password visibility"
      onClick={this.handleClickShowPassword}
      edge="end"
    >
      {this.state.enablePasswordField ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );

  renderEmailStartAdornment = () => <img src={EmailIcon} />;

  renderPasswordStartAdornment = () => <img src={PasswordIcon} />;

  renderTabStyle = (activeTab: number) => {
    return this.state.tabValue === activeTab
      ? { fontWeight: 700, color: "#6F05EA" }
      : { fontWeight: 500, color: "#B7B7B7" };
  };
  //forgot password
  goToForgotPasswordPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ForgotPassword"
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  //end forgot password
  //login api call
  loginApi = (email: string, password: string) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attr = {
      email: email,
      password: password,
      role: this.state.tabValue === 0 ? "customer" : "vendor",
    };
    
    const loggedInData = {
      type: "email_account",
      attributes: attr,
    };

    const httpBody = {
      data: loggedInData,
    };

    const requestedMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestedMessaged.messageId;
    requestedMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userLoginEndPoint
    );

    requestedMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestedMessaged.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestedMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestedMessaged.id, requestedMessaged);
  };
  //end login api call
  //login api response handle call
  //end login api response handle call
  handleResponseJsonData = (apiRequestCallId: string, responseJson: ILoginResponseModel & ILoggedInResponseModel) => {
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.loginAPIResponseHandle(responseJson);
    }
    if (apiRequestCallId === this.loggedInApiCallId) {
      this.loggedInDeviceAPIResponseHandle(responseJson);
    }
  };
  loginAPIResponseHandle(responseJson: ILoginResponseModel) {
    //Check Error Response
    if (
      responseJson &&
      responseJson.errors &&
      responseJson?.errors[0]?.failed_login
    ) {
      this.setState({ loginApiError: responseJson?.errors[0]?.failed_login });
    } else {
      sessionStorage.setItem("login_token", responseJson.meta.token);
      sessionStorage.setItem("login_user_name", this.state.email);
      sessionStorage.setItem("login_user_id", responseJson.account.data.id);
      sessionStorage.setItem(
        "login_refresh_token",
        responseJson.meta.refresh_token
      );
      localStorage.setItem("login_token", responseJson.meta.token);
      localStorage.setItem("login_user_name", responseJson.account.data.attributes.first_name);
      localStorage.setItem("login_user_id", responseJson.account.data.id);
      localStorage.setItem("login_refresh_token",responseJson.meta.refresh_token);
      localStorage.setItem("isUserLogin","true");
      localStorage.setItem("login_user_role",responseJson.account.data.attributes.role.name);
      localStorage.setItem("login_email", responseJson.account.data.attributes.email);
      localStorage.setItem("login_user_profile", responseJson.account.data.attributes.vendor_image ? responseJson.account.data.attributes.vendor_image : defaultUserProfile);
      this.setState({ loginApiError: "" });
      if(responseJson.account.data.attributes.role.name.toLowerCase() === "customer"){
        this.goToLoginPage();
      }
      else{
        this.goToVendorSignUpPage();
      }
      if (this.state.checkedRememberMe) {
        localStorage.setItem("login_password", this.state.password);
        localStorage.setItem("login_email", this.state.email);
      }
      const uuidv4 = require("uuid/v4");
      const device_uuid = uuidv4();
      const deviceInfo = {
        device_name: this.getOSFromUserAgent(navigator.userAgent),
        last_active: new Date(Date.now()).toISOString(),
        location: this.state.deviceLocation,
        device_id:device_uuid,
      };
      localStorage.setItem('device_uuid', device_uuid);
      this.loggedInDeviceApi(deviceInfo);
      
    }
  }
  loggedInDeviceAPIResponseHandle = (responseJson: ILoggedInResponseModel) => {
    if (responseJson.errors) {
      if (responseJson?.errors[0]?.token === "Invalid token") {
        this.goToLoginPage();
      }
    }
  };
  //navigate to register

  getOSFromUserAgent = (userAgent: string) => {
    switch (true) {
      case userAgent.includes("Win"):
        return "Windows";
      case userAgent.includes("Mac"):
        return "MacOS";
      case userAgent.includes("X11"):
      case userAgent.includes("Linux"):
        return "Linux";
      case userAgent.includes("Android"):
        return "Android";
      case  userAgent.includes("iphone") || userAgent.includes("ipad"):
        return "iOS";
      default:
        return "Unknown OS";
    }
  };

  goToRegisterPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegistration"
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  goToLoginPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  //end navigate to register
  loggedInDeviceApi(values: ILoggedDeviceModel) {
    const headerData = {
      "Content-Type": configJSON.loginApiContentType,
      token: localStorage.getItem("login_token"),
    };

    const attr = {
      device_name: values.device_name,
      device_id: values.device_id,
      last_active: values.last_active,
      location: values.location,
    };

    const httpBodyData = {
      sessions: attr,
    };

    const requestedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loggedInApiCallId = requestedMessage.messageId;

    requestedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedInSessionEndPoint
    );

    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBodyData)
    );

    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestedMessage.id, requestedMessage);
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
        this.handleResponseJsonData(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
