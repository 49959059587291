export function getCurrentLocation(): Promise<{ latitude: number, longitude: number }> {
    return new Promise((resolve, reject) => {
      function promptForLocationPermission() {
        return new Promise((resolve, reject) => {
          const permission = confirm("This website would like to access your current location. Would you like to allow it?");
          if (permission) {
            resolve(true); 
          } else {
            reject(false); 
          }
        });
      }
  
      const successCallback = (position: GeolocationPosition) => {
        if (position && position.coords) {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude }); 
        } else {
          reject(new Error('Geolocation data is not available.'));
        }
      };
  
      const errorCallback = (error: any) => {
        reject(new Error('Error getting geolocation'));
      };
  
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
          if (permissionStatus.state === 'granted') {
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
          } else if (permissionStatus.state === 'prompt') {
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
          } else {
            reject(new Error('Geolocation permission denied.'));
          }
        }).catch((error) => {
          reject(new Error('Error checking geolocation permission'));
        });
      } else {
        reject(new Error('Geolocation permissions API not supported.'));
      }
    });
  }