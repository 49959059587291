import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { SignUpModal, CatDogFormModal } from "./EmailAccountRegistration.web";
import { getCountryCode } from 'countries-list'
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";

interface PetModel {
  pet: {
    petName: string;
    breed: string | null;
    size: string | null;
    weight: string | null;
    fur: string | null;
    gender: string | null;
    birthDate: string;
    pet_category: string | null;
    name: string | null;
    account_id: string | null;
    species: string | null;
  };
}
export interface ICountryModel {
  label: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  stepNumber: number;
  selectedPetName: string;
  isBackDropOpen: boolean;
  selectedSubPetName: string;
  isModalOpen: boolean;
  birthDate: Date | null;
  isdatPickerOpen: boolean;
  account_id: string;
  petSignUpToken: string;
  signUpError: string;
  countries: {
    label: string;
    value: string | number | undefined;
  }[];
  cities: {
    label: string;
    value: string | number | undefined;
  }[];
  isoCountryName: string | boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  userSignUpApiCallId: string = "";
  petSignUpApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      stepNumber: 1,
      selectedPetName: "",
      isBackDropOpen: false,
      selectedSubPetName: "",
      isModalOpen: false,
      birthDate: new Date(Date.now()),
      isdatPickerOpen: false,
      account_id: "",
      petSignUpToken: "",
      signUpError: "",
      countries :[],
      cities:[],
      isoCountryName:"",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.validationApiCallId:
           this.handleValidationResponse(responseJson)
            break;
          case this.createAccountApiCallId:
            this.handleCreateAccountResponse(responseJson);
            this.parseApiCatchErrorResponse(errorReponse);
            break;
          case this.userSignUpApiCallId:
            this.handleUserSignUpResponse(responseJson);
            break;
          case this.petSignUpApiCallId:
            this.handlePetSignUpResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigation(message)
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.handleCountryCode(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getCountryList()
    this.handleCountryChange(configJSON.defaultCountry)
  }
  getCountryList = () => {
    const countriesWithCities: string[] = require('countries-cities').getCountries();
    const countryList: ICountryModel[] = countriesWithCities
        .filter((country: string) => {
            const cities: string[] = require('countries-cities').getCities(country);
            return cities && cities.length > 0; // Check if cities exist for the country
        })
        .map((country: string) => ({
            label: country,
            value: country,
        }));
    this.setState({ countries: countryList });
  }
  handleCountryChange = (country:string) => {
    const cityList = require ('countries-cities').getCities(country).map((city:string) => ({
      label: city,
      value: city,
    }));
    this.setState({ cities: cityList, isoCountryName: getCountryCode(country || "Portugal")});
  }
  handleNavigation = (message:Message) => {
    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }
  handleCountryCode = (message:Message) => {
    let selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode,
      });
    }
  }
  handleCreateAccountResponse(responseJson:any){
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(msg);
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleValidationResponse(responseJson:any) {
    this.arrayholder = responseJson.data;

            if (this.arrayholder && this.arrayholder.length !== 0) {
              let regexData = this.arrayholder[0];
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.password_validation_rules) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules,
                });
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }
            }
  }

  handleUserSignUpResponse(responseJson: any) {
    this.setState({ signUpError: "" });
    if (responseJson.errors && responseJson?.errors[0]) {
      this.setState({ signUpError: responseJson?.errors[0].account });
    } else {
      this.setState({
        stepNumber: 2,
        account_id: responseJson.data.id,
        petSignUpToken: responseJson.meta.token,
      });
      sessionStorage.setItem("user_sign-up_token", responseJson.meta.token);
    }
  }

  handlePetSignUpResponse(responseJson: any) {
    if (responseJson.errors && responseJson?.errors[0]) {
      this.setState({ signUpError: responseJson?.errors[0].token || "Something went to wrong" });
    }
    else{
      const userNavMsg: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      userNavMsg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmailAccountLoginBlock"
      );
      userNavMsg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(userNavMsg);
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        "+" + this.state.countryCodeSelected + this.state.phone,
    };

    const registerData = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: registerData,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  handleClickShowPassword = (btnType: string) => {
    if (btnType === "password") {
      this.setState({
        enablePasswordField: !this.state.enablePasswordField,
      });
    } else {
      this.setState({
        enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
      });
    }
  };

  handleClickBackDrop = () => {
    this.setState({ isBackDropOpen: false });
  };

  handleClickPetSelect = (petType: string) => {
    this.setState({ selectedPetName: petType });
    if (petType === "dog" || petType === "cat") {
      this.setState({ selectedSubPetName: "" });
    }
  };
 
  handleOpenModel = () => {
    this.setState({ isModalOpen: true });
  };
  handleCloseModel = () => {
    this.setState({ isModalOpen: false });
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  userSignUp = async (value: SignUpModal) => {
    const userData = {
      data: {
        type: "email_account",
        role: "customer",
        attributes: {
          ...value,
          first_name: value.user_name,
          last_name: "last-name",
          activated: true,
        },
      },
    };
    this.userSignUpApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeAddDetail,
      endPoint: configJSON.userSignUpAPIEndPoint,
      body: userData,
    });
  };

  petSignUp = async (value: CatDogFormModal) => {
    const petInfoData: PetModel = {
      pet: {
        ...value,
        pet_category: this.state.selectedPetName === "other"
          ? this.state.selectedSubPetName
          : this.state.selectedPetName,
        account_id: this.state.account_id,
        petName: value.name,
        birthDate: value.birthdate
      },
    };
    this.petSignUpApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeAddDetail,
      body: petInfoData,
      token: this.state.petSignUpToken,
      endPoint: configJSON.petSignUpAPIEndPoint,
    });
  };

  getPetTypographyStyle = (selectedPetName: string, type?: string, mediaQuery?:string, isRabbit?:boolean ) => {
    const selectPetName =
      type === "subpet"
        ? this.state.selectedSubPetName
        : this.state.selectedPetName;
    const color = selectPetName === selectedPetName ? "#000000" : "";
    const fontWeight = selectPetName === selectedPetName ? "600" : "";
    let width, textOverflow, overflow, whiteSpace = ""; 
    if(mediaQuery === "mediaQuery" && isRabbit){
      if (window.matchMedia("(max-width: 1400px)").matches) {
        width = "70px";
        textOverflow = "ellipsis";
        overflow = "hidden";
        whiteSpace = "nowrap";
      }
    }
    return { color, fontWeight, width, textOverflow, overflow, whiteSpace};
  };
  
  renderEndAdornment = (btnType: string, enableField: boolean) => (
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => this.handleClickShowPassword(btnType)}
      edge="end"
    >
      {enableField ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );
  // Customizable Area End
}
